<template>
  <div
    :class="{ mobilePage: isMobile, appPage: isApp }"
    class="home-wrapper scrollContainer"
    ref="scrollContainer"
  >
    <web-header v-if="!isApp" :disabledScroll="true" />
    <div :class="{ pcPage: !isMobile }">
      <!-- 考卷选择列表 -->
      <div class="webPage pageDet">
        <div v-if="!this.examPaper">
          <div style="font-size: 14px;font-weight: 400; margin-top: 25px; margin-bottom: 13px">通过以下任一测试，即可解锁内容发布及音乐周榜投票权限</div>
          <div class="exam_paper_list">
            <div v-for="(item, idx) in examPaperList" :key="idx" class="exam_paper">
              <div class="examPaperName">{{ item.name }}</div>
              <div class="examPaperContent">
                <img style="position:absolute; margin-left: 19px;" src="@/assets/style/exam_total@2x.png" alt="" width="11px"/>
                <span style="position:absolute; margin-left: 33px;">{{ item.total }}题</span>
                <img style="position:absolute; margin-left: 19px; margin-top: 20px" src="@/assets/style/exam_paper_clock@2x.png" alt="" width="11px" />
                <span style="position:absolute; margin-left: 33px; margin-top: 20px">{{ item.duration }}分钟</span>
                <div class="examPaperStartText" @click="clickStartExam(item)">开始</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebHeader from "@/components/WebHeader.vue";
import { mapGetters } from "vuex";
import { isAppFun } from "@/utils/judgeClient";
import dayjs from "dayjs";
import request from "@/utils/request";
import { openUrl } from "@/utils/navigate";
export default {
  components: {
    WebHeader,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        firstDayOfWeek: 1,
      },
      dateValue: dayjs().format("YYYY-MM-DD"),
      isApp: false,
      sortHoverShow: false,
      examPaperList: [],
      examPaper: null,
      correctNum: 0,
      errorNum: 0,
      resultWarnDialog: false,
      examTimeoutDialog: false,
      examFailDialog: false,
      examPassDialog: false,
      duration: 0,
      remainTime: "0:00",
    };
  },
  computed: {
    ...mapGetters(["isLogin"]),
  },
  watch: {
    isLogin: {
      immediate: true,
      handler(val, oldVal) {
        // 从false变成true, 拉一下活动信息查询
        if (!oldVal && val) {
          //this.getVoteFilter();
        }
      },
    },
  },
  async created() {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      // 当前设备是移动设备
      this.isApp = isAppFun();
    }
    this.getInitExamPaperList();
  },
  methods: {
    handleOpenUrl(urlStr) {
      openUrl(urlStr, this.isApp);
    },
    // 获取入门试卷
    async getInitExamPaperList() {
      const examPaperListRes = await request.get("/dp/exam/initExamPaperList", {});
      if (examPaperListRes.code == 200) {
        if(examPaperListRes.msg.startsWith("已通过")) {
          this.$message({
            message: "已通过入门测试，即将在3秒后返回首页",
          });
          setTimeout(() => {
            this.$router.push('/');
          }, 3000)
        }
        this.examPaperList = examPaperListRes.data;
      }
    },
    // 点击开始考试
    async clickStartExam(item){
      this.$router.push({
        name: "examSub",
        query: {
          examPaperId: item.id,
        },
      });
    },
  },
};
</script>

<style scoped>
.pageDet {
  padding: 0px;
}
.exam_paper_list {
    font-size: 20px;
    font-weight: 400;
    text-align: CENTER;
    line-height: 30px;

    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    /* flex-direction: column; */
    width: 858px;
}
.exam_paper {
  /* display: flex;
  flex-wrap: wrap; */
  width: 262px;
  height: 97px;
  background: #1f1f1f;
  border-radius: 4px;
  box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.10);
  margin: 12px;
  position: relative;
}
.examPaperName {
  font-size: 16px;
  font-weight: 500;
  text-align: LEFT;
  color: #ffffff;
  line-height: 16px;
  padding: 16px 19px;
}
.examPaperContent {
  height: 49px;
  position: relative;
  font-size: 12px;
  font-weight: 400;
  text-align: LEFT;
  color: #999999;
  line-height: 12px;
}
.examPaperStartText {
  width: 57px;
  height: 24px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #9c5cf7;
  line-height: 5px;
  border: 0.8px solid #9c5cf7;
  border-radius: 37px;
  padding: 9px 12px;
  box-sizing: border-box;
  position: absolute;
  right: 16px;
  bottom: 16px;
  cursor: pointer;
}

/* 下面是移动端 */
.mobilePage .exam_paper_list {
  width: 100%;
  justify-content: center;
}
.mobilePage .exam_paper {
  width: 328px;
}
.mobilePage .optionDet {
  height: auto;
  margin-right: 0px;
  padding: 9px 30px 9px 20px;
}
.m_option_text {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  flex: 1;
  align-items: center;
}
.mobilePage .examResult {
  margin: 20px;
  position:absolute;
  right: 0;
}
</style>

