<template>
  <el-dialog
    title=""
    class="musicDetDialog"
    :visible="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @close="handleClose"
  >
    <div
      class="greyShade"
      :style="{ 'background-image': `url(${item.picture})` }"
    >
      <div class="linearShade greyShade">
        <div class="greyShade">
          <div class="musicDialogHead">
            <div class="musicCover">
              <img class="imgCut" :src="item.picture" alt="" />
            </div>
            <div class="musicInfo">
              <div class="musicName textOverflow">{{ item.name }}</div>
              <div class="musicArtist textOverflow">{{ item.artist }}</div>
              <div>发行日期：{{ item.publishDate }}</div>
              <div>类型：{{ musicTypeMap[item.type] }}</div>
              <div class="musicLinkList">
                <img
                  v-for="(musicLinkItem, idx) in item.relatedLinkList"
                  :key="idx"
                  class="musicLinkItem"
                  @click.stop="handleOpenUrl(musicLinkItem.url)"
                  :src="musicLinkItem.logo"
                />
              </div>
              <div style="display: flex; margin-top: 12px">
                <div class="voteNum">已获{{ item.voteCount }}增益</div>
                <div
                  v-if="flag == 1"
                  class="voteBtn"
                  @click.stop="clickVote(item)"
                >
                  增益
                </div>
              </div>
            </div>
          </div>
          <div class="devider"></div>
          <el-table :data="nominateLogList" height="232">
            <el-table-column label="提名人" min-width="30%">
              <template slot-scope="scope">
                <div class="nominateName">
                  <img class="avatar" :src="scope.row.member.avatar" alt="" />
                  <div class="nickname">{{ scope.row.member.nickname }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              property="createTime"
              label="提名时间"
              min-width="32%"
              :formatter="formatCreateTime"
            ></el-table-column>
            <el-table-column label="提名信息" min-width="38%">
              <template slot-scope="scope">
                <div
                  v-if="
                    (!scope.row.brandList || !scope.row.brandList.length) &&
                    (!scope.row.styleList || !scope.row.styleList.length) &&
                    !scope.row.bpm &&
                    !scope.row.mode
                  "
                >
                  -
                </div>
                <div v-else>
                  <div v-if="scope.row.brandList && scope.row.brandList.length">
                    <span>厂牌：</span>
                    <span
                      v-for="(brandItem, idx) in scope.row.brandList"
                      :key="idx"
                      :class="{ encyText: brandItem.mold == 1 }"
                      @click="toEncy(brandItem)"
                    >
                      {{brandItem.text
                      }}{{ idx === scope.row.brandList.length - 1 ? "" : "," }}
                    </span>
                  </div>
                  <div v-if="scope.row.styleList && scope.row.styleList.length">
                    <span>风格/标签：</span>
                    <span
                      v-for="(styleItem, idx) in scope.row.styleList"
                      :key="idx"
                      :class="{ encyText: styleItem.mold == 1 }"
                      @click="toEncy(styleItem)"
                      >{{ styleItem.text
                      }}{{ idx === scope.row.styleList.length - 1 ? "" : "," }}
                    </span>
                  </div>
                  <div v-if="scope.row.bpm">曲速：{{ scope.row.bpm }} BPM</div>
                  <div v-if="scope.row.mode">
                    调式：{{
                      modeList.find((item) => item.value == scope.row.mode)
                        .label
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { isAppFun, isIOS, isAndroid } from "@/utils/judgeClient";
import { openUrl } from "@/utils/navigate";
import request from "@/utils/request";
import dayjs from "dayjs";
import { openEncy } from "@/utils/navigate";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      voteSuccess: false,
      isApp: false,
      musicDialogVisible: false,
      nominateLogList: [],
      musicTypeMap: {
        song: "单曲",
        album: "专辑",
      },
      modeList: [
        {
          label: "A Major",
          value: "A1",
        },
        {
          label: "A Minor",
          value: "A2",
        },
        {
          label: "A# Major",
          value: "A3",
        },
        {
          label: "A# Minor",
          value: "A4",
        },
        {
          label: "Ab Major",
          value: "A5",
        },
        {
          label: "Ab Minor",
          value: "A6",
        },
        {
          label: "B Major",
          value: "B1",
        },
        {
          label: "B Minor",
          value: "B2",
        },
        {
          label: "Bb Major",
          value: "B5",
        },
        {
          label: "Bb Minor",
          value: "B6",
        },
        {
          label: "C Major",
          value: "C1",
        },
        {
          label: "C Minor",
          value: "C2",
        },
        {
          label: "C# Major",
          value: "C3",
        },
        {
          label: "C# Minor",
          value: "C4",
        },
        {
          label: "D Major",
          value: "D1",
        },
        {
          label: "D Minor",
          value: "D2",
        },
        {
          label: "D# Major",
          value: "D3",
        },
        {
          label: "D# Minor",
          value: "D4",
        },
        {
          label: "Db Major",
          value: "D5",
        },
        {
          label: "Db Minor",
          value: "D6",
        },
        {
          label: "E Major",
          value: "E1",
        },
        {
          label: "E Minor",
          value: "E2",
        },
        {
          label: "Eb Major",
          value: "E5",
        },
        {
          label: "Eb Minor",
          value: "E6",
        },
        {
          label: "F Major",
          value: "F1",
        },
        {
          label: "F Minor",
          value: "F2",
        },
        {
          label: "F# Major",
          value: "F3",
        },
        {
          label: "F# Minor",
          value: "F4",
        },
        {
          label: "G Major",
          value: "G1",
        },
        {
          label: "G Minor",
          value: "G2",
        },
        {
          label: "G# Major",
          value: "G3",
        },
        {
          label: "G# Minor",
          value: "G4",
        },
        {
          label: "Gb Major",
          value: "G5",
        },
        {
          label: "Gb Minor",
          value: "G6",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["isLogin"]),
  },
  props: ["item", "flag", "voteId"],
  methods: {
    formatCreateTime(row, column, cellValue) {
      return dayjs(cellValue).format("YYYY-MM-DD HH:mm");
    },
    handleOpenUrl(urlStr) {
      openUrl(urlStr, this.isApp);
    },
    async getNominateLogList(id) {
      const nominateLogListRes = await request.get("/dp/vote/nominateLogList", {
        params: {
          tdId: id,
          type: 1,
        },
      });
      if (nominateLogListRes.code == 200) {
        this.nominateLogList = nominateLogListRes.data.slice();
      }
    },
    toEncy(item) {
      if (item.mold == 0) {
        return;
      }
      if (this.isApp) {
        try {
          if (isAndroid())
            window.Android.skipEncyclopedia(JSON.stringify(item));
          if (isIOS())
            window.webkit.messageHandlers.skipEncyclopedia.postMessage(item);
        } catch (error) {
          console.log(error);
        }
        console.log(item);
      } else openEncy(item.typeId, item.dataId);
    },
    async clickVote(voteItem) {
      if (!this.isLogin) {
        this.$login();
        return;
      }
      const voteRes = await request.post("/dp/vote/musicVote", {
        voteId: this.voteId,
        vsId: voteItem.vsId,
      });
      if (voteRes.code == 200) {
        voteItem.voteCount++;
        this.voteSuccess = true;
        this.$emit("voteHandler", this.voteSuccess);
        this.$message({
          message: "增益成功",
          type: "success",
        });
      }
    },
    handleClose() {
      this.$emit('close')
    }
  },
  async created() {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      // 当前设备是移动设备
      this.isApp = isAppFun();
    }
    this.getNominateLogList(this.item.id);
  },
  // watch: {
  //   musicDialogVisible: {
  //     immediate: true,
  //     handler(val, oldVal) {
  //       // 从false变成true, 拉一下活动信息查询
  //       if (!oldVal && val) {
  //         this.getNominateLogList(this.item.id);
  //       } else {
  //         this.nominateLogList = []
  //       }
  //     },
  //   },
  // },
};
</script>

<style scoped>
.voteBtn {
  margin: 0 10px 0 auto;
}
.musicInfo {
  width: 310px;
}
</style>