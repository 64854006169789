/* eslint-disable no-useless-escape */
//这里区分不同系统
import qs from 'qs'
const ua = typeof window === "object" ? window.navigator.userAgent : "";

export function isIOS() {
  return /(iPhone|iPad|iPod|iOS)/i.test(ua); //ios终端
}
export function isAndroid() {
  return ua.indexOf("Android") > -1 || ua.indexOf("Adr") > -1;
}


/**
 * 判断是否在微信打开
 * @returns
 */
export function isWx() {
  return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
}

/**
 * 判断是否在QQ打开
 * @returns
 */
export function isQQ() {
  return navigator.userAgent.match(/QQ/i);
}

/**
 * 根据有没有方法 来判断是否 客户端中
 * @returns
 */
export function isAppFun() {
  let flag = false;
  try {
    if (isIOS()) {
      window.webkit.messageHandlers.getClipboardData.postMessage(null);
      flag = true;
    }
    if (isAndroid()) {
      window.Android.getClipboardData();
      flag = true;
    }
  } catch (error) {
    console.log(error.message);
    flag = false;
  }
  return flag;
}

/** 打开app的参数拼接，得到一个跳转地址，已兼容ios和安卓
 * 注意iOS的QQ环境，要单独用a标签写跳转，不能用window.location.href等方法
 *
 * @param {*} params
 */
export function getOpenWithAppScheme(params) {
  let scheme = "dropin://" + (params || "main");
  if (isIOS()) {
    scheme =
      (isQQ() ? "com.hearin.dropin://" : "https://dropin.hearinmusic.com/") +
      (params || "");
  }
  return scheme;
}

/**
 * H5 页面打开 app的跳转方法，已兼容iOS和安卓（但iOS的QQ环境，仍要单独用a标签写跳转）
 */
export function openWithApp(params) {
  let downloadURL = "https://dropin.hearinmusic.com";
  if (isIOS()) {
    downloadURL = "https://dropin.hearinmusic.com"; //"tms-apps://itunes.apple.com/cn/app/";
  }
  const scheme = getOpenWithAppScheme(params);

  console.log("打开APP:" + scheme);
  window.location.href = scheme;
  setTimeout(function () {
    let hidden =
      window.document.hidden ||
      window.document.mozHidden ||
      window.document.msHidden ||
      window.document.webkitHidden;
    if (typeof hidden == "undefined" || hidden == false) {
      window.location.href = downloadURL;
    }
  }, 2000);
}

export function setExtinfo(type) {
    if (type == "ency") {
        let url = encodeURIComponent(
            `${window.location.origin}/#/ency?dataId=${this.param.dataId}&typeId=${this.param.typeId}`
          );
          let params = {
            id: this.vo.id,
            dataId: this.vo.dataId,
            typeId: this.vo.typeId,
            name: encodeURIComponent(this.vo.name),
          };
          this.extinfo = `ency?${qs.stringify(params)}&url=${url}`;
    }
    else if (type == "signal") {
        let params = {
            id: this.id,
            memberId: this.vo.memberId,
          }
          this.extinfo = `post?${qs.stringify(params)}`;
    }
    
  }


/**
 * 获取字符串中的 url
 * @param {*} s
 * @returns
 */
export function httpString(s) {
  //var reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
  var reg =
    /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
  //var reg= /^((ht|f)tps?):\/\/[\w\-]+(\.[\w\-]+)+([\w\-\.,@?^=%&:\/~\+#]*[\w\-\@?^=%&\/~\+#])?$/;
  //v = v.replace(reg, "<a href='$1$2'>$1$2</a>"); //这里的reg就是上面的正则表达式
  //s = s.replace(reg, "$1$2"); //这里的reg就是上面的正则表达式
  s = s.match(reg);
  return s;
}


export function IsURL(str_url) {
  // var strRegex = "^((https|http|ftp|rtsp|mms)?://)"
  //  + "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" //ftp的user@
  //     + "(([0-9]{1,3}\.){3}[0-9]{1,3}" // IP形式的URL- 199.194.52.184
  //     + "|" // 允许IP和DOMAIN（域名）
  //     + "([0-9a-z_!~*'()-]+\.)*" // 域名- www.
  //     + "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\." // 二级域名
  //     + "[a-z]{2,6})" // first level domain- .com or .museum
  //     + "(:[0-9]{1,4})?" // 端口- :80
  //     + "((/?)|" // a slash isn't required if there is no file name
  //     + "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$";
  let strRegex =
    /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/i;
  var re = new RegExp(strRegex);
  //re.test()
  if (re.test(str_url)) {
    return true;
  } else {
    return false;
  }
}

export function safeOpenUrl(urlStr) {
  if (!urlStr) return;
  let url = urlStr;
  try {
    if (
      urlStr.toLowerCase().indexOf("http://") !== 0 &&
      urlStr.toLowerCase().indexOf("https://") !== 0 &&
      urlStr.toLowerCase().indexOf("file://") !== 0 &&
      urlStr.toLowerCase().indexOf("ftp://") !== 0
    ) {
      url = `http://${url}`;
    }
    if (isAppFun()) {
      let local = process.env.VUE_APP_SECURITY_URL;
      url = `${local}?url=${encodeURIComponent(url)}&newWebPage=true`;
    }
    window.location.href = url;
  } catch (err) {
    console.log(err, err.message, err.msg);
  }
}

/**
 * 判断是否是 json字符串
 * @param {*} str
 * @returns
 */
export function isJSON(str) {
  if (typeof str == "string") {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  } else {
    return false;
  }
}
