<template>
  <div class="inline">
    <template v-for="(item, index) in list">
      <a
        v-if="item.mold == 1"
        class="link-a"
        :key="index"
        @click="toEncy(item)"
        >{{ item.text }}</a
      >
      <span v-else :key="index">{{ item.text }}</span>
      <template v-if="list.length != index + 1">,&nbsp;</template>
    </template>
  </div>
</template>

<script>
import { openEncy } from "@/utils/navigate";
export default {
  name: "DetList",
  components: {},
  props: {
    list: {
      type: Array,
    },
  },
  data: () => ({}),
  computed: {},
  created() {},
  mounted() {},
  methods: {
    //跳转百科
    toEncy(target) {
      openEncy(target.typeId, target.dataId);
    },
  },
  watch: {},
};
</script>
<style scoped>
.link-a {
  color: #9C5CF7;
  cursor: pointer;
}
.inline {
  display: inline;
  vertical-align: top;
}
</style>