<template>
  <div class="home-wrapper" :class="{mobilePage : isMobile}">
    <!-- 以下是头部板块 -->
    <web-header :disabledScroll="true" />

    <!-- 以下是内容部分 -->
    <div class="secondary">
      <div v-infinite-scroll="loadmore">
        <div class="loading" v-if="loading">
              <img class="loadingImg" src="@/assets/style/loading.gif" alt="" />
            </div>
        <div
          v-for="(dateNews, dateIdx) in newsListData.newsList"
          :key="dateIdx"
        >
          <div class="date" @click="collapse(dateNews)">
            <div class="dateText">{{ dateNews.date }}</div>
            <template v-if="dateNews.esArticleVos.length">
              <div class="foldDiv">
                <img
                  class="fold"
                  src="@/assets/style/icon_more@2x.png"
                  alt=""
                  :class="{
                    unfold: !dateNews.collapse,
                  }"
                />
                <img
                  class="fold foldHover"
                  src="@/assets/style/btn_more@2x.png"
                  alt=""
                  :class="{
                    unfold: !dateNews.collapse,
                  }"
                />
              </div>
            </template>
          </div>
          <template v-if="dateNews.esArticleVos.length && !dateNews.collapse">
            <div class="articleList">
              <div
                class="newsItem"
                v-for="(newsItem, newsIdx) in dateNews.esArticleVos"
                :key="newsIdx"
                @click="toSignal(newsItem.esArticle.id)"
              >
                <div class="newsInfo">
                  <div class="newInfoText hasPic">
                    <div class="newsTitle textOverflow">
                      {{ newsItem.esArticle.title }}
                    </div>
                    <div class="newsDescrip textOverflow">
                      {{ newsItem.esArticle.descriptions }}
                    </div>
                  </div>
                  <div class="infoPhoto">
                    <img
                      class="imgCut"
                      :src="newsItem.esArticle.picture"
                      alt=""
                    />
                  </div>
                </div>
                <div class="articlTopic">
                  <div
                    class="topicItem"
                    v-for="(topicItem, topicIdx) in newsItem.channelList"
                    :key="topicIdx"
                    @click="toTopic(topicItem.id)"
                  >
                    <img
                      class="topicIcon"
                      src="@/assets/style/icon_topic@2x.png"
                      alt=""
                    />
                    <div class="topicText">{{ topicItem.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="!dateNews.esArticleVos.length">
            <div class="newsEmpty">暂无资讯</div>
          </template>
        </div>
        <div class="loadmore loading" :style="{ visibility: loadingMore ? 'visible' : 'hidden' }">
          <img class="loadingImg" src="@/assets/style/loading.gif" alt="" />
        </div>
      </div>
    </div>
    <!-- 二级页面的网站信息 -->
    <SecondFooter />
    <template>
      <el-backtop target=".home-wrapper" :bottom="100" :visibility-height="100">
        <div class="backTop">UP</div>
      </el-backtop>
    </template>
  </div>
</template>

<script>
import WebHeader from "@/components/WebHeader";
import SecondFooter from "@/components/SecondFooter";
import request from "@/utils/request";
import { openSignal, openTopic } from "@/utils/navigate";

export default {
  components: {
    WebHeader,
    SecondFooter,
  },
  data() {
    return {
      articles: [],
      tab: "",
      loading: true,
      newsListData: [],
      newsDateData: [],
      loadingMore: false,
      moreNewsListData: [],
      pastDate: "",
      oldDate: "",
      showLoadmoreTimer: null,
      disableLoadmore: false
    };
  },
  methods: {
    toSignal(signalId) {
      openSignal(signalId);
    },
    toTopic(topicId) {
      openTopic(topicId);
    },
    formatNewsList(list,isFirstPage) {
      return list.map((item, idx) => {
        return Object.assign({}, item, {
          // 第一页默认展开前3个
          collapse: isFirstPage ? idx > 2 : true,
        });
      });
    },
    collapse(dateNews) {
      if (dateNews.collapse) {
        dateNews.collapse = false;
      } else dateNews.collapse = true;
    },
    async loadmore() {
      // 正在拉取或者初始数据都没有的时候，不触发滚动加载
      if (this.loadingMore || !this.newsListData.pastDate || this.newsDateData.oldDate >= this.pastDate) {
        return;
      }
      this.loadingMore = true;
      // this.pastDate = this.newsListData.pastDate;
      // console.log("🌟 this.newsListData.pastDate", this.newsListData.pastDate);
      try {
        const newsListRes = await request.get("/dp/article/getNewsForDay", {
          params: {
            orderType: "NEW_CREATE",
            oldDate: this.newsDateData.oldDate, //这个固定是这个
            newDate: this.newsDateData.newDate, //这个固定是这个
            endDate: this.pastDate,
          },
        });
        this.moreNewsListData = newsListRes.data;
        this.pastDate = this.moreNewsListData.pastDate;
        this.moreNewsListData.newsList = this.formatNewsList(
          this.moreNewsListData.newsList
        );
        this.newsListData.newsList = this.newsListData.newsList.concat(
          this.moreNewsListData.newsList
        );
      } catch (err) {
        // 异常处理逻辑, 先将错误信息打印出来
        console.error('loadmore fail: ', err)
      } finally {
        this.loadingMore = false;
      }
    },
  },
  async created() {
    document.title = "资讯中心 - DROPIN多频";

    this.loading = true;

    // 初始资讯列表数据
    const dailyNewsRes = await request.get("/dp/article/getNewsForDay", {
      params: {
        orderType: "NEW_CREATE",
        defaultNum: 0,
      },
    });
    this.newsDateData = dailyNewsRes.data;
    console.log("🌟 this.newsDateData", this.newsDateData);

    const newsListRes = await request.get("/dp/article/getNewsForDay", {
      params: {
        orderType: "NEW_CREATE",
        oldDate: this.newsDateData.oldDate, //这个固定是这个
        newDate: this.newsDateData.newDate, //这个固定是这个
        endDate: this.newsDateData.newDate,
      },
    });
    this.loading = false;
    this.newsListData = newsListRes.data;
    this.newsListData.newsList = this.formatNewsList(
      this.newsListData.newsList,
      true,
    );
    this.pastDate = this.newsListData.pastDate;
      console.log("🌟 this.newsListData.pastDate", this.newsListData.pastDate);
    console.log("🌟 this.newsListData", this.newsListData);
  },
};
</script>

<style scoped>
.newsEmpty {
  margin: 12px 0 0 32px;
}
/* 移动端适配样式 */
.mobilePage .date {
  width: 100%;
  min-width: 97vw;
}
.mobilePage .newInfoText.hasPic {
  width: 70%;
}
.mobilePage .newInfoText {
  width: 100%;
}
.mobilePage .infoPhoto {
  width: 90px;
  height: 60px;
}
.mobilePage .newsTitle {
  font-size: 16px;
  line-height: 26px;
}
</style>