<template>
  <div class="home-wrapper" :class="{mobilePage : isMobile}">
    <web-header>
      <!-- 里面放滚动切换的内容 -->
      <div class="headSticky scrollSticky">
        <div class="head">
          <div class="headLeft">
            <div id="logo" @click="selectMenuItem()" v-if="!isMobile">
              <img class="imgCut" src="@/assets/style/DPlogo.png" alt="" />
            </div>
            <div class="detailTitle textOverflow scrollSticky">
              {{ specSubDetInfo.name }}
            </div>
          </div>
          <div class="headRight" v-if="!isMobile"></div>
        </div>
      </div>
    </web-header>
    <div class="secondary">
      <div class="specSubHeader">
        <div class="specSubHeaderTitle">{{ specSubDetInfo.name }}</div>
        <div class="specSubHeaderDes textOverflow">
          {{ specSubDetInfo.descriptions }}
        </div>
      </div>
      <div class="loading" v-if="loading">
        <img class="loadingImg" src="@/assets/style/loading.gif" alt="" />
      </div>
      <div
        v-if="specSubDetList.length"
        class="specSubDet"
        v-infinite-scroll="loadmore"
      >
        <div v-for="(subDetItem, index) in specSubDetList" :key="index">
          <template v-if="subDetItem.ency">
            <EncySearch :encySearchItem="subDetItem.ency" />
          </template>
          <template v-else-if="subDetItem.article">
            <div class="articleItem" @click="toSignal(subDetItem.article.id)">
              <div class="articleTitle textOverflow">
                {{ subDetItem.article.title }}
              </div>
              <div class="author">
                <div class="detailAuthorHead">
                  <div class="authorPhoto">
                    <img
                      v-if="
                        subDetItem.article.member &&
                        subDetItem.article.member.avatar
                      "
                      class="imgCut"
                      :src="subDetItem.article.member.avatar"
                      alt=""
                    />
                    <img
                      v-else
                      class="imgCut"
                      src="@/assets/style/img_portrait@2x.png"
                      alt=""
                    />
                  </div>
                  <img
                    v-if="subDetItem.article.member.isRealOfficial === 1"
                    class="userTypeImg"
                    src="@/assets/style/icon_official@2x.png"
                    alt=""
                  />
                  <img
                    v-else-if="subDetItem.article.member.isOfficial === 1"
                    class="userTypeImg"
                    src="@/assets/style/icon_vip@2x.png"
                    alt=""
                  />
                </div>
                <div class="authorRight">
                  <div class="authorName">
                    {{ subDetItem.article.member.nickname }}
                  </div>
                  <div class="publishTime">
                    {{ subDetItem.article.createTime }}
                  </div>
                </div>
              </div>
              <div class="articleInfo">
                <div
                  class="description textOverflow"
                  :class="{ hasPic: subDetItem.article.picture }"
                >
                  {{ subDetItem.article.descriptions }}
                </div>
                <div v-if="subDetItem.article.picture" class="articlePhoto">
                  <img
                    class="imgCut"
                    :src="subDetItem.article.picture"
                    alt=""
                  />
                </div>
              </div>
              <div
                class="articleData"
                :class="{ hasPic: subDetItem.article.picture }"
              >
                {{ subDetItem.article.commentNum }}条评论 ·
                {{ subDetItem.article.praiseNum }}个点头
              </div>
            </div>
          </template>
          <template v-else-if="subDetItem.subject">
            <QuizItem :subDetItem="subDetItem" />
          </template>
        </div>
        <div
          v-if="loadingMore"
          class="loadmore loading"
          :style="{ visibility: loadingMore ? 'visible' : 'hidden' }"
        >
          <img class="loadingImg" src="@/assets/style/loading.gif" alt="" />
        </div>
      </div>
      <template v-if="empty">
        <div class="searchEmpty">
          <img v-if="themeColor=='light'" class="searchEmptyImg" src="@/assets/style/find empty.png" alt="" />
          <img v-else class="searchEmptyImg" src="@/assets/style/empty.gif" alt="" />
          <div class="searchEmptyText">很抱歉，该专题暂时为空</div>
        </div>
      </template>
      <!-- <SecondFooter /> -->
      <template>
        <el-backtop
          target=".home-wrapper"
          :bottom="100"
          :visibility-height="100"
        >
          <div class="backTop">UP</div>
        </el-backtop>
      </template>
    </div>
  </div>
</template>
<script>
import WebHeader from "@/components/WebHeader.vue";
// import SecondFooter from "@/components/SecondFooter";
import EncySearch from "@/components/EncySearch";
import QuizItem from "@/components/QuizItem";
import request from "@/utils/request";
import { openSignal, } from "@/utils/navigate";
export default {
  data() {
    return {
      loading: false,
      loadingMore: false,
      empty: false,
      finish: false,
      pageNum: 1,
      total: 1,
      specSubDetInfo: {},
      specSubDetList: [],
      themeColor: 'dark',
    };
  },
  components: {
    WebHeader,
    // SecondFooter,
    EncySearch,
    QuizItem,
  },
  watch: {
    '$root.themeColor': {
      immediate: true,
      handler(newVal) {
        this.themeColor = newVal;
      },
    },
  },
  mounted() {
    this.themeColor = localStorage.getItem('theme');
    this.$root.themeColor = this.themeColor;
  },
  async created() {
    this.loading = true;
    // locationMobileUrl("specSub",this.$route.query.id);
    this.getSpecSubDet();
  },
  methods: {
    async loadmore() {
      if (this.loadingMore || this.loading || this.finish || this.empty) {
        return;
      }
      this.loadingMore = true;
      this.pageNum++;
      this.getSpecSubDet();
    },
    async getSpecSubDet() {
      if (!this.$route.query.id) {
        return;
      }
      const specialSubjectId = this.$route.query.id;
      const specSubDetRes = await request.get("/dp/specialSubject/detail", {
        params: {
          specialSubjectId: specialSubjectId,
          pageNum: this.pageNum,
          pageSize: 10,
        },
      });
      this.loading = false;
      this.loadingMore = false;
      if (this.pageNum == 1) {
        this.specSubDetInfo = specSubDetRes.data.info;
        this.total = specSubDetRes.data.detail.total;
        window.document.title = `多频专题 - ${this.specSubDetInfo.name}`;
      }
      this.specSubDetList = this.specSubDetList.concat(
        specSubDetRes.data.detail.records
      );
      if (this.total == 0 && !this.specSubDetList.length) {
        return (this.empty = true);
      } else if (this.specSubDetList.length >= this.total || !specSubDetRes.data.detail.records.length) {
        return (this.finish = true);
      }
    },
    toSignal(id) {
      openSignal(id);
    },
  },
};
</script>

<style scoped>
.specSubHeader {
  background: var(--background-color-item);
  width: 834px;
  height: 110px;
  margin-top: 10px;
}
.specSubHeaderTitle {
  font-size: 18px;
  font-weight: 700;
  margin: 20px 0 8px 24px;
}
.specSubHeaderDes {
  margin: 0 24px;
  line-height: 20px;
  word-break: break-all;
}
.specSubHeaderDes.textOverflow {
  -webkit-line-clamp: 2;
}
.specSubDet {
  width: 834px;
}
/* 移动端适配样式 */
.mobilePage .specSubDet {
  width: 100%;
}
.mobilePage .specSubHeader {
  width: 100%;
}
.mobilePage .imgOptionItem {
  width: 60px;
  height: 60px;
}
</style>