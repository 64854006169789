<template>
  <div v-if="!isDelete" class="commentItem">
    <div class="author">
      <div class="authorHead" @click.stop="toPersonal">
        <div class="authorPhoto">
          <img
            v-if="
              commentItem.memberVo &&
              commentItem.memberVo.avatar &&
              commentItem.memberVo.deleted == 0
            "
            class="imgCut"
            :src="commentItem.memberVo.avatar"
            alt=""
          />
          <img
            v-else
            class="imgCut"
            src="@/assets/style/img_portrait@2x.png"
            alt=""
          />
        </div>
        <img
          v-if="
            commentItem.memberVo.isRealOfficial === 1 &&
            commentItem.memberVo.deleted == 0
          "
          class="userTypeImg"
          src="@/assets/style/icon_official@2x.png"
          alt=""
        />
        <img
          v-else-if="
            commentItem.memberVo.isOfficial === 1 &&
            commentItem.memberVo.deleted == 0
          "
          class="userTypeImg"
          src="@/assets/style/icon_vip@2x.png"
          alt=""
        />
      </div>
      <div class="authorRight" @click.stop="toPersonal">
        <div class="authorName" v-if="commentItem.memberVo.deleted == 0">
          {{ commentItem.memberVo.nickname }}
          <img :src="commentItem.memberVo.expLogo" alt="" />
        </div>
        <div class="authorName" v-else>账号已注销</div>
        <div class="publishTime">{{ commentItem.comment.createTime }}</div>
      </div>
      <div class="commentItemBtn">
        <span
          class="clickButton"
          :class="{ btnClicked: isCommentPraise }"
          @click="commentPraise()"
        >
          <template v-if="!isCommentPraise">
            <img
              class="imgCommon"
              src="@/assets/style/icon_like@2x.png"
              alt=""
            />
            <img
              class="imgHover"
              src="@/assets/style/icon_like_hover.png"
              alt=""
            />
          </template>
          <template v-else>
            <img src="@/assets/style/icon_like_hover.png" alt="" />
          </template>
          {{ commentPraiseNum }}</span
        >
      </div>
    </div>

    <div class="commentDetail">
      <div v-if="commentItem.parentComment" class="parentCom">
        <div class="commentText parent">
          <div class="parentComDet">
            <span
              v-if="commentItem.replyMemberVo.deleted == 0"
              class="parentComAuthor"
            >
              @{{ commentItem.replyMemberVo.nickname }}：
            </span>
            <span v-else class="parentComAuthor">@账号已注销：</span>
            {{ commentItem.parentComment.content }}
          </div>
        </div>
        <div
          v-if="commentItem.parentComment.hasMusic !== 0"
          class="commentMusic parent"
          @click="openMusic(ccommentItem.parentComment.musicOriginLinkUrl)"
        >
          <div class="modulePic">
            <img
              class="imgCut"
              :src="commentItem.parentComment.musicCover"
              alt=""
            />
          </div>
          <div class="musicInfo">
            <div class="musicName">
              {{ commentItem.parentComment.musicName }}
            </div>
            <div class="musicArtist">
              {{ commentItem.parentComment.musicAuthor }}
            </div>
          </div>
        </div>
        <div v-if="commentItem.parentComment.picture" class="commentImg">
          <img class="imgCut" :src="commentItem.parentComment.picture" alt="" />
        </div>
      </div>
      <div class="commentText">
        {{ commentItem.comment.content }}
      </div>
      <div
        v-if="commentItem.comment.hasMusic !== 0"
        class="commentMusic"
        @click="openMusic(commentItem.comment.musicOriginLinkUrl)"
      >
        <div class="modulePic">
          <img class="imgCut" :src="commentItem.comment.musicCover" alt="" />
        </div>
        <div class="musicInfo">
          <div class="musicName">{{ commentItem.comment.musicName }}</div>
          <div class="musicArtist">{{ commentItem.comment.musicAuthor }}</div>
        </div>
      </div>
      <div v-if="commentItem.comment.picture" class="commentImg">
        <img class="imgCut" :src="commentItem.comment.picture" alt="" />
      </div>
    </div>
    <div class="commentItemBtn">
      <span class="clickButton" @click="clickReply()"
        ><img
          class="imgCommon"
          src="@/assets/style/icon_signal_comment@2x.png"
          alt=""
        />
        <img
          class="imgHover"
          src="@/assets/style/icon_signal_comment_hover.png"
          alt=""
        />
        回复</span
      >
      <span v-if="isMine" class="clickButton" @click="clickDelete()"
        ><img
          class="imgCommon"
          src="@/assets/style/icon_delete@2x.png"
          alt="" />
        <img
          class="imgHover"
          src="@/assets/style/icon_delete_hover@2x.png"
          alt=""
      /></span>
    </div>
    <div v-show="replyShow" class="replyTextarea">
      <el-input
        type="textarea"
        placeholder="回复评论..."
        ref="replyComment"
        v-model="replyMessage"
        maxlength="500"
        show-word-limit
        @keyup.enter="submitReply(replyMessage, replyMusic, imageUrl)"
        spellcheck="false"
        autosize
      >
      </el-input>
      <div class="commentAttach">
        <div class="commentMusic" v-if="replyHasMusic">
          <img
            class="closeBtnImg"
            src="@/assets/style/btn_close@2x (1).png"
            alt=""
            @click="deleteMusic"
          />
          <div class="modulePic">
            <img class="imgCut" :src="replyMusic.pic" alt="" />
          </div>
          <div class="musicInfo">
            <div class="musicName">{{ replyMusic.title }}</div>
            <div class="musicArtist">{{ replyMusic.artist }}</div>
          </div>
        </div>
        <div v-if="imageUrl" class="commentEditImg">
          <img class="imgCut" :src="imageUrl" alt="" />
          <img
            class="closeBtnImg"
            src="@/assets/style/btn_close@2x (1).png"
            alt=""
          />
        </div>
      </div>
      <div class="commentBtnLine">
        <span class="clickButton musicBtn" @click="addMusicVideo()">
          <img v-if="themeColor=='light'" src="@/assets/style/btn_music_2.png" alt="" />
          <img v-else src="@/assets/style/btn_music@2x.png" alt="" />
        </span>
        <el-upload
          :action="uploadUrl"
          :headers="uploadReqHeader"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :disabled="!isLogin"
        >
          <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar" /> -->
          <img v-if="themeColor=='light'" src="@/assets/style/btn_pic_2.png" alt="" />
          <img v-else src="@/assets/style/btn_pic@2x.png" alt="" />
        </el-upload>
        <span class="clickButton closeComment" @click="replyDissolve()"
          >取消</span
        >
        <span
          class="clickButton DROPBtn"
          :class="{ buttonDisabled: dropDisable }"
          @click="submitReply(replyMessage, replyMusic, imageUrl)"
        >
          <img
            v-if="dropDisable"
            src="@/assets/style/btn_send_default.png"
            alt=""
          />
          <img v-else src="@/assets/style/btn_send.png" alt="" />
        </span>
      </div>
    </div>
    <DeleteComment
      ref="deleteCommentDialog"
      @deleteHandler="localDelete"
      :commentId="commentItem.comment.id"
    />
    <AddMusic ref="mvDialog" @musicHandler="addMusicToReply" />
    <div class="devider"></div>
  </div>
</template>

<script>
import Viewer from "viewerjs";
import { openPersonal } from "@/utils/navigate";
import AddMusic from "@/components/AddMusic.vue";
import DeleteComment from "@/components/DeleteComment.vue";
import { mapGetters } from "vuex";
import request from "@/utils/request";
export default {
  components: {
    AddMusic,
    DeleteComment,
  },
  computed: {
    ...mapGetters(["nickname", "avatar", "id", "isLogin", "token"]),
    isMine() {
      return this.commentItem.memberVo.id === this.id;
    },
    uploadReqHeader() {
      return {
        "X-Duopin-Token": this.token,
      };
    },
    dropDisable() {
      return !this.replyMessage && !this.replyHasMusic && this.imageUrl == "";
    },
  },
  methods: {
    async submitReply(message, music, pic) {
      if (!message && !this.replyHasMusic && pic == "") {
        return;
      }
      const submitRes = await request.post("/dp/comment/submit", {
        articleId: this.commentItem.comment.articleId,
        articleMemberId: this.commentItem.comment.articleMemberId,
        hasMusic: this.replyHasMusic ? 1 : 0,
        musicAuthor: music.artist,
        musicCover: music.pic,
        musicLinkUrl: music.originalUrl,
        musicName: music.title,
        musicOriginLinkUrl: music.originalUrl,
        musicTypeId: music.typeId,
        picture: pic,
        content: message,
        replyMemberId: this.commentItem.comment.memberId,
        parentId: this.commentItem.comment.id,
      });
      if (submitRes.code == 200) {
        // this.commentList.unshift(submitRes.data);
        // this.commentNum++;
        this.localCommentData = submitRes.data;
        this.$emit("commentHandler", this.localCommentData);
        this.replyDissolve();
      }

      console.log(music, pic);
    },
    handleAvatarSuccess(res, file) {
      console.log("===: ", res, file);
      if (!this.isLogin) {
        this.$login();
        return;
      }
      this.imageUrl = res.data.url;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt4M = file.size / 1024 / 1024 < 4;

      if (!isJPG && !isPNG) {
        this.$message.error("请上传 JPG 或 PNG 格式的图片");
      }
      if (!isLt4M) {
        this.$message.error("上传的图片大小不能超过 4MB 哦");
      }
      return (isJPG || isPNG) && isLt4M;
    },
    deleteMusic() {
      this.replyHasMusic = false;
    },
    addMusicToReply(music) {
      this.replyHasMusic = true;
      this.replyMusic = music;
    },
    localDelete(confirmDelete) {
      this.isDelete = confirmDelete;
      this.$emit("deleteHandler", this.isDelete);
    },
    clickDelete() {
      this.$refs["deleteCommentDialog"].centerDialogVisible = true;
      console.log(
        "点了",
        this.$refs["deleteCommentDialog"].centerDialogVisible
      );
    },
    openMusic(url) {
      window.open(url);
    },
    toPersonal() {
      if (this.commentItem.memberVo.deleted == 1) {
        return this.$message("用户已注销");
      } else openPersonal(this.commentItem.memberVo.id);
    },
    addMusicVideo() {
      this.$refs["mvDialog"].contentVisible = true;
    },
    async clickReply() {
      if (!this.isLogin) {
        this.$login();
        return;
      }
      const permissionRes = await request.get("/dp/member/checkAddPermission", {
        params: {
          typeId: 2,
        },
      });
      if (permissionRes && permissionRes.code == 200) {
        this.replyShow = true;
        this.$nextTick(() => {
          this.$refs.replyComment.focus();
        });
      }
    },
    replyDissolve() {
      this.replyMessage = "";
      this.replyHasMusic = false;
      this.replyShow = false;
      this.replyMusic = "";
    },
    async commentPraise() {
      if (!this.isLogin) {
        this.$login();
        return;
      }
      const isPraiseRes = await request.post("/app/praise/submit", {
        contentId: this.commentItem.comment.id,
        type: 2,
        toMemberId: this.commentItem.comment.memberId,
      });
      if (isPraiseRes.code == 200) {
        this.isCommentPraise = !this.isCommentPraise;
        if (this.isCommentPraise) {
          this.commentPraiseNum++;
        } else this.commentPraiseNum--;
      }
    },
  },
  props: ["commentItem"],
  watch: {
    '$root.themeColor': {
      immediate: true,
      handler(newVal) {
        this.themeColor = newVal;
      },
    },
    replyHasMusic(val) {
      if (!val) {
        this.replyMusic = {
          title: "",
          artist: "",
          pic: "",
          originalUrl: "",
        };
      }
    },
  },
  data() {
    return {
      viewers: [],
      replyMessage: "",
      replyShow: false,
      commentPraiseNum: this.commentItem.praiseNum,
      isDelete: false,
      replyHasMusic: false,
      replyMusic: {
        title: "",
        artist: "",
        pic: "",
        originalUrl: "",
      },
      uploadUrl: `${process.env.VUE_APP_API}/dp/common/upload`,
      imageUrl: "",
      isCommentPraise: this.commentItem.ifPraise,
      themeColor: 'dark',
    };
  },
  mounted() {
    this.themeColor = localStorage.getItem('theme');
    this.$root.themeColor = this.themeColor;
    this.$nextTick(() => {
      const nodes = document.getElementsByClassName("commentImg");
      for (let i = 0; i < nodes.length; i++) {
        let viewer = new Viewer(nodes[i], {
          toolbar: false,
          navbar: false,
          title: false,
          movable: false,
          minZoomRatio: 0.3,
          maxZoomRatio: 3,
        });
        this.viewers.push(viewer);
      }
    });
  },
  beforeDestroy() {
    this.viewers.forEach((viewer) => viewer.destroy());
  },
};
</script>
<style>
.commentImg {
  cursor: zoom-in;
}
.authorHead {
  cursor: pointer;
}
.authorHead:hover {
  opacity: 0.5;
}
.authorRight {
  cursor: pointer;
}
.commentItemBtn .clickButton img {
  height: 17px;
  margin-right: 6px;
}
.commentItemBtn {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.commentItemBtn .clickButton {
  display: flex;
  align-items: center;
  margin-left: 36px;
}
@media (any-hover: hover) {
  .commentItemBtn .clickButton:hover {
    color: #9c5cf7;
  }
}
.replyTextarea textarea {
  resize: none;
  color: var(--font-color);
  width: 95.2%;
  padding: 10px;
  border: 1px solid #a4a4a4;
  border-radius: 8px;
  height: 48px;
  background: var(--background-color-item-hover);
  margin: 24px 0 12px 35px;
  font-size: 13px;
}
.replyTextarea .el-textarea .el-input__count {
  bottom: -12px;
  right: 10px;
}
.clickButton.closeComment {
  margin: 0 8px 0 auto;
  border-radius: 21px;
  padding: 5px 15px;
  border: 1px solid #b1b1b1;
  margin-top: 20px;
}
.commentItem .clickButton.DROPBtn {
  margin-left: 0;
  margin-top: 20px;
}
.authorName img {
  width: 26px;
}
.mobilePage .commentItem textarea {
  width: 90%;
}
</style>

<style scoped>
.commentAttach {
  margin: -10px 0 16px 38px;
}
.commentAttach .commentMusic {
  width: 70%;
}
.closeBtnImg {
  width: 13px;
  position: absolute;
  right: 6px;
  top: 6px;
  cursor: pointer;
  padding: 2px 3px;
  border-radius: 50%;
  background: var(--el-button--primary-disabled-background-color);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}
.closeBtnImg:hover {
  background: #666;
}
.commentAttach .commentMusic {
  position: relative;
  cursor: auto;
}
.commentAttach .commentMusic:hover {
  background: var(--background-color-item-hover);
}
.commentEditImg {
  width: 120px;
  height: 120px;
  border-radius: 4px;
  margin-top: 12px;
  overflow: hidden;
  position: relative;
}
.clickButton.btnClicked {
  color: #9c5cf7;
}
</style>

