import request from "@/utils/request";

export function reportPv() {
	return request({
		url: "/dp/home/init",
		method: "get",
		params: {
			os: "pc",
			version: "web"
		}
	})
}