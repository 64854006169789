<template>
  <div class="home-wrapper" ref="scrollContainer">
    <web-header v-if="!isApp" :disabledScroll="true" />
    <div class="pageDet" :class="{ mobilePage: isApp }">
      <div :class="{ pcPage: !isApp, mobilePage: isApp }">
        <!-- 未登录样式 -->
        <div v-if="!isLogin">
          <div class="voteBanner">
            <img v-if="webInfo" class="imgCut" :src="webInfo.image" alt="" />
          </div>
          <div class="loginTips">
            <div class="processTips">{{ processTips[flag] }}</div>
            <div>当前未登录</div>
            <div v-if="!isApp" class="clickLoginBtn" @click="clickLogin()">
              👉 点击登录DROPIN多频 👈
            </div>
            <div v-else>请登录App后<br />再进入该页面查看活动</div>
          </div>
        </div>
        <!-- 已登录获取碎片 -->
        <template v-else>
            <div class="pieceBlcok">
              <div
                class="pieceItem"
                v-for="(pieceItem, idx) in pieceList"
                :key="idx"
                @click="clickPieceItem(pieceItem)"
              >
                <div class="pieceImg">
                  <img class="imgCut" :src="pieceItem.image" alt="" />
                  <div v-if="!pieceItem.acquired" class="disablePiece"></div>
                </div>
                {{ pieceItem.name }}
              </div>
            </div>
            <div>多频碎片收集进度：{{ pieceNum }}/{{ pieceList.length }}</div>
            <template v-if="flag == 1 && pieceNum < pieceList.length">
              <div class="smallText">
                获取提示：碎片正散落在DROPIN多频星际的各个角落，快去App端、网页端、创作后台找找，细心留意有没有和平常不一样的地方吧！
              </div>
            </template>
            <template v-else-if="flag == 2">
              <div class="smallText">{{ processTips[flag] }}</div>
            </template>
          <!-- 碎片已成功收集 -->
          <template v-if="pieceList.length > 0 && pieceNum == pieceList.length">
            <div class="medalItem">
              <img class="imgCut" src="@/assets/style/1st_medal.png" alt="" />
            </div>
            <div>恭喜！<br />你的多频碎片已集齐！</div>
            <div class="smallText">
              特殊成就频谱“一周年见证”<br />及50分贝值已发放到你的帐号，<br />
              请前往App端的个人主页查看！<br />
              <b>DROPIN多频</b> 衷心感谢每一位频友的陪伴<br />
              <b>为了电音！</b>
            </div>
          </template>
          <el-dialog
            class="pieceDialog"
            title=""
            :visible.sync="dialogShow"
            center
            v-if="getPieceItem.image"
          >
            <div class="pieceItem getPieceItem">
              <div class="pieceImg">
                <img class="imgCut" :src="getPieceItem.image" alt="" />
              </div>
              {{ getPieceItem.name }}
            </div>
            <div class="pieceDes">{{ getPieceItem.description }}</div>
            <div class="acquiredTips" v-if="getPieceItem.acquired">
              恭喜！<br />你已获得「{{ getPieceItem.name }}」碎片！
            </div>
            <div class="acquiredTips" v-else>
              很可惜<br />你还未获得「{{ getPieceItem.name }}」碎片
            </div>
            <!-- <span slot="footer" class="dialog-footer"> </span> -->
            <el-button class="" type="primary" @click="dialogShow = false"
              >确定</el-button
            >
          </el-dialog>
        </template>
        <!-- 活动规则 -->
        <div class="voteRule">
          <div>活动规则：</div>
          <div v-html="webInfo.rule"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import WebHeader from "@/components/WebHeader.vue";
// import { openEncy } from "@/utils/navigate";
import request from "@/utils/request";
import { isAppFun } from "@/utils/judgeClient";
export default {
  data() {
    return {
      webInfo: {},
      awId: 2,
      loading: false,
      flag: 1,
      rule: "",
      device: "pc",
      processTips: {
        0: "活动未开始",
        1: "多频碎片收集大挑战进行中",
        2: "多频碎片收集大挑战已结束",
      },
      ruleShow: false,
      isApp: false,
      pieceId: 0,
      pieceIdMap: {
        damnthisoneistooeasy: 1,
        readthesewonderfulcolumnsplz: 2,
        okaynowsaysthuseful: 3,
        pageforthehardcoreusers: 4,
        insomniacscantlivewithoutparties: 5,
        neverstoplearining: 6,
      },
      pieceList: [],
      dialogShow: false,
      pieceNum: 0,
      getPieceItem: {},
    };
  },
  components: {
    WebHeader,
  },

  computed: {
    ...mapGetters(["isLogin", "token"]),
  },
  watch: {
    isLogin: {
      immediate: true,
      handler(val, oldVal) {
        // 从false变成true, 拉一下碎片信息
        if (!oldVal && val) {
          this.getPieceList()
        }
      }
    }
  },
  async created() {
    document.title = "DROPIN多频周年庆 - 碎片收集大挑战！";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "活动期间收集散落在社区各个角落的多频碎片，领取“一周年见证”特殊成就！"
      );
    this.getWebInfo();
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      // 当前设备是移动设备
      this.isApp = isAppFun();
      if (this.isApp) {
        this.device = "mobile";
      } else
        this.$message({
          message: "请使用电脑打开该页面，获得更好体验",
        });
    }
    // if (this.isLogin) {
    //   this.getPieceList();
    // }
  },
  methods: {
    // ...mapActions(["user/resetToken"]),
    clickPieceItem(item) {
      this.getPieceItem = item;
      this.dialogShow = true;
    },
    async getPiece(id) {
      const getPieceRes = await request.get("/dp/prop/getProp", {
        params: {
          awId: this.awId,
          propId: id,
        },
      });
      if (getPieceRes.code == 200) {
        this.dialogShow = true;
        this.getPieceItem = getPieceRes.data;
        this.pieceList.find((obj) => obj.id === id).acquired = true;
        this.pieceNum++;
      }
    },
    async getPieceList() {
      const getPieceListRes = await request.get("/dp/prop/list", {
        params: {
          awId: this.awId,
        },
      });
      if (getPieceListRes.code == 200) {
        this.pieceList = getPieceListRes.data;
        for (let j = 0; j < this.pieceList.length; j++) {
          if (this.pieceList[j].acquired) {
            this.pieceNum++;
          }
        }
      }
      if (this.$route.query.source && this.flag == 1 && this.pieceNum < this.pieceList.length) {
        this.pieceId = this.pieceIdMap[this.$route.query.source];
        this.getPiece(this.pieceId);
      }
    },
    async getWebInfo() {
      console.log("获取活动信息");
      const webInfoRes = await request.get("/dp/home/getActivityWeb", {
        params: {
          awId: this.awId,
        },
      });
      document.title = webInfoRes.data.name;
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", webInfoRes.data.description);
      this.webInfo = webInfoRes.data;
      this.flag = this.webInfo.flag;
    },
    clickLogin() {
      this.$login();
      return;
    },
    handleOpenUrl(urlStr) {
      if (!urlStr) return;

      let url = urlStr;
      if (urlStr.indexOf("http://") !== 0 && urlStr.indexOf("https://") !== 0) {
        url = `http://${url}`;
      }
      window.open(url);
    },
  },
};
</script>

<style scoped>
.acquiredTips {
  font-size: 14px;
  line-height: 24px;
}
.pcPage {
  width: 700px;
}
.pageDet.mobilePage {
  margin-top: 10px;
}
.mobilePage {
  width: 330px;
}
.voteBanner {
  margin: 20px auto;
  border-radius: 4px;
  overflow: hidden;
}
.pcPage .voteBanner {
  height: 298px;
}
.mobilePage .voteBanner {
  height: 140px;
}
.processTips {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}
.greyBlock {
  padding: 30px 0;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}
.submitBtn {
  width: 300px;
  height: 44px;
  color: #fff;
  background: #9c5cf7;
  border-radius: 25px;
  text-align: center;
  font-size: 16px;
  line-height: 44px;
  margin: 40px auto 0 auto;
  cursor: pointer;
}
.ruleDialog {
  margin-top: 8vh;
}
.pieceBlcok {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: 400;
  width: 500px;
  margin: 16px auto;
}
.mobilePage .pieceBlcok {
  width: 330px;
  margin-left: 4px;
}
.pieceItem {
  margin: 10px;
  cursor: pointer;
}
.pieceItem.getPieceItem {
  margin: 6px auto;
  width: 140px;
  color: #b891f2;
}
.pieceImg {
  width: 140px;
  height: 140px;
  position: relative;
  margin-bottom: 2px;
}
.pieceImg .disablePiece {
  position: absolute;
  left: 0;
  top: 0;
  width: 140px;
  height: 140px;
  border-radius: 6px;
  background: rgba(19, 19, 19, 0.7);
}
.smallText {
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  color: #d9d9d9;
  width: 330px;
  margin: 20px auto;
}
.pieceDes {
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  color: #b891f2;
  margin-bottom: 12px;
}
.medalItem {
  width: 160px;
  height: 160px;
  margin: 50px auto 30px auto;
}
.pcPage .medalItem {
  margin-top: 50px;
}
</style>

<style>
.voteRule {
  text-align: left;
  margin: 68px auto 40px auto;
  font-size: 14px;
  color: var(--color-80);
  line-height: 24px;
  width: 660px;
}
.pieceDialog .el-button--primary {
  margin: 16px auto;
  font-size: 14px;
  width: 194px;
  border-radius: 25px;
}
.mobilePage .el-dialog {
  width: 280px ;
  margin-top: 18vh !important ;
}
.pcPage .el-dialog {
  width: 400px;
}
</style>
