<template>
  <div id="app" :class="{
    'is-android': isAndroid
  }">
    <router-view></router-view>
  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import { isAndroid } from "@/utils/judgeClient";
import { mapActions, mapGetters } from 'vuex'
import { reportPv } from './api/stat'

const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

export default {
  name: 'App',
  components: {},
  data() {
    return {
      isAndroid: isAndroid(),
      themeColor: 'dark',
    }
  },
  computed: {
    ...mapGetters(["isLogin"]),
  },

  watch: {
    isMobile: {
      handler(newVal, oldVal) {
        console.log("isMobile:进入了", newVal, oldVal)
        if (newVal) {
          const value = localStorage.getItem('theme');
          if (value == 'light') {
            localStorage.setItem('theme', 'dark');
            // document.documentElement.setAttribute('data-theme', 'dark');
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  async created() {
    // 非生产环境加一下vConsole
    if (process.env.NODE_ENV !== 'production') {
      const script = document.createElement('script')
      script.onload = function() {
        new window.VConsole();
      }
      script.src = "https://unpkg.com/vconsole@latest/dist/vconsole.min.js"
      document.body.append(script)
    }

    // iOS获取登陆态要晚一点，延时一下
    await delay(300)
    await this['user/setTokenLocal']()

    if (this.isLogin) {
      reportPv()
    }
  },
  async mounted() {
    const value = localStorage.getItem('theme')
    document.documentElement.setAttribute('data-theme', value);
  },
  methods: {
    ...mapActions(['user/setTokenLocal'])
  }
}
</script>

<style>
@import './assets/style/index.css';

:root{
  --background-color: #131313;
  --background-color-item: #1F1F1F;
  --background-color-item-hover: #292929;
  --font-weight: 300;
  --font-color: #ffffff;
  --color-80: rgba(255, 255, 255, 0.8);
  --color-70: rgba(255, 255, 255, 0.7);
  --color-60: rgba(255, 255, 255, 0.6);
  --color-50: rgba(255, 255, 255, 0.5);
  --color-40: rgba(255, 255, 255, 0.4);
  --color-15: rgba(255, 255, 255, 0.15);
  --color-10: rgba(255, 255, 255, 0.1);
  /*--halfBottom-color: #4a4a4a; !*rgba(255, 255, 255, 0.1)*!*/
  --search-background-color: #2c2c2c;
  --search-border: 1px solid #4a4a4a;
  --el-backtop-background-color: #3e3e3e;
  --el-button--primary-disabled-background-color: #484848;
  --tab-background-color: #313131;
}
[data-theme="light"] {
  --background-color: #F7F7F7;
  --background-color-item: #ffffff;
  /*--background-color-item-hover: #fafafa;*/
  --background-color-item-hover: #f0f0f0;
  --font-weight: 400;
  --font-color: #000000;
  --color-80: rgba(0, 0, 0, 0.8);
  --color-70: rgba(0, 0, 0, 0.7);
  --color-60: rgba(0, 0, 0, 0.6);
  --color-50: rgba(0, 0, 0, 0.5);
  --color-40: rgba(0, 0, 0, 0.4);
  --color-15: rgba(0, 0, 0, 0.15);
  --color-10: rgba(0, 0, 0, 0.1);

  --search-background-color: #f2f2f2;
  --search-border: 1px solid #e5e5e5;
  --el-backtop-background-color: #c0c0c0;
  --el-button--primary-disabled-background-color: #b7b7b7;
  --tab-background-color: #f0f0f0;
}

html, body, #app {
  height: 100%;
}
</style>