<template>
  <div>
    <div v-for="(music, index) in list" :key="index" style="width:100%">
      <div  @click="openMusic(music.originalUrl)">
        <div class="musicCard">
          <img :src="music.pic" width="40">
          <div style="margin-left: 10px; flex: 1">
            <div class="musicText" style="font-size: 14px; color: #FFFFFF; line-height: 24px;">{{ music.title }}</div>
            <div class="musicText" style="font-size: 12px; color: rgba(255,255,255,0.80); line-height: 16px;">{{ music.artist }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MusicList",
  components: {},
  props: {
    list: {
      type: Array,
    },
  },
  data: () => ({}),
  computed: {},
  created() {},
  mounted() {},
  methods: {
    //跳转音乐
    openMusic(url) {
      window.open(url);
    },
  },
  watch: {},
};
</script>
<style scoped>
.inline {
  display: inline;
  vertical-align: top;
}
.musicCard {
  max-width: 580px;
  display: flex;
  box-sizing: border-box;
  float: left;
  border-radius: 4px;
  backdrop-filter: blur(54.37px);
  width: 100%;
  height: 60px;
  padding: 10px;
  background-color: #292929;
  margin-bottom: 12px;
  cursor: pointer;
}

.musicText {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
</style>