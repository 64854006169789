<template>
<!-- 首页热门百科模块 -->
  <div class="rightItem halfBottom" @click="toEncy(hotEncyItem)">
    <div class="modulePic">
      <img v-if="hotEncyItem.picture" class="imgCut" :src="hotEncyItem.picture" alt="" />
      <img v-else class="imgCut" src="@/assets/style/img_pedia@2x.png" alt="" />
    </div>
    <div class="rightMsg" :class="{ noBrief: !hotEncyItem.descriptions }">
      <div class="itemName">
        <div class="encyType">{{hotEncyItem.typeName}}</div>
        <div class="encyName textOverflow">{{hotEncyItem.name}}</div>
      </div>
      <div v-if="hotEncyItem.descriptions" class="itemBrief textOverflow">
        {{hotEncyItem.descriptions}}
      </div>
    </div>
  </div>
</template>

<script>
import { openEncy } from "@/utils/navigate";
export default {
    methods: {
    toEncy(target) {
      openEncy(target.typeId,target.dataId);
    },
  },
    props: ['hotEncyItem']
}
</script>

