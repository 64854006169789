// import { loginByUsername, logout, getInfo } from '@/api/user'
import {
  loginByUsername,
  loginByMobile,
  logout,
  getInfo,
  regCaptcha,
  resetPwd,
  getMemberRegCaptcha
} from "@/api/login";
import {
  getToken,
  setToken,
  removeToken,
  setRememberCookie,
  removeRememberCookie,
  getRememberCookie
} from "@/utils/auth";
import Cookies from "js-cookie";
import { isAndroid, isAppFun, isIOS } from "../../utils/judgeClient";

const state = {
  //memberId: "",
  isPassExam: "", //是否通过考试
  token: getToken(),
  nickname: "",
  avatar: "",
  id: 0,
  isRealOfficial: 0,
  isOfficial: 0,
  expLogo: "",
  descriptions: "",
  roles: [],
  gender: null,
  mobilePhone: null,
  // roles: ['admin']
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USERINFO: (state, userInfo) => {
    state.nickname = userInfo.nickname;
    state.avatar = userInfo.avatar;
    state.id = userInfo.id;
    state.isRealOfficial = userInfo.isRealOfficial;
    state.isOfficial = userInfo.isOfficial;
    state.expLogo = userInfo.expLogo;
    state.descriptions = userInfo.descriptions;
    state.gender = userInfo.gender;
    state.mobilePhone = userInfo.mobilePhone;
    // 在这里加其他用户信息字段
  }
};

const actions = {
  // 用户名登录
  LoginByUsername({ commit }, userInfo) {
    //const { username, password } = userInfo;
    return new Promise((resolve, reject) => {
      loginByUsername(userInfo)
        .then(response => {
          const { data } = response;
          console.log(" 后台返回的token是 ：" + data.token);
          commit("SET_TOKEN", data.token);
          setToken(data.token);
          if (userInfo.isRemember) {
            setRememberCookie(data.token);
          } else {
            removeRememberCookie();
          }
          if (data.memberInfo) {
            commit('SET_USERINFO', data.memberInfo)
          }
          Cookies.set("MOBILE", data.memberInfo.mobile);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 手机登录
  LoginByMobile({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      loginByMobile(userInfo)
        .then(response => {
          const { data } = response;
          console.log(" 后台返回的token是 ：" + data.token);
          commit("SET_TOKEN", data.token);
          setToken(data.token);
          if (userInfo.isRemember) {
            setRememberCookie(data.token);
          } else {
            removeRememberCookie();
          }
          if (data.memberInfo) {
            commit('SET_USERINFO', data.memberInfo)
          }
          Cookies.set("MOBILE", data.memberInfo.mobile);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 获取验证码
  // eslint-disable-next-line no-unused-vars
  regCaptcha({ commit }, data) {
    return new Promise((resolve, reject) => {
      regCaptcha(data)
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 获取当前用户验证码
  // eslint-disable-next-line no-unused-vars
  getMemberRegCaptcha({ commit }, data) {
    return new Promise((resolve, reject) => {
      getMemberRegCaptcha(data)
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }, // 获取验证码
  // eslint-disable-next-line no-unused-vars
  resetPwd({ commit }, data) {
    return new Promise((resolve, reject) => {
      resetPwd(data)
        .then(response => {
          const { data } = response;
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  //有本地存储token 设置vuex token cookie
  setTokenLocal({ commit }) {
    return new Promise(resolve => {
      let token
      const isApp = isAppFun()
      // App内找客户端拿登陆态
      if (isApp) {
        if (isIOS()) {
          try {
            window.sendClientTokenToH5 = (token) => {
              if (token) {
                commit("SET_TOKEN", token);
                setToken(token);
              }
              resolve()
            }
            window.webkit.messageHandlers.getUserToken.postMessage(null);
            console.log('IOS 获取Token')
          } catch (error) {
            console.log(error.message)
          }
          return
        } else if (isAndroid()) {
          try {
            token = window.Android.getUserToken();
          } catch (error) {
            console.log(error.message)
          }
        }
      } else {
        token = getRememberCookie();
      }

      if (token) {
        commit("SET_TOKEN", token);
        setToken(token);
      }
      resolve();
    });
  },
  // 获取用户信息
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then(response => {
          const { data } = response;
          if (!data) {
            reject("验证失败，请重新登录。");
          }
          if (response.code == 501) {
            reject("验证失败，请重新登录。");
            this.$router.push({
              path: "/login"
            });
          }

          // eslint-disable-next-line no-unused-vars
          // const { nickname, avatar, isPassExam } = data;

          // roles must be a non-empty array
          // if (!roles || roles.length <= 0) {
          //   reject('角色必须是一个非空数组!')
          // }
          console.log(data);
          // const roles = ["admin"];
          //commit("SET_ID", id);
          // commit("SET_ROLES", roles);
          // commit("SET_NAME", nickname);
          // commit("SET_AVATAR", avatar);
          // commit("SET_ISPASSEXAM", isPassExam);
          commit('SET_USERINFO', data)
          //commit("SET_INTRODUCTION", introduction);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          commit("SET_TOKEN", "");
          removeToken();
          removeRememberCookie();

          // reset visited views and cached views
          // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // 前端 登出
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit("SET_TOKEN", "");
      removeToken();
      removeRememberCookie();
      resolve();
    });
  },
  // 给微信登录
  updateToken({ commit }, { token, isRemember }) {
    commit("SET_TOKEN", token);
    setToken(token);
    if (isRemember) {
      setRememberCookie(token);
    } else {
      removeRememberCookie();
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
