import Cookies from "js-cookie";

let cookieDomain = location.hostname

// .hearinmusic.com域名下的页面cookie统一种在hearinmusic.com上
if (location.hostname.indexOf('hearinmusic') !== -1 || location.hostname === 'localhost') {
  cookieDomain = location.hostname.split('.').slice(-2).join('.')
}

const TokenKey = "X-Duopin-Token";
const RememberKey = 'X-Duopin-Remember'

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  // Cookies.set(TokenKey, token);
  Cookies.set(TokenKey, token, { domain: cookieDomain });
  return
}

export function removeToken() {
  // 老版本的cookie放在二级域名，这里清理一下以防万一
  Cookies.remove(TokenKey);
  return Cookies.remove(TokenKey, {
    domain: cookieDomain
  });
}

export function getRememberCookie() {
  return Cookies.get(RememberKey);
}

export function setRememberCookie(token) {
  Cookies.set(RememberKey, token, {
    domain: cookieDomain,
    expires: 14 // 14天
  });
}

export function removeRememberCookie() {
  return Cookies.remove(RememberKey, {
    domain: cookieDomain
  });
}
