<template>
<div>
  <el-dialog
    :append-to-body="true"
    class="followDialog setPwDialog"
    title="绑定手机"
    :visible.sync="centerDialogVisible"
    :class="{ mobilePage: isMobile }"
    center
    :close-on-click-modal="false"
    width="437px"
    :destroy-on-close="true"
  >
    <el-form  :model="setMbForm" :rules="rules" ref="setMbForm">
      <el-form-item label="" prop="mobile">
        <el-input
          ref="mobile"
          v-model="setMbForm.mobile"
          placeholder="请输入手机号"
          spellcheck="false"
          autocomplete="on"
          name="mobile"
          type="text"
          class="halfBottom"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="code" class="codeInput">
        <el-input
          ref="code"
          v-model="setMbForm.code"
          placeholder="请输入验证码"
          maxlength="6"
          autocomplete="on"
          @keyup.enter.native="handleLogin"
          spellcheck="false"
          name="code"
          type="text"
          class="halfBottom"
        ></el-input>
        <el-button
          class="codeBtn"
          :style="{ color: timer === 0 ? '#9C5CF7' : '#b1b1b1' }"
          ref="codeBtn"
          :loading="sending"
          :disabled="sendDisabled"
          size="small"
          @click="onSendSms"
          >{{ sendButtonText }}</el-button
        >
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="centerDialogVisible = false">取消</el-button>
      <el-button type="primary" @click="clickConfirm()">确定</el-button>
    </span>
  </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    const validateCode = (rule, value, callback) => {
      if (value.length < 6 || value === "") {
        callback(new Error("验证码错误"));
      } else {
        callback();
      }
    };

    const validateMobile = (rule, value, callback) => {
      if (value === "" || !/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请填写正确的手机号"));
      } else {
        callback();
      }
    };

    return {
      setMbForm: {
        mobile: "",
        code: "",
      },
      rules: {
        mobile: [
          { required: true, trigger: "blur", validator: validateMobile },
        ],
        code: [{ required: true, trigger: "blur", validator: validateCode }],
      },
      capsTooltip: false,
      passwordType: "password",
      sending: false,
      loading: false,
      centerDialogVisible: false,
      timer: 0,
      sendDisabled: false,
    };
  },
  computed: {
    sendButtonText() {
      if (this.timer === 0) {
        return "获取验证码";
      } else {
        return `${this.timer}s后重新发送`;
      }
    },
  },
  watch: {
    timer(num) {
      if (num > 0) {
        setTimeout(() => {
          this.timer = --num;
        }, 1000);
      }
    },
  },
  methods: {
    checkCapslock(e) {
      const { key } = e;
      this.capsTooltip = key && key.length === 1 && key >= "A" && key <= "Z";
    },
    showPwd(type) {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      if (type == 1) {
        this.$nextTick(() => {
        this.$refs.password.focus();
      });
      }
      else this.$nextTick(() => {
        this.$refs.passwordConfirm.focus();
      });
      
    },
    onSendSms() {
      this.$refs["setMbForm"].validateField("mobile", (valid) => {
        if (valid && valid !== "") {
          return false;
        } else {
          this.sending = true;
          this.$store
            .dispatch("user/regCaptcha", this.setMbForm)
            .then(() => {
              this.$message.success("短信发送成功，请注意查收");
              this.timer = 60;
            })
            .catch(() => {
              this.sending = false;
            })
            .finally(() => (this.sending = false));
        }
      });
    },
    async clickConfirm() {
      this.$refs['setMbForm'].validate(async (valid) => {
        if (valid) {
          const bindMobileRes = await request.post("/dp/member/bindMobile", {
            mobile: this.setMbForm.mobile,
            code: this.setMbForm.code,
          });
          if (bindMobileRes.code == 200) {
            this.$message({
              message: "绑定成功",
              type: "success",
            });
            this.$store.dispatch("user/getInfo");
            this.centerDialogVisible = false;
          }
        } else {
          console.log('校验不通过！error submit!!');
          return false;
        }
      });
    },
  },
};
</script>

<style>
.setPwDialog .el-input {
  display: inline-block;
  height: 47px;
  width: 100%;
  font-weight: 300;
}
.setPwDialog .el-input .el-input__inner {
  background: transparent;
  border: 0 !important;
  border-bottom: none;
  -webkit-appearance: none;
  border-radius: 0px;
  padding: 12px 5px 12px 15px;
  color: #fff;
  height: 47px;
}
.setPwDialog.followDialog {
    margin-top: 6vh;
}
.setPwDialog.followDialog .codeBtn.el-button {
    border: none;
}
.setPwDialog input:-internal-autofill-selected {
    box-shadow: inset 0 0 0 1000px #1f1f1f !important;
}
.el-dialog__headerbtn .el-dialog__close:hover {
    color: #9c5cf7;
}

</style>