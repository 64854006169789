<template>
  <!-- 以下是头部板块 -->
  <div class="home-wrapper" :class="{mobilePage : isMobile}">
    <web-header>
      <!-- 里面放滚动切换的内容 -->
      <div class="headSticky scrollSticky">
        <div class="head">
          <div class="headLeft">
            <div class="detailTitle textOverflow scrollSticky">
              {{ item.title }}
            </div>
          </div>
          <div class="headRight"></div>
        </div>
      </div>
    </web-header>
    <div class="content">
      <div class="left">
        <div class="signalDetail">
          <!-- 以下是信号标题 -->
          <div class="detailTitle">
            {{ item.title }}
          </div>
          <!-- 以下是信号频段 -->
          <div class="articlTopic">
            <div
              v-for="(topic, idx) in item.channelList"
              class="topicItem"
              @click="toTopic(topic)"
              :key="idx"
            >
              <img
                class="topicIcon"
                src="@/assets/style/icon_topic@2x.png"
                alt=""
              />
              <div class="topicText">{{ topic.name }}</div>
            </div>
          </div>
          <!-- 以下是作者信息 -->
          <div class="detailAuthor" v-if="item.member">
            <div class="detailAuthorHead" @click.stop="toPersonal">
              <div class="detailAuthorPhoto">
                <img
                  v-if="item.member && item.member.avatar && item.member.deleted == 0"
                  class="imgCut"
                  :src="item.member.avatar"
                  alt=""
                />
                <img
                  v-else
                  class="imgCut"
                  src="@/assets/style/img_portrait@2x.png"
                  alt=""
                />
              </div>
              <img
                v-if="item.member.isRealOfficial === 1 && item.member.deleted == 0"
                class="detailUserTypeImg"
                src="@/assets/style/icon_official@2x.png"
                alt=""
              />
              <img
                v-else-if="item.member.isOfficial === 1 && item.member.deleted == 0"
                class="detailUserTypeImg"
                src="@/assets/style/icon_vip@2x.png"
                alt=""
              />
            </div>
            <div class="detailAuthorRight" @click.stop="toPersonal">
              <div class="authorName" v-if="item.member.deleted == 0">
                {{ item.member.nickname }}
                <img :src="item.member.expLogo" alt="" />
              </div>
              <div v-else class="authorName">账号已注销</div>
              <div class="publishTime">发布于{{ item.createTime }}</div>
            </div>
            <span
              v-if="!isMine && item.member.deleted == 0"
              class="clickButton followBtn"
              @click="clickFollow()"
              :class="{followedBtn : isAttention }"
            >
              {{ followText }}
            </span>
            <CancelFollow ref="followDialog" :member="item.member" @cancelHandler="localCancel"  />
          </div>
          <!-- 以下是信号正文 -->
          <div class="loading" v-if="loading">
            <img class="loadingImg" src="@/assets/style/loading.gif" alt="" />
          </div>
          <div
            v-if="item.webContent"
            id="webContent"
            class="webContent"
            v-html="item.webContent"
          ></div>
          <!-- <div class="editTime">编辑于08-28</div> -->
        </div>
        <div class="signalBtn">
          <div class="clickButton" @click="comment()">
            <img
              class="imgCommon"
              src="@/assets/style/icon_signal_comment@2x.png"
              alt=""
            />
            <img
              class="imgHover"
              src="@/assets/style/icon_signal_comment_hover.png"
              alt=""
            />
            {{ commentNum }}
            <template v-if="!isMobile">
            条评论
            </template>
          </div>
          <div
            class="clickButton"
            :class="{ btnClicked: isPraise }"
            @click="clickSignalBtn('praise')"
          >
            <template v-if="!isPraise">
              <img
                class="imgCommon"
                src="@/assets/style/icon_like@2x.png"
                alt=""
              />
              <img
                class="imgHover"
                src="@/assets/style/icon_like_hover.png"
                alt=""
              />
            </template>
            <template v-else>
              <img src="@/assets/style/icon_like_hover.png" alt="" />
            </template>
            {{ praiseNum }}
            <template v-if="!isMobile">
            个点头
            </template>
          </div>
          <div
            class="clickButton"
            :class="{ btnClicked: isCatch }"
            @click="clickSignalBtn('catch')"
          >
            <template v-if="!isCatch">
              <img
                class="imgCommon"
                src="@/assets/style/btn_article_catch_default@2x.png"
                alt=""
              />
              <img
                class="imgHover"
                src="@/assets/style/btn_article_catch_default_hover.png"
                alt=""
              />
            </template>
            <template v-else>
              <img src="@/assets/style/btn_article_catched@2x.png" alt="" />
            </template>
            <template v-if="!isMobile">
            {{ catchText }}
            </template>
          </div>
        </div>
        <!-- 以下是评论部分 -->
        <div class="comment">
          <div class="commentTab">评论 {{ commentNum }}</div>
          <div class="sendComment">
            <div class="commentForm">
              <div class="authorHead">
                <div class="authorPhoto">
                  <img v-if="isLogin" class="imgCut" :src="avatar" alt="" />
                  <img
                    v-else
                    class="imgCut"
                    src="@/assets/style/img_portrait@2x.png"
                    alt=""
                  />
                </div>
              </div>
              <el-input
                type="textarea"
                placeholder="我来评论..."
                ref="signalComment"
                v-model="commentMessage"
                maxlength="500"
                show-word-limit
                @focus="focusComment"
                @keyup.enter="
                  submitComment(commentMessage, commentMusic, imageUrl)
                "
                spellcheck="false"
                autosize
              >
              </el-input>
            </div>
            <div class="commentAttach">
              <div v-if="commentHasMusic" class="commentMusic">
                <img
                  class="closeBtnImg"
                  src="@/assets/style/btn_close@2x (1).png"
                  alt=""
                  @click="deleteAttach('music')"
                />
                <div class="modulePic">
                  <img class="imgCut" :src="commentMusic.pic" alt="" />
                </div>
                <div class="musicInfo">
                  <div class="musicName textOverflow">{{ commentMusic.title }}</div>
                  <div class="musicArtist textOverflow">{{ commentMusic.artist }}</div>
                </div>
              </div>
              <div v-if="imageUrl" class="commentEditImg">
                <img class="imgCut" :src="imageUrl" alt="" />
                <img
                  class="closeBtnImg"
                  src="@/assets/style/btn_close@2x (1).png"
                  alt=""
                  @click="deleteAttach('image')"
                />
              </div>
            </div>
            <div class="commentBtnLine">
              <span class="clickButton musicBtn" @click="addMusicVideo()">
                <img v-if="themeColor=='light'" src="@/assets/style/btn_music_2.png" alt="" />
                <img v-else src="@/assets/style/btn_music@2x.png" alt="" />
              </span>
              <AddMusic ref="mvDialog" @musicHandler="addMusicToComment" />
              <div @click="focusComment">
                <el-upload
                  :action="uploadUrl"
                  :headers="uploadReqHeader"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :disabled="!isLogin"
                >
                  <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar" /> -->
                  <img v-if="themeColor=='light'" src="@/assets/style/btn_pic_2.png" alt="" />
                  <img v-else src="@/assets/style/btn_pic@2x.png" alt="" />
                </el-upload>
              </div>
              <span
                class="clickButton DROPBtn"
                :class="{ buttonDisabled: dropDisable }"
                @click="submitComment(commentMessage, commentMusic, imageUrl)"
              >
                <img
                  v-if="dropDisable"
                  src="@/assets/style/btn_send_default.png"
                  alt=""
                />
                <img v-else src="@/assets/style/btn_send.png" alt="" />
              </span>
            </div>
          </div>
          <div class="devider"></div>
          <!-- 评论列表 -->
          <template v-if="commentList.length">
            <div class="commentList" v-infinite-scroll="loadmore">
              <Comment
                v-for="commentItem in commentList"
                :commentItem="commentItem"
                :key="commentItem.comment.id"
                @deleteHandler="localReduce"
                @commentHandler="localComment"
              />
              <div
                v-if="loadingMore"
                class="loadmore loading"
                :style="{ visibility: loadingMore ? 'visible' : 'hidden' }"
              >
                <img
                  class="loadingImg"
                  src="@/assets/style/loading.gif"
                  alt=""
                />
              </div>
            </div>
          </template>
          <div
            v-if="!commentLoading && !commentList.length"
            class="searchEmpty"
          >
            <img v-if="themeColor=='light'"
                 class="searchEmptyImg commentEmpty"
                 src="@/assets/style/comment empty.png"
                 alt=""
            />
            <img v-else
              class="searchEmptyImg commentEmpty"
              src="@/assets/style/comment 1@2x.png"
              alt=""
            />
            <div class="searchEmptyText commentEmpty">暂无评论，等你发声</div>
          </div>
        </div>
      </div>
      <!-- 以下是推荐阅读模块 -->
      <div class="right" v-if="!isMobile">
        <div class="rightField">
          <div class="rightTab halfBottom">
            <div class="rightTabText">推荐阅读</div>
            <div class="refresh">
              <img
                class="refreshImg"
                src="@/assets/style/refresh 1@2x.png"
                alt=""
              />
              <div class="refreshText" @click="pageTurn(pageNum)">切一切</div>
            </div>
          </div>
          <div class="rightList">
            <RecoArticle
              v-for="(item, idx) in recoArticles"
              :recoItem="item"
              :key="idx"
            />
          </div>
        </div>
        <!-- 以下是网站信息 -->
        <div class="sticky">
          <!-- 以下是网站信息 -->
          <FirstFooter />
        </div>
      </div>
      <!-- 去考试弹窗 -->
      <el-dialog :visible.sync="toExamDialog" class="exam_1" center :show-close="false" width="320px" :close-on-click-modal="false" :close-on-press-escape="false" top="40vh">
        <span style="font-size: 16px; font-weight: 400;">暂时还没有发布权限<br/>需要先通过电音小测试才能开通</span>
        <span slot="footer" class="dialog-footer">
          <el-button style="background-color:transparent; color: #9c5cf7; border: 1px solid #9c5cf7; border-radius: 43px; cursor: pointer;"
            @click="toExamDialog = false">下次再说</el-button>
          <el-button type="primary" style="border-radius: 43px; cursor: pointer; marign-left: 20px;" @click="toExam()">放马过来!</el-button>
        </span>
      </el-dialog>
    </div>
    <template>
      <el-backtop target=".home-wrapper" :bottom="100" :visibility-height="100">
        <div class="backTop">UP</div>
      </el-backtop>
    </template>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import Viewer from "viewerjs";
import WebHeader from "@/components/WebHeader.vue";
import FirstFooter from "@/components/FirstFooter.vue";
import RecoArticle from "@/components/RecoArticle.vue";
import Comment from "@/components/Comment.vue";
import request from "@/utils/request";
import {
  openSignal,
  openTopic,
  openEncy,
  openPersonal,
  // locationMobileUrl,
} from "@/utils/navigate";
import AddMusic from "@/components/AddMusic.vue";
import CancelFollow from "@/components/CancelFollow.vue";

// import { mapGetters } from 'vuex';

export default {
  components: {
    WebHeader,
    FirstFooter,
    RecoArticle,
    Comment,
    AddMusic,
    CancelFollow,
  },
  computed: {
    ...mapGetters([
      "nickname",
      "avatar",
      "id",
      "isLogin",
      "isRealOfficial",
      "isOfficial",
      "expLogo",
      "token"
    ]),
    isMine() {
      return this.item.memberId === this.id;
    },
    uploadReqHeader() {
      return {
        'X-Duopin-Token': this.token
      }
    },
    dropDisable() {
      return !this.commentMessage && !this.commentHasMusic && this.imageUrl == "";
    },
  },
  data() {
    return {
      item: {},
      recoArticles: [],
      pageNum: Math.ceil(Math.random() * 19 + 2),
      loading: true,
      commentLoading: true,
      commentList: [],
      commentNum: 0,
      viewer: null,
      signalId: 0,
      lastId: 0,
      loadingMore: false,
      showLoadmoreTimer: null,
      praiseNum: 0,
      commentMessage: "",
      followText: "+关注",
      imageUrl: "",
      isCatch: false,
      isPraise: false,
      isAttention: false,
      catchText: "捕捉",
      commentHasMusic: false,
      commentMusic: {
        title: "",
        artist: "",
        pic: "",
        originalUrl: "",
      },
      uploadUrl: `${process.env.VUE_APP_API}/dp/common/upload`,
      toExamDialog: false,
      themeColor: 'dark',
    };
  },
  // computed: {
  //   ...mapGetters(['userId']),

  // },
  watch: {
    '$root.themeColor': {
      immediate: true,
      handler(newVal) {
        this.themeColor = newVal;
      },
    },
    isCatch(val) {
      if (val) {
        this.catchText = "已捕捉";
      } else this.catchText = "捕捉";
    },
    isAttention(val) {
      if (val) {
        this.followText = "已关注"
      }
      else this.followText = "+关注"
    },
    commentHasMusic(val) {
      if (!val) {
        this.commentMusic = {
          title: "",
          artist: "",
          pic: "",
          originalUrl: "",
          typeId: 0,
        };
      }
    },
  },
  methods: {
    localComment(localCommentData) {
      this.commentList.unshift(localCommentData);
      this.commentNum++;
      this.$message({
          message: "发送成功",
          type: "success",
        });
    },
    localReduce(isDelete) {
      if (isDelete) {
        this.commentNum--;
      }
    },
    localCancel(confirmCancel) {
      if (confirmCancel) {
        this.isAttention = false;
      }
    },
    deleteAttach(type) {
      if(type == "music") {
        this.commentHasMusic = false;
      }
      else if(type == "image") {
        this.imageUrl = "";
      }
    },
    addMusicToComment(music) {
      this.commentHasMusic = true;
      this.commentMusic = music;
    },
    focusComment() {
      if (this.isLogin) {
        return;
      } else this.$login();
      return;
    },
    async clickSignalBtn(btn) {
      if (!this.isLogin) {
        this.$login();
        return;
      } else if (btn == "praise") {
        const isPraiseRes = await request.post("/app/praise/submit", {
          contentId: this.signalId,
          type: 1,
          toMemberId: this.item.memberId,
        });
        if (isPraiseRes.code == 200) {
          this.isPraise = !this.isPraise;
          if (this.isPraise) {
            this.praiseNum++;
          } else this.praiseNum--;
        }
      } else if (btn == "catch") {
        const isCatchRes = await request.get("/dp/collect/collectArticle", {
          params: {
            articleId: this.signalId,
          },
        });
        if (isCatchRes.code == 200) {
          this.isCatch = !this.isCatch;
        }
      }
    },
    comment() {
      this.$nextTick(() => {
        this.$refs.signalComment.focus();
      });
    },
    async submitComment(message, music, pic) {
      if (!message && !this.commentHasMusic && pic == "") {
        return;
      }
      const submitRes = await request.post("/dp/comment/submit", {
        articleId: this.signalId,
        articleMemberId: this.item.memberId,
        hasMusic: this.commentHasMusic ? 1 : 0,
        musicAuthor: music.artist,
        musicCover: music.pic,
        musicLinkUrl: music.originalUrl,
        musicName: music.title,
        musicOriginLinkUrl: music.originalUrl,
        musicTypeId: music.typeId,
        picture: pic,
        content: message,
      });
      if (submitRes.code == 200) {
        this.commentList.unshift(submitRes.data);
        this.commentNum++;
        this.$message({
          message: "发送成功",
          type: "success",
        });
        this.commentMessage = "";
        this.commentHasMusic = false;
        this.imageUrl= "";
      }else if(submitRes.code == 10003) {
        this.toExamDialog = true;
      }

      console.log(music, pic);
    },

    toSignal() {
      openSignal(this.item.esArticle.id);
    },
    toTopic(target) {
      openTopic(target.id);
    },
    toPersonal() {
      if (this.item.member.deleted == 1) {
        return this.$message("用户已注销");
      }
      else
      openPersonal(this.item.member.id);
    },
    // 相关推荐切一切
    async pageTurn(pageNum) {
      this.pageNum = pageNum + 1;
      const recoRes = await request.post("/dp/article/list", {
        orderType: "HOME",
        pageNum: this.pageNum,
        pageSize: 5,
      });
      this.recoArticles = recoRes.data.records;
    },

    // iframe黑色样式
    addDarkClass(domString) {
      try {
        let parser = new DOMParser();
        let newDom = parser.parseFromString(domString, "text/html");
        const iframes = newDom.querySelectorAll("iframe");
        if (localStorage.getItem('theme') == 'light') {
          iframes.forEach((iframe) => (iframe.src));
        } else {
          iframes.forEach((iframe) => (iframe.src += "&darkMode=true"));
        }
        return newDom.body.innerHTML;
      } catch {
        return domString;
      }
    },

    // 滚动加载评论
    async loadmore() {
      if (this.loadingMore || !this.commentList.length || this.lastId === -1) {
        return;
      }
      this.loadingMore = true;
      const commentRes = await request.get("/dp/comment/list", {
        params: {
          articleId: this.signalId,
          lastId: this.lastId,
          pageSize: 10,
        },
      });
      this.lastId = commentRes.data.lastId;
      this.commentList = this.commentList.concat(commentRes.data.commentVoList);
      this.loadingMore = false;
    },
    handleIframeMessage(event) {
      switch (event?.data?.name) {
        case "clickMusic":
          window.open(event?.data?.data?.originalUrl);
          break;
        case "clickBaike":
          openEncy(event.data.data.item.typeId, event.data.data.item.dataId);
          break;
      }
    },
    handleAvatarSuccess(res, file) {
      console.log('===: ', res, file)
      if (!this.isLogin) {
        this.$login();
        return;
      }
      this.imageUrl = res.data.url
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt4M = file.size / 1024 / 1024 < 4;

      if (!isJPG && !isPNG) {
        this.$message.error("请上传 JPG 或 PNG 格式的图片");
      }
      if (!isLt4M) {
        this.$message.error("上传的图片大小不能超过 4MB 哦");
      }
      return (isJPG || isPNG) && isLt4M;
    },
    addMusicVideo() {
      if (!this.isLogin) {
        this.$login();
        return;
      }
      this.$refs["mvDialog"].contentVisible = true;
    },
    async clickFollow() {
      if (!this.isLogin) {
        this.$login();
        return;
      }
      if (this.isAttention) {
        this.$refs["followDialog"].centerDialogVisible = true;
        return;
      }
      const followRes = await request.get("/dp/fans/attention", {
          params: {
            idolId: this.item.memberId,
          },
        });
        if (followRes.code == 200) {
          this.isAttention = true;
        }
    },
    toExam(){
      window.open(`/#/exam`)
      this.toExamDialog = false;
    },
  },
  mounted() {
    this.themeColor = localStorage.getItem('theme');
    this.$root.themeColor = this.themeColor;
  },
  async created() {
    window.addEventListener("message", this.handleIframeMessage, false);
    this.signalId = this.$route.query.id;
    // locationMobileUrl("signal",this.signalId);
    console.log("this.signalId=", this.signalId);
    const res = await request.get("/dp/article/detail", {
      params: {
        id: this.signalId,
      },
    });
    this.item = res.data;
    this.isCatch = res.data.isCollection;
    this.praiseNum = this.item.praiseNum;
    this.commentNum = this.item.commentNum;
    this.isPraise = this.item.ifPraise;
    this.isAttention = this.item.member.isAttention;
    document.title = `${this.item.title} - DROPIN多频`;
    // iframe黑暗样式
    this.item.webContent = this.addDarkClass(this.item.webContent);
    // 点击放大图片
    this.$nextTick(() => {
      this.viewer = new Viewer(document.getElementById("webContent"), {
        toolbar: false,
        navbar: false,
        title: false,
        movable: false,
        minZoomRatio: 0.3,
        maxZoomRatio: 3,
      });
    });
    console.log("===res: ", res);
    this.loading = false;

    // 初始化相关推荐
    const recoRes = await request.post("/dp/article/list", {
      orderType: "HOME",
      pageNum: this.pageNum,
      pageSize: 5,
    });
    this.recoArticles = recoRes.data.records;

    // 初始化评论列表
    const commentRes = await request.get("/dp/comment/list", {
      params: {
        articleId: this.signalId,
        pageSize: 10,
      },
    });
    console.log("🌟 commentRes", commentRes);
    this.commentList = commentRes.data.commentVoList;
    this.lastId = commentRes.data.lastId;
    this.commentLoading = false;
    console.log("🌟 this.lastId", this.lastId);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleIframeMessage, false);
    if (this.viewer && this.viewer.destroy) {
      this.viewer.destroy();
    }
  },
};
</script>
<style>
.backtop {
  width: 50px;
  height: 50px;
  background: rgb(255, 0, 0);
}
.commentText {
  word-wrap: break-word;
}
.searchEmptyImg.commentEmpty {
  width: 88px;
  margin-top: 30px;
  margin-bottom: 16px;
}

.searchEmptyText.commentEmpty {
  margin-bottom: 40px;
}
.clickButton {
  text-align: center;
  cursor: pointer;
  user-select: none;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  color: #b1b1b1;
}
.clickButton.followBtn {
  margin-left: auto;
  border-radius: 21px;
  border: 1px solid #9c5cf7;
  padding: 4px 15px;
  color: #9c5cf7;
}
.signalBtn img {
  width: 17px;
  margin-right: 6px;
}
.commentForm textarea {
  resize: none;
  color: var(--font-color);
  width: 100%;
  padding: 10px;
  border: 1px solid #a4a4a4;
  border-radius: 8px;
  height: 48px;
  background: var(--background-color-item-hover);
  font-size: 13px;
}
textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.commentForm .el-textarea .el-input__count {
  bottom: -24px;
  right: 10px;
}
.el-upload img {
  width: 20px;
  height: 20px;
}
.avatar-uploader {
  width: 20px;
  height: 20px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
}
.avatar {
  width: 20px;
  height: 20px;
  display: block;
}
.el-textarea__inner:focus {
  background: var(--background-color-item);
  border: 1px solid #9c5cf7;
  outline: none;
}
.el-textarea .el-input__count {
  background: transparent;
}
.devider {
  height: 1px;
  width: 100%;
  background: var(--color-15);
  margin: 20px 0;
}
.commentBtnLine {
  display: flex;
  flex-shrink: 0;
}
.commentBtnLine .clickButton {
  height: 18px;
}
.clickButton.DROPBtn {
  margin-left: auto;
  border-radius: 21px;
  padding: 6px 15px;
  font-weight: 600;
  background: #9c5cf7;
  float: right;
  margin-top: 20px;
}
.clickButton.musicBtn img {
  width: 20px;
  height: 20px;
}
.clickButton.musicBtn {
  margin: 0 18px 0 40px;
}
.clickButton.DROPBtn.buttonDisabled {
  /*background: #484848;*/
  background: var(--el-button--primary-disabled-background-color);
  cursor: not-allowed;
}
.clickButton.DROPBtn img {
  width: 37px;
}
.imgHover {
  display: none;
}
@media (any-hover: hover) {
.clickButton:hover .imgHover {
  display: block;
}
.clickButton:hover .imgCommon {
  display: none;
}
}
.clickButton.followBtn.followedBtn {
  background: var(--el-button--primary-disabled-background-color);;
  border: none;
  color: var(--color-40);
}
/* 移动端适配样式 */
.mobilePage .comment {
  padding: 0 20px 12px 20px;
}
.mobilePage .signalDetail {
  padding: 20px;
}
.mobilePage .signalBtn img {
  width: 20px;
}

</style>

<style scoped>
.sendComment {
  margin-top: 20px;
}
@media (any-hover: hover) {
.signalBtn .clickButton:hover {
  color: #9c5cf7;
}
}
.detailAuthorRight {
  cursor: pointer;
}
.detailAuthorRight:hover .authorName {
  color: #9c5cf7;
}
.signalBtn {
  display: flex;
  align-items: center;
  position: sticky;
  bottom: 0;
  background: var(--background-color-item);
  padding: 21px 40px;
}
.signalBtn .clickButton {
  color: #b1b1b1;
  margin-right: 44px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 18px;
}

.commentForm {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.commentForm .authorHead {
  margin-right: 8px;
}
.commentAttach {
  margin: 0 0 16px 38px;
}
.commentAttach .commentMusic {
  width: 70%;
}
.commentAttach .closeBtnImg {
  width: 12px;
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;
  padding: 2px 3px;
  border-radius: 50%;
  background: var(--el-button--primary-disabled-background-color);;
  box-shadow: 0 0 2px rgba(0,0,0,0.25);
}
.closeBtnImg:hover {
  background: #666;
}
.commentMusic {
  position: relative;
  cursor: auto;
}
.commentMusic:hover {
  background: var(--background-color-item-hover);
}
.commentEditImg {
  width: 120px;
  height: 120px;
  border-radius: 4px;
  margin-top: 12px;
  overflow: hidden;
  position: relative;
}
.clickButton.btnClicked {
  color: #9c5cf7;
}
.mobilePage .signalBtn {
  padding: 16px 20px;
}
.mobilePage .signalBtn .clickButton {
  margin-right: 24px;
}
.el-button+.el-button {
    margin-left: 20px;
}
</style>