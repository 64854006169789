<template>
<div>
  <el-dialog
    :append-to-body="true"
    class="followDialog setPwDialog"
    :class="{ mobilePage: isMobile }"
    title="修改密码"
    :visible.sync="centerDialogVisible"
    center
    :close-on-click-modal="false"
    width="437px"
    :destroy-on-close="true"
  >
    <el-form  :model="setPwForm" :rules="rules" ref="setPwForm">
      <el-form-item label="" prop="mobile">
        <el-input
          ref="mobile"
          v-model="mobile"
          placeholder="请输入手机号"
          spellcheck="false"
          autocomplete="off"
          name="mobile"
          type="text"
          class="halfBottom"
          :disabled="true"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="code" class="codeInput">
        <el-input
          ref="code"
          v-model="setPwForm.code"
          placeholder="请输入验证码"
          maxlength="6"
          autocomplete="off"
          @keyup.enter.native="handleLogin"
          spellcheck="false"
          name="code"
          type="text"
          class="halfBottom"
        ></el-input>
        <el-button
          class="codeBtn"
          :style="{ color: timer === 0 ? '#9C5CF7' : '#b1b1b1' }"
          ref="codeBtn"
          :loading="sending"
          :disabled="sendDisabled"
          size="small"
          @click="onSendSms"
          >{{ sendButtonText }}</el-button
        >
      </el-form-item>

      <el-tooltip
        v-model="capsTooltip"
        content="大写已打开"
        placement="right"
        manual
      >
        <el-form-item class="passwordItem" label="" prop="password">
          <el-input
            ref="password"
            v-model="setPwForm.password"
            placeholder="请输入新密码"
            :type="passwordType"
            name="password"
            tabindex="2"
            autocomplete="new-password"
            spellcheck="false"
            @keyup.native="checkCapslock"
            @blur="capsTooltip = false"
            class="halfBottom"
          ></el-input>
          <span class="show-pwd" @click="showPwd(1)">
            <div class="passwordView" v-show="passwordType == 'password'">
              <img src="@/assets/style/btn_close@2x.png" alt="" />
            </div>
            <div class="passwordView" v-show="passwordType == ''">
              <img src="@/assets/style/btn_open@2x.png" alt="" />
            </div>
          </span>
        </el-form-item>
      </el-tooltip>
      <el-tooltip
        v-model="capsTooltip"
        content="大写已打开"
        placement="right"
        manual
      >
        <el-form-item class="passwordItem" label="" prop="passwordConfirm">
          <el-input
            ref="passwordConfirm"
            v-model="setPwForm.passwordConfirm"
            placeholder="请再次输入密码"
            :type="passwordType"
            name="passwordConfirm"
            tabindex="2"
            autocomplete="new-password"
            spellcheck="false"
            @keyup.native="checkCapslock"
            @blur="capsTooltip = false"
            class="halfBottom"
          ></el-input>
          <span class="show-pwd" @click="showPwd(2)">
            <div class="passwordView" v-show="passwordType == 'password'">
              <img src="@/assets/style/btn_close@2x.png" alt="" />
            </div>
            <div class="passwordView" v-show="passwordType == ''">
              <img src="@/assets/style/btn_open@2x.png" alt="" />
            </div>
          </span>
        </el-form-item>
      </el-tooltip>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="centerDialogVisible = false">取消</el-button>
      <el-button type="primary" @click="clickConfirm()">确定</el-button>
    </span>
  </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import request from "@/utils/request";
export default {
  data() {
    const validateCode = (rule, value, callback) => {
      if (value.length < 6 || value === "") {
        callback(new Error("验证码错误"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6 || value === "") {
        callback(new Error("密码必须至少有6个字符"));
      } else {
        callback();
      }
    };
    const validatePasswordConfirm = (rule, value, callback) => {
      if (value.length < 6 || value === "") {
        callback(new Error("密码必须至少有6个字符"));
      } 
      else if (value !== this.setPwForm.password) {
        callback(new Error("两次输入密码不一致"));
      }
      else {
        callback();
      }
    };
    return {
      mobile: "",
      setPwForm: {
        code: "",
        password: "",
        passwordConfirm: "",
      },
      rules: {
        passwordConfirm: [
          {
            required: true,
            trigger: "blur",
            validator: validatePasswordConfirm,
          },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
        code: [{ required: true, trigger: "blur", validator: validateCode }],
      },
      capsTooltip: false,
      passwordType: "password",
      sending: false,
      loading: false,
      centerDialogVisible: false,
      timer: 0,
      sendDisabled: false,
    };
  },
  computed: {
    sendButtonText() {
      if (this.timer === 0) {
        return "获取验证码";
      } else {
        return `${this.timer}s后重新发送`;
      }
    },
    ...mapGetters([
      "isLogin",
      "mobilePhone",
    ]),
  },
  watch: {
    timer(num) {
      if (num > 0) {
        setTimeout(() => {
          this.timer = --num;
        }, 1000);
      }
    },
    mobilePhone(val) {
      if (val) {
        this.mobile = this.mobilePhone;
      }
    },
  },
  methods: {
    checkCapslock(e) {
      const { key } = e;
      this.capsTooltip = key && key.length === 1 && key >= "A" && key <= "Z";
    },
    showPwd(type) {
      if (this.passwordType == "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      if (type == 1) {
        this.$nextTick(() => {
        this.$refs.password.focus();
      });
      }
      else this.$nextTick(() => {
        this.$refs.passwordConfirm.focus();
      });
      
    },
    onSendSms() {
      this.$refs["setPwForm"].validateField("mobile", (valid) => {
        if (valid && valid !== "") {
          return false;
        } else {
          this.sending = true;
          this.$store
            .dispatch("user/regCaptcha", this.setPwForm)
            .then(() => {
              this.$message.success("短信发送成功，请注意查收");
              this.timer = 60;
            })
            .catch(() => {
              this.sending = false;
            })
            .finally(() => (this.sending = false));
        }
      });
    },
    clickConfirm() {
      this.$refs['setPwForm'].validate(async (valid) => {
        if (valid) {
          const setPwRes = await request.post("/dp/member/resetPwd", {
            mobile: this.mobile,
            code: this.setPwForm.code,
            password: this.setPwForm.password
          });
          if (setPwRes.code == 200) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log('校验不通过！error submit!!');
          return false;
        }
      });
    },
  },
};
</script>

<style>
.setPwDialog .el-input {
  display: inline-block;
  height: 47px;
  width: 100%;
  font-weight: 300;
}
.setPwDialog .el-input .el-input__inner {
  background: transparent;
  border: 0 !important;
  border-bottom: none;
  -webkit-appearance: none;
  border-radius: 0px;
  padding: 12px 5px 12px 15px;
  color: #fff;
  height: 47px;
}
.setPwDialog.followDialog {
    margin-top: 6vh;
}
.setPwDialog.followDialog .codeBtn.el-button {
    border: none;
}
.setPwDialog input:-internal-autofill-selected {
    box-shadow: inset 0 0 0 1000px #1f1f1f !important;
}
.el-dialog__headerbtn .el-dialog__close:hover {
    color: #9c5cf7;
}
.setPwDialog.followDialog .el-dialog {
  background: var(--background-color-item-hover);
}
.mobilePage.setPwDialog.followDialog .el-dialog {
  width: 320px !important;
}
.mobilePage.setPwDialog.followDialog .el-dialog__footer {
  padding-bottom: 30px;
}
.mobilePage.setPwDialog.followDialog .el-dialog--center .el-dialog__body {
  padding: 10px 20px;
}
</style>