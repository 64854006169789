<template>
  <!-- 以下是头部板块 -->
  <div class="home-wrapper" :class="{ mobilePage: isMobile }">
    <web-header :switchHeight="120">
      <!-- 里面放滚动切换的内容 -->
      <div class="headSticky scrollSticky" key="scroll">
        <div class="head">
          <div class="headLeft">
            <div id="logo" @click="selectMenuItem()" v-if="!isMobile">
              <img class="imgCut" src="@/assets/style/DPlogo.png" alt="" />
            </div>
            <div class="tabSticky tabSearch scrollSticky">
              <div class="articleTab">
                <div
                  class="tab"
                  :class="{
                    selected: tab === encyType.keyword,
                    unselect: tab !== encyType.keyword,
                  }"
                  v-for="(encyType, idx) in encyFirstType"
                  @click="encyTab(encyType)"
                  :key="idx"
                >
                  <div>{{ encyType.keyword }}</div>
                  <div
                    v-if="tab === encyType.keyword"
                    class="selectedIcon"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="headRight" v-if="!isMobile">
            <div class="search topicSearch">
              <input
                v-model="encyMessage"
                placeholder="搜索百科"
                class="searchInput"
                @keyup.enter="clickSearch(encyMessage)"
              />
              <div class="searchIcon" @click="clickSearch(encyMessage)">
                <img
                  class="searchIconImg"
                  src="@/assets/style/icon_search_pedia@2x.png"
                  alt=""
                />
                <img
                  class="searchIconImg searchIconFocus"
                  src="@/assets/style/icon_search_pedia@2x_white.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </web-header>
    <!-- 以下是内容部分 -->
    <div class="content">
      <div class="left">
        <div class="topicHead topicSearch">
          <div class="allTopic">全部百科</div>
          <div class="search topicSearch">
            <input
              v-model="encyMessage"
              placeholder="搜索百科"
              class="searchInput"
              ref="encySearch"
              @keyup.enter="clickSearch(encyMessage)"
            />
                        <img
              class="closeBtnImg"
              src="@/assets/style/btn_close@2x (1).png"
              alt=""
              v-show="isMobile && encyMessage"
              @click="clearInput()"
            />
            <div class="searchIcon" @click="clickSearch(encyMessage)">
              <img
                class="searchIconImg"
                src="@/assets/style/icon_search_pedia@2x.png"
                alt=""
              />
              <img
                class="searchIconImg searchIconFocus"
                src="@/assets/style/icon_search_pedia@2x_white.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- 以下是百科的分类 -->
        <div>
          <div class="tabSticky topic halfBottom ency">
            <div class="articleTab topicSearch ency">
              <div
                class="tab"
                :class="{
                  selected: tab === encyType.keyword,
                  unselect: tab !== encyType.keyword,
                }"
                v-for="(encyType, idx) in encyFirstType"
                @click="encyTab(encyType)"
                :key="idx"
              >
                <div>{{ encyType.keyword }}</div>
                <div v-if="tab === encyType.keyword" class="selectedIcon"></div>
              </div>
            </div>
          </div>
          <!-- 以下是百科的二次筛选分类 -->
          <div class="tabSticky topic">
            <div class="articleTab topicSearch ency">
              <div
                class="flex screen"
                v-for="(secondType, typeIdx) in encySecondType"
                :key="typeIdx"
                @click.stop="tabHoverShow = true"
              >
                <div class="tab ency">
                  <div>
                    <template v-if="!secondTabList[typeIdx]">
                      {{ secondType.keyword }}
                    </template>
                    <template v-else>
                      <div class="selected">
                        {{ secondTabList[typeIdx] }}
                      </div>
                    </template>
                  </div>

                  <img
                    class="encySelect"
                    src="@/assets/style/up@2x.png"
                    alt=""
                  />
                  <div
                    class="encyTabHover flex"
                    :class="{
                      sort: secondType.keyword === '排序',
                    }"
                    v-show="!isMobile || (isMobile && tabHoverShow)"
                  >
                    <div class="secondType" @click="encySecondTab({}, typeIdx)">
                      不限
                    </div>
                    <div
                      class="secondType"
                      v-for="(secondTypeItem, idx) in secondType.encySearchList"
                      :key="idx"
                      @click.stop="encySecondTab(secondTypeItem, typeIdx)"
                    >
                      {{ secondTypeItem.keyword }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 以下是全部百科的百科列表 -->
          <div class="resultList" v-infinite-scroll="loadmore">
            <div class="loading" v-if="loading">
              <img class="loadingImg" src="@/assets/style/loading.gif" alt="" />
            </div>
            <template v-else-if="encyResult.length">
              <EncySearch
                v-for="(item, idx) in encyResult"
                :encySearchItem="item"
                :key="idx"
              />
              <div
                v-if="loadingMore"
                class="loadmore loading"
                :style="{ visibility: loadingMore ? 'visible' : 'hidden' }"
              >
                <img
                  class="loadingImg"
                  src="@/assets/style/loading.gif"
                  alt=""
                />
              </div>
              <div
                class="noMoreData"
                v-if="
                  !loadingMore && !loading && noMoreData && encyResult.length
                "
              >
                - 没有更多内容了哟 -
              </div>
            </template>
            <template v-else>
              <div class="searchEmpty">
                <img v-if="themeColor=='light'" class="searchEmptyImg" src="@/assets/style/find empty.png" alt="" />
                <img v-else class="searchEmptyImg" src="@/assets/style/empty.gif" alt="" />
                <div class="searchEmptyText">很可惜，没有找到相关信息</div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="right" v-if="!isMobile">
        <div class="sticky">
          <!-- 创作后台 -->
          <div class="rightField">
            <div class="rightTab halfBottom">
              <div class="rightTabText">创作后台</div>
            </div>
            <div class="creatorFlex">
              <div>
                <div
                  class="creatorBtn"
                  style="background: rgba(77, 145, 174, 0.2)"
                  @click="toCreatorAddDialog('ency')"
                >
                  <img src="@/assets/style/icon_creator_pedia@2x.png" alt="" />
                </div>
                编辑百科
              </div>
              <div>
                <div class="creatorBtn" @click="toCreatorAddDialog('signal')">
                  <img src="@/assets/style/icon_signal_grey.png" alt="" />
                </div>
                发信号
              </div>

              <div>
                <div class="creatorBtn" @click="toCreatorAddDialog('topic')">
                  <img
                    src="@/assets/style/icon_topic_creator_grey.png"
                    alt=""
                  />
                </div>
                新建频段
              </div>

              <div>
                <div class="creatorBtn" @click="toCreatorAddDialog('quiz')">
                  <img src="@/assets/style/icon_qa_creator_grey.png" alt="" />
                </div>
                新建频一频
              </div>
            </div>
            <div class="toCreatorBtn pointer" @click="toCreatorAddDialog()">
              进入创作后台 >
            </div>
          </div>
          <!-- 二级页面的网站信息 -->
          <FirstFooter />
        </div>
      </div>
    </div>
    <template>
      <el-backtop target=".home-wrapper" :bottom="100" :visibility-height="100">
        <div class="backTop">UP</div>
      </el-backtop>
    </template>
  </div>
</template>

<script>
import WebHeader from "@/components/WebHeader";
import FirstFooter from "@/components/FirstFooter";
import request from "@/utils/request";
import { openEncy, jumpToCreatorAndAdd } from "@/utils/navigate";
import EncySearch from "@/components/EncySearch";

export default {
  components: {
    WebHeader,
    FirstFooter,
    EncySearch,
  },
  data() {
    return {
      tab: "",
      loading: false,
      loaded: false,
      encyFirstType: [],
      encyResult: [],
      encySecondType: [],
      encySecondTypeItem: [],
      secondTabList: [],
      encySearchList: [],
      loadingMore: false,
      encyPageNum: 1,
      encyType: {},
      totalPages: 1,
      encyMessage: "",
      showLoadmoreTimer: null,
      noMoreData: false,
      tabHoverShow: false,
      themeColor: 'dark',
    };
  },
  watch: {
    '$root.themeColor': {
      immediate: true,
      handler(newVal) {
        this.themeColor = newVal;
      },
    },
  },
  mounted() {
    this.themeColor = localStorage.getItem('theme');
    this.$root.themeColor = this.themeColor;
  },
  async created() {
    document.title = "全部百科 - DROPIN多频";
    this.loading = true;
    const encyTypeRes = await request.get("/dp/ency/getChildCondition", {});
    this.encyFirstType = encyTypeRes.data.EncySearchVo;
    this.encyType = this.encyFirstType[0];
    this.tab = this.encyFirstType[0].keyword;
    this.encySecondType = this.encyFirstType[0].encySearchList;
    console.log("🌟 this.encySecondType", this.encySecondType);

    // 初始百科列表数据

    for (let i = 0; i < this.encyFirstType[0].encySearchList.length; i++) {
      this.encySearchList.push({});
      this.secondTabList.push("");
    }
    const encyListRes = await request.post("/dp/ency/searchChildPage", {
      encySearchList: this.encySearchList,
      pageNum: 1,
      pageSize: 20,
      typeId: this.encyFirstType[0].typeId,
    });
    this.loading = false;
    this.totalPages = encyListRes.data.pages;
    this.encyResult = encyListRes.data.records;
  },
  methods: {
    clearInput() {
      this.encyMessage = "";
      this.$nextTick(() => {
        this.$refs.encySearch.focus();
      });
    },
    toCreatorAddDialog(type) {
      jumpToCreatorAndAdd(type);
    },
    selectMenuItem() {
      this.url = "/";
      if (this.$route.fullPath === this.url) {
        // 点击的是当前路由 手动重定向页面到 '/redirect' 页面
        this.$router.replace({
          path: "/redirect",
          query: {
            path: encodeURI(this.url),
          },
        });
      } else {
        // 正常跳转
        this.$router.push({
          path: this.url,
        });
      }
    },
    toEncy(target) {
      openEncy(target.typeId, target.dataId);
    },
    // 切换一级tab
    async encyTab(encyType) {
      this.secondTabList = [];
      // this.encyResult = [];
      // 500ms后出loading
      const timer = setTimeout(() => {
        this.loading = true;
      }, 500);
      this.tab = encyType.keyword;
      this.encySecondType = encyType.encySearchList;
      this.encyType = encyType;
      this.encyPageNum = 1;
      // 切换一级tab后初始百科列表
      this.encySearchList = [];
      for (let i = 0; i < encyType.encySearchList.length; i++) {
        this.encySearchList.push({});
        console.log("🌟 this.encySearchList", this.encySearchList);
        this.secondTabList.push("");
      }
      const encyListRes = await request.post("/dp/ency/searchChildPage", {
        encySearchList: this.encySearchList,
        pageNum: this.encyPageNum,
        pageSize: 20,
        typeId: encyType.typeId,
      });
      this.loading = false;
      clearTimeout(timer);
      this.loaded = true;
      this.totalPages = encyListRes.data.pages;
      this.encyResult = encyListRes.data.records;
    },
    // 切换二级tab
    async encySecondTab(encySecondType, typeIdx) {
      // this.encyResult = [];
      this.loading = true;
      this.encyPageNum = 1;
      this.tabHoverShow = false;
      console.log("🌟 this.tabHoverShow", this.tabHoverShow);
      this.secondTabList.splice(typeIdx, 1, encySecondType.keyword);
      this.encySearchList.splice(typeIdx, 1, encySecondType);
      const encyListRes = await request.post("/dp/ency/searchChildPage", {
        encySearchList: this.encySearchList,
        pageNum: this.encyPageNum,
        pageSize: 20,
        typeId: this.encyType.typeId,
      });
      this.loading = false;
      this.totalPages = encyListRes.data.pages;
      this.encyResult = encyListRes.data.records;
      console.log("🌟 this.tabHoverShow", this.tabHoverShow);
    },
    // 滚动加载
    async loadmore() {
      if (this.loadingMore || this.loading) {
        return;
      }
      this.encyPageNum++;
      console.log("🌟 this.encyPageNum", this.encyPageNum);
      if (this.encyPageNum > this.totalPages) {
        return (this.noMoreData = true);
      }
      this.loadingMore = true;
      const encyListRes = await request.post("/dp/ency/searchChildPage", {
        encySearchList: this.encySearchList,
        pageNum: this.encyPageNum,
        pageSize: 20,
        typeId: this.encyType.typeId,
      });
      this.loadingMore = false;
      this.encyResult = this.encyResult.concat(encyListRes.data.records);
      console.log("🌟 this.encySearchList", this.encySearchList);
      console.log("🌟 this.encySecondType", this.encySecondType);
    },
    // 搜索百科
    clickSearch(encyMessage) {
      if (!encyMessage) {
        return;
      }
      window.open(`/#/search?keyword=${encyMessage}&type=ency`);
    },
  },
  // props: ["topic"],
};
</script>
<style>
.rightMsg {
  margin: 0 0 0 12px;
}
/* 移动端适配样式 */
.mobilePage .topicHead.topicSearch {
  width: auto;
}
.mobilePage .tabSticky.topic {
  width: 100%;
}
.mobilePage .tab.ency {
  margin-right: 0;
}
.mobilePage .encyTabHover {
  width: 290px;
}
.mobilePage .tabSticky.ency .articleTab {
  flex-wrap: nowrap;
  overflow: auto;
}
.mobilePage .tabSticky .articleTab::-webkit-scrollbar {
  display: none;
}
.mobilePage .tabSticky .articleTab .tab {
  flex-shrink: 0;
}
.mobilePage .tabSticky.tabSearch.scrollSticky .articleTab {
  flex-wrap: nowrap;
  overflow: auto;
}
.mobilePage .encyTabHover.sort {
  left: 24px;
}
.mobilePage .encyTabHover {
  left: -62px;
  z-index: 10;
}
</style>