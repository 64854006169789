<template>
  <el-dialog
  :append-to-body="true"
  class="followDialog"
    title=""
  :visible.sync="centerDialogVisible"
  width="331px"
  center>
  <span>确定要退出登录吗？</span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisible = false">取消</el-button>
    <el-button type="primary" @click="logout()">确定</el-button>
  </span>
</el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        centerDialogVisible: false
      };
    },
    methods: {
      confirmQuiz() {
        this.login = false;
        
      },
      async logout() {
      await this.$store.dispatch('user/logout')
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
      this.centerDialogVisible = false;
      location.reload();
    },
    }
  };
</script>