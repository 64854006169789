<template>
<!-- 首页热门频段模块 -->
  <div class="rightItem halfBottom" @click="toTopic(hotTopicItem)">
    <div class="topicPhoto">
      <img class="imgCut" :src="hotTopicItem.logo" alt="" />
    </div>
    <div class="rightMsg">
      <div class="itemName">{{hotTopicItem.name}}</div>
      <div class="itemBrief">{{hotTopicItem.attentionNum}}人已捕捉 · {{hotTopicItem.articleNum}}条信号</div>
    </div>
  </div>
</template>

<script>
import {  openTopic } from "@/utils/navigate";
export default {
    methods: {
    toTopic(target) {
      openTopic(target.id);
    },
  },
    props: ['hotTopicItem']
}
</script>