<template>
  <body>
    <div class="theme-switch-wrapper">
      <label class="theme-switch" for="checkbox">
        <input type="checkbox" id="checkbox"/>
        <div class="slider round"></div>
      </label>
    </div>
    <button v-if="flag==0" @click="switchTheme(1)" style="color: black">按钮0</button>
    <button v-if="flag==1" @click="switchTheme(0)" style="color: black">按钮1</button>
    <h1> Hi! This is test text </h1>
    <h2> This is smaller test text </h2>
    <h3> Smol test text </h3>
  </body>
</template>

<script>
export default {
  name: "test",
  data(){
    return {
      flag: 0,
    }
  },
  mounted() {
    /*const toggleSwitch = document.querySelector('.theme-switch input[type="checkbox"]');
    toggleSwitch.addEventListener('change', switchTheme, false)*/
  },
  methods: {
    switchTheme(flag) {
      if (flag == 0) {
        this.flag = 0;
        document.documentElement.setAttribute('data-theme', 'dark');
        localStorage.setItem('theme', 'dark');
      } else {
        this.flag = 1;
        document.documentElement.setAttribute('data-theme', 'light');
        localStorage.setItem('theme', 'light');
      }
    }
  }

}
</script>

<style scoped>
body{
  display: flex;
  align-items: center;
  justify-content:center;
  flex-direction: column;
  background-color: var(--background-color);
}

h1, h2, h3{
  color: var(--font-color);
}

.theme-switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
  border-radius: 15px;
}

.theme-switch input {
  display: none;
}

.slider {
  background-color: gray;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;

}

.slider::before {
  bottom: 4px;
  height: 26px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 26px;
  content:"🌞";

}

input:checked + .slider {
  background-color: gray;
}

input:checked + .slider::before {
  transform: translateX(26px);
  content:'🌙';

}

.slider.round {
  border-radius: 15px;
}

.slider.round::before {
  border-radius: 10%;
}
</style>