import Vue from "vue";
import LoginPopupConstructor from "./index.vue";
import store from "@/store";

const LoginPopupVm = Vue.extend(LoginPopupConstructor);

let instance;

function LoginPopup() {
  if (instance) {
    LoginPopup.close();
  }
  instance = new LoginPopupVm({
    store,
  });
  instance.$mount();
  instance.$on("close", LoginPopup.close);
  document.body.appendChild(instance.$el);
}

LoginPopup.close = function() {
  if (!instance) return;
  instance.$off("close", LoginPopup.close);
  document.body.removeChild(instance.$el);
  instance = null;
};

export default LoginPopup;
