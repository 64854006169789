<template>
  <div class="home-wrapper" :class="{mobilePage : isMobile}">
 <web-header :switchHeight="70">
        <!-- 里面放滚动切换的内容 -->
        <div class="headSticky scrollSticky" key="scroll">
          <div class="head">
            <div class="headLeft">
              <div v-if="!isMobile" id="logo" @click="selectMenuItem()">
                <img class="imgCut" src="@/assets/style/DPlogo.png" alt="" />
              </div>
                <!-- <div class="tabSticky topic"> -->
        <div class="articleTab ency specSub">
          <div
            class="tab specSub"
            :class="{
              selected: tab === type.name,
              unselect: tab !== type.name,
            }"
            v-for="(type, idx) in typeList"
                @click="selectType(type)"
                :key="idx"
          >
            <div>{{ type.name }}</div>
            <div v-if="tab === type.name" class="selectedIcon"></div>
          </div>
        </div>
      <!-- </div> -->
            </div>
            <div class="headRight">
            </div>
          </div>
        </div>
      </web-header>

  <div class="secondary">
  <div class="tabSticky topic">
        <div class="articleTab ency specSub">
          <div
            class="tab specSub"
            :class="{
              selected: tab === type.name,
              unselect: tab !== type.name,
            }"
            v-for="(type, idx) in typeList"
                @click="selectType(type)"
                :key="idx"
          >
            <div>{{ type.name }}</div>
            <div v-if="tab === type.name" class="selectedIcon"></div>
          </div>
        </div>
      </div>
      <div class="loading" v-if="loading">
              <img class="loadingImg" src="@/assets/style/loading.gif" alt="" />
            </div>
      <template v-if="specSubList">
      <div class="specSubList" v-infinite-scroll="loadmore">
        <div
        class="specSubItem"
        v-for="(item, idx) in specSubList"
        :key = "idx"
        @click="toSpecSubDet(item)"
        >
          <div class="specSubImg">
          <img class="imgCut" :src="item.logo" alt="">
          </div>
          <div class="specSubTitle textOverflow">
          {{item.name}}
          </div>
        </div>
      </div>
      </template>
      <template v-if="empty">
              <div class="searchEmpty">
                <img
                  class="searchEmptyImg"
                  src="@/assets/style/empty.gif"
                  alt=""
                />
                <div class="searchEmptyText">很可惜，没有找到相关信息</div>
              </div>
            </template>
      <!-- 二级页面的网站信息 -->
    <!-- <SecondFooter v-if="!isMobile" /> -->
    <template>
      <el-backtop target=".home-wrapper" :bottom="100" :visibility-height="100">
        <div class="backTop">UP</div>
      </el-backtop>
    </template>
    </div>
  </div>
  
</template>

<script>
import WebHeader from "@/components/WebHeader.vue";
// import SecondFooter from "@/components/SecondFooter";
import request from "@/utils/request";
export default {
  components: {
    WebHeader,
    // SecondFooter,
  },
  data() {
    return {
      specSubType: "全部",
      tab: "全部",
      typeList: [],
      total: 1,
      pageNum: 1,
      specSubList: [],
      loadingMore: false,
      loading: false,
      finish: false,
      empty: false,
    };
  },
  async created() {
    document.title = "多频专题 - DROPIN多频";
    this.loading = true;
    const specSubTypeRes = await request.get("/dp/specialSubject/getParam", {});
    this.typeList = specSubTypeRes.data;
    this.specSubType = this.typeList[0].value;
    this.tab = this.typeList[0].name;
    this.getSpecSubList(this.specSubType);
    
  },
  methods: {
    toSpecSubDet(item) {
      if(item.type == 0)
      {window.open(`/#/specSubDet?id=${item.id}`)}
      else if (item.id == 1) {
        {window.open("/#/eventWall")}
      }
      else if (item.type == 1) {
        this.handleOpenUrl(item.url)
      }
    },
    handleOpenUrl(urlStr) {
      if (!urlStr) return;

      let url = urlStr;
      if (urlStr.indexOf("http://") !== 0 && urlStr.indexOf("https://") !== 0) {
        url = `http://${url}`;
      }
      window.open(url);
    },
    async selectType(type) {
    this.tab = type.name;
    this.specSubType = type.value;
    this.specSubList = [];
    this.pageNum = 1;
    this.empty = false;
    this.finish = false;
    this.loading = true;
    this.getSpecSubList(this.specSubType);

    },
    async getSpecSubList(value) {
      const specSubListRes = await request.get("/dp/specialSubject/list", {
        params: {
        category: value,
        pageNum: this.pageNum,
        pageSize: 20,
        }
      });
      this.loading = false;
      this.loadingMore = false;
      this.specSubList = this.specSubList.concat(specSubListRes.data.records);
      this.total = specSubListRes.data.total;
      if(this.total == 0 && !this.specSubList.length) {
        return this.empty = true;
      }
      else if (this.specSubList.length >= this.total) {
        return this.finish = true;
      }
    },
    async loadmore() {
      if (this.loadingMore || this.loading || this.finish || this.empty) {
        return;
      }
      this.loadingMore = true;
      this.pageNum++;
      this.getSpecSubList(this.specSubType);
    },
  }
};
</script>

<style scoped>
.articleTab.ency.specSub {
  padding: 2px 0 6px 0;
  border-radius: 4px;
}
.specSubList {
  width: 834px;
  display: flex;
  max-width: 1220px;
  flex-wrap: wrap;
}
.mobilePage .specSubList {
  width: 100%;
}
.specSubItem {
  margin: 10px 10px 44px 0px;
  cursor: pointer;
  width: 198px;
  height: 132px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (any-hover: hover) {
.specSubItem:hover {
  color: #9C5CF7;
}
}
.specSubImg {
  background: var(--tab-background-color);
  width: 186px;
  height: 124px;
  border-radius: 4px;
  overflow: hidden;
  transition: transform .2s ease;
}
@media (any-hover: hover) {
.specSubItem:hover .specSubImg {
  transform: scale(1.05);
}
}
.specSubTitle {
  top: 132px;
  left: 6px;
  position: absolute;
  width: 180px;
  line-height: 20px;
  padding: 2px 0;
}
.specSubTitle.textOverflow {
  -webkit-line-clamp: 2;
}
.tab.specSub {
  margin: 10px;
}
.tabSticky.topic {
  margin-top:6px;
}
/* 移动端适配样式 */
.mobilePage .tabSticky.topic {
  width: 100%;
}
.mobilePage .specSubItem {
  width: 43%;
  height: 103px;
  margin: 12px 6px 44px 16px;
}
.mobilePage .specSubImg {
  width: 100%;
  height: 103px;
}
.mobilePage .specSubTitle {
  top: 110px;
}
.mobilePage .articleTab.ency.specSub {
  flex-wrap: nowrap;
  overflow: auto;
}
.mobilePage .headLeft {
  flex-shrink: 0;

}
.mobilePage .head {
    overflow: auto;
}
.mobilePage .head::-webkit-scrollbar {
  display: none;
}
.mobilePage .specSubTitle {
  width: 100%;
}
</style>
