import request from "@/utils/request";

// 登录方法
export function loginByUsername(data) {
  return request({
    url: "/dp/member/login",
    method: "post",
    data: data
  });
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: "/dp/member/info",
    method: "get"
  });
}

// 退出方法
export function logout() {
  return request({
    url: "/dp/member/logout",
    method: "get"
  });
}
// 获取验证码
export function regCaptcha(data) {
  return request({
    url: "/dp/member/getVerifyCode",
    method: "get",
    params: data
  });
} // 获取当前用户验证码
export function getMemberRegCaptcha(data) {
  return request({
    url: "/creator/auth/getMemberRegCaptcha",
    method: "post",
    data: data
  });
}
// 获取验证码
export function loginByMobile(data) {
  return request({
    url: "/dp/member/loginByMobile",
    method: "post",
    data: data
  });
}
// 修改密码
export function resetPwd(data) {
  return request({
    url: "/creator/auth/resetPwd",
    method: "get",
    params: data
  });
}

/**
 * 初始验证
 * @param {*} data
 * @returns
 */
export function initialVerify(data) {
  return request({
    url: "/creator/professionCertify/initialVerify",
    method: "get",
    params: data
  });
}

/**
 * 新增职业认证
 * @param {*} data
 * @returns
 */
export function saveProfessionCertif(data) {
  return request({
    url: "/creator/professionCertify/save",
    method: "post",
    data: data
  });
}
