<template>
  <div class="resultItem userItem" @click="toPersonal()">
    <div class="detailAuthorHead" @click.stop="toPersonal">
              <div class="detailAuthorPhoto userItemAvatar">
                <img
                  v-if="userItem.memberVo && userItem.memberVo.avatar && userItem.memberVo.deleted == 0"
                  class="imgCut"
                  :src="userItem.memberVo.avatar"
                  alt=""
                />
                <img
                  v-else
                  class="imgCut"
                  src="@/assets/style/img_portrait@2x.png"
                  alt=""
                />
              </div>
              <img
                v-if="userItem.memberVo.isRealOfficial === 1 && userItem.memberVo.deleted == 0"
                class="detailUserTypeImg"
                src="@/assets/style/icon_official@2x.png"
                alt=""
              />
              <img
                v-else-if="userItem.memberVo.isOfficial === 1 && userItem.memberVo.deleted == 0"
                class="detailUserTypeImg"
                src="@/assets/style/icon_vip@2x.png"
                alt=""
              />
            </div>
    <div class="rightMsg">
      <div class="resultName" v-if="userItem.memberVo.deleted == 0">
        {{ userItem.memberVo.nickname }}
        <img :src="userItem.memberVo.expLogo" alt="" />
      </div>
      <div v-else class="resultName">用户已注销
      </div>
      <div class="resultBrief textOverflow" v-if="userItem.memberVo.descriptions && userItem.memberVo.deleted == 0">{{userItem.memberVo.descriptions}}</div>
    </div>
    <!-- <span class="clickButton followBtn" @click="clickFollow()">
              {{ followText }}
            </span> -->
    <!-- <CancelFollow ref="followDialog" /> -->
  </div>
</template>

<script>
import { openPersonal } from "@/utils/navigate";
// import CancelFollow from "@/components/CancelFollow.vue"
export default {
  //   components: {
  // CancelFollow,
  //   },
  data() {
    return {
      followText: "+关注",
    };
  },
  methods: {
    toPersonal() {
      if (this.userItem.memberVo.deleted == 1) {
        return this.$message("用户已注销");
      }
      else
      openPersonal(this.userItem.memberVo.id);
    },
    clickFollow() {
      this.$refs["followDialog"].centerDialogVisible = true;
    },
  },
  props: ["userItem"],
};
</script>

<style>
.detailAuthorPhoto.userItemAvatar {
  width: 50px;
  height: 50px;
}
.userItem .rightMsg {
  justify-content: center;
}
.userItem .clickButton.followBtn {
  flex-shrink: 0;
  margin: auto;
}
/* .resultItem.userItem {
  cursor: auto;
} */
/* .resultItem.userItem:hover {
  background: #1f1f1f;
} */

/* .userItemAvatar:hover {
  opacity: 50%;
} */
.userItem .resultName {
  cursor: pointer;
  margin-right: auto;
}
/* .userItem .resultName:hover {
  color: #9C5CF7;
} */
.resultName img {
  height: 10px;
  margin-left: 4px;
}
</style>