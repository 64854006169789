<template>
  <div class="home-wrapper" ref="scrollContainer">
    <web-header v-if="!isApp" :disabledScroll="true" />
    <div class="lotteryPage" :class="{ mobilePage: isApp }">
      <div class="lotteryBanner">
        <img class="imgCut" :src="lotteryInfo.image" alt="" />
      </div>
      <!-- 未登录样式 -->
      <div v-if="!isLogin">
        <div class="loginTips">
          <div class="processTips">{{ processTips[flag] }}</div>
          <div>当前未登录</div>
          <div v-if="!isApp" class="clickLoginBtn" @click="clickLogin()">
            👉 点击登录DROPIN多频 👈
          </div>
          <div v-else>请登录App后<br />再进入该页面查看活动</div>
        </div>
      </div>
      <!-- 已登录未参与 -->
      <template v-else-if="!isJoin">
        <!-- 活动进行中 -->
        <template v-if="flag == 1">
          <div>
            DROPIN多频一周年，感谢有你陪伴！<br />
            点击“参与抽奖”即有机会赢取<br />
            「空 • 百威旅行音乐嘉年华」双日门票
          </div>
          <div class="joinBtn" @click="clickJoin(message, email)">
            {{ joinText }}
          </div>
        </template>
        <!-- 活动已结束 -->
        <template v-else>
          <div>很可惜，<br />抽奖活动已截止参与</div>
        </template>
      </template>
      <!-- 已登录已参与 -->
      <template v-else-if="isJoin">
        <template v-if="flag == 1 || flag == 2">
          <div>
            参与成功<br />
            开奖时间为8月15日（周一）6:00<br />请通过本页面查看中奖情况
          </div>
        </template>
        <template v-if="flag == 3">
          <template v-if="lotteryInfo.lotteryLog.win">
            <div>
              恭喜您欧气爆棚！<br />
              奖品为：👇👇<br />「空 • 百威旅行音乐嘉年华」双日门票 1张
            </div>
            <div class="smallText">请扫码添加多频小助手领取奖品</div>
            <div>
              <img width="125px" src="@/assets/style/客服@2x.png" alt="" />
            </div>
          </template>
          <template v-else>
            <div>
              很可惜，您没有中奖<br />
              感谢这一年来对DROPIN多频的支持<br />
              为了电音！
            </div>
          </template>
        </template>
      </template>
      <!-- 活动规则 -->
      <div class="voteRule">
        <div>活动规则：</div>
        <div v-html="lotteryInfo.rule"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import WebHeader from "@/components/WebHeader.vue";
// import { openEncy } from "@/utils/navigate";
import request from "@/utils/request";
import { isAppFun } from "@/utils/judgeClient";
export default {
  data() {
    return {
      lotteryInfo: {},
      lotteryId: 2,
      loading: false,
      flag: 1,
      rule: "",
      device: "pc",
      processTips: {
        0: "活动未开始",
        1: "周年庆抽奖活动进行中",
        2: "周年庆抽奖活动已结束",
        3: "周年庆抽奖活动已结束",
      },
      isApp: false,
      joinText: "参与抽奖",
      isJoin: false,
    };
  },
  components: {
    WebHeader,
  },

  computed: {
    ...mapGetters(["isLogin", "token"]),
  },
  watch: {
    isLogin: {
      immediate: true,
      handler(val, oldVal) {
        // 从false变成true, 拉一下信息
        if (!oldVal && val) {
          this.getLotteryInfo();
        }
      }
    }
  },
  async created() {
    document.title = "DROPIN多频周年庆 - 请你去长沙看「空」音乐节";
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", "社区将抽奖送出3张「空 • 百威旅行音乐嘉年华」双日票助频友畅玩！快来一试手气，相约近期最值得期待的音乐节吧！");
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      // 当前设备是移动设备
      this.isApp = isAppFun();
      // this.isApp = true;
      if (this.isApp) {
        this.device = "mobile";
      } else
        this.$message({
          message: "请使用电脑打开该页面，获得更好体验",
        });
    }
    this.getLotteryInfo();
  },
  methods: {
    async clickJoin() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.joinText = "正在参与抽奖，请稍等...";
      const joinLotteryRes = await request.post("/dp/lottery/participate", {
        lotteryId: this.lotteryId,
      });
      this.loading = false;
      if (joinLotteryRes.code == 200) {
        this.isJoin = true;
        this.$nextTick(() => {
          this.$refs.scrollContainer.scrollTop = 0;
        });
      } else return (this.joinText = "参与抽奖");
    },
    async getLotteryInfo() {
      const lotteryInfoRes = await request.get("/dp/lottery/info", {
        params: {
          lotteryId: this.lotteryId,
        },
      });
      if (lotteryInfoRes.code == 200) {
        this.lotteryInfo = lotteryInfoRes.data;
        document.title = lotteryInfoRes.data.name;
        this.isJoin = !!this.lotteryInfo?.lotteryLog;
        this.flag = this.lotteryInfo.flag
      }
    },
    clickLogin() {
      this.$login();
      return;
    },
    handleOpenUrl(urlStr) {
      if (!urlStr) return;

      let url = urlStr;
      if (urlStr.indexOf("http://") !== 0 && urlStr.indexOf("https://") !== 0) {
        url = `http://${url}`;
      }
      window.open(url);
    },
  },
};
</script>

<style scoped>
.lotteryBanner {
  width: 100%;
  max-width: 540px;
  background: none;
  margin: 70px auto 30px auto;
}
.mobilePage .lotteryBanner {
      margin-top: 20px;
    border-radius: 4px;
    overflow: hidden;
    height: 140px;
}
.lotteryPage {
  min-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  background: #131313;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  text-align: CENTER;
  line-height: 30px;
  color: #fff;
  min-height: 100vh;
}
.lotteryPage.mobilePage {
  min-width: 300px;
  max-width: 580px;
  width: 330px;
}

.processTips {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}
.voteRule {
  text-align: left;
  margin: 68px auto 40px auto;
  font-size: 14px;
  color: var(--color-80);
  line-height: 24px;
  width: 540px;
}
.mobilePage .voteRule {
  width: 330px;
}
.submitBtn {
  width: 300px;
  height: 44px;
  color: #fff;
  background: #9c5cf7;
  border-radius: 25px;
  text-align: center;
  font-size: 16px;
  line-height: 44px;
  margin: 40px auto 0 auto;
  cursor: pointer;
}
.smallText {
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  color: #d9d9d9;
  width: 330px;
  margin: 20px auto;
}
</style>

