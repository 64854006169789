<template>
  <el-dialog
    class="article-music-video"
    title="添加音乐"
    :class="{mobilePage : isMobile}"
    :visible.sync="contentVisible"
    :close-on-click-modal="false"
    top="0"
    width="720px"
  >
    <el-form inline class="mv-form">
      <div class="music-video-url">
        <p style="margin: 0"><b>音乐地址</b></p>
        <span>支持添加网易云音乐和QQ音乐的单曲、歌单、专辑及电台</span>
      </div>
      <el-form-item>
        <el-input
          style="width: 580px"
          v-model="url"
          clearable
          type="textarea"
          autosize
          spellcheck="false"
        />
        <el-button
          class="music-video-btn"
          round
          type="primary"
          @click="filterLink"
          >解析</el-button
        >
      </el-form-item>
    </el-form>
    <div
      class="commentMusic"
      @click="openMusic(music.originalUrl)"
      v-if="hasMusic"
    >
      <div class="modulePic">
        <img class="imgCut" :src="music.pic" alt="" />
      </div>
      <div class="musicInfo">
        <div class="musicName textOverflow">
          {{ music.title }}
        </div>
        <div class="musicArtist textOverflow">
          {{ music.artist }}
        </div>
      </div>
    </div>
    <!-- <div class="ieIndex">
      <div v-html="retData" />
    </div> -->
    <span slot="footer" class="dialog-footer">
      <el-button class="dialog-footer-cancel" round @click="closeContent()"
        >取消</el-button
      >
      <el-button
        round
        type="primary"
        @click="closeContent(true)"
        :disabled="!hasMusic"
        >确认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
// import { getBlblVideo, getMusic } from '@/api/article'
import request from "@/utils/request";
export default {
  name: "",
  components: {},
  props: {},
  data: () => ({
    contentVisible: false,
    url: "",
    hasMusic: false,
    music: {
      title: "",
      artist: "",
      pic: "",
      originalUrl: "",
    },
  }),
  computed: {},
  watch: {
    contentVisible(val) {
      if (!val) {
        (this.music = {
          title: "",
          artist: "",
          pic: "",
          originalUrl: "",
        }),
          (this.hasMusic = false);
        this.url = "";
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    openMusic(url) {
      window.open(url);
    },
    closeContent(type) {
      if (type) {
        this.$emit("musicHandler", this.music);
        this.$message({
          message: "添加成功",
          type: "success",
        });
        this.contentVisible = false;
      } else {
        (this.music = {
          title: "",
          artist: "",
          pic: "",
          originalUrl: "",
        }),
          (this.hasMusic = false);
        this.url = "";
        this.contentVisible = false;
      }
    },
    async filterLink() {
      if (!this.url) return false;
      var content = JSON.parse(JSON.stringify(this.url));
      this.oldContent = content;
      if (content.indexOf("163") != -1 || content.indexOf(".qq.") != -1) {
        console.log("是音乐链接");
      } else {
        this.$message({
          message: "解析失败",
          type: "error",
        });
        return false;
      }
      const url = this.httpString(content);
      if (!url) {
        this.errorMsg = true;
        return;
      }
      this.url = url[0];
      if (this.url) {
        const musicRes = await request.get("/dp/analysis/music", {
          params: {
            param: this.url,
          },
        });
        if (musicRes.data) {
          this.hasMusic = true;
          this.music = musicRes.data;
        }
      } else {
        this.errorMsg = true;
      }
    },
    httpString(s) {
      var reg =
        /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
      s = s.match(reg);
      return s;
    },
  },
};
</script>
<style>
.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
  color: var(--color-40);
  background-color: var(--el-button--primary-disabled-background-color);
}
.article-music-video {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-dialog__header {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}
.el-dialog__footer {
  text-align: center;
}
.el-form-item__content {
  display: flex;
  flex-direction: row;
}
.dialog-footer-cancel {
  margin-right: 48px;
  border: 1px solid #9c5cf7;
  color: #9c5cf7;
  background: transparent;
}
.dialog-footer-cancel:focus,
.dialog-footer-cancel:hover {
  border: 1px solid #9c5cf7;
  color: #9c5cf7;
  background: transparent;
}
.music-video-url {
  font-size: 16px;
  font-weight: 400;
  color: var(--font-color);
  margin-bottom: 20px;
}
.music-video-url span {
  color: var(--color-40);
}
.music-video-btn {
  margin-left: 12px;
}

.el-dialog {
  background: var(--background-color-item);
}
.el-dialog__title {
  color: var(--font-color);
}
.article-music-video .el-textarea__inner {
  background: var(--background-color-item-hover);
  border: none;
  color: var(--font-color);
  resize: none;
}
.el-button.is-round {
  padding: 7px 24px;
}
.el-button--primary {
  background-color: #9c5cf7;
  border: none;
}
.el-button--primary:focus,
.el-button--primary:hover {
  background-color: #9c5cf7;
  border: none;
}
.commentItem .article-music-video textarea {
  margin: 0;
  width: 100%;
}
/* 移动端适配样式 */
.mobilePage .el-button.is-round {
  padding: 7px 16px;
}
.mobilePage.article-music-video .el-textarea {
  width: 72% !important;
}
.mobilePage.article-music-video .el-dialog__body {
  padding: 20px 14px;
}
.mobilePage .music-video-btn {
  margin-left: 6px;
}
.mobilePage.article-music-video .el-dialog__header {
  padding: 20px 20px 0 20px;
}
.mobilePage.article-music-video .el-dialog__footer {
  padding: 4px 0 20px 0;
}
</style>
