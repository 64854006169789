<template>
  <transition name="slide">
    <!-- 以下是头部板块 -->
    <div
      v-if="disabledScroll || !showScrollHeader"
      class="headSticky"
      key="default"
      ref="defaultHeader"
      :class="{ mobilePage: isMobile }"
    >
      <div class="head" :style="{ transform: `translateX(-${scrollLeft}px)` }">
        <div class="headLeft">
          <div id="logo" @click="selectMenuItem()">
            <img v-if="themeColor=='light'" class="imgCut" src="@/assets/style/DPlogo2.png" alt="" />
            <img v-else class="imgCut" src="@/assets/style/DPlogo.png" alt="" />
          </div>
          <header-search v-if="!isMobile" :tab="tab" />
        </div>
        <div class="headRight">
          <template v-if="!isMobile">
            <div
              class="headOption"
              :class="{ bold: path === 'home' }"
              @click="selectMenuItem()"
            >
              首页
              <div class="selectedIcon headTab" v-if="path === 'home'"></div>
            </div>
            <div class="headOption downloadOption">
              <div>音乐</div>
              <img class="fold" src="@/assets/style/icon_more@2x.png" alt="" />
              <div class="headMore">
                <div class="headMoreOption" @click="toNominate()">
                  音乐提名
                </div>
                <div class="headLine"></div>
                <div class="headMoreOption" @click="toMusicDaily()">国电上新</div>
                <div class="headLine"></div>
                <div class="headMoreOption" @click="toTopList()">国电周榜</div>
              </div>
            </div>
            <div
              class="headOption"
              :class="{ bold: path === 'allEncy' }"
              @click="toAllEncy()"
            >
              百科
              <div class="selectedIcon headTab" v-if="path === 'allEncy'"></div>
            </div>
            <div
              class="headOption"
              :class="{ bold: path === 'specSub' }"
              @click="toSpecSub()"
            >
              专题
              <div class="selectedIcon headTab" v-if="path === 'specSub'"></div>
            </div>
            <div
              class="headOption"
              :class="{ bold: path === 'eventWall' }"
              @click="toEventWall()"
            >
              活动墙
              <div
                class="selectedIcon headTab"
                v-if="path === 'eventWall'"
              ></div>
            </div>
            <div class="headOption downloadOption">
              <div>更多</div>
              <img class="fold" src="@/assets/style/icon_more@2x.png" alt="" />
              <div class="headMore">
                <div class="headMoreOption" @click="toIntroduce()">
                  功能介绍
                </div>
                <div class="headLine"></div>
                <div class="headMoreOption" @click="toAbout()">关于多频</div>
              </div>
            </div>
            <!-- <div class="headOption downloadOption">
              <div>下载</div>
              <img class="fold" src="@/assets/style/icon_more@2x.png" alt="" />
              <div class="download">
                <div>立即下载 <b>DROPIN多频</b> APP</div>
                <div class="downloadText">为了电音</div>
                <div class="QRcode">
                  <div class="QRcodeImg">
                    <img
                      class="imgCut"
                      src="@/assets/style/安卓 1@2x.png"
                      alt=""
                    />
                  </div>
                  <div class="QRcodeImg">
                    <img
                      class="imgCut"
                      src="@/assets/style/ios 1@2x.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div> -->
            <div v-show="!mobileMoreShow">
              <div v-show="!isLogin" class="headOption" @click="loginClick">
                登录
              </div>
              <div class="headOption downloadOption" style="height: 60px" v-show="isLogin" >
                <div class="headerAvatar" @click.stop="tabHoverShow = true">
                  <!-- 默认头像 -->
                  <img v-show="avatar" class="imgCut" :src="avatar" alt="" />
                  <img
                      v-show="!avatar"
                      class="imgCut"
                      src="@/assets/style/img_portrait@2x.png"
                      alt=""
                  />
                </div>
                <div
                    class="selectedIcon headTab"
                    v-if="path === 'personalPage' && isMine"
                ></div>
                <div class="headMore" @click.stop="tabHoverShow = false" v-show="!isMobile || (isMobile && tabHoverShow)">
                  <div class="headMoreOption halfBottom" @click="toPersonal(id)">
                    个人主页
                  </div>
                  <div class="headMoreOption halfBottom" @click="toSetting()">
                    账号设置
                  </div>
                  <div class="headMoreOption" @click="quitLogin">退出登录</div>
                </div>
              </div>
            </div>
            <div class="headOption" style="width: 30px; height: 30px">
              <img v-if="themeColor=='light'" class="imgCut" @click="changeThemeColor(0)" src="@/assets/style/light@2x.png" alt="" />
              <img v-else class="imgCut" @click="changeThemeColor(1)" src="@/assets/style/dark@2x.png" alt="" />
            </div>
          </template>
          <div v-if="isMobile" class="headOption mobileMoreBtn">
            <img
              v-if="!mobileMoreShow"
              @click="mobileMoreShow = true"
              src="@/assets/style/btn_more.png"
              alt=""
            />
            <img
              @click="mobileMoreShow = false"
              v-else
              src="@/assets/style/btn_close_white.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div v-show="mobileMoreShow" class="mobileMore">
        <header-search :tab="tab" />

        <div
          class="halfBottom mobileMoreOption"
          :class="{ bold: path === 'home' }"
          @click="selectMenuItem()"
        >
          首页
        </div>
        <div
          class="halfBottom mobileMoreOption"
          :class="{ bold: path === 'allEncy' }"
          @click="toAllEncy()"
        >
          百科
        </div>
        <div
          class="halfBottom mobileMoreOption"
          :class="{ bold: path === 'specSub' }"
          @click="toSpecSub()"
        >
          专题
        </div>
        <div
          class="halfBottom mobileMoreOption"
          :class="{ bold: path === 'topicSquare' }"
          @click="toTopicSquare()"
        >
          频段
        </div>
                <div
          class="halfBottom mobileMoreOption"
          :class="{ bold: path === 'news' }"
          @click="toNews()"
        >
          资讯
        </div>
        <div
          class="halfBottom mobileMoreOption"
          :class="{ bold: path === 'eventWall' }"
          @click="toEventWall()"
        >
          活动墙
        </div>
        <div
          class="halfBottom mobileMoreOption"
          :class="{ bold: path === 'introduce' }"
          @click="toIntroduce()"
        >
          功能介绍
        </div>
        <div
          class="halfBottom mobileMoreOption"
          :class="{ bold: path === 'about' }"
          @click="toAbout()"
        >
          关于多频
        </div>
        <div class="halfBottom mobileMoreOption" @click="toCreator()">
          创作后台
        </div>
        <div class="halfBottom mobileMoreOption" @click="toDownloadPage">
          下载
        </div>
      </div>
      <QuitLoginPopup ref="quitLoginDialog" />
    </div>
    <!-- 以下是滚动切换头部板块 -->
    <slot v-if="showScrollHeader"></slot>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderSearch from "./HeaderSearch.vue";
import { getScrollContainer, throttle } from "@/utils/index";
import { openPersonal } from "@/utils/navigate";
import QuitLoginPopup from "@/components/QuitLoginPopup.vue";

export default {
  props: ["tab", "disabledScroll", "switchHeight", "isMine"],
  components: {
    HeaderSearch,
    QuitLoginPopup,
  },
  data() {
    return {
      message: "",
      url: "/",
      type: "",
      path: "",
      home: "home",
      scrollContainer: null,
      defaultHeaderHeight: 0,
      scrollOverHeader: false,
      direction: "bottom",
      lastScrollTop: 0,
      scrollLeft: 0,
      // isLogin: true,
      mobileMoreShow: false,
      tabHoverShow: false,
      themeColor: 'dark',
    };
  },
  computed: {
    ...mapGetters(["isLogin", "avatar", "nickname", "id"]),
    showScrollHeader() {
      return this.scrollOverHeader && this.direction === "bottom";
    },
  },
  methods: {
    toSetting() {
      if(this.path !== 'setting') {
        window.open(`/#/setting`)
      }
    },
    toSearch(message) {
      this.$router.push({
        name: "search",
        query: {
          keyword: message,
        },
      });
    },
    loginClick() {
      this.$login();
      return;
    },
    selectMenuItem() {
      this.url = "/";
      if (this.$route.fullPath === this.url) {
        // 点击的是当前路由 手动重定向页面到 '/redirect' 页面
        this.$router.replace({
          path: "/redirect",
          query: {
            path: encodeURI(this.url),
          },
        });
      } else {
        // 正常跳转
        this.$router.push({
          path: this.url,
        });
      }
    },
    toAbout() {
      this.$router.push({
        name: "about",
      });
    },
    toEventWall() {
      this.$router.push({
        name: "eventWall",
      });
    },
    toIntroduce() {
      this.$router.push({
        name: "introduce",
      });
    },
    toAllEncy() {
      this.$router.push({
        name: "allEncy",
      });
    },
    toSpecSub() {
      this.$router.push({
        name: "specSub",
      });
    },
    toNews() {
      this.$router.push({
        name: "news",
      });
    },
    toNominate() {
      this.$router.push({
        name: "nominate",
      });
    },
    toMusicDaily() {
      this.$router.push({
        name: "daily",
      });
    },
    toTopList() {
      this.$router.push({
        name: "toplist",
      });
    },
    toCreator() {
      window.open("https://creator.hearinmusic.com");
    },
    toPersonal(id) {
      if(this.isMine && this.path == 'personalPage') {
        return;
      }
      openPersonal(id);
    },
    toDownloadPage() {
      window.location.href = "https://dropin.hearinmusic.com";
    },
    toTopicSquare() {
      this.$router.push({
        name: "topicSquare",
      });
    },
    quitLogin() {
      this.$refs["quitLoginDialog"].centerDialogVisible = true;
    },
    handleContainerScroll: throttle(function (e) {
      this.scrollLeft = e.target.scrollLeft;
      this.direction =
        e.target.scrollTop > this.lastScrollTop ? "bottom" : "top";
      this.scrollOverHeader = e.target.scrollTop > this.defaultHeaderHeight;
      this.lastScrollTop = e.target.scrollTop;
    }, 0),
    changeThemeColor(value) {
      if (value == 0) {
        this.$root.themeColor = 'dark';
        this.themeColor = 'dark';
        document.documentElement.setAttribute('data-theme', 'dark');
        localStorage.setItem('theme', 'dark');
      } else {
        this.$root.themeColor = 'light';
        this.themeColor = 'light';
        document.documentElement.setAttribute('data-theme', 'light');
        localStorage.setItem('theme', 'light');
      }
    },
  },
  async created() {
    this.path = this.$route.name;
    console.log("this.path", this.path);
    console.log(this.isLogin, this.id, this.avatar);
    if (this.isLogin == true && !this.avatar && this.id == 0) {
      // 主动拉取用户信息
      this.$store.dispatch("user/getInfo");
    }
  },
  watch: {
    isLogin: {
      immediate: true,
      handler(val, oldVal) {
        // 从false变成true, 拉一下用户信息
        if (!oldVal && val) {
          if (!this.avatar && this.id == 0) {
            this.$store.dispatch("user/getInfo");
          }
        }
      },
    },
  },
  mounted() {
    this.themeColor = localStorage.getItem('theme');
    this.$nextTick(() => {
      this.scrollContainer = getScrollContainer(this.$el, true);
      this.scrollContainer.addEventListener(
        "scroll",
        this.handleContainerScroll.bind(this)
      );

      this.defaultHeaderHeight =
        this.switchHeight || this.$refs.defaultHeader.clientHeight;
    });
  },
  beforeDestroy() {
    this.scrollContainer.removeEventListener(
      "scroll",
      this.handleContainerScroll
    );
  },
};
</script>
<style>
/* 两个header的切换效果 */
.slide-enter-active,
.slide-leave-active {
  /* 0.4s动画时间, ease-in-out: 缓进缓出 */
  transition: transform 0.4s ease-in-out;
}
.slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
  /* 从-100%进入 */
  transform: translateY(-100%);
}
.headOption {
  position: relative;
}
.bold {
  font-weight: 500;
}
.headMore {
  position: absolute;
  right: -34px;
  top: 56px;
  background: var(--tab-background-color);
  border-radius: 4px;
  display: none;
  color: var(--font-color);
  flex-direction: column;
  align-items: center;
  width: 112px;
}
.headMoreOption {
  padding: 14px 12px;
  cursor: pointer;
}
.headMoreOption:hover {
  color: #9c5cf7;
}
@media (any-hover: hover) {
.downloadOption:hover .headMore {
  display: flex;
}
}
.headLine {
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  width: 100%;
}
.headerAvatar {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  overflow: hidden;
}
.mobilePage.headSticky {
  min-width: 280px;
  max-width: none;
}
.mobilePage .head {
  min-width: 280px;
  max-width: none;
}
.relativeDiv {
  position: relative;
}
.mobileMore {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: #1f1f1f;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobileMoreBtn img {
  width: 28px;
}
.mobileMoreBtn.headOption {
  margin-left: 16px;
}
.mobileMoreOption {
  width: 80%;
  padding: 16px;
}
.mobilePage .headMore {
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  right: -70px;
    top: 40px;
    display: flex;
}
.mobilePage .headMoreOption {
  background: #1f1f1f;
  width: 100%;
  text-align: center;
}
</style>