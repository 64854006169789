<template>
  <!-- 信号列表 -->
  <div class="articleItem" @click="toSignal">
    <div class="articleTitle textOverflow">
      <img
        v-if="isTop"
        class="label"
        src="@/assets/style/article_top@2x.png"
        alt=""
      />
      <img
        v-if="item.esArticle.isBetter && item.esArticle.isBetter === 1"
        class="label"
        src="@/assets/style/article_selection@2x.png"
        alt=""
      />
      {{ item.esArticle.title }}
    </div>
    <div class="author" >
      <div class="detailAuthorHead" @click.stop="toPersonal">
        <div class="authorPhoto">
          <img
           v-if="item.member && item.member.avatar && item.member.deleted == 0"
           class="imgCut" :src="item.member.avatar" alt="" />
          <img
           v-else
           class="imgCut" src="@/assets/style/img_portrait@2x.png" alt="" />
        </div>
        <img
          v-if="item.member && item.member.isRealOfficial === 1 && item.member.deleted == 0"
          class="userTypeImg"
          src="@/assets/style/icon_official@2x.png"
          alt=""
        />
        <img
          v-else-if="item.member && item.member.isOfficial === 1 && item.member.deleted == 0"
          class="userTypeImg"
          src="@/assets/style/icon_vip@2x.png"
          alt=""
        />
      </div>
      <div class="authorRight" @click.stop="toPersonal">
        <div v-if="item.member && item.member.deleted == 0" class="authorName">{{ item.member.nickname }} <img :src="item.member.expLogo" alt=""></div>
        <div v-else>账号已注销</div>
        <div class="publishTime">{{ item.esArticle.createTime }}</div>
      </div>
    </div>
    <div class="articleInfo">
      <div
        class="description textOverflow"
        :class="{ hasPic: item.esArticle.picture }"
      >
        {{ item.esArticle.descriptions }}
      </div>
      <div v-if="item.esArticle.picture" class="articlePhoto">
        <img class="imgCut" :src="item.esArticle.picture" alt="" />
      </div>
    </div>
    <div class="articleData" :class="{ hasPic: item.esArticle.picture }">
      {{ item.commentNum }}条评论 · {{ item.praiseNum }}个点头
    </div>
    <div class="articlTopic">
      <div
        v-for="(topic, idx) in item.channelList"
        class="topicItem"
        @click.stop="toTopic(topic)"
        :key="idx"
      >
        <img class="topicIcon" src="@/assets/style/icon_topic@2x.png" alt="" />
        <div class="topicText">{{ topic.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { openSignal, openTopic, openPersonal } from "@/utils/navigate";
export default {
  methods: {
    toSignal() {
      openSignal(this.item.esArticle.id);
    },
    toTopic(target) {
      openTopic(target.id);
    },
    toPersonal() {
      if (this.item.member.deleted == 1) {
        return this.$message("用户已注销");
      }
      else
      openPersonal(this.item.member.id);
    }
  },
  props: ["item", "from"],
  computed: {
    isTop() {
      if (this.from === 'home') {
        return this.item.esArticle.isTop
      } else if (this.from === 'topic') {
        return this.item.esArticle.isChannelTop
      }
      else return false;
    }
  }
};
</script>

<style>
.authorRight:hover .authorName{
  color: #9c5cf7;
}
.detailAuthorHead:hover {
  opacity: 0.5;
}
.detailAuthorHead {
  cursor:pointer;
}
/* 移动端适配样式 */
.mobilePage .articleItem {
  padding: 12px 16px;
}
.mobilePage .description.hasPic {
  width: 74%;
}
.mobilePage .articlePhoto {
  margin-left: 7px;
  width: 90px;
  height: 60px;
}
.mobilePage .articleTitle {
  font-size: 16px;
}
.mobilePage .description.hasPic {
  height: 42px;
}
.mobilePage .description {
  font-size: 14px;
  line-height: 21px;
}
.mobilePage .articleData.hasPic {
  margin-top: -12px;
  font-size: 11px;
}
</style>