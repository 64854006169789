<template>
  <div class="home-wrapper" :class="{mobilePage : isMobile}">
    <!-- 以下是头部板块 -->
    <web-header>
      <!-- 里面放滚动切换的内容 -->
      <div class="headSticky scrollSticky" key="scroll">
        <div class="head">
          <div class="headLeft">
            <div id="logo" @click="selectMenuItem()">
              <img class="imgCut" src="@/assets/style/DPlogo.png" alt="" />
            </div>
            <div class="encyDetName header">
              <div class="encyType">{{ ency.typeName }}</div>
              <div class="encyName encyDet">{{ ency.name }}</div>
            </div>
          </div>
          <div class="headRight"></div>
        </div>
      </div>
    </web-header>

    <!-- 以下是内容部分 -->
    <div class="content">
    <div class="left">
    <div class="greyBlock headBlock">
        <div class="clickButton" :class="{btnClicked : isCatch}" @click="clickCatch()">
        <template v-if="!isCatch">
            <img
              class="imgCommon"
              src="@/assets/style/btn_article_catch_default@2x.png"
              alt=""
            />
            <img
              class="imgHover"
              src="@/assets/style/btn_article_catch_default_hover.png"
              alt=""
            />
          </template>
          <template v-else>
            <img src="@/assets/style/btn_article_catched@2x.png" alt="" />
          </template>
          {{ catchText }}
        </div>
        <div class="encyDetHead">
          <div class="modulePic encyDet" id="encyPic">
            <img
              v-if="ency.picture"
              class="imgCut"
              :src="ency.picture"
              alt=""
            />
            <img
              v-else
              class="imgCut"
              src="@/assets/style/img_pedia_large@2x.png"
              alt=""
            />
          </div>
          <div class="rightMsg">
            <div class="encyDetName">
              <div class="encyType">{{ ency.typeName }}</div>
              <div class="encyName encyDet">{{ ency.name }}</div>
            </div>
            <div v-if="ency.descriptions" class="resultBrief ency">
              {{ ency.descriptions }}
            </div>
          </div>
        </div>
        <!-- 相关频段 -->
        <div
          v-if="ency.relationChannelList && ency.relationChannelList.length"
          class="encyRelative"
        >
          <div class="relativeType">相关频段</div>
          <div class="relativeList">
            <div
              v-for="(relativeTopic, idx) in ency.relationChannelList"
              class="topicItem"
              @click="toTopic(relativeTopic)"
              :key="idx"
            >
              <img
                class="topicIcon"
                src="@/assets/style/icon_topic@2x.png"
                alt=""
              />
              <div class="topicText">{{ relativeTopic.name }}</div>
            </div>
          </div>
        </div>
        <!-- 相关百科 -->
        <div
          v-if="ency.relationEncyList && ency.relationEncyList.length"
          class="encyRelative"
        >
          <div class="relativeType">相关百科</div>
          <div class="relativeList">
            <div
              v-for="(relativeEncy, idx) in ency.relationEncyList"
              class="encyItem"
              @click="toEncy(relativeEncy)"
              :key="idx"
            >
              <img
                class="encyIcon"
                src="@/assets/style/icon_pedia@2x.png"
                alt=""
              />
              <div class="encyItemText">{{ relativeEncy.name }}</div>
            </div>
          </div>
        </div>
        <!-- 相关链接 -->
        <div
          v-if="ency.relatedLinkList && ency.relatedLinkList.length"
          class="encyRelative"
        >
          <div class="relativeType">相关链接</div>
          <div class="relativeList">
            <div
              v-for="(relativeLink, idx) in ency.relatedLinkList"
              class="encyItem relativeLink"
              @click="handleOpenUrl(relativeLink.url)"
              :key="idx"
            >
              <img
                class="encyIcon relativeLink"
                :src="relativeLink.logo"
                alt=""
              />
              <div class="encyItemText relativeLink">
                {{ relativeLink.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
       <!-- 基本信息 -->
      <div class="greyBlock encyDetBlock">
        <div class="encyDetBlockTitle halfBottom">基本信息</div>
        <div class="encyDetBlockContent">
          <template v-if="ency.typeId == 11">
            <!-- 其他类型 -->
            <div v-if="ency.categoryText" class="basicInfo">
              分类：{{ ency.categoryText }}
            </div>
          </template>
          <template v-if="ency.typeId == 12">
            <!-- 艺人 -->
            <!-- <div v-if="ency.originalName" class="text">别名：{{ency.originalName}}</div> -->
            <div v-if="ency.anotherName" class="basicInfo">
              别名：{{ ency.anotherName }}
            </div>
            <div v-if="ency.nationality" class="basicInfo">
              国籍：{{ ency.nationality }}
            </div>
            <div v-if="ency.birthplace" class="basicInfo">
              出生地：{{ ency.birthplace }}
            </div>
            <div v-if="ency.birthday" class="basicInfo">
              生日：{{ ency.birthday }}
            </div>
            <div v-if="ency.sign" class="basicInfo">星座：{{ ency.sign }}</div>
            <div v-if="ency.height" class="basicInfo">
              身高：{{ ency.height }}
            </div>
            <div v-if="ency.profession" class="basicInfo">
              职业：{{ ency.profession }}
            </div>
            <div v-if="ency.styleList && ency.styleList.length" class="text">
              风格：<det-list :list="ency.styleList"></det-list>
            </div>
            <div v-if="ency.brandList && ency.brandList.length" class="text">
              厂牌：<det-list :list="ency.brandList"></det-list>
            </div>
            <div v-if="ency.debutTimeText" class="basicInfo">
              出道时间：{{ ency.debutTimeText }}
            </div>
            <div v-if="ency.officialUrl" class="basicInfo">
              官网：{{ ency.officialUrl }}
            </div>
          </template>
          <template v-if="ency.typeId == 13">
            <!-- <div v-if="ency.originalName" class="text">别名：{{ency.originalName}}</div> -->
            <div v-if="ency.anotherName" class="text">
              别名：{{ ency.anotherName }}
            </div>
            <div v-if="ency.startTimeText" class="text">
              发源时间：{{ ency.startTimeText }}
            </div>
            <div v-if="ency.startAddress" class="text">
              发源地：{{ ency.startAddress }}
            </div>
            <div
              v-if="ency.styleCategoryList && ency.styleCategoryList.length"
              class="text"
            >
              风格类型：<det-list :list="ency.styleCategoryList"></det-list>
            </div>
            <div
              v-if="ency.originalStyleList && ency.originalStyleList.length"
              class="text"
            >
              来源风格：<det-list :list="ency.originalStyleList"></det-list>
            </div>
            <div
              v-if="ency.affectStyleList && ency.affectStyleList.length"
              class="text"
            >
              影响风格：<det-list :list="ency.affectStyleList"></det-list>
            </div>
            <div
              v-if="ency.deriveStyleList && ency.deriveStyleList.length"
              class="text"
            >
              衍生风格：<det-list :list="ency.deriveStyleList"></det-list>
            </div>
            <div
              v-if="ency.representArtistList && ency.representArtist.length"
              class="text"
            >
              代表艺人：<det-list :list="ency.representArtistList"></det-list>
            </div>
            <div
              v-if="ency.representBrandList && ency.representBrandList.length"
              class="text"
            >
              代表厂牌：<det-list :list="ency.representBrandList"></det-list>
            </div>
            <div v-if="ency.referenceUrl" class="text">
              参考链接：{{ ency.referenceUrl }}
            </div>
          </template>
          <template v-if="ency.typeId == 14">
            <!-- 厂牌 -->
            <div v-if="ency.name" class="text">厂牌名称：{{ ency.name }}</div>
            <!-- <div v-if="ency.originalName" class="text">别名：{{ency.originalName}}</div> -->
            <div v-if="ency.anotherName" class="text">
              别名：{{ ency.anotherName }}
            </div>
            <div v-if="ency.foundTimeText" class="text">
              创立时间：{{ ency.foundTimeText }}
            </div>
            <div v-if="ency.foundAddress" class="text">
              创立地：{{ ency.foundAddress }}
            </div>
            <div
              v-if="ency.founderList && ency.founderList.length"
              class="text"
            >
              创始人：<det-list :list="ency.founderList"></det-list>
            </div>
            <div
              v-if="ency.operatorList && ency.operatorList.length"
              class="text"
            >
              主理人员：<det-list :list="ency.operatorList"></det-list>
            </div>
            <div
              v-if="ency.parentBrandList && ency.parentBrandList.length"
              class="text"
            >
              母厂牌：<det-list :list="ency.parentBrandList"></det-list>
            </div>
            <div
              v-if="ency.childBrandList && ency.childBrandList.length"
              class="text"
            >
              子厂牌：<det-list :list="ency.childBrandList"></det-list>
            </div>

            <div
              v-if="ency.majorStyleList && ency.majorStyleList.length"
              class="text"
            >
              主营风格：<det-list :list="ency.majorStyleList"></det-list>
            </div>
            <div
              v-if="ency.delegateArtistList && ency.delegateArtistList.length"
              class="text"
            >
              代表艺人：<det-list :list="ency.delegateArtistList"></det-list>
            </div>
            <div v-if="ency.businessRange" class="text">
              业务范围：{{ ency.businessRange }}
            </div>
            <div v-if="ency.officialUrl" class="text">
              官网地址：{{ ency.officialUrl }}
            </div>
            <div v-if="ency.manageStatusText" class="text">
              运营状态：{{ ency.manageStatusText }}
            </div>
          </template>
          <template v-if="ency.typeId == 15">
            <!-- 场所 -->
            <div v-if="ency.anotherName" class="text">
              别名：{{ ency.anotherName }}
            </div>
            <div v-if="ency.typeText" class="text">
              类型：{{ ency.typeText }}
            </div>
            <div v-if="ency.manageStatusText" class="text">
              经营状态：{{ ency.manageStatusText }}
            </div>
            <div v-if="ency.nation" class="text">国家：{{ ency.nation }}</div>
            <div v-if="ency.city" class="text">城市：{{ ency.city }}</div>
            <div v-if="ency.address" class="text">地址：{{ ency.address }}</div>
            <div v-if="ency.openingTimeText" class="text">
              开业时间：{{ ency.openingTimeText }}
            </div>
            <div v-if="ency.manageScale" class="text">
              规模：{{ ency.manageScale }}
            </div>
            <div
              v-if="ency.majorStyleList && ency.majorStyleList.length"
              class="text"
            >
              主营风格：<det-list :list="ency.majorStyleList"></det-list>
            </div>
            <div v-if="ency.officialUrl" class="text">
              官网地址：{{ ency.officialUrl }}
            </div>
          </template>
          <!-- 电音节 -->
          <template v-if="ency.typeId == 16">
            <div v-if="ency.anotherName" class="text">
              别名：{{ ency.anotherName }}
            </div>
            <div v-if="ency.holdTimeText" class="text">
              举办时间：{{ ency.holdTimeText }}
            </div>
            <div v-if="ency.holdAddress" class="text">
              举办地：{{ ency.holdAddress }}
            </div>
            <div v-if="ency.joinScale" class="text">
              参加规模：{{ ency.joinScale }}
            </div>
            <div
              v-if="ency.sponsorList && ency.sponsorList.length"
              class="text"
            >
              主办方：<det-list :list="ency.sponsorList"></det-list>
            </div>
            <div v-if="ency.foundTimeText" class="text">
              创立时间：{{ ency.foundTimeText }}
            </div>
            <div v-if="ency.officialUrl" class="text">
              官网地址：{{ ency.officialUrl }}
            </div>
            <div v-if="ency.nonceStatusText" class="text">
              运营状态：{{ ency.nonceStatusText }}
            </div>
            <div v-if="ency.referenceUrl" class="text">
              参考链接：{{ ency.referenceUrl }}
            </div>
          </template>
          <!-- 事件百科 -->
          <template v-if="ency.typeId == 17">
            <div v-if="ency.keywords" class="text">
              关键词：{{ ency.keywords }}
            </div>
            <div v-if="ency.anotherName" class="text">
              别名：{{ ency.anotherName }}
            </div>
            <div v-if="ency.affairTimeText" class="text">
              事件时间：{{ ency.affairTimeText }}
            </div>
            <div v-if="ency.affairAddress" class="text">
              事件地点：{{ ency.affairAddress }}
            </div>
            <div
              v-if="ency.majorPersonList && ency.majorPersonList.length"
              class="text"
            >
              主要人物：<det-list :list="ency.majorPersonList"></det-list>
            </div>
          </template>
        </div>
      </div>

      <!-- 详情 -->
      <div
        v-if="ency.content && ency.content !== emptyDet"
        class="greyBlock encyDetBlock"
      >
        <div class="encyDetBlockTitle halfBottom">详情</div>
        <div class="encyDetBlockContent">
          <div class="detInfo" id="detInfo" v-html="ency.content"></div>
        </div>
      </div>
      <!-- 参考 -->
      <div
        v-if="ency.citeReferenceList && ency.citeReferenceList.length"
        class="greyBlock encyDetBlock flexGrow"
      >
        <div class="encyDetBlockTitle halfBottom">参考</div>
        <div class="encyDetBlockContent">
          <div
            class="cite-item"
            v-for="(cite, idx) in ency.citeReferenceList"
            :key="cite.id"
            :id="cite.id"
          >
            <span>[{{ idx + 1 }}] </span>
            <template v-if="+cite.citeSource === 2">
              <span>{{ cite.referenceInfo }}</span>
              <span class="cite-edit-time">{{
                cite.editTime | dateFormator
              }}</span>
            </template>
            <template v-else>
              <span class="cite-url" @click="handleOpenUrl(cite.url)">{{
                cite | referFormator
              }}</span>
              <span class="cite-edit-time">{{
                cite.editTime | dateFormator
              }}</span>
            </template>
          </div>
        </div>
      </div>
      <!-- 相关音乐 -->
      <div
        v-if="ency.musicInfoList && ency.musicInfoList.length"
        class="greyBlock encyDetBlock flexGrow"
      >
        <div class="encyDetBlockTitle halfBottom">相关音乐</div>
        <div class="encyDetBlockContent">
          <div
            class="cite-item"
            v-for="(music, idx) in ency.musicInfoList"
            :key="music.id"
            :id="music.id"
          >
            <span>{{ idx + 1 }}. </span>
            <template>
              <span class="cite-url" @click="handleOpenUrl(music.musicUrl)"
                >{{ music.artist }} - {{ music.songName }}</span
              >
            </template>
          </div>
        </div>
      </div>
      <!-- 署名等信息 -->
      <div class="sign">
        <div>{{ ency.remarks }}</div>
        <div class="agreement">
          本页面全部内容均依照“知识共享中国大陆版协议（CC BY-SA 3.0）”公开发行
        </div>
    </div>
      </div>
      <div class="right" v-if="!isMobile">
      <div class="sticky">
      <!-- 创作后台 -->
        <div class="rightField">
          <div class="rightTab halfBottom">
            <div class="rightTabText">创作后台</div>
          </div>
          <div class="creatorFlex">
          <div >
            <div class="creatorBtn" style="background : rgba(77,145,174,0.20)" @click="toCreatorAddDialog('ency')"><img src="@/assets/style/icon_creator_pedia@2x.png" alt=""></div>
            编辑百科
            </div>
            <div >
            <div class="creatorBtn" @click="toCreatorAddDialog('signal')"><img src="@/assets/style/icon_signal_grey.png" alt=""></div>
            发信号
            </div>
            
            <div >
            <div class="creatorBtn" @click="toCreatorAddDialog('topic')"><img src="@/assets/style/icon_topic_creator_grey.png" alt=""></div>
            新建频段
            </div>
            
            <div >
            <div class="creatorBtn" @click="toCreatorAddDialog('quiz')"><img src="@/assets/style/icon_qa_creator_grey.png" alt=""></div>
            新建频一频
            </div>
          </div>
          <div class="toCreatorBtn pointer" @click="toCreatorAddDialog()" >
            进入创作后台 >
          </div>
        </div>
      <!-- 以下是网站信息 -->
    <FirstFooter />
    </div>
    </div>   
    </div>
    <template>
      <el-backtop target=".home-wrapper" :bottom="100" :visibility-height="100">
        <div class="backTop">UP</div>
      </el-backtop>
    </template>
  </div>
</template>

<script>
import Viewer from "viewerjs";
import WebHeader from "@/components/WebHeader.vue";
import FirstFooter from "@/components/FirstFooter.vue";
import request from "@/utils/request";
import { openEncy, openTopic , jumpToCreatorAndAdd, } from "@/utils/navigate";
import dayjs from "dayjs";
import DetList from "@/components/DetList.vue";

export default {
  components: {
    WebHeader,
    FirstFooter,
    DetList,
  },
  filters: {
    referFormator(refer) {
      if (+refer.citeSource === 2) {
        return `${refer.referenceInfo}`;
      }
      // 格式: 网页标题 - 作者名 -平台名 &#91引用日期2021年8月25日]
      let textArr = [];
      if (refer.title) {
        textArr.push(refer.title);
      }
      if (refer.author) {
        textArr.push(refer.author);
      }
      if (refer.platform) {
        textArr.push(refer.platform);
      }
      if (!textArr.length) return "(参考资料信息不全)";

      let displayStr = textArr.join(" - ");
      // if (refer.editTime) {
      // 	displayStr += ` [引用日期${dayjs(refer.editTime).format('YYYY年M月D日')}]`
      // }
      return displayStr;
    },
    dateFormator(editTime) {
      return ` [引用日期${dayjs(+editTime).format("YYYY年M月D日")}]`;
    },
  },
  // computed: {
  //   baseInfo() {
  //     return JSON.parse(this.ency.baseInfo || null) || [];
  //   },
  // },
  data() {
    return {
      loading: true,
      ency: {},
      emptyDet: "\n",
      viewers: [],
      catchText: "捕捉",
      isCatch: false,
      dataId: 0,
      typeId: 0,
    };
  },
  watch: {
    isCatch(val) {
      if (val) {
        this.catchText = "已捕捉";
      } else this.catchText = "捕捉";
    },
  },
  methods: {
    toCreatorAddDialog(type) {
      jumpToCreatorAndAdd(type,this.typeId,this.dataId);
    },
    selectMenuItem() {
      this.url = "/";
      if (this.$route.fullPath === this.url) {
        // 点击的是当前路由 手动重定向页面到 '/redirect' 页面
        this.$router.replace({
          path: "/redirect",
          query: {
            path: encodeURI(this.url),
          },
        });
      } else {
        // 正常跳转
        this.$router.push({
          path: this.url,
        });
      }
    },
    toTopic(target) {
      openTopic(target.id);
    },
    async clickCatch() {
      const isCatchRes = await request.get("/dp/collect/collectEncy", {
          params: {
            dataId: this.ency.dataId,
            typeId: this.ency.typeId,
          },
        });
        if (isCatchRes.code == 200) {
          this.isCatch = !this.isCatch;
        }
    },
    toEncy(target) {
      openEncy(target.typeId, target.dataId);
    },
    handleOpenUrl(urlStr) {
      if (!urlStr) return;

      let url = urlStr;
      if (urlStr.indexOf("http://") !== 0 && urlStr.indexOf("https://") !== 0) {
        url = `http://${url}`;
      }
      window.open(url);
    },
  },

  async created() {
    const dataId = this.$route.query.dataId;
    this.dataId = dataId;
    const typeId = this.$route.query.typeId;
    this.typeId = typeId;
    // locationMobileUrl("ency",typeId,dataId);
    const encyRes = await request.get("/dp/ency/detail", {
      params: {
        dataId: dataId,
        typeId: typeId,
      },
    });
    this.ency = encyRes.data;
    document.title = `${this.ency.name} 百科 - DROPIN多频`;
    const encyCatchRes = await request.get("/dp/collect/isCollectEncy", {
      params: {
        dataId: dataId,
        typeId: typeId,
      },
    });
    this.isCatch = encyCatchRes.data;
    this.$nextTick(() => {
      const detInfo = document.getElementById("detInfo");
      const encyPic = document.getElementById("encyPic");
      if (detInfo) {
        const detInfoViewer = new Viewer(detInfo, {
          toolbar: false,
          navbar: false,
          title: false,
          movable: false,
          minZoomRatio: 0.3,
          maxZoomRatio: 3,
        });
        this.viewers.push(detInfoViewer);
      }
      if (encyPic) {
        const encyPicViewer = new Viewer(encyPic, {
          toolbar: false,
          navbar: false,
          title: false,
          movable: false,
          minZoomRatio: 0.3,
          maxZoomRatio: 3,
        });
        this.viewers.push(encyPicViewer);
      }
    });
    this.$nextTick(() => {});
    console.log("===encyRes: ", encyRes);
  },
  beforeDestroy() {
    this.viewers.forEach((viewer) => viewer.destroy());
  },
};
</script>

<style scoped>
.cite-url {
  color: #9c5cf7;
  cursor: pointer;
}

.cite-edit-time {
  color: #b1b1b1;
}

.cite-item {
  font-size: 14px;
  line-height: 2;
}

.agreement {
  margin-top: 20px;
}

.sign {
  color: var(--color-80);
  line-height: 20px;
  font-size: 12px;
  margin-top: 12px;
}

.resultBrief.ency {
  margin-right: 36px;
}

.encyItem.relativeLink {
  background: var(--color-15);
  border-radius: 46px;
}

.encyItemText.relativeLink {
  color: var(--color-50);
}

.encyIcon.relativeLink {
  border-radius: 50%;
}

.encyDetName.header {
  margin: 0 0 0 36px;
}
.headBlock .clickButton {
  color: #b1b1b1;
  margin: 0 30px 20px auto;
    width: 70px;
  height: 19px;
  display: flex;
  align-items: center;
}
@media (any-hover: hover) {
.headBlock .clickButton:hover {
  color: #9c5cf7;
}
}
.headBlock .clickButton img {
  width: 17px;
  margin-right: 6px;
}
.clickButton.btnClicked {
  color: #9c5cf7;
}
/* 移动端兼容适配 */
.mobilePage .encyRelative {
  margin: 24px 16px 0 16px;
}
.mobilePage .resultBrief.ency {
  margin-right: 16px;
}
.mobilePage .modulePic.encyDet {
  margin-left: 16px;
}
.mobilePage .encyDetBlockContent {
  padding: 20px 28px 28px 28px;
}
.mobilePage .text {
  word-wrap:break-word;
}
.mobilePage .sign {
  padding: 0 20px;
}
</style>