<template>
  <div class="quiz">
    
      <div class="quizTitle">
        <img src="@/assets/style/quiz_icon.png" alt="" />
        {{ subDetItem.subject.title }}
      </div>
      <!-- 图片题目 -->
      <div
        v-if="subDetItem.subject.type == 1 && subDetItem.subject.content"
        class="quizImg"
      >
        <img class="imgCut" :src="subDetItem.subject.content" alt="" />
      </div>
      <!-- 音乐题目 -->
      <template v-if="subDetItem.subject.type == 2">
        <div class="quizMusic">
          <div class="playButton" @click="clickQuiz()">
            <img src="@/assets/style/btn_play_music@2x.png" alt="" />
          </div>
          {{ safeParse(subDetItem.subject.content).duration }}
        </div>
      </template>
      <!-- 文字选项列表 -->
      <div
        class="option"
        v-if="subDetItem.subject.optionList[0].type == 0"
      >
        <div
          v-for="(optionItem, optionIdx) in subDetItem.subject.optionList"
          :key="optionIdx"
          class="optionItem"
          @click="open"
        >
          {{ optionItem.content }}
        </div>
      </div>
      <!-- 图片选项列表 -->
      <div
        v-if="subDetItem.subject.optionList[0].type == 1"
        class="imgOption"
      >
        <div
          class="relative"
          v-for="(optionItem, optionIdx) in subDetItem.subject.optionList"
          :key="optionIdx"
          @click="open"
        >
          <div class="imgOptionItem">
            <img class="imgCut" :src="optionItem.content" alt="" />
            <!-- 其他选项 -->
            <div class="imgProgress"></div>
          </div>
        </div>
      </div>
      <!-- 答题人数 -->
      <div class="answerNum">
        {{ subDetItem.subject.selectCount }}人参与
        <template
          v-if="
            subDetItem.subject.selectCorrectCount &&
            subDetItem.subject.mold == 0
          "
          >· {{ subDetItem.subject.selectCorrectCount }}人答对</template
        >
      </div>
      <div v-if="subDetItem.subject.createMember" class="contribute">
        <div class="member">
          <div class="memberAvatar">
            <div class="avatarPic">
              <img
                v-if="subDetItem.subject.createMember.avatar"
                class="imgCut"
                :src="subDetItem.subject.createMember.avatar"
                alt=""
              />
              <img
                v-if="
                  !subDetItem.subject.createMember.avatar ||
                  subDetItem.subject.createMember.deleted == 1
                "
                class="imgCut"
                src="@/assets/style/img_portrait@2x.png"
                alt=""
              />
            </div>
            <img
              v-if="subDetItem.subject.createMember.isRealOfficial == 1"
              class="userTypeImg"
              src="@/assets/style/icon_official@2x.png"
              alt=""
            />
            <img
              v-else-if="subDetItem.subject.createMember.isOfficial == 1"
              class="userTypeImg"
              src="@/assets/style/icon_vip@2x.png"
              alt=""
            />
          </div>

          <div
            v-if="subDetItem.subject.createMember.deleted == 1"
            class="nickName deleted"
          >
            已注销
          </div>
          <div v-else class="nickName">
            {{ subDetItem.subject.createMember.nickname }}
          </div>
        </div>
        投稿
      </div>
    
  </div>
</template>

<script>
export default {
  props: ["subDetItem"],
  methods: {
    open() {
      this.$message("请在 DROPIN多频 APP内参与频一频");
    },
  },
};
</script>

<style scoped>
.quiz {
  background: var(--background-color-item);
  padding: 24px;
  border-radius: 4px;
    margin: 12px 0;
}
.quizTitle {
  color: var(--font-color);
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}
.quizTitle img {
  width: 36px;
}
.quizImg {
  width: 197px;
  height: 131px;
  border-radius: 4px;
  margin: 16px auto;
  overflow: hidden;
}
.quizMusic {
  height: 50px;
  background: linear-gradient(270deg, #fbf9ff, #ece5f8);
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin: 16px auto;
  color: #7123e5;
  font-size: 12px;
}
.playButton {
  width: 40px;
  height: 40px;
  background: #7123e5;
  border-radius: 6px;
  margin-left: 5px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.playButton img {
  width: 24px;
}
.optionItem {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 11px 22px 11px 18px;
  color: var(--color-80);
  font-size: 14px;
  margin-top: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.answerNum {
  color: #999999;
  font-size: 11px;
  margin-top: 10px;
}
.nickName {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #fff;
}
.deleted {
  color: #b1b1b1;
}
.memberAvatar {
  position: relative;
  margin-right: 3px;
}
.userTypeImg {
  position: absolute;
  width: 6px;
  height: 6px;
  right: 0;
  bottom: 0;
}
.avatarPic {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 3px;
}
.imgOption {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.imgOptionItem {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  overflow: hidden;
  margin: 6px 4px 10px 4px;
}
.imgProgress {
  background: conic-gradient(#999999 0, #999999 0%, rgba(255,255,255,0.10) 0%, rgba(255,255,255,0.10));
  position: absolute;
  top: 2px;
  left: 0;
  width: 100%;
  height: 90.2%;
  border-radius: 50%;
  mask: radial-gradient(transparent, transparent 61.5%, #000 61.5%, #000 0);
}
.relative {
  position: relative;
  margin: 0 4px 2px 4px;
  cursor: pointer;
}
.expLogo {
  margin-left: 2px;
  width: 28px;
}
.contribute {
  font-size: 11px;
  color: #b1b1b1;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.member {
  display: flex;
  align-items: center;
  margin-right: 2px;
  margin-top: 0;
}
</style>