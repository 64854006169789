<template>
  <div
    :class="{ mobilePage: isMobile, appPage: isApp }"
    class="home-wrapper midautumn"
    ref="scrollContainer"
  >
    <web-header v-if="!isApp" :disabledScroll="true" />
    <div :class="{ pcPage: !isMobile }">
      <div class="webPage pageDet">
        <template v-if="!isLogin">
          <div class="webPageBanner">
            <img v-if="webInfo" class="imgCut" :src="webInfo.image" alt="" />
          </div>
          <div class="loginTips">
            <div class="processTips">{{ processTips[flag] }}</div>
            <div>当前未登录</div>
            <div v-if="!isApp" class="clickLoginBtn" @click="clickLogin()">
              👉 点击登录DROPIN多频 👈
            </div>
            <div v-else>请登录App后<br />再进入该页面查看活动</div>
          </div>
        </template>
        <template v-else>
          <template v-if="flag == 1">
            <div class="webPageBanner">
              <img v-if="webInfo" class="imgCut" :src="webInfo.image" alt="" />
            </div>
            <div v-if="isJoin">
              <!-- 已提交祝愿，等待抽奖 -->
              <div class="webPageText">
                参与成功！<br />记得在中秋假期来DROPIN多频<br />抽取你的专属祝愿哦！
              </div>
            </div>
            <div v-else>
              <!-- 未提交祝愿 -->
              <div class="webPageText">
                DROPIN多频庆中秋特别活动<br />写下你对DROPUCER的中秋祝愿！<br />送出祝愿后，你也可以在中秋假期<br />抽取某个DROPUCER留下的专属祝愿哦！
              </div>
              <div class="inputTips">
                请写下你对对方的中秋祝愿
                (1-50个字符)，祝愿将乘坐多频探月火箭，to the moon and
                back，投送至某个幸运的DROPUCER手上
              </div>
              <el-input
                v-model="message"
                placeholder="请输入你的中秋祝愿"
                maxlength="50"
                type="textarea"
                show-word-limit
              />
              <div class="joinBtn" @click="clickJoin(message)">
                {{ joinText }}
              </div>
            </div>
          </template>
          <template v-if="flag == 2">
            <div class="webPageBanner">
              <img v-if="webInfo" class="imgCut" :src="webInfo.image" alt="" />
            </div>
            <!-- 祝福提交已截止 -->
            <div v-if="isJoin">
              <div class="webPageText">
                参与成功！<br />记得在中秋假期来DROPIN多频<br />抽取你的专属祝愿哦！
              </div>
            </div>
            <div v-else>
              <div class="webPageText">
                很可惜，中秋祝愿投送已截止<br />
                没关系，请在中秋假期来DROPIN多频<br />
                获取我们的官方祝愿吧！
              </div>
            </div>
          </template>
          <template v-if="flag == 3">
            <!-- 活动已开奖 -->
            <div v-if="isJoin && isLottery && isWin">
              <div v-if="isOpen">
                <!-- 已参与已抽取 -->
                <div class="webPageText">
                  以下是 @{{ blessingUser.nickname
                  }}<br />给你送出的专属祝愿<br />请查收：
                </div>
                <div class="cardBox">
                  <div class="cardBoxInner">
                    <div class="blessingBox">{{ blessing }}</div>
                    <div class="blessingUser" @click="toPersonal(blessingUser.id)">
                      <div class="avatar">
                        <img class="imgCut" :src="blessingUser.avatar" alt="" />
                      </div>
                      <span>&nbsp;{{ blessingUser.nickname }} ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <!-- 已参与未抽取 -->
                <div class="webPageText">
                  我们在月球表面发现了这条祝愿<br />
                  但上面似乎覆满了星石与灰尘<br />
                  将它擦拭干净，里面可能有惊喜！
                </div>
                <div class="cardBox">
                  <nut-luckycard
                    :content="cardContent"
                    height="250"
                    width="270"
                    ratio="0.2"
                    @open="opencard"
                    :coverImg="require('@/assets/style/Mask group@2x.png')"
                    backgroundColor="#FFFFFF00"
                  ></nut-luckycard>
                </div>
              </div>
            </div>
            <div v-else>
              <!-- 未参与提交祝愿 -->
              <div class="webPageBanner">
                <img
                  v-if="webInfo"
                  class="imgCut"
                  :src="webInfo.image"
                  alt=""
                />
              </div>
              <div class="webPageText">
                很可惜，你没有投送中秋祝愿<br />无法抽取其他DROPUCER的专属祝愿<br />
                DROPIN多频在此祝你和家人、朋友<br />中秋快乐！团团圆圆！
              </div>
            </div>
          </template>
        </template>
        <div class="voteRule">
          <div>活动规则：</div>
          <div v-html="rule"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import WebHeader from "@/components/WebHeader.vue";
import request from "@/utils/request";
import { mapGetters } from "vuex";
import { isAppFun, isIOS, isAndroid } from "@/utils/judgeClient";
import { Luckycard } from "@nutui/nutui";
import { openPersonal } from "@/utils/navigate";
Luckycard.install(Vue);
export default {
  data() {
    return {
      flag: 1,
      blessing: "",
      lotteryId: 3,
      isJoin: false,
      isOpen: false,
      processTips: {
        0: "活动未开始",
        1: "DROPIN多频庆中秋活动进行中",
        2: "中秋祝愿投送已截止",
        3: "庆中秋活动祝愿已放送",
      },
      isApp: false,
      webInfo: {},
      rule: "",
      message: "",
      joinText: "发送",
      coverImage: "@/assets/style/抖音@2x.png",
      isLottery: false,
      isWin: false,
      lotteryLogId: 0,
      blessingUser: {},
      cardContent: "",
    };
  },
  components: {
    WebHeader,
  },
  computed: {
    ...mapGetters(["isLogin", "token"]),
  },
  watch: {
    isLogin: {
      immediate: true,
      handler(val, oldVal) {
        // 从false变成true, 拉一下活动信息查询
        if (!oldVal && val) {
          this.getAddPrizeLog();
          this.getLotteryInfo();
        }
      },
    },
  },
  async created() {
    document.title = "DROPIN多频庆中秋 - 超越时空的祝愿";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "9月2日-9日期间写下你的中秋祝愿，便可在中秋假期收获来自他人的神秘专属祝愿！"
      );
    this.isApp = isAppFun();
    this.getLotteryInfo();
  },
  methods: {
    toPersonal(id) {
      // if (deleted == 1) {
      //   return this.$message("用户已注销");
      // }
      if (this.isApp) {
        if (isAndroid()) window.Android.toCustomerInfo(id);
      if (isIOS()) window.webkit.messageHandlers.toCustomerInfo.postMessage(id);
      }
      else openPersonal(id);
    },
    async getAddPrizeLog() {
      const addPrizeLogRes = await request.get("/dp/lottery/getAddPrizeLog", {
        params: {
          lotteryId: this.lotteryId,
        },
      });
      if (addPrizeLogRes.code == 200) {
        if (addPrizeLogRes.data) {
          this.isJoin = true;
        }
      }
    },
    async drawPrize() {
      if (this.flag == 3 && this.isJoin) {
        const drawPrizeRes = await request.post("/dp/lottery/drawPrize", {
          lotteryId: this.lotteryId,
        });
        if (drawPrizeRes.code == 200) {
          this.getLotteryInfo();
        }
      }
    },
    async opencard() {
      console.log("🌟 opencard 刮开卡片回调");
      this.isOpen = true;
      const changeStatusRes = await request.get("/dp/lottery/changeStatus", {
        params: {
          lotteryLogId: this.lotteryLogId,
        }
      });
      if (changeStatusRes.code == 200) {
        console.log("已刮奖");
      }
    },
    async getLotteryInfo() {
      console.log("获取活动信息");
      const lotteryInfoRes = await request.get("/dp/lottery/info", {
        params: {
          lotteryId: this.lotteryId,
        },
      });
      document.title = lotteryInfoRes.data.name;
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", lotteryInfoRes.data.description);
      this.webInfo = lotteryInfoRes.data;
      this.flag = this.webInfo.flag;
      this.rule = this.webInfo.rule;
      if (this.webInfo.lotteryLog && this.isLogin) {
        this.isLottery = true;
        this.lotteryLogId = this.webInfo.lotteryLog.lotteryLogId;
        console.log("lotteryLogId",this.lotteryLogId)
        if (this.webInfo.lotteryLog.win) {
          this.isWin = true;
          this.blessing = this.webInfo.lotteryLog.prizeName;
          this.blessingUser = this.webInfo.lotteryLog.prizeCreator;
          this.cardContent = `<div class="cardBoxInner">
                    <div class="blessingBox">${this.blessing}</div>
                    <div class="blessingUser">
                      <div class="avatar">
                        <img class="imgCut" src=${this.blessingUser.avatar} alt="" />
                      </div>
                      <span>&nbsp;${this.blessingUser.nickname} ></span>
                    </div>
                  </div>`;
          if (this.webInfo.lotteryLog.status == 1) {
            this.isOpen = true;
          }
        }
      }
      else if (this.isJoin) {
        this.drawPrize();
      } 
    },
    clickLogin() {
      this.$login();
      return;
    },
    async clickJoin() {
      if (!this.message) {
        this.$message({
          message: "填写的祝愿不可为空",
        });
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.joinText = "正在发送，请稍等...";
      const joinLotteryRes = await request.post("/dp/lottery/addPrize", {
        lotteryId: this.lotteryId,
        prizeName: this.message,
      });
      this.loading = false;
      if (joinLotteryRes.code == 200) {
        this.isJoin = true;
        this.$nextTick(() => {
          this.$refs.scrollContainer.scrollTop = 0;
        });
      } else this.joinText = "发送";
    },
  },
};
</script>

<style>
.webPage {
  width: 700px;
}
.webPageBanner {
  border-radius: 4px;
  overflow: hidden;
  height: 300px;
  width: 100%;
}
.midautumn .webPage textarea {
  margin: 12px auto 0 auto;
  padding: 6px;
  width: 500px;
  background: var(--background-color-item);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  color: var(--font-color);
  min-height: 80px !important;
}
.webPage .inputTips {
  width: 500px;
}
.webPageText {
  font-size: 16px;
  margin-top: 20px;
  line-height: 32px;
}
.mobilePage .webPage {
  width: auto;
}
.mobilePage .webPageBanner {
  width: 94%;
  height: 143px;
}
.mobilePage.midautumn .webPage textarea {
  width: 85%;
}
.mobilePage .webPage .inputTips {
  width: 85%;
}
.mobilePage .webPage .el-textarea .el-input__count {
  right: 40px;
  bottom: 0;
}
.nut-luckycard {
  cursor: pointer;
}
.cardBox .nut-luckycard .nut-content {
  padding: 0 65px;
  width: auto;
}
.cardBox {
  border-radius: 6px;
  overflow: hidden;
  margin-top: 20px;
  background-image: url("~@/assets/style/Group 227@2x.png");
  background-size: 270px 250px;
  width: 270px;
  height: 250px;
}
.cardBox .avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.cardBoxInner {
  width: 270px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.blessingUser {
  display: flex;
  font-size: 13px;
  color: #B47C26;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  cursor: pointer;
  line-height: 24px;
}
.blessingBox {
  width: 170px;
  height: 126px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #683016;
  font-size: 14px;
  line-height: 22px;
}
.appPage .pageDet {
  margin-top: 14px;
}
</style>

<style scoped>

.mobilePage .voteRule {
  width: 88%;
}
.pcPage {
  min-width: 1200px;
}

</style>