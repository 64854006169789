<template>
  <div
    :class="{ mobilePage: isMobile, appPage: isApp }"
    class="home-wrapper scroll-container"
    ref="scrollContainer"
  >
    <web-header v-if="!isApp" :disabledScroll="true" />
    <div :class="{ pcPage: !isMobile }">
      <div class="webPage pageDet">
        <div class="webPageBanner musicBanner">
          <img class="imgCut" :src="webInfo.image" alt="" />
        </div>
        <!-- 未登录样式 -->
        <div v-if="!isLogin">
          <div class="loginTips">
            <div>当前未登录</div>
            <div v-if="!isApp" class="clickLoginBtn" @click="clickLogin()">
              👉 点击登录DROPIN多频 👈
            </div>
            <div v-else>请登录App后<br />再提名音乐</div>
          </div>
        </div>
        <div class="greyBlock" v-if="isLogin">
          <el-form
            :rules="formRules"
            ref="nominateForm"
            :model="nominateForm"
            hide-required-asterisk
          >
            <el-form-item
              :label="`*1. 请添加来自网易云音乐或QQ音乐的单曲、专辑，并点击解析`"
            >
              <el-input
                v-model="nominateForm.musicUrl"
                class="nominateMusicUrl"
                type="textarea"
                autosize
              ></el-input>
              <el-button
                class="music-video-btn"
                round
                type="primary"
                @click="filterUrl(nominateForm.musicUrl)"
                >解析</el-button
              >
            </el-form-item>
            <div class="nominateMusic" v-if="hasMusic">
              <div class="nominateMusicCover">
                <img class="imgCut" :src="music.pic" alt="" />
              </div>
              <div class="nominateMusicInfo">
                <div class="nominateMusicName">{{ music.title }}</div>
                <div class="nominateMusicArtist" v-html="music.artist"></div>
                <div>发行日期：{{ music.publishDate }}</div>
                <div>类型：{{ musicTypeMap[music.type] }}</div>
              </div>
            </div>
            <el-form-item label="*2. 地区" prop="area">
              <span slot="label">
                <span>
                  <span
                    >*2. 地区
                    <el-popover
                      placement="top"
                      width="300"
                      trigger="click"
                      content="中国（含港澳台地区），指包括但不限于由中国（含港澳台地区）籍艺术家、音乐厂牌或唱片公司参与制作或发行的单曲/EP/专辑作品。"
                    >
                      <i slot="reference" class="el-icon-question"></i>
                    </el-popover>
                  </span>
                </span>
              </span>
              <el-select
                v-model="nominateForm.area"
                placeholder=""
                value-key="id"
                placement="bottom"
              >
                <div>
                  <el-option
                    label="中国 (含港澳台地区）"
                    :value="'0'"
                  ></el-option>
                  <el-option label="海外" :value="'1'"></el-option>
                </div>
              </el-select>
            </el-form-item>
            <el-form-item label="3. 厂牌">
              <el-tag
                v-for="(tag, idx) in dynamicLabelTags"
                :key="idx"
                closable
                :disable-transitions="false"
                @close="handleClose(tag, 'label')"
                :class="{ encyTag: tag.mold == 1 }"
              >
                <img
                  v-if="tag.mold == 1"
                  class="encyIcon"
                  src="@/assets/style/icon_pedia@2x.png"
                  alt=""
                />
                {{ tag.name }}
              </el-tag>
              <el-autocomplete
                class="input-new-tag"
                ref="saveLabelTagInput"
                v-if="inputLabelVisible"
                value-key="name"
                v-model="inputLabel.name"
                :fetch-suggestions="querySearchLabel"
                placeholder="请输入内容"
                placement="bottom"
                @select="handleLabelSelect"
              >
                <template slot-scope="{ item }">
                  <img
                    v-if="item.mold!= 0"
                    class="encyIcon"
                    style="margin-right: 4px"
                    src="@/assets/style/icon_pedia@2x.png"
                    alt=""
                  />
                  <span>{{ item.name }}</span>
                </template>
              </el-autocomplete>
              <el-button class="addEncy" @click="showInput('label')">
                {{ addLabelBtnText }}</el-button
              >
            </el-form-item>
            <el-form-item label="4. 风格/标签">
              <el-tag
                v-for="(tag, idx) in dynamicTags"
                :key="idx"
                closable
                :disable-transitions="false"
                @close="handleClose(tag, 'style')"
                :class="{ encyTag: tag.mold == 1 }"
              >
                <img
                  v-if="tag.mold == 1"
                  class="encyIcon"
                  src="@/assets/style/icon_pedia@2x.png"
                  alt=""
                />
                {{ tag.name }}
              </el-tag>
              <el-autocomplete
                class="input-new-tag"
                ref="saveTagInput"
                v-if="inputVisible"
                v-model="inputValue.name"
                value-key="name"
                :fetch-suggestions="querySearchStyle"
                placeholder="请输入内容"
                placement="bottom"
                @select="handleSelect"
              >
              <template slot-scope="{ item }">
                  <img
                    v-if="item.mold!= 0"
                    class="encyIcon"
                    style="margin-right: 4px"
                    src="@/assets/style/icon_pedia@2x.png"
                    alt=""
                  />
                  <span>{{ item.name }}</span>
                </template>
            </el-autocomplete>
              <el-button class="addEncy" @click="showInput('style')">
                {{ addBtnText }}</el-button
              >
            </el-form-item>
            <template v-if="music.type == 'song'">
              <el-form-item label="5. 曲速" prop="bpm">
                <el-input
                  class="speedInput"
                  v-model="nominateForm.bpm"
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                ></el-input>
                BPM
              </el-form-item>
              <el-form-item label="6. 调式" prop="mode">
                <el-select
                  v-model="nominateForm.mode"
                  placeholder=""
                  value-key="value"
                >
                  <div>
                    <el-option
                      :label="modeItem.label"
                      :value="modeItem"
                      v-for="(modeItem, idx) in modeList"
                      :key="idx"
                    ></el-option>
                  </div>
                </el-select>
              </el-form-item>
            </template>
          </el-form>
          <div class="submitBtn" @click="clickSubmitBtn()">提 交</div>
        </div>
        <div class="voteRule">
          <div>提名规则：</div>
          <div v-html="webInfo.rule"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebHeader from "@/components/WebHeader.vue";
import { mapGetters } from "vuex";
import { isAppFun } from "@/utils/judgeClient";
import { filterLink } from "@/utils/navigate";
import request from "@/utils/request";

export default {
  components: {
    WebHeader,
  },
  data() {
    return {
      formRules: {
        area: [{ required: true, message: "该项不可为空", trigger: "blur" }],
      },
      hasMusic: false,
      modeList: [
        {
          label: "暂不填写",
          value: "",
        },
        {
          label: "A Major",
          value: "A1",
        },
        {
          label: "A Minor",
          value: "A2",
        },
        {
          label: "A# Major",
          value: "A3",
        },
        {
          label: "A# Minor",
          value: "A4",
        },
        {
          label: "Ab Major",
          value: "A5",
        },
        {
          label: "Ab Minor",
          value: "A6",
        },
        {
          label: "B Major",
          value: "B1",
        },
        {
          label: "B Minor",
          value: "B2",
        },
        {
          label: "Bb Major",
          value: "B5",
        },
        {
          label: "Bb Minor",
          value: "B6",
        },
        {
          label: "C Major",
          value: "C1",
        },
        {
          label: "C Minor",
          value: "C2",
        },
        {
          label: "C# Major",
          value: "C3",
        },
        {
          label: "C# Minor",
          value: "C4",
        },
        {
          label: "D Major",
          value: "D1",
        },
        {
          label: "D Minor",
          value: "D2",
        },
        {
          label: "D# Major",
          value: "D3",
        },
        {
          label: "D# Minor",
          value: "D4",
        },
        {
          label: "Db Major",
          value: "D5",
        },
        {
          label: "Db Minor",
          value: "D6",
        },
        {
          label: "E Major",
          value: "E1",
        },
        {
          label: "E Minor",
          value: "E2",
        },
        {
          label: "Eb Major",
          value: "E5",
        },
        {
          label: "Eb Minor",
          value: "E6",
        },
        {
          label: "F Major",
          value: "F1",
        },
        {
          label: "F Minor",
          value: "F2",
        },
        {
          label: "F# Major",
          value: "F3",
        },
        {
          label: "F# Minor",
          value: "F4",
        },
        {
          label: "G Major",
          value: "G1",
        },
        {
          label: "G Minor",
          value: "G2",
        },
        {
          label: "G# Major",
          value: "G3",
        },
        {
          label: "G# Minor",
          value: "G4",
        },
        {
          label: "Gb Major",
          value: "G5",
        },
        {
          label: "Gb Minor",
          value: "G6",
        },
      ],
      nominateForm: {
        musicUrl: "",
        area: "",
        bpm: null,
        mode: null,
      },
      dynamicTags: [],
      dynamicLabelTags: [],
      inputVisible: false,
      inputLabelVisible: false,
      inputValue: {
        name: "",
        mold: 0,
      },
      inputLabel: {
        name: "",
        mold: 0,
      },
      labelSearchList: [],
      styleSearchList: [],
      state: "",
      timeout: null,
      isApp: false,
      addBtnText: "+ 添加",
      addLabelBtnText: "+ 添加",
      music: {
        title: "",
        artist: "",
        pic: "",
        originalUrl: "",
        type: null,
      },
      musicTypeMap: {
        song: "单曲",
        album: "专辑",
      },
      webInfo: {},
    };
  },
  computed: {
    ...mapGetters(["isLogin"]),
  },
  async created() {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      // 当前设备是移动设备
      this.isApp = isAppFun();
    }
    this.getWebInfo();
  },
  methods: {
    clickLogin() {
      this.$login();
      return;
    },
    // 获取活动相关信息
    async getWebInfo() {
      const webInfoRes = await request.get("/dp/home/getActivityWeb", {
        params: {
          awId: 5,
        },
      });
      if (webInfoRes.code == 200) {
        document.title = webInfoRes.data.name;
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", webInfoRes.data.description);
        this.webInfo = webInfoRes.data;
      }
    },
    // 删除标签
    handleClose(tag, type) {
      if (type == "label") {
        this.dynamicLabelTags.splice(this.dynamicTags.indexOf(tag), 1);
      } else if (type == "style") {
        this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      }
    },
    // 展示输入框
    showInput(type) {
      if (type == "label") {
        if (!this.inputLabelVisible) {
          this.inputLabelVisible = true;
          this.addLabelBtnText = "取消";
          this.$nextTick(() => {
            this.$refs.saveLabelTagInput.$refs.input.focus();
          });
          return;
        } else this.inputLabelVisible = false;
        this.inputLabel = {
          name: "",
          mold: 0,
        };
        this.addLabelBtnText = "+ 添加";
      } else if (type == "style") {
        if (!this.inputVisible) {
          this.inputVisible = true;
          this.addBtnText = "取消";
          this.$nextTick(() => {
            this.$refs.saveTagInput.$refs.input.focus();
          });
          return;
        } else this.inputVisible = false;
        this.inputValue = {
          name: "",
          mold: 0,
        };
        this.addBtnText = "+ 添加";
      }
    },
    // 确认输入风格标签
    handleInputConfirm(item) {
      if (item.name) {
        item.text = item.name;
        if (item.mold !== 0) {
          item.mold = 1;
          item.encyId = item.id;
        }
        this.dynamicTags.push(item);
      }
      this.inputVisible = false;
      this.addBtnText = "+ 添加";
      this.inputValue = {
        text: "",
        mold: 0,
      };
    },
    // 确认输入厂牌标签
    handleLabelConfirm(item) {
      if (item.name) {
        item.text = item.name;
        if (item.mold !== 0) {
          item.mold = 1;
          item.encyId = item.id;
        }
        this.dynamicLabelTags.push(item);
      }
      this.inputLabelVisible = false;
      this.addLabelBtnText = "+ 添加";
      this.inputLabel = {
        text: "",
        mold: 0,
      };
    },
    // 厂牌输入建议
    async querySearchLabel(queryString, cb) {
      if (!queryString) {
        const emptyResult = [];
        cb(emptyResult);
        return;
      }
      const labelListRes = await request.get("/dp/ency/searchList", {
        params: {
          name: queryString,
          typeId: 14,
        },
      });
      this.labelSearchList = labelListRes.data.slice();
      this.labelSearchList.unshift({ name: queryString, mold: 0 });
      // 调用cb函数，告诉组件结束loading状态，并展示搜索结果(res.result)
      cb(this.labelSearchList);
    },
    // 风格输入建议
    async querySearchStyle(queryString, cb) {
      if (!queryString) {
        const emptyResult = [];
        cb(emptyResult);
        return;
      }
      const styleListRes = await request.get("/dp/ency/searchList", {
        params: {
          name: queryString,
          typeId: 13,
        },
      });
      this.styleList = styleListRes.data.slice();
      this.styleList.unshift({ name: queryString, mold: 0 });
      // 调用cb函数，告诉组件结束loading状态，并展示搜索结果(res.result)
      cb(this.styleList);
    },
    // 选择风格输入建议
    handleSelect(item) {
      console.log(item);
      this.handleInputConfirm(item);
    },
    // 选择厂牌输入建议
    handleLabelSelect(item) {
      console.log(item);
      this.handleLabelConfirm(item);
    },
    // 音乐解析
    async filterUrl(url) {
      const res = await filterLink(url);
      if (res.code == 200) {
        this.hasMusic = true;
        this.music = res.data;
      }
    },
    // 点击提交按钮
    async clickSubmitBtn() {
      if (!this.hasMusic) {
        return this.$message({
          message: "提名必须要填写音乐链接并解析哦",
          type: "error",
        });
      }
      this.$refs.nominateForm.validate(async (valid) => {
        if (valid) {
          const submitRes = await request.post("/dp/vote/musicNominate", {
            area: this.nominateForm.area,
            artist: this.music.artist,
            bpm: this.nominateForm.bpm,
            brandJson: JSON.stringify(this.dynamicLabelTags),
            image: this.music.pic,
            mode: this.nominateForm.mode ? this.nominateForm.mode.value : "",
            musicUrl: this.music.originalUrl,
            name: this.music.title,
            publishDate: this.music.publishDate,
            styleJson: JSON.stringify(this.dynamicTags),
            type: this.music.type,
            voteId: 8,
          });
          if (submitRes.code == 200) {
            this.$message({
              message: "提名成功，可以继续提名哦",
              type: "success",
            });
            this.nominateForm = {
              musicUrl: "",
              area: "",
              bpm: null,
            };
            this.music = {
              title: "",
              artist: "",
              pic: "",
              originalUrl: "",
              type: null,
            };
            this.hasMusic = false;
            this.dynamicTags = [];
            this.dynamicLabelTags = [];
            this.$nextTick(() => {
              this.$refs.scrollContainer.scrollTop = 0;
            });
          }
        } else
          return this.$message({
            message: "地区为必填项哦，请填写后再提名",
            type: "error",
          });
      });
    },
  },
};
</script>

<style scoped>
.greyBlock {
  padding: 30px 0;
  text-align: left;
  font-weight: 400;
}
.speedInput.el-input {
  width: 80px;
  margin-right: 8px;
}
.addEncy.el-button {
  border-radius: 21px;
  background: transparent;
  padding: 6px 16px;
  color: var(--font-color);
  font-weight: 300;
  font-size: 14px;
  height: 32px;
}
.addEncy.el-button:focus,
.addEncy.el-button:hover {
  border-color: var(--color-50);
}
.submitBtn {
  width: 300px;
  height: 44px;
  color: #fff;
  background: #9c5cf7;
  border-radius: 25px;
  text-align: center;
  font-size: 16px;
  line-height: 44px;
  margin: 80px auto 0 auto;
  cursor: pointer;
}
.greyBlock .encyIcon {
  vertical-align: middle;
  margin-bottom: 3px;
}
/* 音乐样式 */
.nominateMusic {
  display: flex;
}
.nominateMusicCover {
  width: 180px;
  height: 180px;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 36px;
  flex-shrink: 0;
}
.nominateMusicName {
  font-size: 24px;
  color: #fff;
  font-weight: 500;
}
.nominateMusicArtist {
  line-height: 44px;
}
.nominateMusicInfo {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.7);
  margin-left: 22px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 24px;
  font-weight: 300;
}
/* 音乐样式 */
</style>

<style>
.el-tag {
  margin: 0 10px 10px 0;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
  border: none;
  font-size: 13px;
}
.encyTag.el-tag {
  background: rgba(113, 212, 255, 0.2);
  color: #4d91ae;
}
.button-new-tag {
  margin-left: 0 10px 10px 0;
  height: 28px;
  line-height: 24px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 180px;
  height: 32px;
  margin-right: 10px;
  line-height: 32px;
}
.input-new-tag .el-input__inner {
  height: 32px;
  line-height: 32px;
  font-size: 13px;
}
.encyTag.el-tag .el-tag__close {
  color: #4d91ae;
}
.el-tag .el-tag__close:hover {
  background-color: #a7a6a6;
  color: #fff;
}
.el-tag .el-tag__close {
  color: #fff;
}
.el-autocomplete-suggestion {
  border: none;
}
.el-autocomplete-suggestion li {
  color: var(--font-color);
  padding: 0 14px;
}
.el-autocomplete-suggestion li.highlighted,
.el-autocomplete-suggestion li:hover {
  background-color: transparent;
  color: #9c5cf7;
}
.el-form-item__content {
  flex-wrap: wrap;
}
.greyBlock .nominateMusicUrl.el-textarea {
  width: 78%;
}
.el-autocomplete-suggestion__list li:first-child {
  color: #9c5cf7;
  font-weight: 600;
}
.el-scrollbar__wrap {
  max-height: 170px;
}
.el-popover {
  background: #292929;
  color: rgba(255, 255, 255, 0.7);
  border: none;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}
.el-popper[x-placement^="right"] .popper__arrow::after {
  border-right-color: #292929;
}
.el-popper[x-placement^="right"] .popper__arrow {
  border-right-color: #292929;
}
.speedInput + .el-form-item__error {
  left: 130px;
  top: 10px !important;
}
.el-select + .el-form-item__error {
  left: 220px;
  top: 10px !important;
}
.webPageBanner.musicBanner {
  height: 250px;
}
.pcPage {
  min-width: 1200px;
}
.mobilePage .voteRule {
  width: 94%;
}
.mobilePage .webPageBanner.musicBanner {
  height: 123px;
}
.mobilePage .nominateMusicCover {
  width: 120px;
  height: 120px;
}
.mobilePage .nominateMusicName {
  font-size: 18px;
}
.mobilePage .nominateMusicInfo {
  height: auto;
  justify-content: unset;
  font-size: 14px;
  margin-left: 16px;
}
.mobilePage .nominateMusicArtist {
  line-height: 30px;
}
.el-popper[x-placement^="bottom"] {
  background: #292929;
}
</style>