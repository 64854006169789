<template>
  <div
    :class="{ mobilePage: isMobile, appPage: isApp }"
    class="home-wrapper scrollContainer"
    ref="scrollContainer"
  >
    <web-header>
      <!-- 里面放滚动切换的内容 -->
      <div class="headSticky scrollSticky">
        <div class="head" style="justify-content: center;">
          <div style="position: relative; display: flex; justify-content: center; width: 834px;">
            <div class="tit_name">{{ examPaper.name }}</div>
            <div class="tit_time">
              <div><img src="@/assets/style/exam_clock@2x.png" alt="" width="15px" /></div>
              <div class="title_right" style="width: 36px;">{{remainTime}}</div>
              <div><img src="@/assets/style/exam_correct_num@2x.png" alt="" width="15px" v-if="!isMobile"/></div>
              <div class="title_right" style="color: #00a80b; width: 18px;" v-if="!isMobile">{{correctNum}}</div>
              <div><img src="@/assets/style/exam_error_num@2x.png" alt="" width="15px" v-if="!isMobile"/></div>
              <div class="title_right" style="color: #e02020;" v-if="!isMobile">{{errorNum}}/{{examPaper.total-examPaper.needScore}}</div>
            </div>
          </div>
        </div>
      </div>
    </web-header>

    <div :class="{ pcPage: !isMobile }">
      <div class="webPage pageDet">

        <!-- 考题列表 -->
        <div class="exam_all" v-if="this.examPaper">
          <div class="exam_tit">
            <div class="tit_name">{{ examPaper.name }}</div>
            <div class="tit_time">
              <div><img src="@/assets/style/exam_clock@2x.png" alt="" width="15px" /></div>
              <div class="title_right" style="width: 36px;">{{remainTime}}</div>
              <div><img src="@/assets/style/exam_correct_num@2x.png" alt="" width="15px" v-if="!isMobile"/></div>
              <div class="title_right" style="color: #00a80b; width: 18px;" v-if="!isMobile">{{correctNum}}</div>
              <div><img src="@/assets/style/exam_error_num@2x.png" alt="" width="15px" v-if="!isMobile"/></div>
              <div class="title_right" style="color: #e02020;" v-if="!isMobile">{{errorNum}}/{{examPaper.total-examPaper.needScore}}</div>
            </div>
          </div>

          <div v-for="(item, idx) in examPaper.subjectList" :key="idx" class="exam_subject_list">
            <div class="subject_title">{{ idx+1+'. '+item.title }}</div>
            <div class="optionListDet">
              <div v-for="(option, i) in item.optionList" :key="i" class="optionDet" @click.stop="submitOption($event,option, item, examPaper.mark)"
                :class="{'succ':item.answerTrue == option.id, 'error':item.answerFalse == option.id}">
                <div class="optionPrefix" v-if="i == 0">A</div>
                <div class="optionPrefix" v-if="i == 1">B</div>
                <div class="optionPrefix" v-if="i == 2">C</div>
                <div class="optionPrefix" v-if="i == 3">D</div>
                <div class="option_text" v-if="!isMobile" >{{ option.content }}</div>
                <div class="m_option_text" v-if="isMobile" >{{ option.content }}</div>
                <div style="width: 28px; text-align: right;">
                  <img src="@/assets/style/exam_correct_num@2x.png" width="15px" v-if="option.id == item.answerTrue"/>
                  <img src="@/assets/style/exam_error_num@2x.png" width="15px" v-if="option.id == item.answerFalse"/>
                </div>
              </div>
            </div>
            <div class="subjectRemark" v-if="item.remarks">{{ item.remarks }}</div>
          </div>

          <div style="display: flex; justify-content: center;" v-if="!isMobile">
            <div class="examResult" style="margin: 36px;" @click="getResultBefore(examPaper.id, examPaper.mark)">查看测试结果</div>
          </div>

          <div class="m_exam_bottom" v-if="isMobile">
            <div style="display: flex; position:absolute; width: 120px; left: 20px; top: 30px">
              <div><img src="@/assets/style/exam_correct_num@2x.png" alt="" width="15px"/></div>
              <span class="title_right" style="color: #00a80b; width: 18px;">{{correctNum}}</span>
              <div><img src="@/assets/style/exam_error_num@2x.png" alt="" width="15px"/></div>
              <span class="title_right" style="color: #e02020;">{{errorNum}}/{{examPaper.total-examPaper.needScore}}</span>
            </div>
            <div class="examResult" style="margin: 20px;position:absolute;right: 0;" @click="getResultBefore(examPaper.id, examPaper.mark)">查看测试结果</div>
          </div>
        </div>

      </div>

      <!-- 没答完点击结果弹窗 -->
      <el-dialog :visible.sync="resultWarnDialog" class="exam_1" center :show-close="false" width="320px" :close-on-click-modal="false" :close-on-press-escape="false" top="40vh">
        <span style="font-size: 16px; font-weight: 400;">未完成测试，需要查看测试结果吗？</span>
        <span slot="footer" class="dialog-footer">
          <el-button style="background-color:transparent; color: #9c5cf7; border: 1px solid #9c5cf7; border-radius: 43px; cursor: pointer;"
            @click="getExamResult(examPaper.id, examPaper.mark)">查看结果</el-button>
          <el-button type="primary" style="border-radius: 43px; cursor: pointer; marign-left: 20px;" @click="resultWarnDialog = false">继续答题</el-button>
        </span>
      </el-dialog>
      <!-- 到时间弹窗 -->
      <el-dialog :visible.sync="examTimeoutDialog" class="exam_1" center :show-close="false" width="320px" :close-on-click-modal="false" :close-on-press-escape="false" top="40vh">
        <span style="font-size: 16px; font-weight: 400;">答题时间已到，本次测试结束</span>
        <span slot="footer" class="dialog-footer" style="display: flex; justify-content: center;">
          <div class="examResult" @click="getExamResult(examPaper.id, examPaper.mark)">查看测试结果</div>
        </span>
      </el-dialog>
      <!-- 考试未通过弹窗 -->
      <el-dialog :visible.sync="examFailDialog" center :show-close="false" width="300px" :close-on-click-modal="false" :close-on-press-escape="false" top="20vh">
        <div style="margin-bottom: 10px">
          <img src="@/assets/style/exam_fail@2x.png" width="165px">
        </div>
        <span style="font-size: 16px; font-weight: 400;">很可惜，你没通过测试，<br/>继续努力哦！</span>
        <span slot="footer" class="dialog-footer">
          <div style="display: flex; justify-content: center; margin-bottom: 20px;">
            <div class="examResult" @click="toExamPage()">重测</div>
          </div>
          <div style="color: rgba(255,255,255,0.70); font-size: 14px; font-weight: 400; cursor: pointer;" @click="toHome()">下次再来</div>
        </span>
      </el-dialog>
      <!-- 考试通过弹窗 -->
      <el-dialog :visible.sync="examPassDialog" center :show-close="false" width="300px" :close-on-click-modal="false" :close-on-press-escape="false" top="20vh">
        <div style="margin-bottom: 10px">
          <img src="@/assets/style/exam_pass@2x.png" width="165px">
        </div>
        <div style="color: #9c5cf7; font-size: 24px; font-weight: 500; margin-bottom: 10px">恭喜!</div>
        <div style="font-size: 16px; font-weight: 400;">你已通过DROPIN多频入门测试！<br/>解锁权限：发布信号、评论及音乐增益</div>
        <span slot="footer" class="dialog-footer" style="display: flex; justify-content: center;">
          <div class="examResult" @click="toHome()">马上体验</div>
        </span>
      </el-dialog>
      
    </div>
  </div>
</template>

<script>
import WebHeader from "@/components/WebHeader.vue";
import { mapGetters } from "vuex";
import { isAppFun } from "@/utils/judgeClient";
import dayjs from "dayjs";
import request from "@/utils/request";
import { openUrl } from "@/utils/navigate";
export default {
  components: {
    WebHeader,
  },
  mounted() {
    // window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      window.addEventListener('beforeunload', vm.beforeunloadFn)
    })
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener('beforeunload', this.beforeunloadFn)
    next()
  },
  destroyed() {
    // window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        firstDayOfWeek: 1,
      },
      dateValue: dayjs().format("YYYY-MM-DD"),
      isApp: false,
      sortHoverShow: false,
      examPaperList: [],
      examPaper: null,
      correctNum: 0,
      errorNum: 0,
      resultWarnDialog: false,
      examTimeoutDialog: false,
      examFailDialog: false,
      examPassDialog: false,
      duration: 0,
      remainTime: "10:00",
    };
  },
  computed: {
    ...mapGetters(["isLogin"]),
  },
  watch: {
    isLogin: {
      immediate: true,
      handler(val, oldVal) {
        // 从false变成true, 拉一下活动信息查询
        if (!oldVal && val) {
          //this.getVoteFilter();
        }
      },
    },
  },
  async created() {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      // 当前设备是移动设备
      this.isApp = isAppFun();
    }
    this.startExam(this.$route.query.examPaperId);
  },
  methods: {
    beforeunloadFn (e) {
      // 这个事件只有在鼠标真正和浏览器有了交互，再刷新或者关闭时才会触发, 浏览器事件会弹框确认用户是否要离开页面
      e = e || window.event
      if (e) {
        e.returnValue = '关闭提示'
      }
      return '关闭提示';
    },
    handleOpenUrl(urlStr) {
      openUrl(urlStr, this.isApp);
    },
    // 点击开始考试
    async startExam(examPaperId){
      const examPaperRes = await request.get("/dp/exam/getExam", {
        params: {
          examPaperId: examPaperId,
          examLevel: 1,
        },
      });
      if (examPaperRes.code == 200) {
        if(examPaperRes.msg.startsWith("已通过")) {
          this.$message({
            message: "已通过入门测试，即将在3秒后返回首页",
          });
          setTimeout(() => {
            this.$router.push('/');
          }, 3000)
        }
        this.examPaper = examPaperRes.data;
        this.duration = examPaperRes.data.duration * 60;
        // 开启定时器
        let timer = setInterval(() => {
          let duration = this.duration;
          if(duration == 0){
            clearInterval(timer);
            // 超时弹窗
            this.examTimeoutDialog = true;
          }
          let min = Math.floor(duration / 60);
          let second = duration % 60;
          if(second < 10){
            second = "0" + second;
          }
          this.remainTime = min+':'+second;
          duration -= 1;
          this.duration = duration;
        }, 1000);
      }
    },
    // 点击选项提交
    async submitOption(event, option, subject, mark){
      if(subject["answered"]) {
        return;
      }
      let _this=this;
      // const currentTarget = event.currentTarget
      const getCorrect = await request.get("/dp/exam/getCorrect", {
        params: {
          examPaperId: subject.examPaperId,
          examSubjectId: subject.id,
          examOptionId: option.id,
          mark: mark, 
        },
      });
      if (getCorrect.code == 200) {
        let newSubject = getCorrect.data;
        subject["answered"] = true; // 已作答标记
        subject["answerTrue"] = newSubject.option.id; // 正确标记
        subject.remarks = "注释：" + newSubject.remarks;
        if(option.id == newSubject.option.id) {
          // currentTarget.classList.add("succ");
          this.correctNum += 1;
        }else {
          subject["answerFalse"] = option.id; // 错误标记
          _this.$forceUpdate(); 
          // currentTarget.classList.add("error");
          this.errorNum += 1;
        }
        if(this.errorNum > this.examPaper.total-this.examPaper.needScore){
          this.examFailDialog = true;
        }
      }
    },
    // 点击结果之前判断
    getResultBefore(examPaperId, mark){
      if((this.examPaper.total > this.errorNum + this.correctNum) && this.duration > 0){
          this.resultWarnDialog = true;
          return;
      }
      this.getExamResult(examPaperId, mark);
    },
    // 结束考试
    async getExamResult(examPaperId, mark){
      const examResult = await request.get("/dp/exam/getExamResult", {
        params: {
          examPaperId: examPaperId,
          mark: mark, 
        },
      });
      if(examResult.code == 200) {
        if(examResult.msg.startsWith("很可惜")){
          this.examFailDialog = true;
        }else {
          this.examPassDialog = true;
        }
      }
    },
    // 去首页
    toHome(){
      this.$router.push('/')
    },
    // 重测
    toExamPage(){
      this.examPaper = null;
      this.correctNum = 0;
      this.errorNum = 0;
      this.examFailDialog = false;
      this.$router.push('/exam');
    },
    
  },
};
</script>

<style scoped>
.pageDet {
  padding: 0px;
}
/* .el-dialog__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.el-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */
.exam_subject_list {
  background: #1f1f1f;
  border-radius: 4px;
  margin-top: 24px;
  padding: 20px 30px;
}
.subject_title {
  font-size: 16px;
  font-weight: 500;
  text-align: LEFT;
  color: #ffffff;
  line-height: normal;
  margin-bottom: 8px;
}
.optionListDet {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
}
.optionDet {
  width: 362px;
  height: 57px;
  background: rgba(255,255,255,0.10);
  border-radius: 6px;
  margin-top: 12px;
  margin-right: 12px;
  padding: 9px 12px 9px 15px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}
.optionDet.succ{
  background-color: #ECFCE9;
  color: #00A80B;
}
.optionDet.error{
  background-color: #FCE9E9;
  color: #E02020;
}
.optionPrefix {
  width: 20px;
  font-size: 14px;
  font-weight: 400;
  text-align: LEFT;
}
.option_text {
  font-size: 14px;
  font-weight: 400;
  text-align: LEFT;
  line-height: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  flex: 1;
  
}
.subjectRemark {
  font-size: 14px;
  font-weight: 400;
  text-align: LEFT;
  color: rgba(255,255,255,0.60);
  line-height: 14px;
  margin-top: 20px;
}
.examResult {
  width: 200px;
  height: 44px;
  background: #9c5cf7;
  border-radius: 22px;
  font-size: 16px;
  font-weight: 500;
  text-align: CENTER;
  color: #ffffff;
  line-height: 45px;
  cursor: pointer;
}
.tit_time{
  position:absolute;
  right: 10px;
  display: flex;
}
.exam_all{
  width: 834px;
}
.exam_tit{
  position: relative;
  height: 48px;
  background: #1f1f1f;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.tit_name {
  font-size: 15px;
  font-weight: 700;
}
.title_right {
  font-size: 14px;
  font-weight: 500;
  margin-right: 18px;
  width: 25px;
  margin-left: 3px;
}
.el-button+.el-button {
    margin-left: 20px;
}

/* 下面是移动端 */
.mobilePage .exam_all{
  width: 100%;
}
.mobilePage .exam_tit{
  margin-top: 0px;
}
.mobilePage .exam_subject_list {
  margin-top: 20px;
  padding: 20px 16px;
}
.mobilePage .optionDet {
  height: auto;
  margin-right: 0px;
  padding: 9px 30px 9px 20px;
}
.m_option_text {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  flex: 1;
  align-items: center;
}
.m_exam_bottom {
  display: flex; 
  position: relative; 
  height: 80px;
  position: sticky;
  bottom: 0;
  background: #1f1f1f;
}
/* .mobilePage .examResult {
  margin: 20px;
  position:absolute;
  right: 0;
} */
</style>

<style>
.mobilePage .exam_1 .el-dialog {
  margin-top: 40vh !important;
}
</style>
