const getters = {
  // sidebar: state => state.app.sidebar,
  // language: state => state.app.language,
  // size: state => state.app.size,
  // device: state => state.app.device,
  // visitedViews: state => state.tagsView.visitedViews,
  // cachedViews: state => state.tagsView.cachedViews,
  // 有token表示已经登录
  isLogin: state => !!state.user.token && state.user.token !== "undefined" ,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  nickname: state => state.user.nickname,
  id: state => state.user.id,
  isRealOfficial: state => state.user.isRealOfficial,
  isOfficial: state => state.user.isOfficial,
  expLogo: state => state.user.expLogo,
  descriptions: state => state.user.descriptions,
  gender: state => state.user.gender,
  mobilePhone: state => state.user.mobilePhone,
  // introduction: state => state.user.introduction,
  // roles: state => state.user.roles,
  // permission_routes: state => state.permission.routes,
  // errorLogs: state => state.errorLog.logs
}
export default getters
