<template>
  <div class="search" :class="{ mobilePage: isMobile }">
    <input
      v-model="message"
      placeholder="搜索信号/频段/百科"
      class="searchInput"
      ref="search"
      @keyup.enter="clickSearch(message)"
    />
    <img
      class="closeBtnImg"
      src="@/assets/style/btn_close@2x (1).png"
      alt=""
      v-show="isMobile && message"
      @click="clearInput()"
    />
    <div class="searchIcon" @click="clickSearch(message)">
      <img class="searchIconImg" src="@/assets/style/Slice 1@2x.png" alt="" />
      <img
        class="searchIconImg searchIconFocus"
        src="@/assets/style/icon_search@2x_white.png"
        alt=""
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      message: "",
      tab: "signal",
    };
  },
  created() {

    this.message = this.$route.query.keyword;
    this.tab = this.$route.query.type;
  },
  methods: {
    clearInput() {
      this.message = '';
              this.$nextTick(() => {
          this.$refs.search.focus();
        });
    },
     clickSearch(message) {
      let url = "search";
      if (!message) {
        return;
      }
      if (this.$route.name === url) {
        // 点击的是当前路由 手动重定向页面到 '/redirect' 页面
        this.$router.replace({
          path: "/redirect",
          query: {
            path: encodeURI(`/${url}?keyword=${message}&type=${this.tab}`),
          },
        });
        console.log("🌟 this.tab", this.tab);
      } else {
        // 正常跳转
        this.$router.push({
          name: "search",
          query: {
            keyword: message,
            type: "signal",
          },
        });
      }
    },
  },
};
</script>

<style>
.search.mobilePage {
  margin: 14px 0;
  width: 88%;
}
.mobilePage .searchInput {
  width: 88%;
}
.mobilePage .search .closeBtnImg {
    width: 10px;
    position: absolute;
    right: 42px;
    top: 9px;
    cursor: pointer;
    padding: 2px 3px;
    border-radius: 50%;
    background: #878787;
    box-shadow: 0 0 2px rgb(0 0 0 / 25%);
}
</style>
