<template>
  <!-- 以下是头部板块 -->
  <div class="home-wrapper" :class="{ mobilePage: isMobile, appPage: isApp }">
    <web-header v-if="!isApp">
      <!-- 里面放滚动切换的内容 -->
      <div class="headSticky scrollSticky">
        <div class="head">
          <div class="headLeft">
            <div class="detailTitle textOverflow scrollSticky">
              {{ item.name }}
            </div>
          </div>
          <div class="headRight"></div>
        </div>
      </div>
    </web-header>
    <div class="content">
      <div class="left">
        <div class="signalDetail">
          <!-- 以下是信号标题 -->
          <div class="detailTitle">
            {{ item.name }}
          </div>
          <!-- 以下是作者信息 -->
          <div class="detailAuthor" v-if="item.member">
            <div class="detailAuthorHead" @click.stop="toPersonal">
              <div class="detailAuthorPhoto">
                <img
                  v-if="item.member && item.member.avatar && item.member.deleted == 0"
                  class="imgCut"
                  :src="item.member.avatar"
                  alt=""
                />
                <img
                  v-else
                  class="imgCut"
                  src="@/assets/style/img_portrait@2x.png"
                  alt=""
                />
              </div>
              <img
                v-if="item.member.isRealOfficial === 1 && item.member.deleted == 0"
                class="detailUserTypeImg"
                src="@/assets/style/icon_official@2x.png"
                alt=""
              />
              <img
                v-else-if="item.member.isOfficial === 1 && item.member.deleted == 0"
                class="detailUserTypeImg"
                src="@/assets/style/icon_vip@2x.png"
                alt=""
              />
            </div>
            <div class="detailAuthorRight" @click.stop="toPersonal">
              <div class="authorName" v-if="item.member.deleted == 0">
                {{ item.member.nickname }}
                <img :src="item.member.expLogo" alt="" />
              </div>
              <div v-else class="authorName">账号已注销</div>
              <div class="publishTime">发布于{{ item.createTime }}</div>
            </div>
            <span
              v-if="!isMine && item.member.deleted == 0 && !isApp"
              class="clickButton followBtn"
              @click="clickFollow()"
              :class="{followedBtn : isAttention }"
            >
              {{ followText }}
            </span>
            <CancelFollow ref="followDialog" :member="item.member" @cancelHandler="localCancel"  />
          </div>
          <!-- 以下是信号正文 -->
          <div class="loading" v-if="loading">
            <img class="loadingImg" src="@/assets/style/loading.gif" alt="" />
          </div>
          <div class="first_part">
            <div class="m_image">
              <img :src="item.image" width=187px; height=281px; style="object-fit: cover;">
            </div>
            <div>
              <div class="textTitle">基本信息</div>
              <div class="textLabel">活动时间：<span class="textValue">{{item.startDate}}-{{item.endDate}}</span></div>
              <div class="textLabel">活动类型：<span class="textValue">{{item.type}}</span></div>
              <div class="textLabel">活动形式：<span class="textValue">{{item.form}}</span></div>
              <div class="textLabel" v-if="item.form.includes('线上')">直播平台：<PlatformLink class="textValue" :list="item.livestreamPlatformList" /></div>
              <div class="textLabel" v-if="item.form.includes('线下')">举办城市：<span class="textValue">{{item.city}}</span></div>
              <div class="textLabel" v-if="item.form.includes('线下')">举办场所：<span class="textValue">{{item.site}}</span></div>
              <div class="textLabel" v-if="item.form.includes('线下') && item.address">详细地址：<span class="textValue">{{item.address}}</span></div>
              <div class="textLabel">主办方：<det-list class="textValue" style="line-height: 20px" :list="item.organizerList" /></div>
              <div class="textLabel" v-if="item.content">活动详情介绍：</div>
              <div
                v-if="item.content"
                id="webContent"
                class="webContent"
                v-html="item.content"
              ></div>
            </div>
          </div>
          <div style="padding:40px 0px 40px 0px; border-top:1px solid rgba(255,255,255,0.10); width:100%">
            <div class="textTitle">音乐阵容</div>
            <div class="textLabel" v-if="item.relatedStyleList && item.relatedStyleList.length>0">
              音乐风格：<det-list class="textValue" style="line-height: 20px" :list="item.relatedStyleList" />
            </div>
            <div class="textLabel" v-if="item.relatedArtistList && item.relatedArtistList.length>0">
              参演艺人：<det-list class="textValue" style="line-height: 20px" :list="item.relatedArtistList" />
            </div>
            <div class="textLabel" v-if="item.relatedMusicList && item.relatedMusicList.length>0" style="display: flex;">
              <div>相关音乐：</div><MusicList class="textValue" style="flex:1" :list="item.relatedMusicList" /> 
            </div>
          </div>
          <div style="padding:40px 0px 40px 0px; border-top:1px solid rgba(255,255,255,0.10);">
            <div class="textTitle">活动参与</div>
            <div class="textLabel" v-if="item.ticketPrice">门票价格：
              <span class="textValue" v-if="item.ticketPrice==0||item.ticketPrice==0.00">免费</span>
              <span class="textValue" v-else>{{item.ticketPrice}}元</span>
            </div>
            <div class="textLabel" v-if="item.participateMode">参与方式：<span class="textValue">{{item.participateMode}}</span></div>
            <div class="textLabel" v-if="item.ticketPlatformList && item.ticketPlatformList.length>0">
              购票平台：<PlatformLink class="textValue" :list="item.ticketPlatformList" />
            </div>
          </div>
          <!-- <div class="editTime">编辑于08-28</div> -->
        </div>
      </div>
      <!-- 以下是推荐阅读模块 -->
      <div class="right" v-if="!isMobile">
        <div class="rightField">
          <div class="rightTab halfBottom">
            <div class="rightTabText">推荐阅读</div>
            <div class="refresh">
              <img
                class="refreshImg"
                src="@/assets/style/refresh 1@2x.png"
                alt=""
              />
              <div class="refreshText" @click="pageTurn(pageNum)">切一切</div>
            </div>
          </div>
          <div class="rightList">
            <RecoArticle
              v-for="(item, idx) in recoArticles"
              :recoItem="item"
              :key="idx"
            />
          </div>
        </div>
        <!-- 以下是网站信息 -->
        <div class="sticky">
          <!-- 以下是网站信息 -->
          <FirstFooter />
        </div>
      </div>

    </div>
    <template>
      <el-backtop target=".home-wrapper" :bottom="100" :visibility-height="100">
        <div class="backTop">UP</div>
      </el-backtop>
    </template>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import Viewer from "viewerjs";
import WebHeader from "@/components/WebHeader.vue";
import FirstFooter from "@/components/FirstFooter.vue";
import RecoArticle from "@/components/RecoArticle.vue";
import request from "@/utils/request";
import DetList from "@/components/DetList.vue";
import MusicList from '@/components/MusicList'
import PlatformLink from '@/components/PlatformLink'
import {
  openSignal,
  openTopic,
  openEncy,
  openPersonal,
  // locationMobileUrl,
} from "@/utils/navigate";
import CancelFollow from "@/components/CancelFollow.vue";
import { isAppFun, isIOS, isAndroid } from "@/utils/judgeClient";

// import { mapGetters } from 'vuex';

export default {
  components: {
    WebHeader,
    FirstFooter,
    RecoArticle,
    CancelFollow,
    DetList,
    MusicList,
    PlatformLink,
  },
  computed: {
    ...mapGetters([
      "nickname",
      "avatar",
      "id",
      "isLogin",
      "isRealOfficial",
      "isOfficial",
      "expLogo",
      "token"
    ]),
    isMine() {
      return this.item.memberId === this.id;
    },
    uploadReqHeader() {
      return {
        'X-Duopin-Token': this.token
      }
    },
  },
  data() {
    return {
      item: {
        form:"",
      },
      recoArticles: [],
      pageNum: Math.ceil(Math.random() * 19 + 2),
      loading: true,
      viewer: null,
      activityId: 0,
      lastId: 0,
      loadingMore: false,
      showLoadmoreTimer: null,
      followText: "+关注",
      imageUrl: "",
      isAttention: false,
      catchText: "捕捉",
      uploadUrl: `${process.env.VUE_APP_API}/dp/common/upload`,
      toExamDialog: false,
      isApp: false,
    };
  },
  // computed: {
  //   ...mapGetters(['userId']),

  // },
  watch: {
    isCatch(val) {
      if (val) {
        this.catchText = "已捕捉";
      } else this.catchText = "捕捉";
    },
    isAttention(val) {
      if (val) {
        this.followText = "已关注"
      }
      else this.followText = "+关注"
    },
  },
  methods: {
    localCancel(confirmCancel) {
      if (confirmCancel) {
        this.isAttention = false;
      }
    },

    toSignal() {
      openSignal(this.item.esArticle.id);
    },
    toTopic(target) {
      openTopic(target.id);
    },
    toPersonal() {
      // if (deleted == 1) {
      //   return this.$message("用户已注销");
      // }
      let id = this.item.member.id;
      if (this.isApp) {
        if (isAndroid()) window.Android.toCustomerInfo(id);
        if (isIOS()) window.webkit.messageHandlers.toCustomerInfo.postMessage(id);
      }
      else openPersonal(id);
    },
    // 相关推荐切一切
    async pageTurn(pageNum) {
      this.pageNum = pageNum + 1;
      const recoRes = await request.post("/dp/article/list", {
        orderType: "HOME",
        pageNum: this.pageNum,
        pageSize: 5,
      });
      this.recoArticles = recoRes.data.records;
    },

    // iframe黑色样式
    addDarkClass(domString) {
      try {
        let parser = new DOMParser();
        let newDom = parser.parseFromString(domString, "text/html");
        const iframes = newDom.querySelectorAll("iframe");
        if (localStorage.getItem('theme') == 'light') {
          iframes.forEach((iframe) => (iframe.src));
        } else {
          iframes.forEach((iframe) => (iframe.src += "&darkMode=true"));
        }
        return newDom.body.innerHTML;
      } catch {
        return domString;
      }
    },
    handleIframeMessage(event) {
      switch (event?.data?.name) {
        case "clickMusic":
          window.open(event?.data?.data?.originalUrl);
          break;
        case "clickBaike":
          openEncy(event.data.data.item.typeId, event.data.data.item.dataId);
          break;
      }
    },
    async clickFollow() {
      if (!this.isLogin) {
        this.$login();
        return;
      }
      if (this.isAttention) {
        this.$refs["followDialog"].centerDialogVisible = true;
        return;
      }
      const followRes = await request.get("/dp/fans/attention", {
          params: {
            idolId: this.item.member.id,
          },
        });
        if (followRes.code == 200) {
          this.isAttention = true;
        }
    },
  },
  async created() {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      // 当前设备是移动设备
      this.isApp = isAppFun();
    }
    this.activityId = this.$route.query.id;
    // locationMobileUrl("signal",this.signalId);
    console.log("this.activityId=", this.activityId);
    const res = await request.get("/dp/activityInfo/detail", {
      params: {
        id: this.activityId,
      },
    });
    this.item = res.data;
    window.addEventListener("message", this.handleIframeMessage, false);
    this.isAttention = this.item.member.isAttention;
    document.title = `${this.item.name} - DROPIN多频`;
    // iframe黑暗样式
    this.item.content = this.addDarkClass(this.item.content);
    // 点击放大图片
    this.$nextTick(() => {
      this.viewer = new Viewer(document.getElementById("webContent"), {
        toolbar: false,
        navbar: false,
        title: false,
        movable: false,
        minZoomRatio: 0.3,
        maxZoomRatio: 3,
      });
    });
    console.log("===res: ", res);
    this.loading = false;

    // 初始化相关推荐
    const recoRes = await request.post("/dp/article/list", {
      orderType: "HOME",
      pageNum: this.pageNum,
      pageSize: 5,
    });
    this.recoArticles = recoRes.data.records;
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleIframeMessage, false);
    if (this.viewer && this.viewer.destroy) {
      this.viewer.destroy();
    }
  },
};
</script>
<style>
.clickButton {
  text-align: center;
  cursor: pointer;
  user-select: none;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  color: #b1b1b1;
}
.clickButton.followBtn {
  margin-left: auto;
  border-radius: 21px;
  border: 1px solid #9c5cf7;
  padding: 4px 15px;
  color: #9c5cf7;
}
textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.el-upload img {
  width: 20px;
  height: 20px;
}
.clickButton.musicBtn img {
  width: 20px;
  height: 20px;
}
.clickButton.musicBtn {
  margin: 0 18px 0 40px;
}
.clickButton.DROPBtn img {
  width: 37px;
}
.imgHover {
  display: none;
}
@media (any-hover: hover) {
.clickButton:hover .imgHover {
  display: block;
}
.clickButton:hover .imgCommon {
  display: none;
}
}
.clickButton.followBtn.followedBtn {
  background: var(--el-button--primary-disabled-background-color);
  border: none;
  /*color: #b1b1b1;*/
  color: var(--color-40);
}
/* 移动端适配样式 */
.mobilePage .comment {
  padding: 0 20px 12px 20px;
}
.mobilePage .signalDetail {
  padding: 20px;
}
</style>

<style scoped >
.detailAuthorRight {
  cursor: pointer;
}
.detailAuthorRight:hover .authorName {
  color: #9c5cf7;
}
.clickButton.btnClicked {
  color: #9c5cf7;
}
.el-button+.el-button {
    margin-left: 20px;
}

/* 新 */
.textTitle {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 700;
}
.textLabel {
  padding-top: 10px;
  font-size: 15px;
  font-weight: 700;
}
.textValue {
  font-weight: 400;
}
.webContent {
  padding: 0px;
}
.first_part {
  display: flex; 
  margin-top:40px;
}
.mobilePage .first_part {
  display: block; 
}
.m_image {
  margin-right: 30px;
}
.mobilePage .m_image {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
}
</style>