<template>
  <el-dialog
    class="followDialog"
    title=""
    :visible.sync="centerDialogVisible"
    width="331px"
    center
  >
    <span>确定要删除该条评论？</span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="centerDialogVisible = false">取消</el-button>
      <el-button type="primary" @click="deleteComment(commentId)"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      centerDialogVisible: false,
      confirmDelete: false,
    };
  },
  methods: {
    async deleteComment() {
      const deleteRes = await request.get("/dp/comment/delete", {
        params: {
          id: this.commentId,
        },
      });
      if (deleteRes.code == 200) {
        this.confirmDelete = true;
        this.$emit("deleteHandler", this.confirmDelete);
        this.$message({
          message: "删除成功",
          type: "success",
        });
      }
      this.centerDialogVisible = false;
    },
  },
  props: ["commentId"],
};
</script>