<template>
  <div class="home-wrapper" ref="scrollContainer">
    <web-header :disabledScroll="true" />
    <div class="pageDet">
      <div v-if="lotteryInfo.flag == 1">
        <div v-if="!isJoin">
          <div class="lotteryBanner">
            <img class="imgCut" src="@/assets/style/pubgedmpartyBanner.png" alt="" />
          </div>
          <div class="welcomeText">
            PUBG夏日盛典电音大轰趴
            <br />福利门票抽奖活动
          </div>
          <div v-if="!isLogin" class="loginTips">
            <div>当前未登录</div>
            <div class="clickLoginBtn" @click="clickLogin()">
              👉 点击登录DROPIN多频 👈
            </div>
          </div>
          <div v-else>
            <div class="inputTips">请输入昵称 (1-7个字符)，领取专属邀请函</div>
            <input
              class="searchInput"
              v-model="message"
              placeholder="请输入你的昵称"
              maxlength="7"
            />
            <div class="inputTips">请输入邮箱，以便接收到中奖通知</div>
            <input
              class="searchInput"
              v-model="email"
              placeholder="请输入你的邮箱"
            />
            <div class="joinBtn" @click="clickJoin(message, email)">
              {{ joinText }}
            </div>
          </div>
        </div>
        <div v-else>
          <div class="joinedImg" @click="toSignal()">
            <img
              class="imgCut"
              src="@/assets/style/pubgedmpartyBanner.png"
              alt=""
            />
          </div>
          <div>
            抽奖参与成功！
            <br />请领取您的专属邀请函
          </div>
          <div class="smallText">
            开奖时间为7月25日（周一）18:00<br />请通过本页面查看中奖情况和领取奖品
          </div>
          <div class="invitationImg">
            <img class="imgCut" :src="invitationImg" alt="" />
          </div>
        </div>
      </div>
      <div v-if="lotteryInfo.flag == 2">
        <div v-if="!isLogin">
          <div class="lotteryBanner">
            <img class="imgCut" src="@/assets/style/pubgedmpartyBanner.png" alt="" />
          </div>
          <div class="welcomeText">
            PUBG夏日盛典电音大轰趴
            <br />福利门票抽奖活动
          </div>

          <div class="loginTips">
            <div>当前未登录</div>
            <div class="clickLoginBtn" @click="clickLogin()">
              👉 点击登录DROPIN多频 👈
            </div>
          </div>
        </div>
        <div v-else-if="isJoin">
          <div class="joinedImg" @click="toSignal()">
            <img
              class="imgCut"
              src="@/assets/style/pubgedmpartyBanner.png"
              alt=""
            />
          </div>
          <div>
            抽奖参与成功！
            <br />请领取您的专属邀请函
          </div>
          <div class="smallText">
            开奖时间为7月25日（周一）18:00<br />请通过本页面查看中奖情况和领取奖品
          </div>
          <div class="invitationImg">
            <img
              class="imgCut"
              :src="lotteryInfo.lotteryLog.participateImage"
              alt=""
            />
          </div>
        </div>
        <div v-else>
          <div class="joinedImg" @click="toSignal()">
            <img
              class="imgCut"
              src="@/assets/style/pubgedmpartyBanner.png"
              alt=""
            />
          </div>
          <div>很可惜，<br />抽奖活动已截止参与</div>
        </div>
      </div>
      <div v-if="lotteryInfo.flag == 3">
        <div v-if="!isLogin">
          <div class="lotteryBanner">
            <img class="imgCut" src="@/assets/style/pubgedmpartyBanner.png" alt="" />
          </div>
          <div class="welcomeText">
            PUBG夏日盛典电音大轰趴
            <br />福利门票抽奖活动
          </div>
          <div class="loginTips">
            <div>当前未登录</div>
            <div class="clickLoginBtn" @click="clickLogin()">
              👉 点击登录DROPIN多频 👈
            </div>
          </div>
        </div>
        <div v-else-if="isJoin">
          <div v-if="lotteryInfo.lotteryLog.win">
            <div class="joinedImg" @click="toSignal()">
            <img
              class="imgCut"
              src="@/assets/style/pubgedmpartyBanner.png"
              alt=""
            />
          </div>
            <div>恭喜您被空投砸中了！<br />大吉大利！今晚吃鸡！</div>
            <div class="winCode">您的福利兑换码为：</div>
            <div class="winCodeDet">{{ lotteryInfo.lotteryLog.winCode }}</div>
            <div class="lotteryRule codeRule">
              使用请于<a href="https://live.bilibili.com/3645373">直播间</a
              >弹幕输入：门票+六位兑换码。如：门票ABCDEF<br />
              <br />
              使用说明：<br />
              1、
              兑换码可用于在2022年7月28日-31日举行的“PUBG夏日盛典电音大轰趴”活动直播间兑换贵族专属修勾形象等福利。<br />
              2、
              该兑换码一次兑换后可在全部四个活动日中生效使用，但仅支持单次单人兑换，兑后即失效，并不可被其他用户重复兑换，请谨慎保管以防泄露。
            </div>
          </div>
          <div v-else>
            <div class="joinedImg" @click="toSignal()">
            <img
              class="imgCut"
              src="@/assets/style/pubgedmpartyBanner.png"
              alt=""
            />
          </div>
            <div>很可惜，您没有中奖</div>
          </div>
        </div>
        <div v-else>
          <div class="joinedImg" @click="toSignal()">
            <img
              class="imgCut"
              src="@/assets/style/pubgedmpartyBanner.png"
              alt=""
            />
          </div>
          <div>很可惜，<br />抽奖活动已截止参与</div>
        </div>
      </div>

      <!-- <div v-if="lotteryInfo.flag == 3" class="winList">
      中奖名单：
      <div
        v-for="(winItem, idx) in lotteryInfo.winMemberList"
        :key="idx"
        class="winItem"
      >
        {{ winItem.nickname }}
      </div>
    </div> -->
      <div class="lotteryRule">
        活动规则：<br />
        1、本次抽奖活动需通过“DROPIN多频”电子音乐社区移动端APP，或官方网站<a
          href="https://hearinmusic.com"
          >https://www.hearinmusic.com</a
        >（使用PC网页打开）参与，仅限DROPIN多频注册用户参与。
        <br />
        2、抽奖通道开放时间为7月22日（周五）18:00 -
        7月24日（周日）23:59，开放时间过后将无法报名参与抽奖活动。所有成功参加抽奖的用户都将获得专属活动门票邀请函一张。<br />
        3、活动开奖时间为7月25日（周一）18:00。我们将通过邮箱、DROPIN多频APP站内信及本页面通知中奖用户，请留意相关中奖信息，避免耽误兑换。开奖后用户将可以在本页面查看中奖情况和领取奖品。
        <br />
        4、中奖用户将获得于7月28日 -
        31日举行的“PUBG夏日盛典电音大轰趴”活动福利门票，含福利兑换码一条，可用于在活动直播间兑换贵族专属修勾形象等福利。
        <br />
        5、兑换码一次兑换后可在全部四个活动日中生效使用，但仅支持单次单人兑换，兑后即失效，并不可被其他用户重复兑换，请谨慎保管以防泄露。
        <br />
        6、任何疑问反馈，请联系客服邮箱：contact_us@hearinmusic.com。
        <br />
        7、本次抽奖活动由DROPIN多频与修勾夜店联合发起，所有奖品与苹果公司无关。
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import WebHeader from "@/components/WebHeader.vue";
import request from "@/utils/request";
import { locationMobileUrl } from "@/utils/navigate";
export default {
  components: {
    WebHeader,
  },
  computed: {
    ...mapGetters(["isLogin", "token"]),
  },
  data() {
    return {
      lotteryId: 1,
      lotteryInfo: {},
      isJoin: false,
      message: "",
      email: "",
      pcUrl: process.env.VUE_APP_PC_URL,
      invitationImg: "",
      loading: false,
      joinText: "立即参与",
    };
  },
  async created() {
    locationMobileUrl("external", "pubgedmparty");
    this.getLotteryInfo();
  },
  methods: {
    clickLogin() {
      this.$login();
      return;
    },
    async getLotteryInfo() {
      const lotteryInfoRes = await request.get("/dp/lottery/info", {
        params: {
          lotteryId: this.lotteryId,
        },
      });
      if (lotteryInfoRes.code == 200) {
        this.lotteryInfo = lotteryInfoRes.data;
        document.title = lotteryInfoRes.data.name;
        this.message = this.lotteryInfo.memberVo.nickname.slice(0, 7);
        this.isJoin = !!this.lotteryInfo?.lotteryLog;
        this.invitationImg = this.lotteryInfo?.lotteryLog?.participateImage;
      }
    },
    async clickJoin(nickName, email) {
      if (nickName == "" || email == "") {
        this.$message({
          message: "昵称及邮箱不可为空",
        });
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.joinText = "正在生成邀请函，请稍等...";
      const joinLotteryRes = await request.post("/dp/lottery/participate", {
        lotteryId: this.lotteryId,
        nickName: nickName,
        email: email,
      });
      this.loading = false;

      if (joinLotteryRes.code == 200) {
        this.isJoin = true;
        this.invitationImg = joinLotteryRes.data;
        this.$nextTick(() => {
          this.$refs.scrollContainer.scrollTop = 0;
        });
      } else {
        this.$message({
          message: "网络异常，请重试",
        });
        this.joinText = "立即参与";
      }
    },
    toSignal() {
      window.open("https://www.hearinmusic.com/#/signal?id=13570");
    },
  },
};
</script>

<style>
.pageDet {
  font-size: 20px;
  font-weight: 400;
  text-align: CENTER;
  line-height: 30px;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
  width: 1200px;
}
.loginTips {
  font-size: 16px;
  font-weight: 400;
  margin: 30px auto 20px auto;
}
.clickLoginBtn {
  cursor: pointer;
  color: #9263ef;
}
.joinBtn {
  width: 300px;
  height: 44px;
  background: #9c5cf7;
  border-radius: 25px;
  text-align: center;
  font-size: 16px;
  line-height: 44px;
  margin: 40px auto 0 auto;
  cursor: pointer;
}
.inputTips {
  text-align: left;
  font-size: 15px;
  font-weight: 300;
  width: 90%;
  margin: 40px auto 0 auto;
}
</style>

<style scoped>


/* .lotteryPage {
  
} */
.home-wrapper input {
  margin: 12px auto 0 auto;
  padding: 6px;
  width: 334px;
  background: #1f1f1f;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
}
.lotteryBanner {
  width: 375px;
  height: 667px;
  background: none;
}
.welcomeText {
  margin: 30px auto 16px auto;
}

.joinedImg {
  width: 330px;
  height: auto;
  margin: 37px auto 14px auto;
  cursor: pointer;
}
.invitationImg {
  width: 330px;
  height: 154px;
  background: none;
  border-radius: 15px;
  margin-top: 50px;
  overflow: hidden;
}
.winCode {
  font-size: 15px;
  margin: 48px 0 17px 0;
  font-weight: 300;
}
.winCodeDet {
  width: 330px;
  height: 48px;
  background: #1f1f1f;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  line-height: 48px;
}
.winList {
  margin-top: 30px;
}
.lotteryRule {
  font-size: 13px;
  font-weight: 300;
  text-align: left;
  width: 330px;
  margin: 50px 0 20px 0;
  line-height: 20px;
  color: #d9d9d9;
}

.lotteryRule.codeRule {
  margin: 20px 0 10px 0;
}
.smallText {
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  margin-top: 10px;
  color: #d9d9d9;
}
</style>