<template>
  <div
    :class="{ mobilePage: isMobile, appPage: isApp }"
    class="home-wrapper scrollContainer"
    ref="scrollContainer"
  >
    <web-header v-if="!isApp" :disabledScroll="true" />
    <div :class="{ pcPage: !isMobile }">
      <div class="webPage pageDet">
        <div class="webPageBanner musicBanner">
          <img class="imgCut" :src="webInfo.image" alt="" />
        </div>
        <div class="dateEditor">
          <el-date-picker
            v-model="dateValue"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            :editable="false"
            :clearable="false"
            @change="getMusicList(dateValue)"
          >
          </el-date-picker>
        </div>
        <div class="musicList" v-if="musicList.length">
          <MusicBox v-for="(item, idx) in musicList" :item="item" :key="idx" />
        </div>
        <div class="quizEmpty" v-else-if="!loading">
          <img v-if="themeColor=='light'"
               class="quizEmptyImg"
               src="@/assets/style/quizEmpty@2x.png"
               alt=""
          />
          <img v-else
            class="quizEmptyImg"
            src="@/assets/style/list_empty.png"
            alt=""
          />
          <div class="quizEmptyText">
            暂时没有找到这一天发行的国电哦<br />快去给你喜欢的国电<a
              style="color: #dec7ff; text-decoration: underline"
              href="https://hearinmusic.com/#/music/nominate?newWebPage=true"
              >提名</a
            >吧
          </div>
        </div>
        <div class="voteRule">
          <div>收录规则：</div>
          <div v-html="webInfo.rule"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebHeader from "@/components/WebHeader.vue";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import request from "@/utils/request";
import MusicBox from "@/components/MusicBox.vue";
import { isAppFun } from "@/utils/judgeClient";
export default {
  components: {
    WebHeader,
    MusicBox,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        firstDayOfWeek: 1,
      },
      dateValue: dayjs().format("YYYY-MM-DD"),
      musicList: [],
      isApp: false,
      loading: false,
      webInfo: {},
      themeColor: 'dark',
    };
  },
  computed: {
    ...mapGetters(["isLogin"]),
  },
  watch: {
    '$root.themeColor': {
      immediate: true,
      handler(newVal) {
        this.themeColor = newVal;
      },
    },
  },
  mounted() {
    this.themeColor = localStorage.getItem('theme');
    this.$root.themeColor = this.themeColor;
  },
  async created() {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      // 当前设备是移动设备
      this.isApp = isAppFun();
    }
    this.getWebInfo();
    if (this.$route.query.date) {
      this.dateValue = dayjs(this.$route.query.date).format("YYYY-MM-DD");
    }
    this.getMusicList(this.dateValue);
  },
  methods: {
    // 获取活动相关信息
    async getWebInfo() {
      const webInfoRes = await request.get("/dp/home/getActivityWeb", {
        params: {
          awId: 6,
        },
      });
      if (webInfoRes.code == 200) {
        document.title = webInfoRes.data.name;
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", webInfoRes.data.description);
        this.webInfo = webInfoRes.data;
      }
    },
    async getMusicList(date) {
      this.loading = true;
      this.musicList = [];
      const musicListRes = await request.post("/dp/vote/musicList", {
        currentDate: date,
        voteId: 8,
      });
      if (musicListRes.code == 200) {
        this.loading = false;
        this.musicList = musicListRes.data;
      }
    },
  },
};
</script>

<style scoped>
.dateEditor {
  width: 100%;
  margin-top: 16px;
  position: sticky;
  top: 61px;
  z-index: 99;
  background: var(--background-color);
  color: var(--font-color)
}
.appPage.mobilePage .dateEditor {
  top: 0;
}
.mobilePage .dateEditor {
  top: 50px;
}

.musicList {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 16px;
  width: 100%;
}
.quizEmptyImg {
  margin-top: 100px;
  width: 110px;
}
.quizEmptyText {
  line-height: 22px;
  margin-bottom: 30px;
}
</style>

<style>
.dateEditor .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  display: block;
}
.dateEditor .el-input--suffix .el-input__inner {
  background: transparent;
  font-size: 16px;
  color: var(--font-color);
  cursor: pointer;
}
/* 日期选择器样式 */
.el-picker-panel {
  background: #292929;
  border: none;
  color: #fff;
}
.el-picker-panel__icon-btn {
  color: #fff;
}
.el-date-picker__header-label {
  color: #fff;
}
.el-date-picker__header-label:hover {
  color: #9c5cf7;
}
.el-date-table th {
  color: #fff;
  border-bottom: solid 1px #666666;
}
.el-date-table td.next-month,
.el-date-table td.prev-month {
  color: #666;
}
.el-date-table td.today span {
  color: #9c5cf7;
}
.el-date-table td.current:not(.disabled) span {
  background-color: #9c5cf7;
}
.el-date-table td:hover {
  color: #9c5cf7 !important;
}
.el-date-picker__header--bordered {
  border-bottom: solid 1px #666;
}
.el-year-table td.today .cell {
  color: #9c5cf7;
}
.el-picker-panel__icon-btn:hover {
  color: #9c5cf7;
}
.el-month-table td .cell:hover {
  color: #9c5cf7;
}
.el-year-table td .cell:hover,
.el-year-table td.current:not(.disabled) .cell {
  color: #9c5cf7;
}
.el-month-table td.today .cell {
  color: #9c5cf7;
}
.el-month-table td.current:not(.disabled) .cell {
  color: #9c5cf7;
}
.el-date-table td.disabled div {
  background-color: #414141;
}
.musicDetDialog .el-dialog {
  width: 567px;
  height: 550px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
.musicDetDialog .el-table {
  height: 232px;
  overflow: auto;
  border-radius: 6px;
}
.musicDetDialog .devider {
  margin: 34px 0;
  position: relative;
}
.mobilePage .musicDetDialog .devider {
  margin: 28px 0;
}
.musicDetDialog .musicCover {
  width: 150px;
  height: 150px;
}
.musicInfo {
  text-align: left;
}
.musicDetDialog .musicInfo {
  margin-left: 27px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 24px;
}
.musicDetDialog .musicName {
  color: #fff;
  font-size: 15px;
}
.musicDetDialog .musicLinkList {
  margin-top: 9px;
}
.musicDetDialog .greyShade {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  display: block;
  padding: 46px 36px;
  background-size: cover;
}
.musicDetDialog .el-table__body {
  color: #fff;
  font-size: 13px;
  font-weight: 300;
}
.musicDetDialog .el-table tr {
  background: none;
}
.musicDetDialog .el-table th.el-table__cell {
  background: none;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.musicDetDialog
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background: none;
}
.el-table,
.el-table__expanded-cell {
  background: rgba(255, 255, 255, 0.05);
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background: none;
}
.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.musicDetDialog th {
  font-weight: 400;
}
.el-table .cell {
  padding-left: 20px;
  padding-right: 6px;
}
.el-table th.el-table__cell > .cell {
  padding-left: 20px;
  padding-right: 6px;
}
.el-dialog {
  border-radius: 6px;
}
.el-dialog__headerbtn {
  z-index: 1000;
  top: 12px;
  right: 12px;
}
.el-dialog__headerbtn:focus .el-dialog__close,
.el-dialog__headerbtn:hover .el-dialog__close {
  color: #7123e5;
}
.pcPage .musicBox:nth-child(odd) {
  margin-right: 12px;
}
/* 移动端适配 */
.mobilePage .musicBox {
  margin: 0 auto;
}
.mobilePage .musicDetDialog .el-dialog {
  width: 90%;
  margin-top: 12vh !important;
}
.mobilePage .musicDetDialog .greyShade {
  padding: 36px 16px;
}
.el-dialog__wrapper {
  overflow: unset;
}
.mobilePage .musicDetDialog .musicCover {
  width: 120px;
  height: 120px;
}
.mobilePage .musicDetDialog .musicInfo {
  margin-left: 18px;
}
</style>
