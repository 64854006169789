<template>
  <!-- 以下是内容板块 -->
  <div
    class="home-wrapper scroll-container"
    :class="{ mobilePage: isMobile }"
    ref="scrollContainer"
  >
    <web-header :switchHeight="isMobile ? 220 : 60">
      <!-- 里面放滚动切换的内容 -->
      <div class="headSticky scrollSticky" key="scroll">
        <div class="head">
          <div class="headLeft">
            <div class="articleTab">
              <div
                class="tab"
                :class="{
                  selected: tab === 'radar',
                  unselect: tab !== 'radar',
                }"
                @click="switchTab('radar')"
              >
                <div>雷达</div>
                <div v-if="tab === 'radar'" class="selectedIcon"></div>
              </div>
              <div
                class="tab"
                :class="{
                  selected: tab === 'latest',
                  unselect: tab !== 'latest',
                }"
                @click="switchTab('latest')"
              >
                <div>最新</div>
                <div v-if="tab === 'latest'" class="selectedIcon"></div>
              </div>
              <div
                class="tab"
                :class="{
                  selected: tab === 'comment',
                  unselect: tab !== 'comment',
                }"
                @click="switchTab('comment')"
              >
                <div>近期热议</div>
                <div v-if="tab === 'comment'" class="selectedIcon"></div>
              </div>
            </div>
          </div>
          <div v-show="!isMobile" class="headRight">
            <header-search />
          </div>
        </div>
      </div>
    </web-header>

    <div class="content">
      <!-- web-header 的占位元素，变得不可见的时候触发visibilityChanged，修改showScrollHeader的值 -->
      <div
        class="webHeaderPlaceholder"
        v-observe-visibility="visibilityChanged"
      ></div>
      <div class="left">
        <!-- 以下是文章板块 -->
        <van-swipe v-if="isMobile" class="my-swipe" :autoplay="3000" indicator-color="white" height="150">
        <van-swipe-item
          v-for="item in bannerList"
          :key="item.picture"
        >
          <img
            :src="item.picture"
            alt=""
            class="imgCut"
            @click="toBanner(item)"
          />
        </van-swipe-item>
      </van-swipe>
        <div class="tabSticky">
          <div class="articleTab">
            <div
              class="tab"
              :class="{
                selected: tab === 'radar',
                unselect: tab !== 'radar',
              }"
              @click="switchTab('radar')"
            >
              <div>雷达</div>
              <div v-if="tab === 'radar'" class="selectedIcon"></div>
            </div>
            <div
              class="tab"
              :class="{
                selected: tab === 'latest',
                unselect: tab !== 'latest',
              }"
              @click="switchTab('latest')"
            >
              <div>最新</div>
              <div v-if="tab === 'latest'" class="selectedIcon"></div>
            </div>
            <div
              class="tab"
              :class="{
                selected: tab === 'comment',
                unselect: tab !== 'comment',
              }"
              @click="switchTab('comment')"
            >
              <div>近期热议</div>
              <div v-if="tab === 'comment'" class="selectedIcon"></div>
            </div>
          </div>
        </div>
        <div class="articleList" v-infinite-scroll="loadmore">
          <div class="loading" v-if="loading">
            <img class="loadingImg" src="@/assets/style/loading.gif" alt="" />
          </div>
          <!-- 以下是一篇文章 -->
          <template v-else-if="articles.length">
            <DropinArticle
              v-for="(item, idx) in articles"
              from="home"
              :item="item"
              :key="idx"
            />
            <div
              v-if="loadingMore"
              class="loadmore loading"
              :style="{ visibility: loadingMore ? 'visible' : 'hidden' }"
            >
              <img class="loadingImg" src="@/assets/style/loading.gif" alt="" />
            </div>
          </template>
          <div v-else-if="loaded && !loading && finish">
            <div class="searchEmpty">
              <img v-if="themeColor=='light'" class="searchEmptyImg" src="@/assets/style/find empty.png" alt="" />
              <img v-else class="searchEmptyImg" src="@/assets/style/empty.gif" alt="" />
              <div class="searchEmptyText">很可惜，没有找到相关信息</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isMobile" class="right">
        <!-- banner板块 -->
        <div class="block">
          <el-carousel>
            <el-carousel-item v-for="item in bannerList" :key="item.picture">
              <img
                :src="item.picture"
                alt=""
                class="imgCut"
                @click="toBanner(item)"
              />
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- 创作后台 -->
        <div class="rightField">
          <div class="rightTab halfBottom">
            <div class="rightTabText">创作后台</div>
          </div>
          <div class="creatorFlex">
            <div>
              <div
                class="creatorBtn"
                style="background: rgba(255, 53, 141, 0.2)"
                @click="toCreatorAddDialog('signal')"
              >
                <img src="@/assets/style/icon_signal@2x.png" alt="" />
              </div>
              发信号
            </div>
            <div>
              <div
                class="creatorBtn"
                style="background: rgba(77, 145, 174, 0.2)"
                @click="toCreatorAddDialog('ency')"
              >
                <img src="@/assets/style/icon_creator_pedia@2x.png" alt="" />
              </div>
              编辑百科
            </div>
            <div>
              <div
                class="creatorBtn"
                style="background: rgba(156, 92, 247, 0.2)"
                @click="toCreatorAddDialog('topic')"
              >
                <img src="@/assets/style/icon_creator_topic@2x.png" alt="" />
              </div>
              新建频段
            </div>

            <div>
              <div
                class="creatorBtn"
                style="background: rgba(255, 181, 8, 0.2)"
                @click="toCreatorAddDialog('quiz')"
              >
                <img src="@/assets/style/icon_creator_qa@2x.png" alt="" />
              </div>
              新建频一频
            </div>
          </div>
          <div class="toCreatorBtn pointer" @click="toCreatorAddDialog()">
            进入创作后台 >
          </div>
        </div>
        <!-- 热门活动 -->
        <div class="rightField" v-if="hotEventList.length">
          <div class="rightTab halfBottom">
            <div class="rightTabText">热门活动</div>
          </div>
          <div class="halfBottom">
            <div
              class="hotEvent"
              :per-page="3"
              :autoplay="true"
              :mouse-drag="false"
            >
              <div
                class="hotEventItem"
                @click="clickEvent(item)"
                v-for="(item, idx) in hotEventList"
                :key="idx"
              >
                <div class="hotEventPoster">
                  <img class="imgCut" :src="item.pic" alt="" />
                </div>
                <div class="hotEventCity">
                  <img src="@/assets/style/Frame 249@2x.png" alt="" />
                  <span>{{ item.city }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="checkMoreInfo" @click="toEventWall">
            <div class="checkMoreInfoText">查看更多活动 ></div>
          </div>
        </div>
        <!-- 以下是资讯板块 -->
        <div class="rightField" v-if="dailyNews.length">
          <div class="rightTab halfBottom">
            <div class="rightTabText">每日资讯</div>
          </div>
          <div class="rightList">
            <DailyNews
              v-for="(item, idx) in dailyNews"
              :dailyNewsItem="item"
              :key="idx"
            />
          </div>
          <div class="checkMoreInfo" @click="toNews">
            <div class="checkMoreInfoText">查看更多资讯 ></div>
          </div>
        </div>
        <!-- 以下是频段板块 -->
        <div class="rightField" v-if="hotTopics.length">
          <div class="rightTab halfBottom">
            <div class="rightTabText">热门频段</div>
          </div>
          <div class="rightList">
            <HotTopic
              v-for="(item, idx) in hotTopics"
              :hotTopicItem="item"
              :key="idx"
            />
          </div>
          <div class="checkMoreInfo" @click="toSquare">
            <div class="checkMoreInfoText">查看更多频段 ></div>
          </div>
        </div>
        <!-- 以下是百科板块 -->

        <div class="rightField" v-if="hotEncys.length">
          <div class="rightTab halfBottom">
            <div class="rightTabText">热门百科</div>
          </div>
          <div class="rightList">
            <HotEncy
              v-for="(item, idx) in hotEncys"
              :hotEncyItem="item"
              :key="idx"
            />
          </div>
          <div class="checkMoreInfo" @click="toAllEncy">
            <div class="checkMoreInfoText">查看更多百科 ></div>
          </div>
        </div>
        <!-- 以下是网站信息 -->
        <div class="sticky">
          <FirstFooter />
        </div>
      </div>
    </div>
    <template>
      <el-backtop target=".home-wrapper" :bottom="100" :visibility-height="100">
        <div class="backTop">UP</div>
      </el-backtop>
    </template>
  </div>
</template>

<script>
import dayjs from "dayjs";
import WebHeader from "@/components/WebHeader.vue";
import HeaderSearch from "@/components/HeaderSearch.vue";
import DropinArticle from "@/components/DropinArticle.vue";
import FirstFooter from "@/components/FirstFooter.vue";
import HotTopic from "@/components/HotTopic.vue";
import HotEncy from "@/components/HotEncy.vue";
import DailyNews from "@/components/DailyNews.vue";
import request from "@/utils/request";
import { openSignal, jumpToCreatorAndAdd, openTopic } from "@/utils/navigate";
// import LoginPopup from "@/components/LoginPopup.vue" ;
const tns = require("tiny-slider").tns;
console.log("tns", tns);

export default {
  components: {
    WebHeader,
    HeaderSearch,
    DropinArticle,
    FirstFooter,
    HotTopic,
    HotEncy,
    DailyNews,
    // LoginPopup,
  },
  data() {
    return {
      loading: false,
      loaded: false, //是否完成初次加载
      tab: "radar", // 雷达: radar, 最新: latest
      articles: [],
      fakeArticles: [],
      hotTopics: [],
      hotEncys: [],
      dailyNews: [],
      pageNum: Math.ceil(Math.random() * 20 + 1),
      loadingMore: false,
      articlePageNum: 1,
      totalPages: 1,
      showLoadmoreTimer: null,
      showScrollHeader: false,
      bannerList: [],
      searchTab: "signal",
      removeArticleIds: [],
      endTime: "",
      finish: false,
      total: 1,
      hotEventList: [],
      pcUrl: process.env.VUE_APP_PC_URL,
      themeColor: 'dark',
    };
  },
  watch: {
    '$root.themeColor': {
      immediate: true,
      handler(newVal) {
        this.themeColor = newVal;
      },
    },
  },
  mounted() {
    this.themeColor = localStorage.getItem('theme');
    this.$root.themeColor = this.themeColor;
  },
  // 生命周期
  async created() {
    document.title = "DROPIN多频 - 为了电音";
    // 超过500ms才出loading
    const timer = setTimeout(() => {
      this.loading = true;
    }, 100);
    // banner数据
    const bannerRes = await request.get("/dp/home/banner", {
      params: {
        position: 1,
      },
    });
    this.bannerList = bannerRes.data;
    // 信号列表数据
    const res = await request.post("/dp/article/list", {
      orderType: "HOME",
      pageNum: this.articlePageNum,
      pageSize: 10,
    });
    clearTimeout(timer);
    this.loaded = true;
    this.loading = false;
    this.articles = res.data.records;
    this.totalPages = res.data.pages;
    console.log("🌟 res", res);

    // 热门活动数据
    this.getHotEventList();
    // 每日资讯数据
    const dailyNewsRes = await request.get("/dp/article/getNewsForDay", {
      params: {
        orderType: "NEW_CREATE",
        defaultNum: 0,
      },
    });
    this.dailyNews = dailyNewsRes.data.newsList;

    // 热门频段数据
    const topicListRes = await request.get("/dp/channel/list", {
      params: {
        pageNum: this.pageNum,
        pageSize: 5,
      },
    });
    this.hotTopics = topicListRes.data.records;

    // 热门百科数据
    const hotEncyRes = await request.get("/dp/ency/list", {
      params: {
        pageNum: this.pageNum,
        pageSize: 5,
      },
    });
    this.hotEncys = hotEncyRes.data.records;
  },
  beforeDestroy() {},
  destroyed() {},
  methods: {
    // 打开活动外链页面
    handleOpenUrl(urlStr) {
      let url = urlStr;
      if (
        urlStr.toLowerCase().indexOf("https://") !== 0 &&
        urlStr.toLowerCase().indexOf("http://") !== 0 &&
        urlStr.toLowerCase().indexOf("ftp://") !== 0 &&
        urlStr.toLowerCase().indexOf("file://") !== 0
      ) {
        url = "http://" + url;
      }
      console.log("url", url);
      window.open(url);
    },
    clickCount(id) {
      request.get("/dp/activity/clickActivity", {
        params: {
          id: id,
        },
      });
    },
    // 点击某个活动海报
    clickEvent(item) {
      if(item.isUserUpload) {
        this.$router.push({
          name: "activity",
          query: {
            id: item.id,
          },
        });
      } else {
        console.log(item.url, item.id);
        this.clickCount(item.id);
        this.handleOpenUrl(item.url);
      }
    },
    toEventWall() {
      this.$router.push({
        name: "eventWall",
      });
    },
    async getHotEventList() {
      const hotEventListRes = await request.get("/dp/activity/carousel", {});
      this.hotEventList = hotEventListRes.data;
      this.$nextTick(() => {
        // 轮播图设置
        tns({
          container: ".hotEvent",
          items: 3,
          autoplayTimeout: 2600, // 时间间隔
          autoplay: true,
          controls: false,
          nav: false,
          navContainer: false,
          autoplayButton: false,
          autoplayText: false,
          autoplayButtonOutput: false,
          autoplayResetOnVisibility: false,
        });
      });
    },
    toCreatorAddDialog(type) {
      jumpToCreatorAndAdd(type);
    },
    toBanner(target) {
      if (target.jumpInfo.mold == 1) {
        if (target.jumpInfo.typeId == 1) {
          openSignal(target.jumpInfo.dataId);
        }
        if (target.jumpInfo.typeId == 4) {
          window.open(`/#/specSubDet?id=${target.jumpInfo.dataId}`);
        }
        if (target.jumpInfo.typeId == 3) {
          openTopic(target.jumpInfo.dataId);
        }
      }
      if (target.jumpInfo.mold == 2) {
        this.handleOpenUrl(target.jumpInfo.url);
      }
      // if (target.linkArticleId) {
      //   openSignal(target.linkArticleId);
      // } else if (target.link) {
      //   window.open(target.link);
      // }
    },
    toCreator() {
      window.open("https://creator.hearinmusic.com");
    },
    toSquare() {
      this.$router.push({
        name: "topicSquare",
      });
    },
    toAllEncy() {
      this.$router.push({
        name: "allEncy",
      });
    },
    toNews() {
      this.$router.push({
        name: "news",
      });
    },
    // formatArticles() {

    // },
    async switchTab(tab) {
      // 超过500ms才出loading
      this.loading = true;
      this.tab = tab;
      this.finish = false;
      this.articles = [];
      if (this.tab === "radar") {
        this.articlePageNum = 1;
        const res = await request.post("/dp/article/list", {
          orderType: "HOME",
          pageNum: this.articlePageNum,
          pageSize: 10,
        });
        // this.articles = [];
        this.articles = res.data.records;
        this.totalPages = res.data.pages;
      } else if (this.tab === "latest") {
        const res = await request.post("/dp/article/list", {
          orderType: "NEW_CREATE",
          pageSize: 10,
        });
        // this.articles = [];
        this.removeArticleIds = [];
        this.articles = res.data.records;
        this.total = res.data.total;
        this.endTime =
          this.articles[this.articles.length - 1].esArticle.createTime;
        this.removeArticleIds.push(
          this.articles[this.articles.length - 1].esArticle.id
        );
      } else if (this.tab === "comment") {
        this.endTime = this.getCurrentTime();
        const res = await request.post("/dp/article/list", {
          orderType: "NEW_COMMENT",
          pageSize: 10,
          endTime: this.endTime,
        });
        // this.articles = [];
        this.removeArticleIds = [];
        this.articles = res.data.records;
        this.total = res.data.total;
        this.endTime =
          this.articles[this.articles.length - 1].esArticle.newCommentTime;
        this.removeArticleIds.push(
          this.articles[this.articles.length - 1].esArticle.id
        );
      }
      this.loaded = true;
      this.loading = false;

      // 切换tab后滚动到顶部
      this.$nextTick(() => {
        const elem = this.$refs.scrollContainer;
        elem.scrollTop = 0;
      });
    },
    async loadmore() {
      if (this.loadingMore || this.loading || !this.loaded || this.finish) {
        return;
      }
      this.articlePageNum++;
      console.log("🌟 this.articlePageNum", this.articlePageNum);
      if (this.articlePageNum > this.totalPages) {
        return;
      }
      this.loadingMore = true;
      if (this.tab === "radar") {
        const res = await request.post("/dp/article/list", {
          orderType: "HOME",
          pageNum: this.articlePageNum,
          pageSize: 10,
        });
        this.loadingMore = false;
        this.articles = this.articles.concat(res.data.records);
      } else if (this.tab === "latest") {
        const res = await request.post("/dp/article/list", {
          orderType: "NEW_CREATE",
          endTime: this.endTime,
          removeArticleIds: this.removeArticleIds,
          pageSize: 10,
        });
        this.loadingMore = false;
        this.articles = this.articles.concat(res.data.records);
        this.removeArticleIds = [];
        this.endTime =
          res.data.records[res.data.records.length - 1].esArticle.createTime;
        this.removeArticleIds.push(
          res.data.records[res.data.records.length - 1].esArticle.id
        );
      } else if (this.tab === "comment") {
        const res = await request.post("/dp/article/list", {
          orderType: "NEW_COMMENT",
          endTime: this.endTime,
          removeArticleIds: this.removeArticleIds,
          pageSize: 10,
        });
        this.loadingMore = false;
        if (!res.data.records.length) {
          this.finish = true;
        } else {
          this.articles = this.articles.concat(res.data.records);
          if (this.articles.length >= this.total) {
            this.finish = true;
          } else {
            this.removeArticleIds = [];
            this.endTime =
              res.data.records[
                res.data.records.length - 1
              ].esArticle.newCommentTime;
            this.removeArticleIds.push(
              res.data.records[res.data.records.length - 1].esArticle.id
            );
          }
        }
      }
    },
    visibilityChanged(isVisible) {
      this.showScrollHeader = !isVisible;
    },
    getCurrentTime() {
      // 格式 YYYY-MM-DD HH:mm:ss
      return dayjs().format("YYYY-MM-DD HH:mm:ss");
    },
  },
};
</script>
<style>
.home-wrapper {
  height: 100%;
  overflow: auto;
}

.webHeaderPlaceholder {
  position: absolute;
  /* height保持跟web-header相同 */
  height: 61px;
  width: 100%;
}

.rightItem.halfBottom.news:first-child .newsPhoto {
  display: block;
}

.rightItem.halfBottom.news:first-child .infoItemText.textOverflow {
  -webkit-line-clamp: 3;
  color: #bc96f2;
}
.rightList:hover .rightItem.halfBottom.news:first-child .newsPhoto {
  display: none;
}
.rightList:hover .rightItem.halfBottom.news:first-child .infoItemText.textOverflow {
  -webkit-line-clamp: 2;
  color: var(--font-color);
}

.rightItem.halfBottom.news:hover .newsPhoto {
  display: block !important;
}
.rightItem.halfBottom.news:hover .infoItemText.textOverflow {
  -webkit-line-clamp: 3 !important;
  color: #bc96f2 !important;
}
.el-carousel__item:nth-child(2n) {
  background-color: rgba(156, 92, 247, 0.5);
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: rgba(156, 92, 247, 0.2);
}

.block {
  margin-top: 12px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
}
.el-carousel__button {
  width: 12px;
}
.encyName.textOverflow {
  word-break: break-all;
  max-width: 220px;
}
.itemBrief {
  word-break: break-all;
  max-width: 256px;
}
.el-carousel__mask {
  background-color: #000000;
  opacity: 0.4;
}
.creatorBtn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 10px 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: var(--background-color-item-hover);
}
.creatorBtn:hover {
  opacity: 0.5;
}
.creatorFlex {
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  padding: 10px 0;
}
.creatorBtn img {
  width: 28px;
}
.toCreatorBtn {
  width: 315px;
  height: 38px;
  background: var(--background-color-item-hover);
  border-radius: 40px;
  margin: 20px auto;
  text-align: center;
  line-height: 38px;
  font-weight: 500;
  color: var(--color-80);
}
.pointer {
  cursor: pointer;
}
.hotEvent {
  padding: 23px 0 18px 0;
  margin: 0 9px;
  display: flex;
  overflow: hidden;
}
.hotEventPoster {
  width: 94px;
  height: 141px;
  overflow: hidden;
  border-radius: 4px;
}
.hotEventItem {
  margin: 0 6px;
  width: 94px;
  cursor: pointer;
}
.hotEventCity img {
  width: 12px;
  margin-right: 4px;
}
.hotEventCity {
  margin-top: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hotEventItem:hover {
  opacity: 0.5;
}
.tns-liveregion {
  display: none;
}
.activityBanner {
  background: #777777;
  border-radius: 4px;
  width: 354px;
  height: 146px;
  overflow: hidden;
  cursor: pointer;
  margin-top: 12px;
}
.right .el-carousel__container {
  height: 146px !important;
}
/* 移动端适配样式 */
.mobilePage .content {
  max-width: none;
  min-width: 280px;
}
.mobilePage .left {
  width: 100%;
}
.my-swipe.van-swipe {
    width: 350px;
    margin: 10px auto 0 auto;
    border-radius: 4px;
}
.van-swipe-item {
  width: 350px !important;
}
.mobilePage .van-swipe__track {
  height: 157px !important;
}
</style>