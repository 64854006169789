<template>
  <div class="home-wrapper" ref="scrollContainer">
    <web-header v-if="!isApp" :disabledScroll="true" />
    <div class="pageDet" :class="{ mobilePage: isMobile, appPage: isApp }">
      <div :class="{ pcPage: !isMobile, mobilePage: isMobile }">
        <div class="voteBanner">
          <img class="imgCut" :src="voteInfo.image" alt="" />
        </div>
        <!-- 未登录样式 -->
        <div v-if="!isLogin && flag !== 4">
          <div v-if="!isLogin" class="loginTips">
            <div class="processTips">{{ processTips[flag] }}</div>
            <div>当前未登录</div>
            <div v-if="!isApp" class="clickLoginBtn" @click="clickLogin()">
              👉 点击登录DROPIN多频 👈
            </div>
            <div v-else>请登录App后<br />再进入该页面参与活动</div>
          </div>
        </div>
        <!-- 提名阶段 -->
        <div v-else-if="flag == 1" class="greyBlock">
          <el-form
            :rules="formRules"
            ref="nominateForm"
            :model="nominateForm"
            hide-required-asterisk
            :show-message="false"
          >
            <el-form-item label="请选择你想提名的奖项" prop="awardType">
              <el-select
                v-model="nominateForm.awardType"
                placeholder=""
                value-key="id"
              >
                <div>
                  <el-option
                    :label="awardItem.name"
                    :value="awardItem"
                    v-for="awardItem in voteInfo.voteList"
                    :key="awardItem.id"
                  ></el-option>
                </div>
              </el-select>
            </el-form-item>
            <template v-if="nominateForm.awardType.type == '2'">
              <el-form-item
                :label="`输入你所提名的单曲或专辑的链接进行解析，支持解析网易云音乐和QQ音乐的单曲专辑链接。(必填)`"
              >
                <el-input
                  v-model="nominateForm.musicUrl"
                  class="nominateMusicUrl"
                  type="textarea"
                  autosize
                ></el-input>
                <el-button
                  class="music-video-btn"
                  round
                  type="primary"
                  @click="filterLink(nominateForm.musicUrl)"
                  >解析</el-button
                >
              </el-form-item>
              <div class="commentMusic" v-if="hasMusic">
                <div class="modulePic">
                  <img class="imgCut" :src="music.pic" alt="" />
                </div>
                <div class="musicInfo">
                  <div v-html="music.title" class="musicName">
                    <!-- {{ music.title }} -->
                  </div>
                  <div v-html="music.artist" class="musicArtist">
                    <!-- {{ music.artist }} -->
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <el-form-item
                :label="`请输入你所提名的艺人或厂牌名称（必填）`"
                prop="name"
              >
                <el-input v-model="nominateForm.name"></el-input>
              </el-form-item>
              <el-form-item
                :label="`请输入与该提名相关的链接，即艺人或厂牌在各大平台的介绍链接等（必填）`"
                prop="referenceUrl"
              >
                <el-input v-model="nominateForm.referenceUrl"></el-input>
              </el-form-item>
            </template>

            <el-form-item
              :label="`请简单描述你的提名理由（选填）`"
              prop="description"
            >
              <el-input
                class="nominateDesc"
                type="textarea"
                v-model="nominateForm.description"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="submitBtn" @click="clickSubmitBtn(nominateForm)">
            提 交
          </div>
        </div>
        <!-- 提名清算 -->
        <div v-else-if="flag == 2">
          <div class="processTips loginTips">{{ processTips[flag] }}</div>
        </div>
        <!-- 投票中 -->
        <div v-else-if="flag == 3 || flag == 4">
          <div class="greyBlock voteBlock">
            <div class="awardList halfBottom">
              <div
                v-for="(awardItem, idx) in voteInfo.voteList"
                :key="idx"
                class="tab"
                :class="{
                  selected: voteAwardType.name === awardItem.name,
                  unselect: voteAwardType.name !== awardItem.name,
                }"
                @click="switchTab(awardItem)"
              >
                {{ awardItem.name }}
              </div>
            </div>
            <div class="toRules halfBottom">
              <img
                style="width: 23px; margin-right: 4px"
                src="@/assets/style/icon_info@2x.png"
                alt=""
              />
              <span>每个奖项最多可投5票</span>
              <span @click="ruleShow = true" class="enterRules"
                >活动规则 ></span
              >
            </div>
            <div class="sortTypeBlock">
              <div class="sortType">
                {{ sortType.name }}<img src="@/assets/style/down.png" alt="" />
                <div class="sortTypeHover">
                  <div
                    class="sortTypeItem"
                    @click="switchSortType({ name: '得票数', id: 0 })"
                  >
                    得票数
                  </div>
                  <div
                    class="sortTypeItem"
                    @click="switchSortType({ name: '首字母', id: 1 })"
                  >
                    首字母
                  </div>
                </div>
              </div>
            </div>
            <div class="rankList">
              <div
                v-for="(waitingItem, idx) in waitingList"
                :key="idx"
                class="voteItem halfBottom"
                :class="{
                  firstPrize:
                    waitingItem.rank == 1 &&
                    flag == 4 &&
                    sortType.name == '得票数',
                }"
              >
                <div
                  v-if="
                    waitingItem.rank == 1 &&
                    flag == 4 &&
                    sortType.name == '得票数'
                  "
                >
                  <img
                    class="winnerImg"
                    src="@/assets/style/icon_winner@2x.png"
                    alt=""
                  />
                </div>

                <div
                  :class="{
                    musicVoteName: voteAwardType.type == '2',
                    voteName: waitingItem.ency,
                  }"
                  @click="clickVoteName(voteAwardType, waitingItem)"
                >
                  <template
                    v-if="
                      (sortType.name == '得票数' && flag != 4) ||
                      waitingItem.rank !== 1
                    "
                  >
                    {{ waitingItem.rank }}&nbsp;&nbsp;
                  </template>
                  <span>{{ waitingItem.name }}</span>

                  <img
                    v-if="voteAwardType.type == '2'"
                    class="playBtn"
                    src="@/assets/style/icon_play@2x.png"
                    alt=""
                  />
                </div>
                <div
                  v-if="voteAwardType.type !== '2' && waitingItem.ency"
                  class="encyItem"
                  @click="clickVoteName(voteAwardType, waitingItem)"
                >
                  <img
                    class="encyIcon"
                    src="@/assets/style/icon_pedia@2x.png"
                    alt=""
                  />
                  <!-- <div class="encyItemText">百科名</div> -->
                </div>
                <div class="leftAuto">
                  <div class="voteNum">{{ waitingItem.voteCount }} 票</div>
                  <div
                    v-if="flag == 3"
                    class="voteBtn"
                    @click="clickVote(voteAwardType.id, waitingItem)"
                  >
                    投票
                  </div>
                </div>
              </div>
            </div>
          </div>
          <el-dialog
            class="ruleDialog"
            title="活动规则"
            :visible.sync="ruleShow"
            width="575px"
            center
          >
            <span v-html="voteInfo.rule"></span>
            <span slot="footer" class="dialog-footer"> </span>
          </el-dialog>
        </div>
        <!-- 活动规则 -->
        <div class="voteRule" v-if="flag == 0 || flag == 1 || flag == 2">
          <div>活动规则：</div>
          <div v-html="voteInfo.rule"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import WebHeader from "@/components/WebHeader.vue";
import { openEncy } from "@/utils/navigate";
import request from "@/utils/request";
import { isAppFun, isIOS, isAndroid } from "@/utils/judgeClient";
export default {
  data() {
    return {
      hasMusic: false,
      music: {
        title: "",
        artist: "",
        pic: "",
        originalUrl: "",
      },
      voteInfo: {},
      awId: 1,
      isJoin: false,
      loading: false,
      flag: 1,
      rule: "",
      device: "pc",
      processTips: {
        0: "活动未开始",
        1: "提名活动进行中",
        2: "提名结算中，投票活动即将开启，敬请关注！",
        3: "投票活动进行中",
      },
      nominateForm: {
        awardType: {},
        name: "",
        referenceUrl: "",
        description: "",
      },
      name: "",
      formRules: {
        awardType: [
          { required: true, message: "该项不可为空", trigger: "change" },
        ],
        name: [{ required: true, message: "该项不可为空", trigger: "blur" }],
        referenceUrl: [
          { required: true, message: "该项不可为空", trigger: "blur" },
        ],
      },
      voteAwardType: {},
      // voteAwardType.type：奖项类型，1：艺人 2：音乐 3：厂牌
      sortType: {
        id: 0,
        name: "得票数",
      },
      ruleShow: false,
      rankPosition: 1,
      isApp: false,
      waitingList: [],
    };
  },
  components: {
    WebHeader,
  },

  computed: {
    ...mapGetters(["isLogin", "token"]),
  },
  async created() {
    document.title = "WLMS行业峰会年度颁奖盛典——多频网络提名会场（投票阶段";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "2022年8月25日~9月4日，来为WLMS电子音乐行业峰会“最佳艺人”、“最佳音乐”、“最佳厂牌”等各类奖项投票吧！"
      );
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      // 当前设备是移动设备
      this.isApp = isAppFun();
      if (this.isApp) {
        this.device = "mobile";
      }
      // else
      //   this.$message({
      //     message: "请使用电脑打开该页面，获得更好体验",
      //   });
    }
    this.getVoteInfo();
  },
  methods: {
    // ...mapActions(["user/resetToken"]),
    async getWaitingList(voteType, sortType) {
      const waitingListRes = await request.get("/dp/vote/list", {
        params: {
          sortType: sortType.id,
          voteId: voteType.id,
        },
      });
      if (waitingListRes.code == 200) {
        this.waitingList = waitingListRes.data;
      }
    },
    async clickVote(id, voteItem) {
      const voteRes = await request.post("/dp/vote/participate", {
        voteId: id,
        vsId: voteItem.id,
      });
      if (voteRes.code == 200) {
        voteItem.voteCount++;
        this.$message({
          message: "投票成功",
          type: "success",
        });
      }
    },
    async clickSubmitBtn(form) {
      if (form.awardType.type == "2") {
        if (!this.hasMusic) {
          return this.$message({
            message: "提名音乐类奖项时，必须要填写音乐链接并解析哦",
            type: "error",
          });
        }
        const submitMusicRes = await request.post("/dp/vote/nominate", {
          description: form.description,
          referenceUrl: JSON.stringify(this.music),
          voteId: form.awardType.id,
        });
        if (submitMusicRes.code == 200) {
          this.$message({
            message: "提名成功，可以继续提名哦",
            type: "success",
          });
          this.nominateForm = {
            awardType: this.voteInfo.voteList[0],
            name: "",
            musicUrl: "",
            referenceUrl: "",
            description: "",
          };
          this.hasMusic = false;
          this.music = {
            title: "",
            artist: "",
            pic: "",
            originalUrl: "",
          };
        }
      } else if (form.awardType.type !== "2")
        this.$refs.nominateForm.validate(async (valid) => {
          if (valid) {
            const submitRes = await request.post("/dp/vote/nominate", {
              description: form.description,
              name: form.name,
              referenceUrl: form.referenceUrl,
              voteId: form.awardType.id,
            });
            if (submitRes.code == 200) {
              this.$message({
                message: "提名成功，可以继续提名哦",
                type: "success",
              });
              this.nominateForm = {
                awardType: this.voteInfo.voteList[0],
                name: "",
                referenceUrl: "",
                description: "",
              };
            }
          } else
            return this.$message({
              message: "艺人或厂牌的名称、介绍链接为必填项哦，请填写后再提名",
              type: "error",
            });
        });
    },
    async getVoteInfo() {
      const voteInfoRes = await request.get("/dp/vote/info", {
        params: {
          awId: this.awId,
        },
      });
      document.title = voteInfoRes.data.name;
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", voteInfoRes.data.description);
      //   if (!voteInfoRes.data.memberVo) {
      // this["user/resetToken"]();
      // }
      this.voteInfo = voteInfoRes.data;
      this.nominateForm.awardType = this.voteInfo.voteList[0];
      this.voteAwardType = this.voteInfo.voteList[0];
      console.log("this.voteAwardType", this.voteAwardType);
      this.flag = this.voteInfo.voteList[0].flag;
      console.log("this.nominateForm.awardType", this.nominateForm.awardType);
      if (this.flag == 3 || this.flag == 4) {
        this.getWaitingList(this.voteAwardType, this.sortType);
      }
    },
    async filterLink(urlStr) {
      if (!urlStr) return false;
      var content = JSON.parse(JSON.stringify(urlStr));
      if (content.indexOf("163") != -1 || content.indexOf(".qq.") != -1) {
        console.log("是音乐链接");
      } else {
        this.$message({
          message: "解析失败",
          type: "error",
        });
        return false;
      }
      const url = this.httpString(content);
      if (!url) {
        this.errorMsg = true;
        return;
      }
      urlStr = url[0];
      if (urlStr) {
        const musicRes = await request.get("/dp/analysis/musicWeb", {
          params: {
            param: urlStr,
          },
        });
        if (musicRes.data) {
          this.hasMusic = true;
          this.music = musicRes.data;
        }
      } else {
        this.errorMsg = true;
      }
    },
    httpString(s) {
      var reg =
        /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
      s = s.match(reg);
      return s;
    },
    switchTab(tab) {
      this.voteAwardType = tab;
      this.waitingList = [];
      this.sortType = {
        name: "得票数",
        id: 0,
      };
      this.getWaitingList(this.voteAwardType, this.sortType);
    },
    switchSortType(type) {
      this.sortType = type;
      this.waitingList = [];
      this.getWaitingList(this.voteAwardType, this.sortType);
    },
    clickLogin() {
      this.$login();
      return;
    },
    clickVoteName(typeItem, item) {
      if (typeItem.type == "2") {
        this.handleOpenUrl(item.referenceUrl);
      } else this.toEncy(item.ency);
    },
    toEncy(item) {
      if (this.isApp) {
        try {
          if (isAndroid())
            window.Android.skipEncyclopedia(JSON.stringify(item));
          if (isIOS())
            window.webkit.messageHandlers.skipEncyclopedia.postMessage(item);
        } catch (error) {
          console.log(error);
        }
        console.log(item);
      } else openEncy(item.typeId, item.dataId);
    },
    handleOpenUrl(urlStr) {
      let url = urlStr;
      if (
        urlStr.toLowerCase().indexOf("https://") !== 0 &&
        urlStr.toLowerCase().indexOf("http://") !== 0 &&
        urlStr.toLowerCase().indexOf("ftp://") !== 0 &&
        urlStr.toLowerCase().indexOf("file://") !== 0
      ) {
        url = "http://" + url;
      }
      if (this.isApp) {
        let urlObj = new URL(url);
        urlObj.searchParams.set("newWebPage", true);
        url = urlObj.toString();
        window.location.href = url;
      } else window.open(url);
    },
  },
};
</script>

<style scoped>
.greyBlock .commentMusic {
  margin-bottom: 24px;
  cursor: auto;
}
.greyBlock .commentMusic:hover {
  background: var(--background-color-item-hover);
}
.greyBlock.voteBlock {
  padding: 6px 0 24px 0;
  margin-bottom: 30px;
}
.pcPage {
  width: 700px;
  min-width: 700px;
}
.pageDet.mobilePage {
  margin-top: 10px;
}
.mobilePage {
  width: 100%;
}
.voteBanner {
  margin-top: 8px;
  border-radius: 4px;
  overflow: hidden;
}
.mobilePage .voteBanner {
  margin-top: 48px;
}
.mobilePage.appPage .voteBanner {
  margin-top: 8px;
}
.pcPage .voteBanner {
  height: 298px;
}
.mobilePage .voteBanner {
  height: 140px;
}
.processTips {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}
.voteRule {
  text-align: left;
  margin: 68px auto 40px auto;
  font-size: 14px;
  color: var(--color-80);
  line-height: 24px;
}
.greyBlock {
  padding: 30px 0;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}

.submitBtn {
  width: 300px;
  height: 44px;
  color: #fff;
  background: #9c5cf7;
  border-radius: 25px;
  text-align: center;
  font-size: 16px;
  line-height: 44px;
  margin: 40px auto 0 auto;
  cursor: pointer;
}
.awardList {
  display: flex;
  padding: 18px 0;
  flex-wrap: wrap;
}
.toRules {
  padding: 0 30px;
  height: 58px;
  display: flex;
  align-items: center;
}

.enterRules {
  margin-left: auto;
  color: var(--color-60);
  cursor: pointer;
}
.greyBlock .halfBottom::after {
  left: 3%;
  width: 94%;
}
.rankList {
  font-size: 15px;
  font-weight: 500;
}
.voteItem {
  display: flex;
  align-items: center;
  padding: 26px 30px;
  flex-wrap: wrap;
  line-height: 34px;
}
.mobilePage .voteItem {
  padding: 20px;
}
.rankPosition {
  margin-right: 14px;
}
.voteNum {
  margin-right: 15px;
}
.leftAuto {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.voteItem .encyItem {
  margin: 0 0 0 6px;
  background: none;
}
.voteName {
  cursor: pointer;
}
.musicVoteName {
  cursor: pointer;
}
.musicVoteName .playBtn {
  width: 13px;
  height: 14px;
  margin-left: 6px;
}
/* .musicVoteName:hover .playBtn {
  display: block;
} */
/* .musicVoteName:hover {
  color: #9c5cf7;
} */
.ruleDialog {
  margin-top: 8vh;
}
.firstPrize {
  color: #ffb508;
}
.firstPrize .winnerImg {
  width: 44px;
  margin-right: 10px;
}
.musicInfo {
  line-height: 20px;
}
</style>

<style>
.greyBlock .el-form-item__label {
  float: none;
  color: var(--font-color);
  line-height: 30px;
  font-size: 15px;
}
.greyBlock .el-form-item__content {
  margin-top: 6px;
}
.greyBlock .el-form {
  margin: 0 30px;
}
.mobilePage .greyBlock .el-form {
  margin: 0 20px;
}
.greyBlock .el-button.is-round {
  height: 32px;
}
.greyBlock .el-select .el-input.is-focus .el-input__inner {
  border: none;
}
.el-scrollbar__wrap {
  background: var(--background-color-item-hover);
}
.el-select-dropdown__item {
  color: var(--font-color);
  font-weight: 400;
}
.el-popper[x-placement^="bottom"] .popper__arrow {
  border-bottom-color: var(--background-color-item-hover);
  border-top-color: var(--background-color-item-hover);
}
.el-popper[x-placement^="top"] .popper__arrow {
  border-bottom-color: var(--background-color-item-hover);
  border-top-color: var(--background-color-item-hover);
}
.el-popper[x-placement^="top"] .popper__arrow::after {
  border-bottom-color: var(--background-color-item-hover);
  border-top-color: var(--background-color-item-hover);
}
.el-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: var(--background-color-item-hover);
  border-top-color: var(--background-color-item-hover);
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: var(--background-color-item-hover);
  color: #9c5cf7;
}
.el-select-dropdown__item.selected:hover {
  color: #9c5cf7;
}
.el-select-dropdown__item.selected {
  color: var(--font-color);
  font-weight: 400;
}
.el-select-dropdown {
  border: none;
}
.greyBlock .el-select .el-input.is-focus .el-input__inner {
  background: var(--background-color-item-hover);
}
.el-input--suffix .el-input__inner {
  background: var(--background-color-item-hover);
  border: none;
  color: var(--font-color);
}
.greyBlock .el-input__inner {
  background: var(--background-color-item-hover);
  color: var(--font-color);
  border: none;
}
.greyBlock .el-textarea__inner {
  background: var(--background-color-item-hover);
  color: var(--font-color);
  border: none;
  min-height: 40px !important;
  padding: 8px 15px;
}
.greyBlock .nominateDesc .el-textarea__inner {
  min-height: 80px !important;
}
.ruleDialog .el-dialog--center .el-dialog__body {
  text-align: left;
  font-size: 14px;
  font-weight: 300;
  padding: 10px 36px;
}
.pageDet .el-form-item__error {
  right: 105px !important;
}
.el-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: var(--background-color-item-hover);
}
.voteBtn {
  background: #9c5cf7;
  border-radius: 21px;
  padding: 0 15px;
  cursor: pointer;
  line-height: 26px;
  color: #fff;
}
.sortTypeBlock {
  height: 50px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.sortType {
  margin-left: auto;
  cursor: pointer;
  padding: 10px;
  position: relative;
}
.sortType:hover {
  border-radius: 4px 4px 0 0;
  background: var(--tab-background-color);
}
.sortTypeHover {
  position: absolute;
  display: none;
  background: var(--tab-background-color);;
  width: 126px;
  border-radius: 4px 0 4px 4px;
  top: 46px;
  right: 0;
  padding: 12px 4px 12px 13px;
  z-index: 1000;
}
.sortType:hover .sortTypeHover {
  display: flex;
}
.sortTypeItem {
  padding: 0 6px;
  background: var(--color-10);
  border-radius: 2px;
  margin-right: 6px;
  color: var(--color-50);
}
.sortTypeItem:hover {
  background: rgba(156, 92, 247, 0.2);
  color: #9c5cf7;
}
.sortType img {
  width: 6px;
  margin: 2px;
}
</style>
