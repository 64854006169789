<template>
  <el-dialog
  class="followDialog"
    title=""
  :visible.sync="centerDialogVisible"
  width="331px"
  center>
  <span>你将不再关注 {{member.nickname}}</span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisible = false">取消</el-button>
    <el-button type="primary" @click="cancelFollow(member.id)">确定</el-button>
  </span>
</el-dialog>
</template>

<script>
import request from "@/utils/request";
  export default {
    data() {
      return {
        centerDialogVisible: false,
        confirmCancel: false,
      };
    },
    methods: {
      async cancelFollow(id) {
        const followRes = await request.get("/dp/fans/attention", {
          params: {
            idolId: id,
          },
        });
        if (followRes.code == 200) {
          this.confirmCancel = true;
          this.$emit("cancelHandler", this.confirmCancel);
          this.centerDialogVisible = false;
        }
      }
    },
    props: ["member"],
  };
</script>

<style>
.el-dialog__body {
  font-size: 16px;
  color: var(--font-color);
  font-weight: 500;
}
.el-dialog--center .el-dialog__body {
  text-align: center;
  padding: 20px;
}
.followDialog .el-button {
  padding: 7px 24px;
  border-radius: 43px;
}
.followDialog {
  margin-top: 20vh;
}
.followDialog .el-dialog {
  border-radius: 4px;
}
.followDialog .el-button.el-button--default {
  background: transparent;
  color: #9C5CF7;
  border: 1px solid #9C5CF7;
  margin-right: 10px;
}
.el-dialog__footer {
  padding: 4px 0 48px 0;
}
</style>