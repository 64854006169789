<template>
  <!-- 全部搜索的信号搜索结果 -->
  <div class="rightItem halfBottom recoItem" @click="toSignal">
    <div class="recoArticle">
      <div class="infoItemText textOverflow">
        {{ recoItem.esArticle.title }}
      </div>
      <div class="articleData">{{ recoItem.commentNum }}条评论 · {{ recoItem.praiseNum }}个点头</div>
    </div>
  </div>
</template>

<script>
import { openSignal } from '@/utils/navigate'
export default {
    methods: {
    toSignal() {
      openSignal(this.recoItem.esArticle.id)
    },
  },
  props: ["recoItem"],
};
</script>
