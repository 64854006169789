<template>
  <!-- 全部搜索的信号搜索结果 -->
  <div class="articleItem generalSearch" @click="toSignal">
    <div class="articleTitle textOverflow">
      {{ articleSearchItem.esArticle.title }}
    </div>
    <div class="author">
      <div class="detailAuthorHead" @click.stop="toPersonal">
        <div class="authorPhoto">
          <img
           v-if="articleSearchItem.member.avatar && articleSearchItem.member.deleted == 0"
           class="imgCut" :src="articleSearchItem.member.avatar" alt="" />
          <img
           v-else
           class="imgCut" src="@/assets/style/img_portrait@2x.png" alt="" />
        </div>
        <img
          v-if="articleSearchItem.member.isRealOfficial === 1 && articleSearchItem.member.deleted == 0"
          class="userTypeImg"
          src="@/assets/style/icon_official@2x.png"
          alt=""
        />
        <img
          v-else-if="articleSearchItem.member.isOfficial === 1 && articleSearchItem.member.deleted == 0"
          class="userTypeImg"
          src="@/assets/style/icon_vip@2x.png"
          alt=""
        />
      </div>
      <div class="authorRight" @click.stop="toPersonal">
        <div v-if="articleSearchItem.member.deleted == 0" class="authorName">{{ articleSearchItem.member.nickname }}</div>
        <div v-else class="authorName">账号已注销</div>
        <div class="publishTime">
          {{ articleSearchItem.esArticle.createTime }}
        </div>
      </div>
    </div>
    <div class="articleInfo">
      <div
        class="description textOverflow"
        :class="{ hasPic: articleSearchItem.esArticle.picture }"
      >
        {{ articleSearchItem.esArticle.descriptions }}
      </div>
      <div
        v-if="articleSearchItem.esArticle.picture"
        class="articlePhoto generalSearch"
      >
        <img class="imgCut" :src="articleSearchItem.esArticle.picture" alt="" />
      </div>
    </div>
    <div
      class="articleData"
      :class="{ hasPic: articleSearchItem.esArticle.picture }"
    >
      {{ articleSearchItem.commentNum }}条评论 ·
      {{ articleSearchItem.praiseNum }}个点头
    </div>
  </div>
</template>

<script>
import { openSignal, openPersonal } from "@/utils/navigate";

export default {
  methods: {
    toSignal() {
      openSignal(this.articleSearchItem.esArticle.id);
    },
    toPersonal() {
      if (this.articleSearchItem.member.deleted == 1) {
        return this.$message("用户已注销");
      }
      else
      openPersonal(this.articleSearchItem.member.id);
    },
  },
  props: ["articleSearchItem"],
};
</script>
