<template>
  <!-- 全部搜索的频段搜索结果 -->
  <div class="resultItem" @click="toTopic(topicSearchItem)">
    <div class="topicPhoto">
      <img class="imgCut" :src="topicSearchItem.logo" alt="" />
    </div>
    <div class="rightMsg">
      <div class="resultName">{{ topicSearchItem.name }}</div>
      <div class="resultBrief textOverflow">
        {{ topicSearchItem.description }}
      </div>
      <div class="resultBrief">
        {{ topicSearchItem.attentionNum }}人已捕捉 ·
        {{ topicSearchItem.articleNum }}条信号
      </div>
    </div>
  </div>
</template>

<script>
import {  openTopic } from "@/utils/navigate";
export default {
  methods: {
    toTopic(target) {
      openTopic(target.id);
    },
  },
  props: ["topicSearchItem"],
};
</script>

<style>
.rightMsg {
  width: 728px;
}
</style>