<template>
  <!-- 全部搜索的百科搜索结果 -->
  <div class="resultItem" @click="toEncy(encySearchItem)">
    <div class="modulePic">
      <img
        v-if="encySearchItem.picture"
        class="imgCut"
        :src="encySearchItem.picture"
        alt=""
      />
      <img v-else class="imgCut" src="@/assets/style/img_pedia@2x.png" alt="" />
    </div>
    <div class="rightMsg" :class="{ noBrief: !encySearchItem.descriptions || !encySearchItem.descriptions.match(/\S+/)}">
      <div class="resultName">
        <div class="encyType">{{ encySearchItem.typeName }}</div>
        <div class="encyName">{{ encySearchItem.name }}</div>
      </div>
      <div
        v-if="
          encySearchItem.descriptions && encySearchItem.descriptions.match(/\S+/)
        "
        class="resultBrief textOverflow"
      >
        {{ encySearchItem.descriptions }}
      </div>
    </div>
  </div>
</template>

<script>
import { openEncy } from "@/utils/navigate";

export default {
  data() {
    return {
      emptyDet: "\n\r",
    };
  },
  props: ["encySearchItem"],
  methods: {
    toEncy(target) {
      openEncy(target.typeId, target.dataId);
    },
  },

};

</script>

<style scoped>
.resultBrief.textOverflow {
  -webkit-line-clamp: 2;
}
.rightMsg.noBrief {
  width: 728px;
}
</style>