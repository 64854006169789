<template>
  <div class="loginBg" :class="{ mobilePage: isMobile }">
    <div class="loginPopup">
      <div v-show="mobileLogin">
        <div class="login_header">
          <a @click="clickMbLogin" :class="{ active: loginType == 0 }"
            >免密码登录</a
          >
          <a @click="clickPsLogin" :class="{ active: loginType == 1 }"
            >密码登录</a
          >
          <el-button
            ref="closeBtn"
            class="closeBtn"
            type="primary"
            @click="loginClose"
          >
            <img
              class="closeBtnImg"
              src="@/assets/style/btn_close@2x (1).png"
              alt=""
            />
          </el-button>
        </div>
        <el-form :model="loginForm" :rules="rules" ref="loginForm">
          <div v-if="loginType == 0">
            <el-form-item label="" prop="mobile">
              <el-input
                ref="mobile"
                v-model="loginForm.mobile"
                placeholder="请输入手机号"
                spellcheck="false"
                autocomplete="on"
                name="mobile"
                type="text"
              ></el-input>
            </el-form-item>
            <el-form-item label="" prop="code" class="codeInput">
              <el-input
                ref="code"
                v-model="loginForm.code"
                placeholder="请输入验证码"
                maxlength="6"
                autocomplete="on"
                @keyup.enter.native="handleLogin"
                spellcheck="false"
                name="code"
                type="text"
              ></el-input>
              <el-button
                class="codeBtn"
                :style="{ color: timer === 0 ? '#9C5CF7' : '#b1b1b1' }"
                ref="codeBtn"
                :loading="sending"
                :disabled="sendDisabled"
                size="small"
                @click="onSendSms('login')"
                >{{ sendButtonText }}</el-button
              >
            </el-form-item>
          </div>

          <div v-else>
            <el-form-item label="" prop="username">
              <el-input
                ref="username"
                name="username"
                v-model="loginForm.username"
                placeholder="请输入用户名"
                spellcheck="false"
                autocomplete="on"
                type="text"
              ></el-input>
            </el-form-item>
            <el-tooltip
              v-model="capsTooltip"
              content="大写已打开"
              placement="right"
              manual
            >
              <el-form-item class="passwordItem" label="" prop="password">
                <el-input
                  ref="password"
                  v-model="loginForm.password"
                  placeholder="请输入密码"
                  :type="passwordType"
                  name="password"
                  tabindex="2"
                  autocomplete="on"
                  spellcheck="false"
                  @keyup.native="checkCapslock"
                  @blur="capsTooltip = false"
                  @keyup.enter.native="handleLogin"
                  @change="inputChange"
                ></el-input>
                <span class="show-pwd" @click="showPwd">
                  <div class="passwordView" v-show="passwordType == 'password'">
                    <img src="@/assets/style/btn_close@2x.png" alt="" />
                  </div>
                  <div class="passwordView" v-show="passwordType == ''">
                    <img src="@/assets/style/btn_open@2x.png" alt="" />
                  </div>
                </span>
              </el-form-item>
            </el-tooltip>
          </div>

          <el-form-item>
            <el-checkbox class="isRemember" v-model="loginForm.isRemember"
              >在14天内保持我的登录状态</el-checkbox
            >
          </el-form-item>
          <el-form-item>
            <el-button
              ref="loginBtn"
              :loading="loading"
              type="primary"
              class="login-btn"
              @click.native.prevent="handleLogin()"
            >
              登录
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 微信扫码登录 -->
      <div v-show="!mobileLogin && !bindMobile" class="wxLogin">
        <el-button
          ref="closeBtn"
          class="closeBtn"
          type="primary"
          @click="loginClose"
        >
          <img
            class="closeBtnImg"
            src="@/assets/style/btn_close@2x (1).png"
            alt=""
          />
        </el-button>
        <div class="wxLoginText">请使用微信扫码登录</div>
        <div class="wxLoginImg">
          <template v-if="qrCodeUrl">
          <img class="imgCut" :src="qrCodeUrl" alt="" />
          <div v-if="codeExpired" class="expired codeLoading" @click="clickWx()">已过期，点击重新获取</div>
          </template>
          <div v-else class="codeLoading">正在获取二维码...</div>
        </div>
        <el-checkbox class="isRemember" v-model="loginForm.isRemember"
          >在14天内保持我的登录状态</el-checkbox
        >
      </div>
      <!-- 选择绑定手机号 -->
      <div v-show="!mobileLogin && bindMobile" class="bindMobileBox">
        <div class="login_header">
          <a>绑定已注册手机号</a>
          <el-button
            ref="closeBtn"
            class="closeBtn"
            type="primary"
            @click="loginClose"
          >
            <img
              class="closeBtnImg"
              src="@/assets/style/btn_close@2x (1).png"
              alt=""
            />
          </el-button>
        </div>
        <el-form :model="bindMobileForm" :rules="rules" ref="bindMobileForm">
          <el-form-item label="" prop="mobile">
            <el-input
              ref="mobile"
              v-model="bindMobileForm.mobile"
              placeholder="请输入手机号"
              spellcheck="false"
              autocomplete="on"
              name="mobile"
              type="text"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="code" class="codeInput">
            <el-input
              ref="code"
              v-model="bindMobileForm.code"
              placeholder="请输入验证码"
              maxlength="6"
              autocomplete="on"
              @keyup.enter.native="handleLogin"
              spellcheck="false"
              name="code"
              type="text"
            ></el-input>
            <el-button
              class="codeBtn"
              :style="{ color: timer === 0 ? '#9C5CF7' : '#b1b1b1' }"
              ref="codeBtn"
              :loading="sending"
              :disabled="sendDisabled"
              size="small"
              @click="onSendSms('bind')"
              >{{ sendButtonText }}</el-button
            >
          </el-form-item>
          <el-button
            ref="bindBtn"
            type="primary"
            class="login-btn"
            @click.native.prevent="handleBind(bindMobileForm)"
          >
            绑定
          </el-button>
        </el-form>
      </div>
      <template v-if="!bindMobile">
        <div class="tips">
          <span>*未注册手机或微信将在验证后自动注册登录，注册即代表同意</span>
          <a href="https://user-protocol.hearinmusic.com/">《多频用户协议》</a>
          <a href="https://privacy-policy.hearinmusic.com/">《多频隐私政策》</a>
        </div>
        <div class="separator">
          <div class="separatorText">其他登录方式</div>
        </div>
        <div class="loginType">
          <img
            v-show="mobileLogin"
            class="imgCut"
            src="@/assets/style/btn_wechat@2x.png"
            alt=""
            @click="clickWx()"
          />
          <img
            v-show="!mobileLogin"
            class="imgCut"
            src="@/assets/style/btn_phone@2x.png"
            alt=""
            @click="clickMobile()"
          />
        </div>
      </template>
      <el-dialog
        title=""
        :visible.sync="contentVisible"
        :append-to-body="true"
        class="wxLoginSelect"
        center
        :close-on-click-modal="false"
      >
        <span style="text-align: center"
          >该微信号未注册多频，请选择创建新账号或绑定手机号：</span
        >
        <el-button
          class="selectBtn"
          round
          type="primary"
          @click="clickNewAccount()"
          >创建新账号</el-button
        >
        <el-button
          class="selectBtn"
          round
          type="primary"
          @click="clickBindMobile()"
          >绑定已注册手机号</el-button
        >
      </el-dialog>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    const validateUsername = (rule, value, callback) => {
      // if (!validUsername(value)) {
      //   callback(new Error('Please enter the correct user name'))
      // } else {
      //   callback()
      // }
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6 || value === "") {
        callback(new Error("密码必须至少有6个字符"));
      } else {
        callback();
      }
    };
    const validateMobile = (rule, value, callback) => {
      if (value === "" || !/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请填写正确的手机号"));
      } else {
        callback();
      }
    };

    const validateCode = (rule, value, callback) => {
      if (value.length < 6 || value === "") {
        callback(new Error("验证码错误"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: "",
        password: "",
        mobile: "",
        code: "",
        isRemember: true,
      },
      bindMobileForm: {
        mobile: "",
        code: "",
      },
      rules: {
        username: [
          { required: true, trigger: "blur", validator: validateUsername },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
        mobile: [
          { required: true, trigger: "blur", validator: validateMobile },
        ],
        code: [{ required: true, trigger: "blur", validator: validateCode }],
      },
      loginType: 0, //0代表短信，1代表密码, 3代表微信扫码
      capsTooltip: false,
      passwordType: "password",
      sending: false,
      loading: false,
      sendDisabled: false,
      loginDisabled: true,
      timer: 0,
      showMessage: "",
      mobileLogin: true,
      qrCodeUrl: "",
      pollingTimer: null,
      bindMobile: false,
      isNew: false,
      contentVisible: false,
      codeExpired: false,
      // loginShow: true,
      qrCodeTimer: null,
    };
  },
  methods: {
    async handleBind(form) {
      const bindMobileRes = await request.post("/dp/wx/bindMobile", {
        code: form.code,
        mobile: form.mobile,
        wxKey: this.sceneStr,
      });
      if (bindMobileRes.data && bindMobileRes.data.token) {
        const wxLoginForm = {
          token: bindMobileRes.data.token,
          isRemember: this.loginForm.isRemember,
        };
        this.$store
          .dispatch("user/updateToken", wxLoginForm)
          .then(() => {
            console.log(" 微信登录拿到token，更新token ");
            this.loading = false;
            this.$message({
              message: "登录成功，正在返回...",
              type: "success",
            });
            location.reload();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    async clickNewAccount() {
      // 选择注册新账号
      const qrCodeRegisterRes = await request.get("/dp/wx/qrCodeRegister", {
        params: {
          wxKey: this.sceneStr,
        },
      });
      if (qrCodeRegisterRes.data && qrCodeRegisterRes.data.token) {
        const wxRegisterForm = {
          token: qrCodeRegisterRes.data.token,
          isRemember: this.loginForm.isRemember,
        };
        this.$store
          .dispatch("user/updateToken", wxRegisterForm)
          .then(() => {
            console.log(" 微信登录拿到token，更新token ");
            this.loading = false;
            this.$message({
              message: "登录成功，正在返回...",
              type: "success",
            });
            location.reload();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    clickBindMobile() {
      this.bindMobile = true;
      this.contentVisible = false;
    },
    clickMobile() {
      this.stopPolling();
      this.mobileLogin = true;
    },
    // 开始轮询
    async polling() {
      // 清除之前的轮询，以防万一
      this.stopPolling();

      // 查询扫码状态
      const qrCodeLoginRes = await request.get("/dp/wx/qrCodeLogin", {
        params: {
          wxKey: this.sceneStr,
        },
      });

      // 假设qrCodeLoginRes.success表示登录成功，不需要继续轮询
      if (qrCodeLoginRes.data && qrCodeLoginRes.data.token) {
        // 在这里执行登录成功的逻辑
        const wxLoginForm = {
          token: qrCodeLoginRes.data.token,
          isRemember: this.loginForm.isRemember,
        };
        this.$store
          .dispatch("user/updateToken", wxLoginForm)
          .then(() => {
            console.log(" 微信登录拿到token，更新token ");
            this.loading = false;
            this.$message({
              message: "登录成功，正在返回...",
              type: "success",
            });
            location.reload();
          })
          .catch(() => {
            this.loading = false;
          });
        // 这里return出去，不发起下一次轮询
        return;
      } else if (qrCodeLoginRes.data && qrCodeLoginRes.data.isNew) {
        console.log("新账号");
        // 如果是新账号则询问是否需要绑定手机号
        this.isNew = true;
        this.contentVisible = true;
        return;
      }
      // 否则等1s再发起下一个请求
      else
        this.pollingTimer = setTimeout(() => {
          this.polling();
        }, 1000);
    },
    // 停止轮询
    stopPolling() {
      this.pollingTimer && clearTimeout(this.pollingTimer);
      this.pollingTimer = null;
    },
    async clickWx() {
      console.log("点击微信登录");
      this.mobileLogin = false;
      this.codeExpired = false;
      this.qrCodeTimer && clearTimeout(this.qrCodeTimer);
      this.qrCodeTimer = null;
      const getQrCodeRes = await request.get("/dp/wx/getQrCode", {});
      if (getQrCodeRes.code == 200) {
        this.qrCodeUrl = `https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${getQrCodeRes.data.ticket}`;
        this.qrCodeTimer = setTimeout(() => {
          this.codeExpired = true;
          this.qrCodeTimer = null;
          this.stopPolling();
        }, 1000 * 60 * 30);
        this.sceneStr = getQrCodeRes.data.sceneStr;
        this.polling();
      }
    },
    clickMbLogin() {
      this.loginType = 0;
      this.$nextTick(() => {
        this.$refs.mobile.focus();
      });
    },
    clickPsLogin() {
      this.loginType = 1;
      this.$nextTick(() => {
        this.$refs.username.focus();
      });
    },
    checkCapslock(e) {
      const { key } = e;
      this.capsTooltip = key && key.length === 1 && key >= "A" && key <= "Z";
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    inputChange() {
      console.log(this.loginType);
      if (this.loginType == 1) {
        if (this.loginForm.username !== "" && this.loginForm.password !== "") {
          this.$refs["loginBtn"].$el.style.color = "#fff";
          this.$refs["loginBtn"].$el.style.backgroundColor = "#7123e5";
        }
      } else if (this.loginType == 0) {
        if (
          /^1[3456789]\d{9}$/.test(this.loginForm.mobile) &&
          this.loginForm.code.length >= 6
        ) {
          this.$refs["loginBtn"].$el.style.color = "#fff";
          this.$refs["loginBtn"].$el.style.backgroundColor = "#7123e5";
        }
      }
    },
    onSendSms(type) {
      if (type == "login") {
        this.$refs["loginForm"].validateField("mobile", (valid) => {
        if (valid && valid !== "") {
          return false;
        } else {
          this.sending = true;
          this.$store
            .dispatch("user/regCaptcha", this.loginForm)
            .then(() => {
              this.$message.success("短信发送成功，请注意查收");
              this.timer = 60;
            })
            .catch(() => {
              this.sending = false;
            })
            .finally(() => (this.sending = false));
        }
      });
      }
      else if (type == "bind") {
        this.$refs["bindMobileForm"].validateField("mobile", (valid) => {
        if (valid && valid !== "") {
          return false;
        } else {
          this.sending = true;
          this.$store
            .dispatch("user/regCaptcha", this.bindMobileForm)
            .then(() => {
              this.$message.success("短信发送成功，请注意查收");
              this.timer = 60;
            })
            .catch(() => {
              this.sending = false;
            })
            .finally(() => (this.sending = false));
        }
      });
      }
      
    },
    handleLogin() {
      if (this.loginType == 1) {
        this.$refs.loginForm.validate((valid) => {
          if (!valid) return;
          this.loading = true;
          this.$store
            .dispatch("user/LoginByUsername", this.loginForm)
            .then(() => {
              console.log(" 登录完成了 ");
              // this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
              this.loading = false;
              this.loginClose();
              location.reload();
            })
            .catch(() => {
              this.loading = false;
            });
        });
      } else {
        this.$refs.loginForm.validate((valid) => {
          if (!valid) return;
          this.loading = true;
          // this.$store.dispatch('user/login', this.loginForm)
          this.$store
            .dispatch("user/LoginByMobile", this.loginForm)
            .then(() => {
              console.log(" 登录完成了 ");
              // this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
              this.loading = false;
              this.loginClose();
              location.reload();
            })
            .catch(() => {
              this.loading = false;
            });
        });
      }
    },
    loginClose() {
      this.$emit("close");
      // if (this.loginShow) {
      //   this.loginShow =false;
      // }
      // else return;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.loginForm.mobile === "") {
        this.$refs.mobile.focus();
      } else if (this.loginForm.code === "") {
        this.$refs.code.focus();
      }
    });
  },
  computed: {
    sendButtonText() {
      if (this.timer === 0) {
        return "获取验证码";
      } else {
        return `${this.timer}s后重新发送`;
      }
    },
  },
  watch: {
    timer(num) {
      if (num > 0) {
        setTimeout(() => {
          this.timer = --num;
        }, 1000);
      }
    },
  },
};
</script>

<style>
.active {
  color: var(--font-color);
  padding-bottom: 10px;
  position: relative;
  font-weight: 500;
}

.active:after {
  content: "";
  width: 34px;
  height: 4px;
  background: linear-gradient(90deg, #9c5cf7, #7123e5 100%);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
}
.passwordView {
  color: #333;
  cursor: pointer;
  position: absolute;
  right: 6px;
  top: 8px;
}
.passwordView img {
  width: 18px;
  height: 18px;
}
.passwordItem {
  position: relative;
}
.codeBtn.el-button {
  background: transparent;
  border: none;
  position: absolute;
  right: 6px;
  top: 17px;
  background: none;
  padding: 2px;
}
.el-button.is-loading:before {
  background: transparent;
}
.loginPopup .el-input {
  display: inline-block;
  height: 47px;
  width: 100%;
}
.loginPopup .el-input .el-input__inner {
  background: transparent;
  border: 0 !important;
  border-bottom: 1px solid #545454 !important;
  -webkit-appearance: none;
  border-radius: 0px;
  padding: 12px 5px 12px 15px;
  color: var(--font-color);
  height: 47px;
}
.el-form-item__error {
  color: #d32f32 !important;
  top: 14px !important;
  left: auto;
  right: 34px;
  font-size: 12px;
  font-weight: 400;
}
.codeInput .el-form-item__error {
  right: 104px;
}
.show-pwd button {
  border: none;
  position: absolute;
  right: 6px;
  top: 17px;
  background: none;
  padding: 2px;
}
.isRemember .el-checkbox__label {
  font-weight: 400;
  font-size: 13px;
}
.el-checkbox__inner {
  background-color: transparent;
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #292929;
  border-color: rgba(255, 255, 255, 0.7);
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #7123e5;
}
.isRemember .el-checkbox__input.is-checked + .el-checkbox__label {
  color: var(--color-70);
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: var(--color-70);
}
.login-btn {
  width: 370px;
  height: 44px;

  border-radius: 25px;
  font-size: 16px;
  font-weight: 500;
  border: none;
}
.login-btn.el-button--primary {
  color: rgb(255, 255, 255);
  background-color: rgb(113, 35, 229);
}
.closeBtn.el-button--primary {
  border: none;
  padding: 0;
  margin-left: 190px;
  color: #666;
  font-weight: 300;
  background: none;
}
.mobilePage .closeBtn.el-button--primary {
  margin-left: 36%;
}
.wxLoginSelect .el-dialog {
  margin-top: 36vh !important;
  width: 280px !important;
}
.wxLoginSelect .el-dialog__body {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.wxLoginSelect .el-dialog--center .el-dialog__body {
  padding: 16px 30px 36px 30px;
}
</style>

<style scoped>
.loginBg {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.7);
}
.loginPopup {
  width: 370px;
  background: var(--background-color-item-hover);
  border-radius: 4px;
  padding: 28px;
}
.login_header {
  margin-bottom: 30px;
}

.login_header a {
  margin-right: 20px;
  cursor: pointer;
}

.tips {
  color: var(--color-40);
  font-weight: 400;
  font-size: 12px;
  position: relative;
  text-align: left;
  margin-top: -8px;
}
.tips a {
  color: inherit;
}
.closeBtnImg {
  width: 14px;
}
.mobilePage .loginPopup {
  width: 78%;
  padding: 28px 20px;
}

/* 微信登录 */
.separator {
  position: relative;
  height: 1px;
  background: var(--color-10);
  width: 300px;
  margin: 30px auto 20px auto;
}
.separatorText {
  padding: 0 10px;
  top: -8px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  color: var(--color-50);
  font-size: 12px;
}
.loginType {
  width: 33px;
  height: 33px;
  margin: 5px auto;
  cursor: pointer;
}
.wxLoginImg {
  width: 200px;
  height: 200px;
  margin: 0 auto 26px auto;
  background: rgb(68, 68, 68);
  position: relative;
}
.wxLogin {
  margin: 0 0 14px 0;
}
.wxLoginText {
  text-align: center;
  margin-bottom: 19px;
}
.wxLogin .closeBtn {
  margin-left: 350px;
}
.codeLoading {
  background: rgb(68, 68, 68);
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 200px;
}
.selectBtn {
  margin: 20px auto 0px auto;
}
.bindMobileBox .login_header a {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}
.expired {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}
</style>