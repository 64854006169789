import Vue from 'vue'
import { Carousel, CarouselItem,  InfiniteScroll, Message,  Backtop, Button, Form, Input, FormItem, Tooltip, Checkbox, Upload, Dialog, Select, Option, Radio, RadioGroup, Tag, Autocomplete, Popover, DatePicker, Table, TableColumn, Divider, Dropdown, DropdownItem, DropdownMenu} from 'element-ui'
import VueObserveVisibility from 'vue-observe-visibility'
import App from './App.vue'
import router from './router'
import LoginPopup from './components/LoginPopup/index.js'
import store from '@/store'

Vue.config.productionTip = false

Vue.use(VueObserveVisibility);
Vue.use(InfiniteScroll);
Vue.prototype.$message = Message;
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Backtop);
Vue.use(Button);
Vue.use(Form);
Vue.use(Input);
Vue.use(FormItem);
Vue.use(Tooltip);
Vue.use(Checkbox);
Vue.use(Upload);
Vue.use(Dialog);
Vue.use(Select);
Vue.use(Option);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Tag);
Vue.use(Autocomplete);
Vue.use(Popover);
Vue.use(DatePicker);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Divider);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);

Vue.prototype.$login = LoginPopup
window.$login = LoginPopup

Vue.mixin({
  data() {
    return {
      isMobile: /Mobi|Android|iPhone/i.test(navigator.userAgent)
    }
  }
})

new Vue({
  router,
  store,
  data: function(){
    return {
      themeColor: 'dark', // 全局变量，在页面用this.$root.themeColor调用
    }
  },
  render: h => h(App),
}).$mount('#app')

