<template>
  <div class="home-wrapper" :class="{mobilePage : isMobile}">
    <!-- 以下是头部板块 -->
    <web-header :switchHeight="150">
      <!-- 里面放滚动切换的内容 -->
      <div class="headSticky scrollSticky" key="scroll">
        <div class="head">
          <div class="headLeft">
            <div id="logo" @click="selectMenuItem()" v-if="!isMobile">
              <img class="imgCut" src="@/assets/style/DPlogo.png" alt="" />
            </div>
            <div>
              <div class="topicHeadTitle header">
                <img
                  class="topicHeadImg"
                  src="@/assets/style/icon_topic@2x_white.png"
                  alt=""
                />
                {{ topic.name }} 频段
              </div>
            </div>
            <div class="articleTab">
              <div
                class="tab"
                :class="{
                  selected: tab === 'latest',
                  unselect: tab !== 'latest',
                }"
                @click="switchTab('latest')"
              >
                <div>最新</div>
                <div v-if="tab === 'latest'" class="selectedIcon"></div>
              </div>
              <div
                class="tab"
                :class="{
                  selected: tab === 'hot',
                  unselect: tab !== 'hot',
                }"
                @click="switchTab('hot')"
              >
                <div>热门</div>
                <div v-if="tab === 'hot'" class="selectedIcon"></div>
              </div>
            </div>
          </div>
          <div class="headRight">
            <header-search />
          </div>
        </div>
      </div>
    </web-header>
    <!-- 以下是内容部分 -->
    <div class="content">
    <div class="left">
      <div class="topicHeadBlock">
        <div class="clickButton" :class="{ btnClicked: isCatch }" @click="clickCatch()">
          <template v-if="!isCatch">
            <img
              class="imgCommon"
              src="@/assets/style/btn_article_catch_default@2x.png"
              alt=""
            />
            <img
              class="imgHover"
              src="@/assets/style/btn_article_catch_default_hover.png"
              alt=""
            />
          </template>
          <template v-else>
            <img src="@/assets/style/btn_article_catched@2x.png" alt="" />
          </template>
          {{ catchText }}
        </div>
        <div class="topicHead halfBottom">
          <div class="topicHeadPic">
            <img class="imgCut" :src="topic.logo" alt="" />
          </div>
          <div class="topicHeadInfo">
            <div class="topicHeadTitle">
              {{ topic.name }}
            </div>
            <div class="topicDescrip">
              {{ topic.description }}
            </div>
            <div class="topicBrief">
              {{ topic.attentionNum }}人已捕捉 · {{ topic.articleNum }}条信号
            </div>
          </div>
        </div>
      </div>
      <div class="tabSticky topic">
        <div class="articleTab">
          <div
            class="tab"
            :class="{
              selected: tab === 'latest',
              unselect: tab !== 'latest',
            }"
            @click="switchTab('latest')"
          >
            <div>最新</div>
            <div v-if="tab === 'latest'" class="selectedIcon"></div>
          </div>
          <div
            class="tab"
            :class="{
              selected: tab === 'hot',
              unselect: tab !== 'hot',
            }"
            @click="switchTab('hot')"
          >
            <div>热门</div>
            <div v-if="tab === 'hot'" class="selectedIcon"></div>
          </div>
        </div>
      </div>
      <!-- 以下是文章列表 -->
      <div class="articleList" v-infinite-scroll="loadmore">
        <div class="loading" v-if="loading">
          <img class="loadingImg" src="@/assets/style/loading.gif" alt="" />
        </div>
        <template v-else-if="articles.length">
          <DropinArticle
            v-for="(item, idx) in articles"
            :item="item"
            :key="idx"
          />
          <div
            v-if="loadingMore"
            class="loadmore loading"
            :style="{ visibility: loadingMore ? 'visible' : 'hidden' }"
          >
            <img class="loadingImg" src="@/assets/style/loading.gif" alt="" />
          </div>
        </template>
        <template v-else>
          <div class="searchEmpty">
            <img v-if="themeColor=='light'" class="searchEmptyImg" src="@/assets/style/find empty.png" alt="" />
            <img v-else class="searchEmptyImg" src="@/assets/style/empty.gif" alt="" />
            <div class="searchEmptyText">很可惜，没有找到相关信息</div>
          </div>
        </template>
      </div>
    </div>
    <div class="right" v-if="!isMobile">
    <div class="sticky">
    <!-- 创作后台 -->
        <div class="rightField">
          <div class="rightTab halfBottom">
            <div class="rightTabText">创作后台</div>
          </div>
          <div class="creatorFlex">
            <div >
            <div class="creatorBtn" style="background : rgba(255,53,141,0.20)" @click="toCreatorAddDialog('signal')"><img src="@/assets/style/icon_signal@2x.png" alt=""></div>
            发信号
            </div>
            <div >
            <div class="creatorBtn" @click="toCreatorAddDialog('ency')"><img src="@/assets/style/icon_pedia_creator_grey.png" alt=""></div>
            编辑百科
            </div>
            <div >
            <div class="creatorBtn" @click="toCreatorAddDialog('topic')"><img src="@/assets/style/icon_topic_creator_grey.png" alt=""></div>
            新建频段
            </div>
            
            <div >
            <div class="creatorBtn" @click="toCreatorAddDialog('quiz')"><img src="@/assets/style/icon_qa_creator_grey.png" alt=""></div>
            新建频一频
            </div>
          </div>
          <div class="toCreatorBtn pointer" @click="toCreatorAddDialog()" >
            进入创作后台 >
          </div>
        </div>
         <!-- 二级页面的网站信息 -->
    <FirstFooter />
        </div>
        
    </div>
   
    </div>
    <template>
      <el-backtop target=".home-wrapper" :bottom="100" :visibility-height="100">
        <div class="backTop">UP</div>
      </el-backtop>
    </template>
  </div>
</template>

<script>
import WebHeader from "@/components/WebHeader";
import FirstFooter from "@/components/FirstFooter";
import DropinArticle from "@/components/DropinArticle";
import request from "@/utils/request";
import { openSignal, openTopic, jumpToCreatorAndAdd } from "@/utils/navigate";
import HeaderSearch from "@/components/HeaderSearch.vue";


export default {
  components: {
    WebHeader,
    FirstFooter,
    DropinArticle,
    HeaderSearch,
  },
  data() {
    return {
      articles: [],
      topic: {},
      tab: "latest",
      loading: true,
      loadingMore: false,
      articlePageNum: 1,
      totalPages: 1,
      showLoadmoreTimer: null,
      disableLoadmore: false,
      channelIds: [],
      noMoreData: false,
      catchText: "捕捉",
      isCatch: false,
      channelId: 0,
      parentId: 0,
      themeColor: 'dark',
    };
  },
  watch: {
    '$root.themeColor': {
      immediate: true,
      handler(newVal) {
        this.themeColor = newVal;
      },
    },
    isCatch(val) {
      if (val) {
        this.catchText = "已捕捉"
      }
      else this.catchText = "捕捉"
    },
  },
  mounted() {
    this.themeColor = localStorage.getItem('theme');
    this.$root.themeColor = this.themeColor;
  },
  methods: {
    toCreatorAddDialog(type) {
      jumpToCreatorAndAdd(type,this.parentId,this.channelId,this.topic.name);
    },
    selectMenuItem() {
      this.url = "/";
      if (this.$route.fullPath === this.url) {
        // 点击的是当前路由 手动重定向页面到 '/redirect' 页面
        this.$router.replace({
          path: "/redirect",
          query: {
            path: encodeURI(this.url),
          },
        });
      } else {
        // 正常跳转
        this.$router.push({
          path: this.url,
        });
      }
    },
    toSignal() {
      openSignal(this.item.esArticle.id);
    },
    toTopic(target) {
      openTopic(target.id);
    },
    async clickCatch() {
      const isCatchRes = await request.get("/dp/collect/collectChannel", {
      params: {
        channelId: this.channelId,
      },
    });
    if (isCatchRes.code == 200) {
      if (this.isCatch) {
        this.isCatch = false;
        return this.catchText = "捕捉";
      }
      else this.isCatch = true;
      return this.catchText = "已捕捉";
    }  
    },
    async switchTab(tab) {
      this.loading = true;
      this.noMoreData = false;
      this.tab = tab;
      if (this.tab === "latest") {
        this.articlePageNum = 1;
        const res = await request.post("/dp/article/list", {
          orderType: "CHANNEL_NEW",
          pageNum: 1,
          pageSize: 10,
          channelIds: this.channelIds,
        });
        this.articles = res.data.records;
        this.totalPages = res.data.pages;
      } else if (this.tab === "hot") {
        this.articlePageNum = 1;
        const res = await request.post("/dp/article/list", {
          orderType: "CHANNEL_HOT",
          pageNum: 1,
          pageSize: 10,
          channelIds: this.channelIds,
        });
        this.articles = res.data.records;
        this.totalPages = res.data.pages;
      }
      this.loading = false;
    },
    async loadmore() {
      if (this.loadingMore || this.loading || this.noMoreData) {
        return;
      }
      this.articlePageNum++;
      console.log("🌟 this.articlePageNum", this.articlePageNum);
      if (this.articlePageNum > this.totalPages) {
        return (this.noMoreData = true);
      }
      this.loadingMore = true;
      if (this.tab === "latest") {
        const res = await request.post("/dp/article/list", {
          orderType: "CHANNEL_NEW",
          pageNum: this.articlePageNum,
          pageSize: 10,
          channelIds: this.channelIds,
        });
        this.loadingMore = false;
        this.articles = this.articles.concat(res.data.records);
      } else if (this.tab === "hot") {
        const res = await request.post("/dp/article/list", {
          orderType: "CHANNEL_HOT",
          pageNum: this.articlePageNum,
          pageSize: 10,
          channelIds: this.channelIds,
        });
        this.loadingMore = false;
        this.articles = this.articles.concat(res.data.records);
      }
    },
  },

  async created() {
    const topicId = this.$route.query.id;
    this.channelIds.push(topicId);
    this.channelId = topicId;
    console.log("topicId=", topicId);
    const topicRes = await request.get("/dp/channel/detail", {
      params: {
        id: topicId,
      },
    });
    this.topic = topicRes.data;
    this.parentId = topicRes.data.parentId;
    document.title = `${this.topic.name} 频段 - DROPIN多频`;
    this.isCatch = this.topic.isAttention;
    console.log("===topicRes: ", topicRes);
    const res = await request.post("/dp/article/list", {
      orderType: "CHANNEL_NEW",
      pageNum: 1,
      pageSize: 10,
      channelIds: this.channelIds,
    });
    this.loading = false;
    this.articles = res.data.records;
    this.totalPages = res.data.pages;
  },
  // props: ["topic"],
};
</script>
<style scoped>
.topicHeadImg {
  width: 15px;
  height: 15px;
}
.topicHeadTitle.header {
  margin: 0 16px 0 32px;
}
.topicHeadBlock .clickButton {
  color: #b1b1b1;
  margin: 12px 30px 0px auto;
  width: 70px;
  height: 19px;
  display: flex;
  align-items: center;
}
@media (any-hover: hover) {
.topicHeadBlock .clickButton:hover {
  color: #9c5cf7;
}
}
.topicHeadBlock .clickButton img {
  width: 17px;
  margin-right: 6px;
}
.topicHeadBlock {
  width: 100%;
  background: var(--background-color-item);
  border-radius: 4px;
  margin-top: 12px;
  padding-top: 12px;
}
.topicHead {
  margin-top: 0;
}
.clickButton.btnClicked {
  color: #9c5cf7;
}
</style>