import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './pages/Home/index.vue'
import Search from './pages/Home/search.vue'
import Signal from './pages/Home/signal.vue'
import Topic from './pages/Home/topic.vue'
import Ency from './pages/Home/ency.vue'
import TopicSquare from './pages/Home/topicSquare.vue'
import AllEncy from './pages/Home/allEncy.vue'
import News from './pages/Home/news.vue'
import Introduce from './pages/Home/introduce.vue'
import About from './pages/Home/about.vue'
import EventWall from './pages/Home/eventWall.vue'
import SpecSub from './pages/Home/specSub.vue'
import SpecSubDet from './pages/Home/specSubDet.vue'
import PersonalPage from './pages/Home/personalPage.vue'
import Setting from './pages/Home/setting.vue'
import Pubgedmparty from './pages/Home/extenalPage/pubgedmparty.vue'
import Wlmsvote from './pages/Home/extenalPage/wlmsvote.vue'
import Dropinpuzzles from './pages/Home/extenalPage/dropinpuzzles.vue'
import Anniversarylottery from './pages/Home/extenalPage/anniversarylottery.vue'
import Midautumnblessing from './pages/Home/extenalPage/midautumnblessing.vue'
import Nominate from './pages/Home/music/nominate.vue'
import Daily from './pages/Home/music/daily.vue'
import Toplist from './pages/Home/music/toplist.vue'
import exam from './pages/Home/exam.vue'
import examSub from './pages/Home/examSub.vue'
import activity from './pages/Home/activity.vue'
import test from './pages/Home/test.vue'

Vue.use(VueRouter)


const router = new VueRouter({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/search',
            name: 'search',
            component: Search
        },
        {
            path: '/signal',
            name: 'signal',
            component: Signal
        },
        {
            path: '/topic',
            name: 'topic',
            component: Topic
        },
        {
            path: '/ency',
            name: 'ency',
            component: Ency
        },
        {
            path: '/topicSquare',
            name: 'topicSquare',
            component: TopicSquare
        },
        {
            path: '/allEncy',
            name: 'allEncy',
            component: AllEncy
        },
        {
            path: '/news',
            name: 'news',
            component: News
        },
        {
            path: '/about',
            name: 'about',
            component: About
        },
        {
            path: '/introduce',
            name: 'introduce',
            component: Introduce
        },
        {
            path: '/eventWall',
            name: 'eventWall',
            component: EventWall
        },
        {
            path: '/specSub',
            name: 'specSub',
            component: SpecSub
        },
        {
            path: '/specSubDet',
            name: 'specSubDet',
            component: SpecSubDet
        },
        {
            path: '/personalPage',
            name: 'personalPage',
            component: PersonalPage
        },
        {
            path: '/setting',
            name: 'setting',
            component: Setting
        },
        {
            path: '/pubgedmparty',
            name: 'pubgedmparty',
            component: Pubgedmparty
        },
        {
            path: '/wlmsvote',
            name: 'wlmsvote',
            component: Wlmsvote
        },
        {
            path: '/dropinpuzzles',
            name: 'dropinpuzzles',
            component: Dropinpuzzles
        },
        {
            path: '/anniversarylottery',
            name: 'anniversarylottery',
            component: Anniversarylottery
        },
        {
            path: '/midautumnblessing',
            name: 'midautumnblessing',
            component: Midautumnblessing
        },
        {
            path: '/music/nominate',
            name: 'nominate',
            component: Nominate
        },
        {
            path: '/music/daily',
            name: 'daily',
            component: Daily
        },
        {
            path: '/music/toplist',
            name: 'toplist',
            component: Toplist
        },
        {
            path: '/exam',
            name: 'exam',
            component: exam
        },
        {
            path: '/examSub',
            name: 'examSub',
            component: examSub
        },
        {
            path: '/activity',
            name: 'activity',
            component: activity
        },
        {
            path: '/redirect',
            component: () => import('@/components/Redirect.vue')
        },
        {
            path: '/test',
            name: 'test',
            component: test
        },
    ]
})

export default router