<template>
  <div class="home-wrapper" :class="{mobilePage : isMobile}">
    <web-header :switchHeight="360">
      <!-- 里面放滚动切换的内容 -->
      <div class="headSticky scrollSticky" key="scroll">
        <div class="head">
          <div class="headLeft">
            <div id="logo" @click="selectMenuItem()">
              <img class="imgCut" src="@/assets/style/DPlogo.png" alt="" />
            </div>
          </div>
          <div class="headRight">
              <div class="filterCity stickyHead">
                <div class="cityName">
                  {{ filterCityName }}
                  <img class="fold" src="@/assets/style/up@2x.png" alt="" />
                </div>
                <div class="filterPopup">
                  <div
                    class="cityItem"
                    v-for="(cityListItem, index) in cityList"
                    :key="index"
                    @click.stop="clickCity(cityListItem)"
                  >
                    {{ cityListItem.name }}
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </web-header>
    <div v-if="bannerList.length" class="banner">
      <van-swipe v-if="isMobile" class="my-swipe" :autoplay="3000" indicator-color="white" height="147">
        <van-swipe-item
          v-for="bannerItem in bannerList"
          :key="bannerItem.picture"
        >
          <img
            :src="bannerItem.picture"
            alt=""
            class="imgCut"
            @click="handleOpenUrl(bannerItem.jumpInfo.url)"
          />
        </van-swipe-item>
      </van-swipe>
      <el-carousel :interval="4000" type="card" height="252px" v-else>
        <el-carousel-item
          v-for="bannerItem in bannerList"
          :key="bannerItem.picture"
        >
          <img
            :src="bannerItem.picture"
            alt=""
            class="imgCut"
            @click="handleOpenUrl(bannerItem.jumpInfo.url)"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="wall" :class="{ bannerEmpty: !bannerList.length }">
      <div class="cityFilter">
        <div class="filterCity" @click.stop="tabHoverShow = true">
          <div class="cityName">
            {{ filterCityName }}
            <img class="fold" src="@/assets/style/up@2x.png" alt="" />
          </div>
          <div class="filterPopup" v-show="!isMobile || (isMobile && tabHoverShow)">
            <div
              class="cityItem"
              v-for="(cityListItem, index) in cityList"
              :key="index"
              @click.stop="clickCity(cityListItem)"
            >
              {{ cityListItem.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="loading" v-if="loading">
        <img class="loadingImg" src="@/assets/style/loading.gif" alt="" />
      </div>
      <div class="quizEmpty" v-if="empty">
        <img v-if="themeColor=='light'" class="searchEmptyImg" src="@/assets/style/find empty.png" alt="" />
        <img v-else class="searchEmptyImg" src="@/assets/style/empty.gif" alt="" />
        <div class="quizEmptyText">{{ emptyText }}</div>
      </div>
      <div v-infinite-scroll="loadmore" >
        <div
          @click="clickEvent(item)"
          class="item"
          v-for="(item, itemIdx) in eventList"
          :key="itemIdx"
        >
          <div class="itemContent">
            <div class="eventImg">
              <img :src="item.pic" alt="" />
              <div
                v-if="item.labelList && item.labelList.length"
                class="eventStyle"
              >
                {{ item.labelList[0] }}
              </div>
            </div>
            <div class="eventDet">
              <div class="eventAdd">
                <div class="eventCity" v-if="item.city">
                  <img src="@/assets/style/Frame 249@2x.png" alt="" />
                  {{ item.city }}
                </div>
                <div class="eventPlace textOverflow">
                  @<span v-if="item.site">{{ item.site }}</span>
                  <span v-if="item.form.includes('线上') && item.form.includes('线下')"> / </span>
                  <span v-if="item.form.includes('线上')">线上</span>
                </div>
              </div>
              <div class="eventDate textOverflow">
                <img src="@/assets/style/btn_calender@2x (1).png" alt="" />
                <template v-if="item.startTime === item.endTime">
                  {{ item.startTime }}
                </template>
                <template v-else>
                  {{ item.startTime }} - {{ item.endTime }}
                </template>
              </div>
            </div>
          </div>
          <div class="overLable" v-if="item.isEnd === 1">已结束</div>
          <div :class="{ eventOver: item.isEnd === 1 }"></div>
        </div>
        <div v-if="eventOverFinished" class="finish">- 已经到底咯 -</div>
      </div>
    </div>
    <template>
      <el-backtop target=".home-wrapper" :bottom="100" :visibility-height="100">
        <div class="backTop">UP</div>
      </el-backtop>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import { Swipe, SwipeItem } from 'vant';
import WebHeader from "@/components/WebHeader.vue";
import request from "@/utils/request";
// import {locationMobileUrl} from "@/utils/navigate";

Vue.use(Swipe);
Vue.use(SwipeItem);

export default {
  components: {
    WebHeader,
  },
  data() {
    return {
      filterCityName: "全部城市",
      cityValue: "",
      isEnd: 0,
      pageNum: 1,
      pageSize: 10,
      id: 0,
      eventList: [],
      emptyText: "没有找到相关的活动哟",
      eventIngTotal: 0,
      eventOverTotal: 0,
      loading: false,
      loadingMore: false,
      eventIngFinished: false,
      eventOverFinished: false,
      empty: false,
      ingEmpty: false,
      bannerList: [],
      cityList: [],
      tabHoverShow: false,
      themeColor: 'dark',
    };
  },
  watch: {
    '$root.themeColor': {
      immediate: true,
      handler(newVal) {
        this.themeColor = newVal;
      },
    },
  },
  mounted() {
    this.themeColor = localStorage.getItem('theme');
    this.$root.themeColor = this.themeColor;
  },
  async created() {
    // locationMobileUrl("eventWall");
    document.title = "活动墙 - DROPIN多频"
    this.loading = true;
    const bannerRes = await request.get("/dp/home/banner", {
      params: {
        position: 2,
      },
    });
    this.bannerList = bannerRes.data;
    const cityRes = await request.get("/dp/activity/getSearchParam", {
      params: {},
    });
    this.cityList = cityRes.data;
    this.getFirstPage("");
  },
  methods: {
    async getFirstPage(value) {
      this.loading = true;
      this.isEnd = 0;
      this.eventIngTotal = 0;
      this.eventOverTotal = 0;
      this.eventOverFinished = false;
      this.eventIngFinished = false;
      this.ingEmpty = false;
      this.empty = false;
      this.pageNum = 1;
      this.cityValue = value;
      const eventRes = await request.get("/dp/activity/list", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          isEnd: this.isEnd,
          city: value,
        },
      });
      this.loading = false;
      if (eventRes.code == 200 && eventRes.data) {
        this.eventList = eventRes.data.records;
        console.log("this.eventList", this.eventList);
        this.eventIngTotal = eventRes.data.total;
        if (!this.eventList || !this.eventList.length) {
          // 如果没有正在进行的活动，则走加载方法请求已结束的活动
          this.ingEmpty = true;
          this.pageNum = 0;
          this.loadmore();
          this.emptyText = "没有准备上线的活动哟";
          return;
        }
        if (this.eventList.length >= this.eventIngTotal) {
          console.log("正在进行的活动不满一页");
          this.pageNum = 0;
          this.isEnd = 1;
          this.eventIngFinished = true;
          return this.loadmore();
        } 
      }
      else {
          this.$toast("网络异常，请重试");
          this.emptyText = "网络异常，请重试";
          return (this.empty = true);
        }
    },
    clickCity(item) {
      this.filterCityName = item.name;
      this.value = item.value;
      this.tabHoverShow = false;
      this.eventList = [];
      this.getFirstPage(item.value);
    },

    // 打开活动外链页面
    handleOpenUrl(urlStr) {
      let url = urlStr;
      if (
        urlStr.toLowerCase().indexOf("https://") !== 0 &&
        urlStr.toLowerCase().indexOf("http://") !== 0 &&
        urlStr.toLowerCase().indexOf("ftp://") !== 0 &&
        urlStr.toLowerCase().indexOf("file://") !== 0
      ) {
        url = "http://" + url;
      }
      console.log("url", url);
      window.open(url);
    },
    // 统计活动点击
    clickCount(id) {
      request.get("/dp/activity/clickActivity", {
        params: {
          id: id,
        },
      });
    },
    // 点击某个活动海报
    clickEvent(item) {
      if(item.isUserUpload) {
        this.$router.push({
          name: "activity",
          query: {
            id: item.id,
          },
        });
      } else {
        this.clickCount(item.id);
        this.handleOpenUrl(item.url);
      }
    },
    // 滚动加载翻页
    async loadmore() {
      if (
        this.loadingMore ||
        this.loading ||
        this.eventOverFinished ||
        this.empty
      ) {
        return;
      }

      // 没到底则翻页拿正在进行的数据
      if (!this.eventIngFinished && !this.ingEmpty) {
        this.pageNum++;
        this.loadingMore = true;
        const eventIngRes = await request.get("/dp/activity/list", {
          params: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            isEnd: this.isEnd,
            city: this.value,
          },
        });
        if (eventIngRes.code == 200 && eventIngRes.data) {
          this.eventList = this.eventList.concat(eventIngRes.data.records);
          this.eventIngTotal = eventIngRes.data.total;
          this.loadingMore = false;
          // 判断正在进行的活动是否到底
          if (this.eventList.length >= this.eventIngTotal) {
            this.isEnd = 1;
            this.pageNum = 0;
            this.eventIngFinished = true;
          }
        } else {
          this.$toast("网络异常，请重试");
        }
      }
      // 到底则拿已结束的活动
      else {
        this.isEnd = 1;
        this.pageNum++;
        this.loadingMore = true;
        const eventOverRes = await request.get("/dp/activity/list", {
          params: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            isEnd: this.isEnd,
            city: this.value,
          },
        });
        if (eventOverRes.code == 200 && eventOverRes.data) {
          this.eventList = this.eventList.concat(eventOverRes.data.records);
          this.loadingMore = false;
          this.eventOverTotal = eventOverRes.data.total;
          if (!this.eventList.length) {
            this.emptyText = "没有找到活动信息哟";
            return (this.empty = true);
          }
          else if (
            this.eventList.length >=
            this.eventIngTotal + this.eventOverTotal
          ) {
            return (this.eventOverFinished = true);
          }
        } else {
          this.$toast("网络异常，请重试");
        }
      }
    },
  },
};
</script>


<style scoped>
.imgCut {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner {
  /*background: #1f1f1f;*/
  background: var(--background-color-item);
  padding: 90px 0 12px 0;
  min-width: 1200px;
}
.wall {
  width: 100%;
  max-width: 1220px;
  margin: 7px auto 20px auto;
  min-height: 70vh;
}
.wall.bannerEmpty {
  margin-top: 70px;
}
.item {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.eventImg {
  width: 100%;
  height: 281px;
  border-radius: 4px 4px 0 0;
  position: relative;
  overflow: hidden;
}
.eventImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.itemContent {
  display: flex;
  flex-direction: column;
  width: 187px;
  margin: 8px;
  font-size: 13px;
}
.eventStyle {
  font-size: 12px;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  position: absolute;
  right: 8px;
  top: 9px;
  padding: 4px;
  color: #ffffff;
}
.eventDet {
  padding: 14px 10px;
  color: var(--font-color);
  background: var(--background-color-item);
  border-radius: 0px 0px 4px 4px;
}
.eventDet img {
  width: 12px;
}

.eventAdd {
  display: flex;
}
.eventCity {
  white-space: nowrap;
  margin-right: 4px;
}
.eventPlace {
  color: var(--color-50);
}
.eventDate {
  font-size: 12px;
  margin-top: 9px;
}
.eventOver {
  position: absolute;
  top: 0;
  left: 0;
  width: 187px;
  height: 351px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  margin: 8px;
  overflow: hidden;
}
.textOverflow {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
}
.overLable {
  position: absolute;
  top: 134px;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  background: #000000;
  border-radius: 4px;
  padding: 4px 6px;
  font-size: 12px;
  color: #fff;
}


.finish {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  color: #a264ff;
  font-size: 12px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  margin: 0;
  opacity: 0.75;
  cursor: pointer;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.el-carousel__mask {
  background-color: #000000;
  opacity: 0.4;
}
.el-carousel {
  width: 1200px;
  margin: 0 auto;
}
.el-carousel__indicators--outside {
  margin-top: 12px;
}
.el-carousel__item--card.is-in-stage {
  border-radius: 4px;
  overflow: hidden;
  width: 593px;
}
.cityFilter {
  width: 1200px;
  background: var(--background-color-item);
  height: 48px;
  display: flex;
  align-items: center;
  margin: auto;
  border-radius: 4px;
}
.filterCity {
  font-size: 14px;
  margin-right: 30px;
  margin-left: auto;
  position: relative;
}
.cityName {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 3px 3px 0 0;
}
.fold {
  width: 6px;
  height: 4px;
  margin-left: 4px;
  transform: rotate(180deg);
}
.filterPopup {
  display: none;
  position: absolute;
  right: 0;
  width: 406px;
  z-index: 100;
  padding: 12px 20px;
  /*background: #313131;*/
  background: var(--tab-background-color);
  border-radius: 3px 0 3px 3px;
  flex-wrap: wrap;
}
.cityItem {
  margin-left: 10px;
  height: 32px;
  color: var(--color-50);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 56px;
  cursor: pointer;
}
@media (any-hover: hover) {
.filterCity:hover {
  background: var(--tab-background-color);
}
}
.cityName:hover .fold {
  transform: rotate(360deg);
}
.filterCity:hover .filterPopup {
  display: flex;
}
.cityItem:hover {
  font-weight: 700;
  color: #9c5cf7;
}
.filterPopup:hover {
  display: flex;
}
/* 移动端适配样式 */
.mobilePage .el-carousel {
  width: auto;
}
.mobilePage .wall {
  max-width: none;
  min-width: 280px;
  padding : 0;
}
.mobilePage .cityFilter {
  width: auto;
}
.mobilePage .itemContent {
  width: auto;
}
.mobilePage .item {
  width: 48%;
  margin-left: 6px;
}
.mobilePage .eventImg {
  height: 255px;
}
.mobilePage .headSticky {
  min-width: 280px;
}
.mobilePage .filterCity {
  margin-right: 14px;
}
.mobilePage .filterPopup {
  width: 200px;
}
.mobilePage .banner {
  background: none;
  padding-top: 70px;
  min-width: 280px;
}
.my-swipe.van-swipe {
      width: 350px;
    margin: 0 auto;
    border-radius: 4px;
}
.van-swipe-item {
  width: 350px !important;
}
.mobilePage .quizEmptyImg {
  margin-top: 80px;
}
</style>

<style>
  .quizEmpty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.quizEmptyText {
  color: #dec7ff;
  margin: 10px 0;
  font-size: 13px;
}
.quizEmptyImg {
  width: 150px;
  margin-top: 260px;
  /* margin-bottom: 16px; */
}
</style>
