<template>
  <div
    :class="{ mobilePage: isMobile, appPage: isApp }"
    class="home-wrapper scrollContainer"
    ref="scrollContainer"
  >
    <web-header v-if="!isApp" :disabledScroll="true" />
    <div :class="{ pcPage: !isMobile }">
      <div class="webPage pageDet">
        <div class="webPageBanner musicBanner">
          <img class="imgCut" src="@/assets/style/国电周榜_画板 1.png" alt="" />
          <div class="coinRule" @click="ruleShow = true">
            多频币规则<img
              style="width: 13px"
              src="@/assets/style/Slice 2@2x.png"
              alt=""
            />
          </div>
          <div class="dropinCoin" @click="ruleShow = true">
            <div class="coinData">
              <div>本周待发</div>
              <div class="coinNum">
                <img
                  class="coinImg"
                  src="@/assets/style/idea 1 1@2x.png"
                  alt=""
                /><span>4000</span>
              </div>
            </div>
            <div class="coinData">
              <div>已拥有</div>
              <div class="coinNum">
                <img
                  class="coinImg"
                  src="@/assets/style/idea 1 1@2x.png"
                  alt=""
                /><span>{{ coinsNum }}</span>
              </div>
            </div>
          </div>
        </div>
        <template v-if="isMobile">
          <div
            style="height: 38px; font-size: 14px; margin: 10px 20px 0 auto"
            v-if="voteInfo.flag == 1"
          >
            本周剩余
            <span style="font-size: 24px; color: #9c5cf7">{{
              remainVotes
            }}</span>
            次增益
          </div>
          <div
            style="font-size: 14px; margin: 10px 20px 0 auto"
            v-else-if="voteInfo.flag == 0"
          >
            榜单评选结果公示中
          </div>
        </template>
        <div class="sortTypeBlock">
          <el-select
            v-model="voteInfo"
            placeholder=""
            value-key="num"
            @change="getMusicVoteList(voteInfo, sortType.id)"
          >
            <div>
              <el-option
                :label="`第${voteItem.num}期 (${voteItem.nominateStartDate}-${voteItem.nominateEndDate})`"
                :value="voteItem"
                v-for="(voteItem, idx) in voteFilter"
                :key="idx"
                :disabled="voteItem.flag == 2"
                ><span
                  @click="disabledTips(voteItem.flag)"
                  style="float: left"
                  >{{
                    `第${voteItem.num}期 (${voteItem.nominateStartDate}-${voteItem.nominateEndDate})`
                  }}</span
                >
                <span
                  class="voteFlag"
                  @click="disabledTips(voteItem.flag)"
                  :style="`background:${getFlagColor(voteItem.flag)};`"
                  >{{ flagTextType[voteItem.flag] }}</span
                >
              </el-option>
            </div>
            <div slot="prefix">
              <span
                class="voteFlag"
                :style="`background:${getFlagColor(voteInfo.flag)};`"
                >{{ flagTextType[voteInfo.flag] }}</span
              >
            </div>
          </el-select>
          <div class="sortRightBlock">
            <div class="sortType" @click.stop="sortHoverShow = true">
              {{ sortType.name }}<img src="@/assets/style/down.png" alt="" />
              <div class="sortTypeHover" v-if="!isMobile || sortHoverShow">
                <div
                  class="sortTypeItem"
                  @click.stop="switchSortType({ name: '增益数', id: 0 })"
                >
                  增益数
                </div>
                <div
                  class="sortTypeItem"
                  @click.stop="switchSortType({ name: '首字母', id: 1 })"
                >
                  首字母
                </div>
              </div>
            </div>
            <template v-if="!isMobile">
              <div v-if="voteInfo.flag == 1" style="height: 38px">
                本周剩余
                <span style="font-size: 24px; color: #9c5cf7">{{
                  remainVotes
                }}</span>
                次增益
              </div>
              <div v-else-if="voteInfo.flag == 0">榜单评选结果公示中</div>
            </template>
          </div>
        </div>
        <div class="musicVoteList">
          <div
            v-for="(item, idx) in musicVoteList"
            :key="idx"
            class="voteItemBack"
            :class="{
              backColor: item.rank <= 3 && item.rank > 0,
            }"
          >
            <div class="musicVoteItem" @click.stop="clickVoteItem(idx)">
              <div v-if="voteInfo.flag == 0">
                <img
                  style="position: absolute; width: 65px; top: 0"
                  :src="getRankImg(item.rank)"
                  alt=""
                />
              </div>
              <div
                class="musicRank"
                :style="`color:${getRankColor(item.rank)};`"
              >
                <template
                  v-if="
                    item.rank > 3 || (voteInfo.flag !== 0 && item.rank != 0)
                  "
                  >{{ item.rank }}</template
                >
                <template v-else-if="item.rank == 0">-</template>
                <template v-else>&nbsp;&nbsp;&nbsp;</template>
              </div>
              <div class="musicCover">
                <img class="imgCut" :src="item.picture" alt="" />
              </div>
              <div class="musicInfo">
                <div class="musicName textOverflow">{{ item.name }}</div>
                <div class="musicArtist textOverflow">{{ item.artist }}</div>
                <div class="musicLinkList">
                  <img
                    v-for="(musicLinkItem, idx) in item.relatedLinkList"
                    :key="idx"
                    class="musicLinkItem"
                    @click.stop="handleOpenUrl(musicLinkItem.url)"
                    :src="musicLinkItem.logo"
                  />
                </div>
              </div>
              <div class="voteBox">
                <div class="voteNum">已获{{ item.voteCount }}增益</div>
                <div
                  v-if="voteInfo.flag == 1"
                  class="voteBtn"
                  @click.stop="clickVote(item)"
                >
                  增益
                </div>
              </div>
            </div>
            <MusicVoteDialog
              v-if="activeDialogIdx === idx"
              ref="musicVoteDialog"
              :item="item"
              :flag="voteInfo.flag"
              :key="idx"
              :voteId="voteInfo.voteId"
              @voteHandler="localReduce"
              @close="handleCloseDialog"
            />
          </div>
          <el-dialog
            class="ruleDialog"
            title="多频币发放规则"
            :visible.sync="ruleShow"
            center
          >
            <span>
              1、每期榜单评选结果公布后，多频将向眼光独到的参与提名和增益投票的用户发放多频币奖励。多频币将可在兑换商店开启后，<b>兑换演出门票、唱片、周边、现金红包以及实物礼品</b>，以激励热心支持国内电子音乐的社区用户。<br />
              2、提名奖励：每期榜单评选中获得前三名（含并列名次）的作品，其首位提名用户，可以瓜分如下数量的多频币。<b>第一名：500多频币，第二名：300多频币，第三名：200多频币</b>。若名次并列，则一并瓜分所属名次及所占据名次的奖励。<br />
              3、增益奖励：每期榜单评选中获得前三名（含并列名次）的作品，前30%对其进行增益的用户，可以瓜分如下数量的多频币。<b>第一名：1500多频币，第二名：1000多频币，第三名：500多频币。</b>若名次并列，则一并瓜分所属名次及所占据名次的奖励。<br />
              4、每期榜单发放的多频币奖励将可能因特殊活动或节日有所不同，具体发放数量请留意榜单页面上方头图处公布的信息。<br />
              5、多频币的最小单位为1枚，当获得的奖励多频币出现小数位时将自动向下取整。<br />
              6、其他获得多频币的渠道将在国庆过后陆续开放，敬请期待！
            </span>
            <span slot="footer" class="dialog-footer"> </span>
          </el-dialog>
        </div>
        <div class="voteRule">
          <div>榜单规则：</div>
          <div v-html="webInfo.rule"></div>
        </div>
        <!-- 去考试弹窗 -->
        <el-dialog :visible.sync="toExamDialog" class="exam_1" center :show-close="false" width="320px" :close-on-click-modal="false" :close-on-press-escape="false" top="40vh">
          <span style="font-size: 16px; font-weight: 400;">暂时还没有发布权限<br/>需要先通过电音小测试才能开通</span>
          <span slot="footer" class="dialog-footer">
            <el-button style="background-color:transparent; color: #9c5cf7; border: 1px solid #9c5cf7; border-radius: 43px; cursor: pointer;"
              @click="toExamDialog = false">下次再说</el-button>
            <el-button type="primary" style="border-radius: 43px; cursor: pointer; marign-left: 20px;" @click="toExam()">放马过来!</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import WebHeader from "@/components/WebHeader.vue";
import { mapGetters } from "vuex";
import { isAppFun } from "@/utils/judgeClient";
import dayjs from "dayjs";
import request from "@/utils/request";
import MusicVoteDialog from "@/components/MusicVoteDialog.vue";
import { openUrl } from "@/utils/navigate";
export default {
  components: {
    WebHeader,
    MusicVoteDialog,
  },
  data() {
    return {
      coinsNum: 0,
      ruleShow: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        firstDayOfWeek: 1,
      },
      dateValue: dayjs().format("YYYY-MM-DD"),
      isApp: false,
      musicVoteList: [],
      sortType: {
        id: 0,
        name: "增益数",
      },
      remainVotes: 0,
      voteFilter: [],
      voteInfo: { flag: 0 },
      sortHoverShow: false,
      flagTextType: {
        0: "已结束",
        1: "进行中",
        2: "提名中",
      },
      webInfo: {},
      // isMonday: dayjs().day() === 1,
      activeDialogIdx: -1,
      toExamDialog: false,
    };
  },
  computed: {
    ...mapGetters(["isLogin"]),
  },
  watch: {
    isLogin: {
      immediate: true,
      handler(val, oldVal) {
        // 从false变成true, 拉一下活动信息查询
        if (!oldVal && val) {
          this.getVoteFilter();
        }
      },
    },
  },
  async created() {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      // 当前设备是移动设备
      this.isApp = isAppFun();
    }
    this.getWebInfo();
    this.getVoteFilter();
  },
  methods: {
    handleOpenUrl(urlStr) {
      openUrl(urlStr, this.isApp);
    },
    localReduce(voteSuccess) {
      if (voteSuccess) {
        this.remainVotes--;
      }
    },
    // 获取活动相关信息
    async getWebInfo() {
      const webInfoRes = await request.get("/dp/home/getActivityWeb", {
        params: {
          awId: 4,
        },
      });
      if (webInfoRes.code == 200) {
        document.title = webInfoRes.data.name;
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", webInfoRes.data.description);
        this.webInfo = webInfoRes.data;
      }
    },
    disabledTips(flag) {
      if (flag == 2) {
        this.$message("该期提名中，请前往页面下方提名入口参与");
      }
    },
    async getVoteFilter() {
      const voteFilterRes = await request.get("/dp/vote/getMusicVoteFilter", {
        params: {
          awId: 4,
        },
      });
      if (voteFilterRes.code == 200) {
        this.voteFilter = voteFilterRes.data.voteFilter;
        // 活动默认定位链接指定的期数或在进行中的期数
        if (this.$route.query.num) {
          this.voteInfo = this.voteFilter.find(
            (item) => item.num == this.$route.query.num
          );
          if (!this.voteInfo) {
            this.voteInfo = this.voteFilter.find((item) => item.flag == 1);
            if (!this.voteInfo) {
              this.voteInfo = this.voteFilter[1];
            }      
          }
        } else {
          this.voteInfo = this.voteFilter.find((item) => item.flag == 1);
          if (!this.voteInfo) {
              this.voteInfo = this.voteFilter[1];
            }   
        }
        this.getMusicVoteList(this.voteInfo, this.sortType.id);
        if (this.isLogin) {
          this.remainVotes = voteFilterRes.data.remainVotes;
          this.coinsNum = voteFilterRes.data.coinsNum;
        }
      }
    },
    async getMusicVoteList(item, type) {
      if (item.flag == 2) {
        return;
      }
      const musicListRes = await request.post("/dp/vote/musicVoteList", {
        voteId: item.voteId,
        sortType: type,
      });
      if (musicListRes.code == 200) {
        this.musicVoteList = musicListRes.data;
      }
    },
    getRankImg(rank) {
      const rankImgMap = {
        1: require("@/assets/style/NO1.png"),
        2: require("@/assets/style/NO2.png"),
        3: require("@/assets/style/NO3.png"),
      };
      return rankImgMap[rank];
    },
    getRankColor(rank) {
      const colorMap = {
        1: "#FF9900",
        2: "#FF00F5",
        3: "#00B6FF",
      };
      return colorMap[rank] || "#fff"; // 其他名次的颜色
    },
    getFlagColor(flag) {
      const colorMap = {
        2: "#FF358D",
        1: "#9C5CF7",
        0: "#999999",
      };
      return colorMap[flag] || "transparent"; // 其他名次的颜色
    },
    clickVoteItem(idx) {
      // this.$refs["musicVoteDialog"][idx].musicDialogVisible = true;
      this.activeDialogIdx = idx
    },
    switchSortType(type) {
      this.sortHoverShow = false;
      this.sortType = type;
      this.musicVoteList = [];
      this.getMusicVoteList(this.voteInfo, this.sortType.id);
    },
    async clickVote(voteItem) {
      if (!this.isLogin) {
        if (!this.isApp) {
          this.$login();
          return;
        } else
          this.$message({
            message: "请登录后再进入该页面重试",
          });
      }
      const voteRes = await request.post("/dp/vote/musicVote", {
        voteId: this.voteInfo.voteId,
        vsId: voteItem.vsId,
      });
      if (voteRes.code == 200) {
        voteItem.voteCount++;
        this.remainVotes--;
        this.$message({
          message: "增益成功",
          type: "success",
        });
      }else if(voteRes.code == 10003) {
        if (this.isApp) {
          this.$message({
            message: "暂时没有增益权限，请前往个人主页-我的频谱，通过任一知识频谱考试后再重试",
          });
        }else {
          this.toExamDialog = true;
        }
      }
    },
    handleCloseDialog() {
      this.activeDialogIdx = -1
    },
    toExam(){
      window.open(`/#/exam`)
      this.toExamDialog = false;
    },
  },
};
</script>

<style scoped>
.el-select {
  width: 250px;
}
.sortTypeBlock {
  margin-top: 10px;
  padding: 0;
  width: 700px;
}
.dateEditor {
  width: 100%;
  margin-top: 16px;
  position: sticky;
  top: 61px;
  z-index: 99;
  background: var(--background-color);
}
.mobilePage .dateEditor {
  top: 50px;
}
.musicVoteList {
  width: 100%;
  margin-bottom: 20px;
}
.musicVoteItem {
  width: 700px;
  height: 108px;
  /*background: #1f1f1f;*/
  background: var(--background-color);
  border: 5px solid var(--background-color-item-hover);
  border-radius: 6px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -1px 0px 0px rgba(255, 255, 255, 0.25);
  margin-top: 12px;
  position: absolute;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 13px;
}
.voteItemBack {
  position: relative;
  width: 662px;
  height: 62px;
  margin-bottom: 62px;
}
.backColor {
  margin-bottom: 70px;
}
.backColor::before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  filter: blur(6px);
  z-index: -1;
  background: linear-gradient(
    90deg,
    #ff9900,
    rgba(255, 0, 245, 1) 50%,
    #00b6ff 100%
  );
  left: 18px;
  top: 62px;
  border-radius: 6px;
}
.musicVoteItem .musicCover {
  width: 70px;
  height: 70px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
/* .musicVoteItem .musicLinkItem {
  width: 18px;
  height: 18px;
} */
.musicVoteItem .musicInfo {
  line-height: 24px;
  max-width: 350px;
}
.musicVoteItem .musicArtist {
  line-height: 17px;
  font-weight: 300;
}
.musicVoteItem .musicLinkList {
  margin-top: 9px;
}
.musicRank {
  margin: 0 24px;
  font-size: 16px;
  font-weight: 700;
}
.voteBox {
  margin: 0 21px 0 auto;
  flex-shrink: 0;
}
.musicVoteItem .voteNum {
  margin-bottom: 20px;
  color: var(--color-70);
}
.musicVoteItem .voteBtn {
  box-sizing: border-box;
  width: 58px;
  margin-left: auto;
}
.mobilePage .musicVoteList {
  width: 95%;
  margin: 0 auto;
}
.mobilePage .voteItemBack {
  width: 90%;
}
.mobilePage .musicVoteItem {
  width: 112%;
}
.mobilePage .musicRank {
  margin: 0 14px;
}
.mobilePage .sortTypeBlock {
  width: 94%;
}
.sortRightBlock {
  display: flex;
  margin-left: auto;
  flex-wrap: wrap;
  align-items: center;
}
.sortType {
  margin-right: 24px;
}
.mobilePage .sortType {
  margin-right: 0;
}
.webPageBanner.musicBanner {
  position: relative;
  height: auto;
}
.dropinCoin {
  position: absolute;
  color: #efe397;
  top: 96px;
  left: 63px;
  font-size: 13px;
  font-weight: 600;
  line-height: 26px;
}
.coinImg {
  width: 20px;
  flex-shrink: 0;
}
.mobilePage .coinImg {
  width: 12px;
}
.coinNum {
  display: flex;
  justify-content: center;
  font-size: 14px;
  align-items: center;
}
.coinRule {
  position: absolute;
  top: 56px;
  left: 84px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.mobilePage .coinRule {
  top: 13.4%;
  left: 7%;
  font-size: 9px;
}
.coinData + .coinData {
  margin-top: 18px;
}
.mobilePage .dropinCoin {
  top: 37%;
  left: 7%;
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
}
.mobilePage .coinData + .coinData {
  margin-top: 9px;
}
.mobilePage .coinNum {
  font-size: 12px;
}
.el-button+.el-button {
    margin-left: 20px;
}
</style>

<style>
.sortTypeBlock .el-input__prefix {
  left: auto;
  right: 30px;
  top: 12px;
  line-height: 16px;
  height: 16px;
}
.voteFlag {
  background: #9c5cf7;
  color: #fff;
  padding: 0 4px 1px 4px;
  border-radius: 4px;
  margin-left: 7px;
  font-size: 11px;
}
.sortTypeBlock .el-input--prefix .el-input__inner {
  padding: 0 14px;
}
.sortTypeBlock .el-select-dropdown__item {
  padding: 0 14px;
}
.el-select-dropdown__item.is-disabled {
  color: var(--font-color);
}
.el-select-dropdown__item.is-disabled:hover {
  background: var(--background-color-item-hover);
}
.mobilePage .ruleDialog .el-dialog {
  width: 330px;
  height: 400px;
}
.pcPage .ruleDialog .el-dialog {
  width: 500px;
  height: 600px;
}
.mobilePage .ruleDialog .el-dialog--center .el-dialog__body {
  overflow: auto;
  height: 300px;
  padding: 8px 20px;
}
.ruleDialog {
  color: #d9d9d9;
}
</style>
