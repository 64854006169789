<template>
  <div class="home-wrapper personalPage" :class="{ mobilePage: isMobile }">
    <web-header :isMine="isMine">
      <!-- 里面放滚动切换的内容 -->
      <div class="headSticky scrollSticky">
        <div class="head">
          <div class="headLeft">
            <div class="detailTitle textOverflow scrollSticky">
              {{ userInfo.nickname }} 的个人主页
            </div>
          </div>
          <div class="headRight"></div>
        </div>
      </div>
    </web-header>
    <div class="content">
      <div class="left">
        <div class="personalBlock">
          <div class="flex">
            <div class="relative">
              <div class="personalAvatar">
                <img
                  v-if="userInfo && userInfo.avatar"
                  class="imgCut"
                  :src="userInfo.avatar"
                  alt=""
                />
                <img
                  v-else
                  class="imgCut"
                  src="@/assets/style/img_portrait@2x.png"
                  alt=""
                />
                <img
                  v-if="userInfo.isRealOfficial === 1"
                  class="detailUserTypeImg"
                  src="@/assets/style/icon_vip2_big.png"
                  alt=""
                />
                <img
                  v-else-if="userInfo.isOfficial === 1"
                  class="detailUserTypeImg"
                  src="@/assets/style/icon_vip_big.png"
                  alt=""
                />
              </div>
            </div>
            <div class="personalInfo">
              <div class="personalName">
                {{ userInfo.nickname }}
                <img :src="userInfo.expLogo" alt="" /><img
                  v-if="userInfo.gender == 1"
                  src="@/assets/style/icon_male@2x.png"
                  alt=""
                /><img
                  v-else-if="userInfo.gender == 2"
                  src="@/assets/style/icon_female@2x.png"
                  alt=""
                />
              </div>
              <div v-if="userInfo.officialInfo" class="identify">
                {{ userInfo.officialInfo }}
              </div>
              <div v-else class="identify">DROPUCER发电者</div>
            </div>
            <!-- <div v-if="isMine" class="creatorBtn" @click="toCreator()">
              创作后台 <img src="@/assets/style/btn_backward@2x.png" alt="" />
            </div> -->
            <span
              v-if="!isMine"
              class="clickButton followBtn"
              @click="clickFollow()"
              :class="{ followedBtn: isAttention }"
            >
              {{ followText }}
            </span>
            <CancelFollow
              ref="followDialog"
              :member="userInfo"
              @cancelHandler="localCancel"
            />
          </div>
          <div class="personalData">
            <b>{{ userInfo.fansNum }}</b> 粉丝 ｜
            <b>{{ userInfo.idolNum }}</b> 关注 ｜ 收获
            <b>{{ userInfo.praiseNum }}</b> 点头 ·
            <b>{{ userInfo.collectNum }}</b> 捕捉
          </div>
          <div v-if="userInfo.descriptions" class="introduce">
            简介：{{ userInfo.descriptions }}
          </div>
        </div>
        <div class="tabSticky">
          <div
            class="articleTab"
            :class="{ halfBottom: tab === 'catch' || tab === 'follow' }"
          >
            <div
              class="tab"
              :class="{
                selected: tab === 'publish',
                unselect: tab !== 'publish',
              }"
              @click="switchTab('publish')"
            >
              <div>发布({{ userInfo.articleNum }})</div>
              <div v-if="tab === 'publish'" class="selectedIcon"></div>
            </div>
            <div
              class="tab"
              :class="{
                selected: tab === 'catch',
                unselect: tab !== 'catch',
              }"
              @click="switchTab('catch')"
            >
              <div>捕捉</div>
              <div v-if="tab === 'catch'" class="selectedIcon"></div>
            </div>
            <div
              class="tab"
              :class="{
                selected: tab === 'follow',
                unselect: tab !== 'follow',
              }"
              @click="switchTab('follow')"
            >
              <div>关注</div>
              <div v-if="tab === 'follow'" class="selectedIcon"></div>
            </div>
          </div>
          <div class="articleTab secondTab" v-if="tab === 'catch'">
            <div
              class="tab"
              :class="{
                selected: secondTab === 'topic',
                unselect: secondTab !== 'topic',
              }"
              @click="switchSecondTab('catch', 'topic')"
            >
              频段
            </div>
            <div
              class="tab"
              :class="{
                selected: secondTab === 'signal',
                unselect: secondTab !== 'signal',
              }"
              @click="switchSecondTab('catch', 'signal')"
            >
              信号
            </div>
            <div
              class="tab"
              :class="{
                selected: secondTab === 'ency',
                unselect: secondTab !== 'ency',
              }"
              @click="switchSecondTab('catch', 'ency')"
            >
              百科
            </div>
          </div>
          <div class="articleTab secondTab" v-if="tab === 'follow'">
            <div
              class="tab"
              :class="{
                selected: secondTab === 'following',
                unselect: secondTab !== 'following',
              }"
              @click="switchSecondTab('follow', 'following')"
            >
              关注
            </div>
            <div
              class="tab"
              :class="{
                selected: secondTab === 'followers',
                unselect: secondTab !== 'followers',
              }"
              @click="switchSecondTab('follow', 'followers')"
            >
              粉丝
            </div>
          </div>
        </div>
        <div class="loading" v-if="loading">
          <img class="loadingImg" src="@/assets/style/loading.gif" alt="" />
        </div>
        <div v-infinite-scroll="loadmore">
          <template v-if="tab == 'publish' && articles.length">
            <DropinArticle
              v-for="(item, idx) in articles"
              :item="item"
              :key="idx"
            />
          </template>
          <template v-else-if="tab == 'catch'">
            <template v-if="secondTab == 'topic'">
              <TopicSearch
                v-for="(item, idx) in catchList"
                :topicSearchItem="item"
                :key="idx"
              />
            </template>
            <template v-else-if="secondTab == 'signal'">
              <DropinArticle
                v-for="(item, idx) in catchList"
                :item="item"
                :key="idx"
              />
            </template>
            <template v-else-if="secondTab == 'ency'">
              <EncySearch
                v-for="(item, idx) in catchList"
                :encySearchItem="item"
                :key="idx"
              />
            </template>
          </template>
          <template v-else-if="tab == 'follow'">
            <template v-if="secondTab == 'following'"
              ><UserItem
                v-for="(item, idx) in followList"
                :userItem="item"
                :key="idx"
              />
            </template>
            <template v-else-if="secondTab == 'followers'"
              ><UserItem
                v-for="(item, idx) in followList"
                :userItem="item"
                :key="idx"
              />
            </template>
          </template>
          <div
            v-if="loadingMore"
            class="loadmore loading"
            :style="{ visibility: loadingMore ? 'visible' : 'hidden' }"
          >
            <img class="loadingImg" src="@/assets/style/loading.gif" alt="" />
          </div>
        </div>
        <div v-if="empty" class="searchEmpty">
          <img v-if="themeColor=='light'" class="searchEmptyImg" src="@/assets/style/find empty.png" alt="" />
          <img v-else class="searchEmptyImg" src="@/assets/style/empty.gif" alt="" />
          <div class="searchEmptyText">很可惜，没有找到相关信息</div>
        </div>
      </div>
      <div class="right" v-if="!isMobile">
        <!-- 创作后台 -->
        <div class="rightField" v-if="isMine">
          <div class="rightTab halfBottom">
            <div class="rightTabText">创作后台</div>
          </div>
          <div class="creatorFlex">
            <div>
              <div
                class="creatorBtn"
                style="background: rgba(255, 53, 141, 0.2)"
                @click="toCreatorAddDialog('signal')"
              >
                <img src="@/assets/style/icon_signal@2x.png" alt="" />
              </div>
              发信号
            </div>
            <div>
              <div
                class="creatorBtn"
                style="background: rgba(77, 145, 174, 0.2)"
                @click="toCreatorAddDialog('ency')"
              >
                <img src="@/assets/style/icon_creator_pedia@2x.png" alt="" />
              </div>
              编辑百科
            </div>
            <div>
              <div
                class="creatorBtn"
                style="background: rgba(156, 92, 247, 0.2)"
                @click="toCreatorAddDialog('topic')"
              >
                <img src="@/assets/style/icon_creator_topic@2x.png" alt="" />
              </div>
              新建频段
            </div>

            <div>
              <div
                class="creatorBtn"
                style="background: rgba(255, 181, 8, 0.2)"
                @click="toCreatorAddDialog('quiz')"
              >
                <img src="@/assets/style/icon_creator_qa@2x.png" alt="" />
              </div>
              新建频一频
            </div>
          </div>
          <div class="toCreatorBtn pointer" @click="toCreatorAddDialog()">
            进入创作后台 >
          </div>
        </div>
        <div class="sticky">
          <div class="personal download">
            <div>消息、浏览历史、修改个人信息等更多设置及功能</div>
            <div>请到APP里查看并操作</div>
            <div>立即下载 <b>DROPIN多频</b> APP</div>
            <div>为了电音</div>
            <div class="QRcode">
              <div class="QRcodeImg">
                <img class="imgCut" src="@/assets/style/安卓 1@2x.png" alt="" />
              </div>
              <div class="QRcodeImg">
                <img class="imgCut" src="@/assets/style/ios 1@2x.png" alt="" />
              </div>
            </div>
          </div>
          <!-- 以下是网站信息 -->
          <FirstFooter />
        </div>
      </div>
    </div>
    <template>
      <el-backtop target=".home-wrapper" :bottom="100" :visibility-height="100">
        <div class="backTop">UP</div>
      </el-backtop>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import WebHeader from "@/components/WebHeader.vue";
import FirstFooter from "@/components/FirstFooter.vue";
import UserItem from "@/components/UserItem.vue";
import CancelFollow from "@/components/CancelFollow.vue";
import request from "@/utils/request";
import DropinArticle from "@/components/DropinArticle.vue";
import TopicSearch from "@/components/TopicSearch";
import EncySearch from "@/components/EncySearch";
import { jumpToCreatorAndAdd } from "@/utils/navigate";

export default {
  components: {
    WebHeader,
    FirstFooter,
    UserItem,
    CancelFollow,
    DropinArticle,
    EncySearch,
    TopicSearch,
  },
  data() {
    return {
      publishNum: 0,
      tab: "publish",
      secondTab: "",
      myContent: false,
      followText: "+关注",
      userId: 0,
      memberId: 0,
      pageNum: 1,
      total: 1,
      userInfo: {},
      articles: [],
      loading: false,
      loadingMore: false,
      empty: false,
      finish: false,
      dropinType: 1,
      catchList: [],
      followList: [],
      followRequest: "/dp/fans/fansList",
      isAttention: false,
      themeColor: 'dark',
    };
  },
  computed: {
    ...mapGetters(["nickname", "avatar", "id", "isLogin"]),
    isMine() {
      return this.userId == this.id;
    },
  },
  watch: {
    '$root.themeColor': {
      immediate: true,
      handler(newVal) {
        this.themeColor = newVal;
      },
    },
    isAttention(val) {
      if (val) {
        this.followText = "已关注";
      } else this.followText = "+关注";
    },
  },
  mounted() {
    this.themeColor = localStorage.getItem('theme');
    this.$root.themeColor = this.themeColor;
  },
  async created() {
    this.loading = true;
    this.userId = parseInt(this.$route.query.userId);
    const userInfoRes = await request.get("/dp/member/userInfo", {
      params: {
        memberId: this.userId,
      },
    });
    this.userInfo = userInfoRes.data;
    this.isAttention = this.userInfo.isAttention;
    document.title = `${this.userInfo.nickname} 的个人主页 - DROPIN多频`;
    this.getPublishList();
  },
  methods: {
    toCreatorAddDialog(type) {
      jumpToCreatorAndAdd(type);
    },
    localCancel(confirmCancel) {
      if (confirmCancel) {
        this.isAttention = false;
      }
    },
    async getPublishList() {
      if (!this.$route.query.userId || this.tab !== "publish") {
        return;
      }
      const publishRes = await request.post("/dp/article/list", {
        orderType: "NEW_CREATE",
        pageNum: this.pageNum,
        pageSize: 10,
        memberId: this.userId,
      });
      this.loading = false;
      this.loadingMore = false;
      if (this.pageNum == 1) {
        this.total = publishRes.data.total;
      }
      this.articles = this.articles.concat(publishRes.data.records);
      if (this.total == 0 && !this.articles.length) {
        return (this.empty = true);
      } else if (
        this.articles.length >= this.total ||
        !publishRes.data.records.length
      ) {
        return (this.finish = true);
      }
    },
    async getCatchList(secondTab) {
      if (!this.$route.query.userId || this.tab !== "catch") {
        return;
      }
      if (secondTab == "signal") {
        this.dropinType = 1;
      } else if (secondTab == "topic") {
        this.dropinType = 3;
      } else if (secondTab == "ency") {
        this.dropinType = 10;
      }
      if (this.isMine) {
        this.memberId = null;
      } else this.memberId = this.userId;
      const catchRes = await request.get("/dp/collect/queryPage", {
        params: {
          dropinType: this.dropinType,
          pageNum: this.pageNum,
          pageSize: 10,
          memberId: this.memberId,
        },
      });
      this.loading = false;
      this.loadingMore = false;
      if (this.pageNum == 1 && catchRes.data.records) {
        if (catchRes.data.total == 0 || !catchRes.data.records.length) {
          return (this.empty = true);
        }
        this.total = catchRes.data.total;
      }
      this.catchList = this.catchList.concat(catchRes.data.records);
      if (
        this.catchList.length >= this.total ||
        !catchRes.data.records.length
      ) {
        return (this.finish = true);
      }
    },
    async getFollowList(secondTab) {
      if (!this.$route.query.userId || this.tab !== "follow") {
        return;
      }
      if (secondTab == "following") {
        this.followRequest = "/dp/fans/idolList";
      } else if (secondTab == "followers") {
        this.followRequest = "/dp/fans/fansList";
      }
      const followRes = await request.get(this.followRequest, {
        params: {
          pageNum: this.pageNum,
          pageSize: 10,
          toMemberId: this.userId,
        },
      });
      this.loading = false;
      this.loadingMore = false;
      if (this.pageNum == 1) {
        this.total = followRes.data.total;
      }
      this.followList = this.followList.concat(followRes.data.records);
      if (this.total == 0 && !this.followList.length) {
        return (this.empty = true);
      } else if (
        this.followList.length >= this.total ||
        !followRes.data.records.length
      ) {
        return (this.finish = true);
      }
    },
    async loadmore() {
      if (this.loadingMore || this.loading || this.finish || this.empty) {
        return;
      }
      this.loadingMore = true;
      this.pageNum++;
      if (this.tab == "publish") {
        this.getPublishList();
      } else if (this.tab == "catch") {
        this.getCatchList(this.secondTab);
      } else if (this.tab == "follow") {
        this.getFollowList(this.secondTab);
      }
    },
    toCreator() {
      window.open("https://creator.hearinmusic.com");
    },
    // toSignal() {
    //   openSignal(this.item.esArticle.id);
    // },
    // toTopic(target) {
    //   openTopic(target.id);
    // },
    // toPersonal() {
    //   openPersonal(this.item.member.id);
    // },
    // toEncy(target) {
    //   openEncy(target.typeId, target.dataId);
    // },
    async switchTab(tab) {
      this.tab = tab;
      if (this.tab == "catch") {
        this.switchSecondTab("catch", "topic");
      } else if (this.tab == "follow") {
        this.switchSecondTab("follow", "following");
      } else if (this.tab == "publish") {
        this.pageNum = 1;
        this.empty = false;
        this.finish = false;
        this.loading = true;
        this.total = 1;
        this.articles = [];
        this.getPublishList();
      }
    },
    async switchSecondTab(tab, secondTab) {
      this.secondTab = secondTab;
      this.pageNum = 1;
      this.empty = false;
      this.finish = false;
      this.loading = true;
      this.total = 1;
      this.catchList = [];
      this.followList = [];
      if (tab == "catch") {
        this.getCatchList(secondTab);
      } else if (tab == "follow") {
        this.getFollowList(secondTab);
      }
    },
    async clickFollow() {
      if (!this.isLogin) {
        this.$login();
        return;
      }
      if (this.isAttention) {
        this.$refs["followDialog"].centerDialogVisible = true;
        return;
      }
      const followRes = await request.get("/dp/fans/attention", {
        params: {
          idolId: this.userInfo.id,
        },
      });
      if (followRes.code == 200) {
        this.isAttention = true;
      }
    },
  },
};
</script>

<style scoped>
.right.sticky {
  height: 500px;
}
.personalName img {
  height: 11px;
  margin-right: 3px;
}
.relative {
  position: relative;
}
.personalAvatar .detailUserTypeImg {
  width: 20px;
}
.personalAvatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}
.personalBlock {
  padding: 26px;
  background: var(--background-color-item);
  border-radius: 4px;
  margin-top: 12px;
  display: block;
}
.personal.download {
  display: flex;
  position: inherit;
  margin-top: 12px;
  background: var(--background-color-item);
  font-size: 13px;
  text-align: center;
  z-index: inherit;
}
.personal .QRcodeImg {
  width: 107px;
  height: 133px;
  margin: 0 20px;
}
.personalInfo {
  margin-left: 18px;
  width: 550px;
}
.personalName {
  font-weight: 600;
  font-size: 16px;
}
.identify {
  font-size: 12px;
  margin-top: 4px;
  color: var(--color-70);
}
.personalData {
  font-size: 14px;
  margin-top: 24px;
}

.introduce {
  font-size: 12px;
  margin-top: 24px;
  color: var(--color-70);
}
.personalPage .articleTab.halfBottom::after {
  left: 2%;
  width: 96%;
}
.personalPage .articleTab.halfBottom {
  border-radius: 4px 4px 0 0;
}
.personalPage .articleTab.secondTab {
  border-radius: 0 0 4px 4px;
}
.follow {
  cursor: pointer;
}
.follow:hover {
  color: #9c5cf7;
}

.personalBlock .clickButton.followBtn {
  margin-bottom: 62px;
  flex-shrink: 0;
}
.mobilePage .personalBlock {
  padding: 16px;
}
</style>