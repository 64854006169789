<template>
  <div class="inline">
    <span v-for="(item, index) in list" :key="index">
      <template v-if="index != 0">&nbsp;/&nbsp;</template>
      <span class="link-a" >
        <span style="cursor: pointer;" @click="openWebpage(item.url)">{{item.name}}</span>
        <div class="hoverImg" v-if="item.qrCode">
          <div><img :src="item.qrCode" alt="" style="width: 150px; height: 150px;" /></div>
          <div class="link-b" style="text-align:center;" @click="openWebpage(item.url)">点击跳转链接</div>
        </div>
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: "PlatformLink",
  components: {},
  props: {
    list: {
      type: Array,
    },
  },
  data: () => ({}),
  computed: {},
  created() {},
  mounted() {},
  methods: {
    //跳转网页
    openWebpage(urlStr) {
      // 打开活动外链页面
      if(urlStr){
        let url = urlStr;
        if (
            urlStr.toLowerCase().indexOf("https://") !== 0 &&
            urlStr.toLowerCase().indexOf("http://") !== 0 &&
            urlStr.toLowerCase().indexOf("ftp://") !== 0 &&
            urlStr.toLowerCase().indexOf("file://") !== 0
        ) {
          url = "http://" + url;
        }
        window.open(url);
      }
    },
  },
  watch: {},
};
</script>
<style scoped>
.link-a {
  position: relative;
  line-height: 24px;
}
.link-a:hover {
  color: #9c5cf7;
}
.link-a .hoverImg {
  display: none;
  position: absolute;
  z-index: 1000;
  right: -50px;
  top: 18px;
  background: #292929;
  border-radius: 4px;
  padding: 15px;
}
.link-a:hover .hoverImg {
  display: block;
}
.link-b {
  cursor: pointer;
  color: #FFFFFF;
  border-top:1px solid rgba(255,255,255,0.10);
  margin-top: 10px;
  padding-top: 15px;
}
.link-b:hover {
  color: #9c5cf7;
}
.inline {
  display: inline;
  vertical-align: top;
}
</style>