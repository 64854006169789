<template>
<!-- 首页的每日资讯模块 -->
  <div class="rightItem halfBottom news" @click="toSignal">
    <div class="newsPhoto">
      <img class="imgCut" :src="dailyNewsItem.esArticle.picture" alt="" />
    </div>
    <div class="infoItemText textOverflow">
      {{ dailyNewsItem.esArticle.title }}
    </div>
  </div>
</template>

<script>
import { openSignal } from "@/utils/navigate";
export default {
    methods: {
    toSignal() {
      openSignal(this.dailyNewsItem.esArticle.id);
    },
  },
  props: ["dailyNewsItem"],
};
</script>
