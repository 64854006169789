import request from "@/utils/request";
export function openSignal(id) {
  window.open(`/#/signal?id=${id}`);
}

export function openTopic(id) {
  window.open(`/#/topic?id=${id}`);
}

export function openEncy(typeId, dataId) {
  window.open(`/#/ency?typeId=${typeId}&dataId=${dataId}`);
}

export function openPersonal(userId) {
  window.open(`/#/personalPage?userId=${userId}`);
}

export function openUrl(urlStr,isApp) {
  let url = urlStr;
      if (
        urlStr.toLowerCase().indexOf("https://") !== 0 &&
        urlStr.toLowerCase().indexOf("http://") !== 0 &&
        urlStr.toLowerCase().indexOf("ftp://") !== 0 &&
        urlStr.toLowerCase().indexOf("file://") !== 0
      ) {
        url = "http://" + url;
      }
      if (isApp) {
        let urlObj = new URL(url);
        urlObj.searchParams.set("newWebPage", true);
        url = urlObj.toString();
        window.location.href = url;
      } else window.open(url);
}

const creatorUrl = process.env.VUE_APP_CREATOR_URL;

const typePathMap = {
  signal: "/#/article/index",
  ency: "/#/ency/indexEdit",
  quiz: "/#/submit/subject",
  topic: "/#/submit/pinduan",
};

export function httpString(s) {
  var reg = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
  s = s.match(reg);
  return s;
}

export function jumpToCreatorAndAdd(type, typeId, dataId, name) {
  if (!type) {
    window.open(creatorUrl);
    return;
  }
  if (type == "ency") {
    const url = `${creatorUrl}${typePathMap[type]}${
      typeId && dataId ? `?typeId=${typeId}&dataId=${dataId}` : ""
    }`;
    window.open(url);
    return;
  } else if (type == "signal") {
    const url = `${creatorUrl}${typePathMap[type]}?target=add${
      typeId && dataId && name
        ? `&parentId=${typeId}&channelId=${dataId}&name=${encodeURIComponent(
            name
          )}`
        : ""
    }`;
    window.open(url);
    return;
  }
  const url = `${creatorUrl}${typePathMap[type]}?target=add`;
  console.log(
    "url: ",
    url,
    creatorUrl,
    process.env.VUE_APP_CREATOR_URL,
    process.env.ENV
  );
  window.open(url);
}

const mobileUrl = process.env.VUE_APP_MOBILE_URL;

export function locationMobileUrl(type, typeId, dataId) {
  console.log(mobileUrl);
  if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
    // 当前设备是移动设备
    if (type == "eventWall") {
      // window.location.href = `${mobileUrl}/#/eventWall`
      window.location.replace(`${mobileUrl}/#/eventWall`);
    } else if (type == "ency") {
      window.location.replace(
        `${mobileUrl}/#/encyShare?typeId=${typeId}&dataId=${dataId}`
      );
    } else if (type == "signal") {
      window.location.replace(`${mobileUrl}/#/signalShare?id=${typeId}`);
    } else if (type == "specSub") {
      // window.location.href = `${mobileUrl}/#/specialSubjectShare?id=${typeId}`
      window.location.replace(
        `${mobileUrl}/#/specialSubjectShare?id=${typeId}`
      );
    } else if (type == "external") {
      window.location.replace(`${mobileUrl}/#/${typeId}`);
    }
  } else return;
}

export async function filterLink(urlStr) {
  if (!urlStr) return false;
  var content = JSON.parse(JSON.stringify(urlStr));
  if (content.indexOf("163") != -1 || content.indexOf(".qq.") != -1) {
    console.log("是音乐链接");
  } else {
    this.$message({
      message: "解析失败",
      type: "error",
    });
    return false;
  }
  const url = httpString(content);
  if (!url) {
    this.errorMsg = true;
    return;
  }
  urlStr = url[0];
  if (urlStr) {
    const musicRes = await request.get("/dp/analysis/musicWeb", {
      params: {
        param: urlStr,
      },
    });
        return musicRes;
  }
}
