<template>
  <div class="musicBox">
    <div
      class="musicItem"
      :style="{ 'background-image': `url(${item.picture})` }"
      @click.stop="clickMusicItem(item.id)"
    >
      <div class="linearShade">
        <div class="greyShade">
          <div class="musicCover">
            <img class="imgCut" :src="item.picture" alt="" />
          </div>
          <div class="musicInfo">
            <div class="musicName textOverflow">{{ item.name }}</div>
            <div class="musicArtist textOverflow">{{ item.artist }}</div>
            <div class="musicLinkList">
              <img
                v-for="(musicLinkItem, idx) in item.relatedLinkList"
                :key="idx"
                class="musicLinkItem"
                @click.stop="handleOpenUrl(musicLinkItem.url)"
                :src="musicLinkItem.logo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="musicDialogVisible"
      class="musicDetDialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <div
        class="greyShade"
        :style="{ 'background-image': `url(${item.picture})` }"
      >
        <div class="linearShade greyShade">
          <div class="greyShade">
            <div class="musicDialogHead">
              <div class="musicCover">
                <img class="imgCut" :src="item.picture" alt="" />
              </div>
              <div class="musicInfo">
                <div class="musicName textOverflow">{{ item.name }}</div>
                <div class="musicArtist textOverflow">{{ item.artist }}</div>
                <div>发行日期：{{ item.publishDate }}</div>
                <div>类型：{{ musicTypeMap[item.type] }}</div>
                <div class="musicLinkList">
                  <img
                    v-for="(musicLinkItem, idx) in item.relatedLinkList"
                    :key="idx"
                    class="musicLinkItem"
                    @click.stop="handleOpenUrl(musicLinkItem.url)"
                    :src="musicLinkItem.logo"
                  />
                </div>
              </div>
            </div>
            <div class="devider"></div>
            <el-table :data="nominateLogList" height="320">
              <el-table-column label="提名人" min-width="30%">
                <template slot-scope="scope">
                  <div class="nominateName" @click="toPersonal(scope.row.member.id)">
                    <img class="avatar" :src="scope.row.member.avatar" alt="" />
                    <div class="nickname">{{ scope.row.member.nickname }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                property="createTime"
                label="提名时间"
                min-width="32%"
                :formatter="formatCreateTime"
              ></el-table-column>
              <el-table-column label="提名信息" min-width="38%">
                <template slot-scope="scope">
                  <div
                    v-if="
                      !scope.row.brandList.length &&
                      !scope.row.styleList.length &&
                      !scope.row.bpm &&
                      !scope.row.mode
                    "
                  >
                    -
                  </div>
                  <div v-else>
                    <div v-if="scope.row.brandList.length">
                      <span>厂牌：</span>
                      <span
                        v-for="(brandItem, idx) in scope.row.brandList"
                        :key="idx"
                        :class="{ encyText: brandItem.mold == 1 }"
                        @click="toEncy(brandItem)"
                      >
                        {{brandItem.text
                        }}{{
                          idx === scope.row.brandList.length - 1 ? "" : ","
                        }}
                      </span>
                    </div>
                    <div v-if="scope.row.styleList.length">
                      <span>风格/标签：</span>
                      <span
                        v-for="(styleItem, idx) in scope.row.styleList"
                        :key="idx"
                        :class="{ encyText: styleItem.mold == 1 }"
                        @click="toEncy(styleItem)"
                        >{{ styleItem.text
                        }}{{
                          idx === scope.row.styleList.length - 1 ? "" : ","
                        }}
                      </span>
                    </div>
                    <div v-if="scope.row.bpm">
                      曲速：{{ scope.row.bpm }} BPM
                    </div>
                    <div v-if="scope.row.mode">
                      调式：{{
                        modeList.find((item) => item.value == scope.row.mode)
                          .label
                      }}
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { isAppFun, isIOS, isAndroid } from "@/utils/judgeClient";
import { openUrl } from "@/utils/navigate";
import request from "@/utils/request";
import dayjs from "dayjs";
import { openEncy, openPersonal } from "@/utils/navigate";

export default {
  data() {
    return {
      isApp: false,
      musicDialogVisible: false,
      nominateLogList: [],
      musicTypeMap: {
        song: "单曲",
        album: "专辑",
      },
      modeList: [
        {
          label: "A Major",
          value: "A1",
        },
        {
          label: "A Minor",
          value: "A2",
        },
        {
          label: "A# Major",
          value: "A3",
        },
        {
          label: "A# Minor",
          value: "A4",
        },
        {
          label: "Ab Major",
          value: "A5",
        },
        {
          label: "Ab Minor",
          value: "A6",
        },
        {
          label: "B Major",
          value: "B1",
        },
        {
          label: "B Minor",
          value: "B2",
        },
        {
          label: "Bb Major",
          value: "B5",
        },
        {
          label: "Bb Minor",
          value: "B6",
        },
        {
          label: "C Major",
          value: "C1",
        },
        {
          label: "C Minor",
          value: "C2",
        },
        {
          label: "C# Major",
          value: "C3",
        },
        {
          label: "C# Minor",
          value: "C4",
        },
        {
          label: "D Major",
          value: "D1",
        },
        {
          label: "D Minor",
          value: "D2",
        },
        {
          label: "D# Major",
          value: "D3",
        },
        {
          label: "D# Minor",
          value: "D4",
        },
        {
          label: "Db Major",
          value: "D5",
        },
        {
          label: "Db Minor",
          value: "D6",
        },
        {
          label: "E Major",
          value: "E1",
        },
        {
          label: "E Minor",
          value: "E2",
        },
        {
          label: "Eb Major",
          value: "E5",
        },
        {
          label: "Eb Minor",
          value: "E6",
        },
        {
          label: "F Major",
          value: "F1",
        },
        {
          label: "F Minor",
          value: "F2",
        },
        {
          label: "F# Major",
          value: "F3",
        },
        {
          label: "F# Minor",
          value: "F4",
        },
        {
          label: "G Major",
          value: "G1",
        },
        {
          label: "G Minor",
          value: "G2",
        },
        {
          label: "G# Major",
          value: "G3",
        },
        {
          label: "G# Minor",
          value: "G4",
        },
        {
          label: "Gb Major",
          value: "G5",
        },
        {
          label: "Gb Minor",
          value: "G6",
        },
      ],
    };
  },
  props: ["item"],
  methods: {
    toPersonal(id) {
      if (this.isApp) {
        if (isAndroid()) window.Android.toCustomerInfo(id);
      if (isIOS()) window.webkit.messageHandlers.toCustomerInfo.postMessage(id);
      }
      else openPersonal(id);
    },
    formatCreateTime(row, column, cellValue) {
      return dayjs(cellValue).format("YYYY-MM-DD HH:mm");
    },
    clickMusicItem(id) {
      this.musicDialogVisible = true;
      this.getNominateLogList(id);
    },
    handleOpenUrl(urlStr) {
      openUrl(urlStr, this.isApp);
    },
    async getNominateLogList(id) {
      const nominateLogListRes = await request.get("/dp/vote/nominateLogList", {
        params: {
          tdId: id,
          type: 1,
        },
      });
      if (nominateLogListRes.code == 200) {
        this.nominateLogList = nominateLogListRes.data.slice();
      }
    },
    toEncy(item) {
      if (item.mold == 0) {
        return;
      }
      if (this.isApp) {
        try {
          if (isAndroid())
            window.Android.skipEncyclopedia(JSON.stringify(item));
          if (isIOS())
            window.webkit.messageHandlers.skipEncyclopedia.postMessage(item);
        } catch (error) {
          console.log(error);
        }
        console.log(item);
      } else openEncy(item.typeId, item.dataId);
    },
  },
  async created() {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      // 当前设备是移动设备
      this.isApp = isAppFun();
    }
  },
};
</script>

<style>
.musicItem {
  width: 344px;
  height: 175px;
  background-size: cover;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  margin-top: 12px;
  cursor: pointer;
}
.greyShade {
  position: absolute;
  width: 344px;
  height: 175px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 15px;
  box-sizing: border-box;
}
.linearShade {
  position: absolute;
  width: 344px;
  height: 175px;
  background: linear-gradient(
    0deg,
    rgba(41, 41, 41, 0.8) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.musicItem .musicName {
  font-size: 15px;
}
.musicItem .musicArtist {
  font-size: 13px;
  line-height: 17px;
  margin-top: 4px;
  height: 37px;
  -webkit-line-clamp: 2;
}

.musicDialogHead {
  display: flex;
}
.musicDetDialog .linearShade.greyShade {
  background: linear-gradient(
    0deg,
    rgba(40, 40, 40, 1) 42%,
    rgba(255, 255, 255, 0) 100%
  );
}
.musicDetDialog .el-dialog__headerbtn .el-dialog__close {
  color: #fff;
}
.musicDetDialog .linearShade.greyShade .greyShade {
  background: rgba(0, 0, 0, 0.3);
}
.musicDetDialog .musicArtist {
  margin: 6px 0;
}
.musicDetDialog .avatar {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  flex-shrink: 0;
}
.musicCover {
  width: 145px;
  height: 145px;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
}
.musicLinkItem {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  overflow: hidden;
  margin-right: 8px;
  cursor: pointer;
}
.musicLinkList {
  margin-top: 12px;
  display: flex;
}
/* 表格相关样式 */
.nominateName {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.nominateName .nickname {
  margin-left: 5px;
  font-weight: 400;
}
.el-table .cell {
  word-break: break-word;
}

.encyText {
  text-decoration: underline;
  cursor: pointer;
}
.mobilePage .el-table .cell {
  padding-left: 12px;
}
.mobilePage .el-table th.el-table__cell > .cell {
  padding-left: 12px;
}
</style>

