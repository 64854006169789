<template>
  <div class="home-wrapper settingPage" :class="{ mobilePage: isMobile }">
    <web-header :disabledScroll="true" />
    <div class="secondary">
      <div class="tabSticky topic">
        <div class="articleTab ency specSub">
          <div
            class="tab specSub"
            :class="{
              selected: tab === '账号与安全',
              unselect: tab !== '个人资料',
            }"
            @click="clickTab('账号与安全')"
          >
            <div>账号与安全</div>
            <div v-if="tab === '账号与安全'" class="selectedIcon"></div>
          </div>
          <div
            class="tab specSub"
            :class="{
              selected: tab === '个人资料',
              unselect: tab !== '账号与安全',
            }"
            @click="clickTab('个人资料')"
          >
            <div>个人资料</div>
            <div v-if="tab === '个人资料'" class="selectedIcon"></div>
          </div>
        </div>
      </div>
      <div class="greyBlock">
        <div v-show="tab === '账号与安全'" class="accountSetting">
          <div class="halfBottom settingItem">
            <div class="settingType">
              <div class="settingTypeTitle">绑定手机</div>
              <div class="settingTypeDet">{{ mobileState }}</div>
            </div>
            <div v-if="!mobilePhone" class="settingBtn" @click="clickSetMb()">绑定</div>
            <div v-else style="margin: 0 30px 0 auto;">已绑定</div>
          </div>
          <div class="halfBottom settingItem">
            <div class="settingType">
              <div class="settingTypeTitle">密码</div>
            </div>
            <div class="settingBtn" @click="clickSetPw()">修改</div>
          </div>
        </div>
        <div v-show="tab === '个人资料'" class="infoSetting">
          <el-form :rules="rules" ref="ruleForm" :model="form">
            <el-form-item label="昵称" prop="nickname">
              <el-input
                v-model="form.nickname"
                :disabled="formDisabled"
              ></el-input>
            </el-form-item>
            <div class="formItemTips">
              请输入1-20个字符，<em><b
                >30天内限修改1次</b
              ></em>
            </div>
            <el-form-item label="简介" prop="description">
              <el-input
                v-model="form.description"
                type="textarea"
                :disabled="formDisabled"
                maxlength="50"
              ></el-input>
            </el-form-item>
            <div class="formItemTips">请填写你的个人简介（50字以内）</div>
            <el-form-item label="性别" prop="genderLabel">
              <el-radio-group
                v-model="form.genderLabel"
                :disabled="formDisabled"
              >
                <el-radio label="男"></el-radio>
                <el-radio label="女"></el-radio>
                <el-radio label="秘密"></el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <div
            class="settingBtn editBtn"
            v-show="formDisabled"
            @click="formDisabled = false"
          >
            编辑
          </div>
          <div class="editing">
            <div
              class="settingBtn editBtn"
              v-show="!formDisabled"
              @click="clickCancelEdit()"
            >
              取消
            </div>
            <div
              class="settingBtn editBtn"
              v-show="!formDisabled"
              @click="clickSubmitEdit()"
            >
              保存
            </div>
          </div>
        </div>
        <SetPassword ref="setPwDialog" />
        <SetMobile ref="setMbDialog" />
      </div>
    </div>
  </div>
</template>

<script>
import WebHeader from "@/components/WebHeader.vue";
import SetPassword from "@/components/SettingPopup/SetPassword.vue";
import SetMobile from "@/components/SettingPopup/SetMobile.vue";
import { mapGetters } from "vuex";
import request from "@/utils/request";
export default {
  components: {
    WebHeader,
    SetPassword,
    SetMobile,
  },
  computed: {
    ...mapGetters([
      "nickname",
      "avatar",
      "id",
      "isLogin",
      "descriptions",
      "gender",
      "mobilePhone",
    ]),
  },
  watch: {
    id: {
      immediate: true,
      handler(val, oldVal) {
        // 从false变成true, 拉一下个人信息
        if (!oldVal && val) {
          this.form = {
            nickname: this.nickname,
            description: this.descriptions,
            genderLabel: this.genderMap[this.gender],
          };
        }
      },
    },
    mobilePhone(val) {
      if (val) {
        this.mobileState = this.mobilePhone;
      } else this.mobileState = "未绑定";
    },
  },
  async created() {
    document.title = "个人设置 - DROPIN多频";
  },
  data() {
    const calNickNameLength = (nickname) => {
      // eslint-disable-next-line
      const reg = /[^\x00-\xff]/
      let len = 0
      for (let i = 0; i < nickname.length; i++) {
        if (nickname[i].match(reg)) {
          len += 2
        } else {
          len++
        }
      }
      return len
    }
    const validateNickname = (rule, value, callback) => {
      console.log('===validateNickname', value, typeof value)
      if (!value) {
        return callback(new Error('昵称不能为空'))
      }
      const len = calNickNameLength(value)
      console.log('len', len)
      if (len < 1 || len > 20) {
        return callback(new Error('请输入1-20个字符'))
      }
      callback()
    }
    return {
      tab: "账号与安全",
      form: {
        nickname: "",
        description: "",
        genderLabel: "男",
      },
      rules: {
        nickname: [
          { validator: validateNickname, trigger: 'blur'}
        ]
      },
      formDisabled: true,
      mobileState: "未绑定",
      genderMap: {
        0: "秘密",
        1: "男",
        2: "女",
      },
      genderEditMap: {
        秘密: 0,
        男: 1,
        女: 2,
      },
    };
  },
  methods: {
    clickTab(value) {
      if (!this.isLogin) {
        this.$login();
        return;
      }
      this.tab = value;
      if (value == '账号与安全') {
        this.clickCancelEdit();
      }
    },
    clickSetPw() {
      if (!this.isLogin) {
        this.$login();
        return;
      }
      if (!this.mobilePhone) {
        this.$message({
              message: "请绑定手机号后，再修改密码",
              type: "error",
            });
        return;
      }
      this.$refs["setPwDialog"].centerDialogVisible = true;
    },
    clickSetMb() {
      if (!this.isLogin) {
        this.$login();
        return;
      }
      this.$refs["setMbDialog"].centerDialogVisible = true;
    },
    clickCancelEdit() {
      this.formDisabled = true;
      this.form = {
        nickname: this.nickname,
        description: this.descriptions,
        genderLabel: this.genderMap[this.gender],
      };
    },
    async clickSubmitEdit() {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          const editRes = await request.post("/dp/member/edit", {
            descriptions: this.form.description,
            gender: this.genderEditMap[this.form.genderLabel],
            nickname: this.form.nickname,
          });
          if (editRes.code == 200) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.$store.dispatch("user/getInfo");
            return (this.formDisabled = true);
          }
        } else {
          console.log('校验不通过！error submit!!');
          return false;
        }
      });
    },

  },
};
</script>

<style scoped>
.articleTab.ency.specSub {
  margin-top: 6px;
  border-radius: 4px;
}
.settingPage .greyBlock {
  width: 834px;
  height: 70vh;
  min-height: 600px;
}
.settingItem {
  padding: 24px 36px;
  display: flex;
  align-items: center;
}
.settingBtn {
  border: 1px solid #9c5cf7;
  border-radius: 21px;
  color: #9c5cf7;
  margin: 0 20px 0 auto;
  padding: 4px 15px;
  height: 20px;
  width: 28px;
  cursor: pointer;
}
.settingTypeTitle {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}
.infoSetting .el-form {
  margin: 40px;
}
.formItemTips {
  margin-bottom: 26px;
  font-size: 12px;
}
.editBtn.settingBtn {
  margin-right: 40px;
}
.editing {
  display: flex;
  margin: 0 26px 0 auto;
  width: 150px;
}
.editing .editBtn.settingBtn {
  margin: 0 14px 0 0;
}
.accountSetting {
  margin-top: 20px;
}
.mobilePage .settingItem {
  padding: 16px 10px 16px 30px;
}
.mobilePage .accountSetting {
  margin-top: 10px;
}
.mobilePage.settingPage .greyBlock {
  width: 100%;
}
</style>

<style>
.el-radio__input.is-checked + .el-radio__label {
  color: #9c5cf7;
}
.el-radio {
  color: var(--font-color);
  font-weight: 300;
}
.el-radio__input.is-checked .el-radio__inner {
  border-color: #9c5cf7;
  background: #9c5cf7;
}
.infoSetting .el-form-item {
  margin-bottom: 8px;
}
.infoSetting .el-textarea.is-disabled .el-textarea__inner {
  background: var(--background-color-item-hover);
  color: var(--font-color);
  border: none;
}
.infoSetting .el-input.is-disabled .el-input__inner {
  background: var(--background-color-item-hover);
  color: var(--font-color);
  border: none;
}
.infoSetting .el-radio__input.is-disabled + span.el-radio__label {
  color: var(--font-color);
}
.infoSetting .el-radio__input.is-disabled .el-radio__inner,
.el-radio__input.is-disabled.is-checked .el-radio__inner {
  background-color: var(--color-40);
}
.infoSetting .el-radio__input.is-disabled.is-checked .el-radio__inner::after {
  background-color: #fff;
}
.mobilePage .greyBlock .infoSetting .el-form {
  margin: 30px;
}
.infoSetting .el-form-item__error {
  top: calc(100% + 28px)!important;
  left: 0;
}
</style>