<template>
    <!-- 以下是内容部分 -->
    <div class="home-wrapper" :class="{mobilePage : isMobile}">
      <web-header>
        <!-- 里面放滚动切换的内容 -->
        <div class="headSticky scrollSticky" key="scroll">
          <div class="head">
      <div class="headLeft">
        <div id="logo" @click="selectMenuItem()" v-if="!isMobile">
          <img class="imgCut"  src="@/assets/style/DPlogo.png" alt="" />
        </div>
        <div class="tabSticky tabSearch scrollSticky">
          <div class="articleTab">
            <div
              class="tab"
              :class="{
                selected: tab === 'signal',
                unselect: tab !== 'signal',
              }"
              @click="switchTab('signal')"
            >
              <div>信号</div>
              <div v-if="tab === 'signal'" class="selectedIcon"></div>
            </div>
            <div
              class="tab"
              :class="{
                selected: tab === 'topic',
                unselect: tab !== 'topic',
              }"
              @click="switchTab('topic')"
            >
              <div>频段</div>
              <div v-if="tab === 'topic'" class="selectedIcon"></div>
            </div>
            <div
              class="tab"
              :class="{
                selected: tab === 'ency',
                unselect: tab !== 'ency',
              }"
              @click="switchTab('ency')"
            >
              <div>百科</div>
              <div v-if="tab === 'ency'" class="selectedIcon"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="headRight">
        <header-search />
      </div>
    </div>
        </div>
      </web-header>
      <div class="secondary">
        <!-- 以下是搜索tab部分 -->
        <div class="tabSticky tabSearch">
          <div class="articleTab">
            <div
              class="tab"
              :class="{
                selected: tab === 'signal',
                unselect: tab !== 'signal',
              }"
              @click="switchTab('signal')"
            >
              <div>信号</div>
              <div v-if="tab === 'signal'" class="selectedIcon"></div>
            </div>
            <div
              class="tab"
              :class="{
                selected: tab === 'topic',
                unselect: tab !== 'topic',
              }"
              @click="switchTab('topic')"
            >
              <div>频段</div>
              <div v-if="tab === 'topic'" class="selectedIcon"></div>
            </div>
            <div
              class="tab"
              :class="{
                selected: tab === 'ency',
                unselect: tab !== 'ency',
              }"
              @click="switchTab('ency')"
            >
              <div>百科</div>
              <div v-if="tab === 'ency'" class="selectedIcon"></div>
            </div>
          </div>
        </div>
        <!-- 以下是全局搜索频段结果部分 -->

        <div
          class="resultList"
          v-if="tab === 'topic'"
          v-infinite-scroll="loadmore"
        >
          <div class="loading" v-if="loading">
            <img class="loadingImg" src="@/assets/style/loading.gif" alt="" />
          </div>
          <div class="searchEmpty" v-else-if="loaded && !topicResult.length">
            <img v-if="themeColor=='light'" class="searchEmptyImg" src="@/assets/style/find empty.png" alt="" />
            <img v-else class="searchEmptyImg" src="@/assets/style/empty.gif" alt="" />
            <div class="searchEmptyText">很可惜，没有找到相关信息</div>
          </div>
          <template v-else>
            <TopicSearch
              v-for="(item, idx) in topicResult"
              :topicSearchItem="item"
              :key="idx"
            />
          </template>
        </div>

        <!-- 以下是全局搜索百科结果部分 -->
        <div
          class="resultList"
          v-if="tab === 'ency'"
          v-infinite-scroll="loadmore"
        >
          <div class="loading" v-if="loading">
            <img class="loadingImg" src="@/assets/style/loading.gif" alt="" />
          </div>
          <div class="searchEmpty" v-else-if="loaded && !encyResult.length">
            <img v-if="themeColor=='light'" class="searchEmptyImg" src="@/assets/style/find empty.png" alt="" />
            <img v-else class="searchEmptyImg" src="@/assets/style/empty.gif" alt="" />
            <div class="searchEmptyText">很可惜，没有找到相关信息</div>
          </div>
          <template v-else>
            <EncySearch
              v-for="(item, idx) in encyResult"
              :encySearchItem="item"
              :key="idx"
            />
          </template>
        </div>
        <!-- 以下是全局搜索信号结果部分 -->
        <div
          class="resultList"
          v-if="tab === 'signal'"
          v-infinite-scroll="loadmore"
        >
          <div class="loading" v-if="loading">
            <img class="loadingImg" src="@/assets/style/loading.gif" alt="" />
          </div>
          <div class="searchEmpty" v-else-if="loaded && !articleResult.length">
            <img v-if="themeColor=='light'" class="searchEmptyImg" src="@/assets/style/find empty.png" alt="" />
            <img v-else class="searchEmptyImg" src="@/assets/style/empty.gif" alt="" />
            <div class="searchEmptyText">很可惜，没有找到相关信息</div>
          </div>
          <template v-else>
            <ArticleSearch
              v-for="(item, idx) in articleResult"
              :articleSearchItem="item"
              :key="idx"
            />
          </template>
        </div>
        <div v-if="loadingMore" class="loadmore loading" :style="{ visibility: loadingMore ? 'visible' : 'hidden' }">
          <img class="loadingImg" src="@/assets/style/loading.gif" alt="" />
        </div>
        <div class="noMoreData" v-if="!loadingMore && !loading && noMoreData && (articleResult.length || topicResult.length || encyResult.length)">- 没有更多内容了哟 -</div>
      </div>
    <!-- 二级页面的网站信息 -->
    <SecondFooter />
    <template>
      <el-backtop target=".home-wrapper" :bottom="100" :visibility-height="100">
        <div class="backTop">UP</div>
      </el-backtop>
    </template>
  </div>
</template>

<script>
import WebHeader from "@/components/WebHeader";
import SecondFooter from "@/components/SecondFooter";
import TopicSearch from "@/components/TopicSearch";
import EncySearch from "@/components/EncySearch";
import ArticleSearch from "@/components/ArticleSearch";
import request from "@/utils/request";
import HeaderSearch from "@/components/HeaderSearch.vue";

export default {
  components: {
    WebHeader,
    SecondFooter,
    TopicSearch,
    EncySearch,
    ArticleSearch,
    HeaderSearch,
  },
  data() {
    return {
      topicResult: [],
      encyResult: [],
      articleResult: [],
      tab: "signal", // 信号: signal, 频段: topic, 百科：ency
      resultPageNum: 1,
      totalPages: 1,
      keyword: "",
      loadingMore: false,
      loading: false,
      loaded: false,
      noMoreData: false,
      showLoadmoreTimer: null,
      disableLoadmore: false,
      themeColor: 'dark',
    };
  },
  watch: {
    '$root.themeColor': {
      immediate: true,
      handler(newVal) {
        this.themeColor = newVal;
      },
    },
  },
  mounted() {
    this.themeColor = localStorage.getItem('theme');
    this.$root.themeColor = this.themeColor;
  },
  methods: {
    selectMenuItem() {
      this.url = "/";
      if (this.$route.fullPath === this.url) {
        // 点击的是当前路由 手动重定向页面到 '/redirect' 页面
        this.$router.replace({
          path: "/redirect",
          query: {
            path: encodeURI(this.url),
          },
        });
      } else {
        // 正常跳转
        this.$router.push({
          path: this.url,
        });
      }
    },
    // 切换搜索tab
    async switchTab(tab) {
      this.tab = tab;
      this.url = "search";
      this.loading = true;
      if (this.$route.name === this.url) {
        // 点击的是当前路由 手动重定向页面到 '/redirect' 页面
        this.$router.replace({
          path: "/redirect",
          query: {
            path: encodeURI(
              `/${this.url}?keyword=${this.keyword}&type=${this.tab}`
            ),
          },
        });
        
      } else {
        // 正常跳转
        this.$router.push({
          name: "search",
          query: {
            keyword: this.keyword,
            type: this.tab,
          },
        });
      }
    },
    // 滚动加载下一页
    async loadmore() {
      if (this.loadingMore || this.loading || !this.loaded || this.noMoreData) {
        return;
      }
      this.resultPageNum++;
      console.log("🌟 this.resultPageNum", this.resultPageNum);
      if (this.resultPageNum > this.totalPages) {
        return this.noMoreData = true;
      }
      this.loadingMore = true;
      // 搜索信号tab
      if (this.tab === "signal") {
        const searchRes = await request.post("/dp/article/list", {
            orderType: "NEW_CREATE",
            pageNum: this.resultPageNum,
            pageSize: 10,
            title: this.keyword,
        });
        this.loadingMore = false;
        this.articleResult = this.articleResult.concat(searchRes.data.records);
        this.totalPages = searchRes.data.pages;
      }
      // 搜索频段tab
      else if (this.tab === "topic") {
        const searchRes = await request.get("/dp/channel/list", {
          params: {
            pageSize: 20,
            pageNum: this.resultPageNum,
            name: this.keyword,
          },
        });
        this.loadingMore = false;
        this.topicResult = this.topicResult.concat(searchRes.data.records);
        this.totalPages = searchRes.data.pages;
      }
      // 搜索百科tab
      else if (this.tab === "ency") {
        const searchRes = await request.get("/dp/ency/list", {
          params: {
            pageNum: this.resultPageNum,
            pageSize: 20,
            name: this.keyword,
          },
        });
        this.loadingMore = false;
        this.encyResult = this.encyResult.concat(searchRes.data.records);
        this.totalPages = searchRes.data.pages;
      }
    },
  },
  async created() {
    this.keyword = this.$route.query.keyword;
    this.type = this.$route.query.type;
    document.title = `${this.keyword} - 搜索结果 - DROPIN多频`;
    // 500ms后出loading
     const timer = setTimeout(() => {
       this.loading = true
     }, 500)
    if (this.type === "signal") {
      const searchRes = await request.post("/dp/article/list", {
          orderType: "NEW_CREATE",
          pageNum: this.resultPageNum,
          pageSize: 10,
          title: this.keyword,
      });
      this.loading = false;
      this.articleResult = searchRes.data.records;
      this.totalPages = searchRes.data.pages;
    } else if (this.type === "topic") {
      this.tab = "topic";
      const searchRes = await request.get("/dp/channel/list", {
        params: {
          pageSize: 20,
          pageNum: this.resultPageNum,
          name: this.keyword,
        },
      });
      this.loading = false;
      this.topicResult = searchRes.data.records;
      this.totalPages = searchRes.data.pages;
    }
    // 搜索百科tab
    else if (this.type === "ency") {
      this.tab = "ency";
      const searchRes = await request.get("/dp/ency/list", {
        params: {
          pageNum: this.resultPageNum,
          pageSize: 20,
          name: this.keyword,
        },
      });
      this.loading = false;
      this.encyResult = searchRes.data.records;
      this.totalPages = searchRes.data.pages;
    }
    clearTimeout(timer)
     this.loaded = true
  },
};
</script>
<style>
.head {
  max-width: 1220px;
}
/* 移动端适配样式 */
.mobilePage .tabSticky.tabSearch {
  width: 100%;
}
.mobilePage .resultList {
  width: 100%;
}
</style>