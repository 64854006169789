<template>
  <div class="home-wrapper" :class="{ mobilePage: isMobile }">
    <web-header :switchHeight="110">
      <!-- 里面放滚动切换的内容 -->
      <div class="headSticky scrollSticky" key="scroll">
        <div class="head">
          <div class="headLeft">
            <div id="logo" @click="selectMenuItem()" v-if="!isMobile">
              <img class="imgCut" src="@/assets/style/DPlogo.png" alt="" />
            </div>
            <div class="tabSticky tabSearch scrollSticky">
              <div class="articleTab">
                <div
                  class="tab"
                  :class="{
                    selected: tab === topic.name,
                    unselect: tab !== topic.name,
                  }"
                  v-for="(topic, idx) in firstTopic"
                  @click="topicTab(topic)"
                  :key="idx"
                >
                  <div>{{ topic.name }}</div>
                  <div v-if="tab === topic.name" class="selectedIcon"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="headRight" v-if="!isMobile">
            <div class="search topicSearch">
              <input
                v-model="topicMessage"
                placeholder="搜索频段"
                class="searchInput"
                @keyup.enter="clickSearch(topicMessage)"
              />
              <div class="searchIcon" @click="clickSearch(topicMessage)">
                <img
                  class="searchIconImg"
                  src="@/assets/style/icon_topic@2x_search.png"
                  alt=""
                />
                <img
                  class="searchIconImg searchIconFocus"
                  src="@/assets/style/icon_topic@2x_white.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </web-header>
    <!-- 以下是内容部分 -->
    <div class="content">
      <div class="left">
        <div class="topicHead halfBottom topicSearch ency">
          <div class="allTopic">全部频段</div>
          <div class="search topicSearch">
            <input
              v-model="topicMessage"
              placeholder="搜索频段"
              class="searchInput"
              ref="topicSearch"
              @keyup.enter="clickSearch(topicMessage)"
            />
            <img
              class="closeBtnImg"
              src="@/assets/style/btn_close@2x (1).png"
              alt=""
              v-show="isMobile && topicMessage"
              @click="clearInput()"
            />
            <div class="searchIcon" @click="clickSearch(topicMessage)">
              <img
                class="searchIconImg"
                src="@/assets/style/icon_topic@2x_search.png"
                alt=""
              />
              <img
                class="searchIconImg searchIconFocus"
                src="@/assets/style/icon_topic@2x_white.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- 以下是频段广场的频段分类 -->
        <div class="tabSticky topic">
          <div class="articleTab topicSearch ency">
            <div
              class="tab"
              :class="{
                selected: tab === topic.name,
                unselect: tab !== topic.name,
              }"
              v-for="(topic, idx) in firstTopic"
              @click="topicTab(topic)"
              :key="idx"
            >
              <div>{{ topic.name }}</div>
              <div v-if="tab === topic.name" class="selectedIcon"></div>
            </div>
          </div>
        </div>
        <!-- 以下是频段广场的频段列表 -->
        <div class="resultList" v-infinite-scroll="loadmore">
          <div class="loading" v-if="loading">
            <img class="loadingImg" src="@/assets/style/loading.gif" alt="" />
          </div>
          <template v-else-if="topicResult.length">
            <TopicSearch
              v-for="(item, idx) in topicResult"
              :topicSearchItem="item"
              :key="idx"
            />
            <div
              v-if="loadingMore"
              class="loadmore loading"
              :style="{ visibility: loadingMore ? 'visible' : 'hidden' }"
            >
              <img class="loadingImg" src="@/assets/style/loading.gif" alt="" />
            </div>
          </template>
          <template v-else>
            <div class="searchEmpty">
              <img v-if="themeColor=='light'" class="searchEmptyImg" src="@/assets/style/find empty.png" alt="" />
              <img v-else class="searchEmptyImg" src="@/assets/style/empty.gif" alt="" />
              <div class="searchEmptyText">很可惜，没有找到相关信息</div>
            </div>
          </template>
          <div
            class="noMoreData"
            v-if="!loadingMore && !loading && noMoreData && topicResult.length"
          >
            - 没有更多内容了哟 -
          </div>
        </div>
      </div>

      <div class="right" v-if="!isMobile">
        <div class="sticky">
          <!-- 创作后台 -->
          <div class="rightField">
            <div class="rightTab halfBottom">
              <div class="rightTabText">创作后台</div>
            </div>
            <div class="creatorFlex">
              <div>
                <div
                  class="creatorBtn"
                  style="background: rgba(156, 92, 247, 0.2)"
                  @click="toCreatorAddDialog('topic')"
                >
                  <img src="@/assets/style/icon_creator_topic@2x.png" alt="" />
                </div>
                新建频段
              </div>

              <div>
                <div class="creatorBtn" @click="toCreatorAddDialog('signal')">
                  <img src="@/assets/style/icon_signal_grey.png" alt="" />
                </div>
                发信号
              </div>
              <div>
                <div class="creatorBtn" @click="toCreatorAddDialog('ency')">
                  <img
                    src="@/assets/style/icon_pedia_creator_grey.png"
                    alt=""
                  />
                </div>
                编辑百科
              </div>
              <div>
                <div class="creatorBtn" @click="toCreatorAddDialog('quiz')">
                  <img src="@/assets/style/icon_qa_creator_grey.png" alt="" />
                </div>
                新建频一频
              </div>
            </div>
            <div class="toCreatorBtn pointer" @click="toCreatorAddDialog()">
              进入创作后台 >
            </div>
          </div>
          <!-- 网站信息 -->
          <FirstFooter />
        </div>
      </div>
    </div>

    <template>
      <el-backtop target=".home-wrapper" :bottom="100" :visibility-height="100">
        <div class="backTop">UP</div>
      </el-backtop>
    </template>
  </div>
</template>

<script>
import WebHeader from "@/components/WebHeader";
import FirstFooter from "@/components/FirstFooter";
import TopicSearch from "@/components/TopicSearch";
import request from "@/utils/request";
import { openSignal, openTopic, jumpToCreatorAndAdd } from "@/utils/navigate";

export default {
  components: {
    WebHeader,
    FirstFooter,
    TopicSearch,
  },
  data() {
    return {
      articles: [],
      tab: "",
      loading: true,
      firstTopic: [],
      topicResult: [],
      firstTopicItem: {},
      topicPageNum: 1,
      topicId: 0,
      loadingMore: false,
      totalPages: 1,
      topicMessage: "",
      noMoreData: false,
      showLoadmoreTimer: null,
      disableLoadmore: false,
      themeColor: 'dark',
    };
  },
  watch: {
    '$root.themeColor': {
      immediate: true,
      handler(newVal) {
        this.themeColor = newVal;
      },
    },
  },
  mounted() {
    this.themeColor = localStorage.getItem('theme');
    this.$root.themeColor = this.themeColor;
  },
  methods: {
    clearInput() {
      this.topicMessage = "";
      this.$nextTick(() => {
        this.$refs.topicSearch.focus();
      });
    },
    toCreatorAddDialog(type) {
      jumpToCreatorAndAdd(type);
    },
    selectMenuItem() {
      this.url = "/";
      if (this.$route.fullPath === this.url) {
        // 点击的是当前路由 手动重定向页面到 '/redirect' 页面
        this.$router.replace({
          path: "/redirect",
          query: {
            path: encodeURI(this.url),
          },
        });
      } else {
        // 正常跳转
        this.$router.push({
          path: this.url,
        });
      }
    },
    toSignal() {
      openSignal(this.item.esArticle.id);
    },
    toTopic(target) {
      openTopic(target.id);
    },
    async topicTab(topic) {
      this.topicId = topic.id;
      this.topicPageNum = 1;
      this.tab = topic.name;
      this.noMoreData = false;
      this.loading = true;
      this.topicResult = [];

      const topicListRes = await request.get("/dp/channel/list", {
        params: {
          parentId: this.topicId,
          pageNum: this.topicPageNum,
          pageSize: 20,
        },
      });
      this.loading = false;
      this.topicResult = topicListRes.data.records;
      this.firstTopicItem = topic;
      this.topicResult.unshift(this.firstTopicItem);
      this.totalPages = topicListRes.data.pages;
    },
    async loadmore() {
      if (this.loadingMore || this.loading || this.noMoreData) {
        return;
      }
      this.topicPageNum++;
      console.log("🌟 this.topicPageNum", this.topicPageNum);
      if (this.topicPageNum > this.totalPages) {
        return (this.noMoreData = true);
      }
      this.loadingMore = true;
      const topicListRes = await request.get("/dp/channel/list", {
        params: {
          parentId: this.topicId,
          pageNum: this.topicPageNum,
          pageSize: 20,
        },
      });
      this.loadingMore = false;
      this.topicResult = this.topicResult.concat(topicListRes.data.records);
    },
    // 搜索频段
    clickSearch(topicMessage) {
      if (!topicMessage) {
        return;
      }
      window.open(`/#/search?keyword=${topicMessage}&type=topic`);
    },
  },
  async created() {
    document.title = "频段广场 - DROPIN多频";
    this.loading = true;

    const firstTopicRes = await request.get("/dp/channel/list", {
      params: {
        parentId: this.topicId,
        pageNum: 1,
        pageSize: 20,
      },
    });
    this.firstTopic = firstTopicRes.data.records;
    this.tab = this.firstTopic[0].name;

    // 初始频段列表数据
    const topicListRes = await request.get("/dp/channel/list", {
      params: {
        parentId: this.firstTopic[0].id,
        pageSize: 20,
        pageNum: 1,
      },
    });
    this.loading = false;
    this.topicResult = topicListRes.data.records;
    this.firstTopicItem = this.firstTopic[0];
    this.topicResult.unshift(this.firstTopicItem);
    this.totalPages = topicListRes.data.pages;
  },

  // props: ["topic"],
};
</script>
<style>
.mobilePage .headLeft {
  overflow: auto;
}
</style>

<style scoped>
.mobilePage .tabSticky.topic .articleTab.topicSearch.ency {
  flex-wrap: nowrap;
  overflow: auto;
}
.mobilePage .articleTab.topicSearch.ency .tab {
  margin-left: 16px;
}
</style>